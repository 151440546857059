import { template } from "translations/templating";

export type TranslationsEN = typeof en;

const en = {
  abbreviations: {
    centimeter: {
      abbreviation: "cm",
      fullForm: "centimeter",
    },
    kilogram: {
      abbreviation: "kg",
      fullForm: "Kilogram",
    },
    kilometer: {
      abbreviation: "km",
      fullForm: "kilometer",
    },
    kilometers: {
      abbreviation: "km",
      fullForm: "kilometers",
    },
    sapv: {
      abbreviation: "SAPV",
      fullForm: "Specialized palliative ambulatory care",
    },
    sgb: {
      abbreviation: "SGB",
      fullForm: "Social security statute book",
    },
    sgbV: {
      abbreviation: "SGB V",
      fullForm: "The Fifth Book of the social security statute book",
    },
    sgbXI: {
      abbreviation: "SGB XI",
      fullForm: "The Eleventh Book of the social security statute book",
    },
  },
  accessibility: {
    skipLink: "Skip to main content",
  },
  acp: {
    accountRoleAccessTooltip:
      "If ACCESS is selected, the user has access and can log in to the partner app",
    accountRoleAdmissionsTooltip:
      "If ADMISSIONS is selected, the user has access to the Recare Admissions form settings",
    accountRoleAnalyticsTooltip:
      "If ANALYTICS is selected, the user has access to the Recare Analytics Dashboard in the partner app. ",
    accountRoleContactTooltip:
      "If CONTACT is selected, the user is displayed as a contact for the social worker in the hospital.",
    accountRoleVoiceTooltip:
      "If VOICE is selected, the user has access to the Recare Voice app",
    accountTimeline: {
      accountCreated: "Account created",
      accountDeactivated: "Account deactivated",
      accountReactivated: "Account reactivated",
      accountUpdated: "Account updated",
      activationEmailSent: "Activation email sent",
      clinicStatusChanged: "Clinic status updated",
      domainAdded: (args: { domainname: string }) =>
        template("Domain {{domainname}} was added to the whitelist", args),
      domainRemoved: (args: { domainname: string }) =>
        template("Domain {{domainname}} was removed from the whitelist", args),
      login: "Account logged in",
      passwordChange: "Password was changed",
      passwordResetSuccessfully: "Password reset successfully",
      passwordSetByAdmin: "Password set by admin",
      permissionsUpdated: (args: { resource: string }) =>
        template("Permissions updated: {{resource}}", args),
      resetPasswordRequestedByAdmin: "Reset password requested",
      resetPasswordRequestedByUser: "Reset password requested by user",
      ssoLogin: "Account has logged in via SSO",
      title: "Timeline",
    },
    acpAccountProfile: {
      activeAddons: "Active add-on features",
      activeIntegrations: "Active integrations",
      addOnRcmReport: "Recare Analytics",
      addOnRcmReportPurchased: "Purchased",
      addOnRecareAdmissions: "Recare Admissions",
      addOnRecareAdmissionsPurchased: "Purchased",
      addOnRecareVoicePurchased: "Purchased",
      addonTcare: "Transitional care",
      datesTcareTrial: (args: { fromDate: string; untilDate: string }) =>
        template("from {{fromDate}} until {{untilDate}}", args),
      integrationArchive: "Archive integration",
      integrationInformationMessage:
        "If you have an active integration, it will be displayed here. Please note that this information is for your reference only and will be automatically filled in.",
      integrationReceiver: "Receiver integration",
      integrationValue: {
        active: "active",
        inactive: "inactive",
      },
      ssoInfo: "SSO Account",
      tcarePurchased: "Purchased",
      tcareTrial: "Trial",
    },
    acpCompany: {
      ikNumberAlreadyExists: (args: { companyId: string }) =>
        template("IK number already in use for company # {{companyId}}", args),
      ikNumberAlreadyExistsError: "This IK number already exists",
      ikNumberAlreadyTaken: (args: { ikNumber: string; companyId: string }) =>
        template(
          "IK number {{ikNumber}} already in use for company #{{companyId}}",
          args,
        ),
      messageIkNumber:
        "As the provider is part of an integration the IK number cannot be altered - please contact the product team",
    },
    addAccount: "Add account",
    addAccounts: "Add accounts",
    addHospital: "Add hospital",
    addListOfStation: "Add the list of stations",
    addPriorityProvider: "Add Priority Provider",
    addProvider: "Add provider",
    alreadyHasPermission: "Account already has permissions for this entity",
    barthelIndex: {
      addNewAssessment: "Add new assessment",
      bathing: "Bathing",
      bathingValueFive:
        "Independent bathing or showering incl. entry/exit, cleaning and drying yourself",
      date: "Date",
      deplacement: "Getting up and walking",
      deplacementValueFifteen:
        "Walk from the seat to the standing position without supervision or personnel assistance and at least 50 m without walking frames (but if necessary with poles/crutches)",
      deplacementValueFive:
        "With a non-professional aid or walking frame from the seat to the standing position and cover distances in the living area. Alternatively: in the living area completely independently in a wheelchair",
      deplacementValueTen:
        "From the seat to the stand without supervision or personal assistance and walk at least 50 m with the aid of a walking carriage.",
      dialogTitle: "Barthel index",
      dressing: "Dressing and undressing",
      dressingValueFive:
        "At least dresses and undresses the upper part of the body independently within a reasonable time, provided that the utensils are within easy reach",
      dressingValueTen:
        "Takes on and takes off daily clothes, shoes (and any necessary aids, e.g. antithrombosis stockings, prostheses) independently within a reasonable period of time.",
      editHasData: "Fill in detailed form",
      editHasNoData: "See more details or add new assessment",
      fecalIncontinence: "Fecal incontinence",
      fecalIncontinenceValueFive:
        "Is on average not more than 1x/week fecal incontinence or needs help with rectal laxative measures / anus-praeter (AP) treatment",
      fecalIncontinenceValueTen:
        "Is continental in stool, if necessary independent with rectal laxative measures or AP treatment",
      fecalIncontinenceValueZero:
        "The average is more than 1x/week stool incontinent",
      fieldsMissing: "Some fields are missing",
      food: "Food",
      foodValueFive:
        "Help with bite-sized preparation, but independent taking or help with PEG feeding/supply",
      foodValueTen: "Completely independent or independent PEG feeding/supply",
      foodValueZero: "No independent intake and no MS/PEG nutrition",
      inputPlaceholder: "Type in the sum and date",
      maxAmountOfDetailsReached:
        "The maximum amount of assessments has been reached",
      personalCare: "Personal Care",
      personalCareValueFive:
        "On-site completely independent incl. tooth brushing, shaving and hairdressing",
      relocating: "Setting up and relocating",
      relocatingFive:
        "Substantial assistance (from trained non-professional or professional help)",
      relocatingValueFifteen:
        "Completely independent from lying position in (roll-)chair and back",
      relocatingValueTen:
        "Supervision or little help (from untrained non-professional)",
      relocatingZero: "Is not actually transferred from the bed",
      stairClimbing: "Stair climbing",
      stairClimbingValueFive:
        "With supervision or help from a non-professional at least one floor up and down",
      stairClimbingValueTen:
        "Climb up and down at least one Floor without supervision or personnel assistance (including poles and crutches if necessary)",
      toiletUse: "Toilet use",
      toiletUseValueFive:
        "On-site assistance or supervision required for using the toilet or toilet chair or flushing / cleaning them",
      toiletUseValueTen:
        "On site completely independent use of toilet or toilet chair incl. flushing / cleaning",
      toiletUseValueZero: "Does not actually use a toilet or a toilet chair",
      uninaryIncontinence: "Urinary incontinence",
      uninaryIncontinenceValueFive:
        "Compensates his urinary incontinence independently and with overwhelming success (on average not more than 1x/day wetting of clothing or bed linen) or needs help with the care of his urinary catheter system",
      uninaryIncontinenceValueTen:
        "Is urinary continent or compensates his urinary incontinence / provides his permanent catheter (DK) completely independently and successfully (no wetting of clothes or bed linen)",
      uninaryIncontinenceValueZero:
        "Average is more than 1x/day urinary incontinence",
      valueZero: 'Does not fulfill "5"',
    },
    batchSendActivation:
      "Send activation email to all accounts with status created",
    billing: "Billing",
    blacklistedAssessmentFields: "Blacklisted assessment fields",
    careproviderPages: {
      catchmentArea: "Catchment Area",
      completeProfile: "Complete profile",
      completeProfileAccountsWarning:
        "Once the profile is completed and saved, newly created accounts will receive an activation email to set up their password. Already existing accounts will be added to the Team of the provider. Please, make sure that the data above is correct.",
      dpaSection: {
        churchOwnedInfo: "Is church owned:",
        currentDpaVersion: "The current DPA can be found {select:here}",
        dpaUploadOnlyForChurch:
          "Please note: Uploading the DPA is only necessary for church owned providers, as we need a signed document from them - if they are not church-owned, they have to tick the checkbox in the app.",
        noDpaUploadedYet: "DPA not yet uploaded",
        signedAt: "DPA signed at:",
        uploadedFile: "Uploaded file:",
      },
      duplicatedFrom: "Duplicated from",
      nearbyClinics: "Nearby clinics and Prio-clinics",
      onboarding: {
        onboardingContact: "Onboarding Contact",
      },
      stats: {
        acceptedRequests: "Accepted requests",
        activeSince: "Active since",
        allTime: "All time",
        attributedPatients: "Attributed patients",
        messagesSent: "Messages sent",
        onboardingSource: "Source of the onboarding",
        pastDays: "Past 30 days",
        receivedRequests: "Received requests",
        requestsDeclined: "Requests declined",
        responseRate: "Response rate",
        responseTime: "Response time",
        title: "Stats",
      },
      statusForm: {
        errorActiveAccountsLinked:
          "Please remove the linked active accounts from the profile (if obsolete), or activate the profile manually (if the active accounts should still have access). Attention: Please note if the active user accounts are also linked to other profiles and should still have access (chain) and in this case activate the setup profile manually.",
        errorInvalidStatus:
          "Status Invalid is deprecated. Please use Inactive + a reason!",
        errorMissingSpecializations:
          "Please set the required specializations, before being able to change the status",
        errorNoFacilitiesNoServices:
          'Please set the required solutions and/or services, before being able to change the status. The status "Trash" or "Inactive" can still be set.',
        errorTransitionToPendingPassword:
          'Please complete the profile including accounts and click on the "Profile filled" button. The status will automatically change to "Pending Password Activation".',
        onboardingStatus: "Onboarding Status",
        reason: "Reason",
        status: "Status",
      },
      statusUpdateErrorNoAccount:
        "Cannot update provider to status active, no active accounts attached",
      tabs: {
        accounts: "Accounts",
        capacity: "Capacity",
        catchment: "Catchment Area",
        profile: "Profile",
        services: "Services",
        timeline: "Timeline",
      },
    },
    careSeekerContacts: "Contacts",
    clinics: "Clinics",
    confirmDeleteInvoice: (args: { invoiceReference: string }) =>
      template(
        "Are you sure that you want to delete the invoice {{invoiceReference}}?",
        args,
      ),
    consultants: {
      consultantName: "Consultant name",
      createSubheading: "Create consultant",
      description: "Description",
      documentation: {
        events: {
          consultantCreated: {
            title: "Consultant Created",
          },
          consultantFileCreated: {
            title: "Consultant File Uploaded",
          },
          consultantFileDeleted: {
            title: "Consultant File Deleted",
          },
          consultantUpdated: {
            description: "Following fields were updated: ",
            title: "Consultant Updated",
          },
          noEvents: "No events for this consultant",
        },
        title: "Documentation",
      },
      generalInformation: "General information",
      noRolesTooltip: "You do not have roles to edit consultants",
      openingHours: {
        description:
          'If no opening hours are set, we won\'t show any opening hours on the profile page. If at least one day is set, we will show the opening hours on the profile page. All other non-filled days will be shown as "closed".',
        errorMissingHours:
          "Opening hours can't be empty if the consultant is open on this day.",
        formattingHint: "Format: 8.00 - 18.00 or 8.00 - 12.00 & 14.00 - 18.00",
        heading: "Opening hours",
        placeholder: (args: { day: string }) =>
          template("Opening hours on {{day}}", args),
      },
      projectSponsor: "Project sponsor",
      searchPlaceholder: "Add consultant",
      subheading: "Update consultant details",
      uploadFilesCardTitle: "Consultant profile photo uploader",
    },
    copiedToClipboard: "Successfully copied to clipboard",
    copyPasteStations: "Copy paste three excel columns",
    copyReceiverAccountActivationLink: "Copy Receiver account activation link",
    copySelfonboardingLink: "Copy self-onboarding link",
    copySenderAccountActivationLink: "Copy Sender account activation link",
    createConsultant: "Create consultant",
    createReceiver: "Create receiver",
    createSender: "Create Sender",
    deactivateAccountDetails:
      "The only way to reactivate this account will be via the activation email",
    deactivateAccountTitle: "Do you really want to deactivate this account?",
    digitalPatientRecord: "Digital patient record",
    emailAlreadyLinked: "This email address is already linked to an account.",
    emailsSent: "Emails were sent",
    errorAddingRole: (args: { emailDomain: string; list: string }) =>
      template(
        'The account was added, however, due to the account email domain "{{emailDomain}}" not being listed in this Careseekers allowed domains [{{list}}], the addition of roles has been rejected.',
        args,
      ),
    errorAddingSeekerRoles: (args: { emailDomain: string }) =>
      template(
        'Cannot add roles for this Careseeker due to the email domain "{{emailDomain}}" for this account not being in the allowable email domains for this sender.',
        args,
      ),
    errorOnboardingMailNearbyClinicMissing:
      "Could not send onboarding mail, no nearby/prio clinic available",
    excelImportAccounts: "Excel import accounts",
    excelImportAccountsBadFormat:
      "The data was not entered in the correct format",
    excelImportAccountsPlaceholder: "Copy paste accounts from excel",
    excelImportAccountsRowError: (args: { row: string }) =>
      template(
        "Problem in row {{row}}. Please check on errors (email already exists etc.)",
        args,
      ),
    failedToLoadReceiverRules: "Failed to load rules",
    filterBySocialWorker: "Do not display by SW by default",
    firstLogin: "First login",
    generalInformation: "General Information",
    hospitalConfiguration: "Hospital specific configuration",
    hospitalFance: "Hospital - France",
    hospitalInformation: "Information about the hospital",
    hospitalInterests: {
      details: "More details",
      geriatrics: "Geriatrics",
      homeCare: "Home care ",
      kisIntegration: "HIS integration",
      medicalSupplies: "Medical Supplies",
      rehab: "Reha",
      title: "The hospital is interested in the following modules:",
      transportation: "Transportation",
    },
    hospitalProfile: "Hospital profile",
    identities: "Identities",
    inactiveClinics: "Inactive clinics",
    informationSystem: "Hospital information system",
    institutionIdentifier: "Institution identifier ",
    internalAccounts: "Internal Accounts",
    internalPermissions: "Internal Permissions",
    internalRemarks: "Internal remarks",
    internetBrowser: "Browser",
    invitationEmailSent: (args: { account: string }) =>
      template("Invitation email was sent to {{account}}", args),
    ipWhitelistingForLogins: "Allowed IP ranges for login",
    kisApiActivated: "KIS API activated",
    lastLogin: "Last login",
    listedInbToCApp: "We want to be listed in the B2C App",
    login: {
      prefixPreprod: "Preprod",
      prefixProd: "Production",
      titlePreprod: "ACP Preprod",
      titleProd: "ACP Production ",
    },
    missingContactsData: "Missing info has to be filled",
    noOnboardingEmail: "No onboarding email",
    notes: "Notes",
    notifyActivationSent: "Activation email was sent",
    notifyPasswordResetSent: "Forgot password email was sent",
    numberOfBeds: "Number of beds",
    onboardingEmail: "Onboarding email",
    onboardingRadius: "Onboarding Radius (km)",
    onboardingRequirements: "Onboarding requirements",
    organisationProviders:
      "Organisational bonus points for the following providers",
    pasteZipcodesButton: "Paste From Clipboard",
    pasteZipcodesTooltip:
      'In order to paste a list, copy (cmd+c) the postal codes in the correct format (e.g. 10999, 10559) and click on "Paste from clipboard"',
    permissions: "Permissions",
    priorityListActivationTarget: "Priority list activation target",
    priorityProviders: "Priority Providers",
    priorityProvidersList: {
      name: "Name",
      onboarding: "Onboarding",
      patientType: "Type of patient",
      status: "Status",
    },
    profile: "Profile",
    providerSearch: {
      enterLocation: "Please enter a location or Clinic name",
      facility: "Facility",
      manuallyAddedProviders: "See all manually added providers",
      manuallyAddedProvidersTitle: "Manually added providers",
      numberOfProvidersWithStatus: (args: {
        numberOfProviders: string;
        status: string;
      }) =>
        template(
          "There are {{numberOfProviders}} providers with  status {{status}}.",
          args,
        ),
      patientType: "patient type",
      rateOfAllProvidersInCertainAreawithFacility: (args: {
        rateOfFacilityProviders: string;
        radiusWithFacility: string;
        facilities: string;
      }) =>
        template(
          "It is {{rateOfFacilityProviders}}% of all the providers in a radius of {{radiusWithFacility}}km with facility ({{facilities}}).",
          args,
        ),
      rateOfAllProvidersInCertainAreawithService: (args: {
        rateOfServiceProvider: string;
        radiusWithService: string;
        services: string;
      }) =>
        template(
          "It is {{rateOfServiceProvider}}% of all the providers in a radius of {{radiusWithService}}km with service ({{services}}).",
          args,
        ),
      rateOfProviders: (args: {
        rateOfProviders: string;
        selectionRadius: string;
        totalDescription: string;
      }) =>
        template(
          "It is {{rateOfProviders}}% of all the providers in a radius of {{selectionRadius}}km with ({{totalDescription}}).",
          args,
        ),
      services: "Services",
      showMoreButton: "show more",
    },
    recareAccounts: "Recare Accounts",
    receiverFilters: {
      careproviderId: "Receiver ID",
      careseekerId: "Careseeker ID",
      createdAt: "Created at",
      false: "false",
      filterId: "Filter ID",
      isWhitelist: "Is whitelist rule",
      productGroup: "Product group",
      searchPartialZipcode: "Search partial zipcode",
      true: "true",
    },
    receiverRules: "Receiver rules",
    resetGroups: {
      activeFiles: "Active files",
      activePatients: "Active patients",
      activeRequests: "Active requests",
      archived: "Archived patients, requests and files",
      columnCount: "Count",
      columnLostSession: "Lost session",
      errorText:
        "Unfortunately, you do not have the rights to reset groups. Please contact one of the following accounts if you wish to reset this group:",
      menuResetGroup: "Reset encryption group",
      resetGroupsSubtitle: (args: { sessionNumber: string }) =>
        template(
          "If you reset the group, all non-shared encrypted data will be lost, so you should only do this if you have no other option. The group must regain access to {{sessionNumber}} sessions. The following table contains the number of lost sessions.",
          args,
        ),
      resetGroupsTitle: "Are you sure you want to reset the group?",
      successMessage: "Seald group successfully reset.",
      understandButton: "I understand",
    },
    satisfactionScore: {
      dateLastScore: "Last update: ",
      submitSatisfactionScore: "Submit satisfaction score",
      title: "Customer Satisfaction",
    },
    searchAccounts: "Search accounts",
    searchClinics: "Search clinics",
    searchProviders: "Search providers",
    sendActivationMail: "Send activation mail",
    senderPage: {
      bankAccountOwner: "Bank Account Owner",
      bankingInformationTitle: "Information on fee settlement",
      bankName: "Name and city of the Bank",
      billingAddress: "Street, street number, post code, city",
      hospitalGerman: "Hospital - Germany",
      hospitalNumber: "IK number of the hospital",
      iban: "IBAN",
      insurance: "Health insurance",
    },
    senderRules: "Sender rules",
    senderType: "Sender category selection",
    sendInfoEmail: "Send info email",
    sendPasswordResetEmail: "Reset Password Email",
    sendWelcomeEmail: "Activation Email",
    sessionExpirationDuration: "User session expiration (in minutes)",
    startDate: "Start date",
    stationCode: "Station",
    stationDescription: "Description",
    stationPhoneNumber: "Station phone number",
    stations: "Stations",
    test: "test",
    title: "Title",
    transferPerMonth: "Number of transfers per month",
    whitelistedHomeCareSpecializations: "Activated Home Care Specialisations",
    whitelistedHospitalSpecializations: "Activated Hospital specialisations",
    whitelistedRehaSpecializations: "Activated Rehab specialisations",
    workshopDate: "Kick Off Workshop date",
  },
  actions: {
    abort: "Abort",
    accept: "Accept",
    actions: "Actions",
    add: "Add",
    addMember: "Add member",
    addMoreInformation: "Add more information",
    addSearch: "Add search",
    applyFilters: "Apply filters",
    archiveButton: "Archive",
    back: "Back",
    backToAnswers: "Back to answers",
    backToDashboard: "Back to dashboard",
    backToSearch: "Back to search",
    book: "book",
    bookTransport: "book transport",
    cancel: "Cancel",
    chooseFile: "Choose a file",
    clear: "Clear",
    clearDate: "Clear date",
    close: "Close",
    confirm: "Confirm",
    continueSearch: "Continue search",
    create: "Create",
    deactivate: "Deactivate",
    decline: "Decline",
    delete: "Delete",
    discard: "Discard",
    dismiss: "Dismiss",
    download: "Download",
    edit: "Edit",
    editPatientProfile: "Edit patient profile",
    expand: "Expand",
    finish: "Finish",
    giveAccess: "Grant access",
    handoverInsurance: "Handover to insurance",
    import: "Import",
    invalid: "invalid",
    invalidTimeFormat: "Invalid time format",
    learnMore: "Learn more",
    minimize: "Minimize",
    missing: "Required",
    moreOptions: "More options",
    next: "Next",
    no: "No",
    notify: "Notify",
    pause: "Pause",
    print: "Print",
    printDashboard: "Print dashboard",
    refresh: "Refresh",
    reject: "Reject",
    resend: "Resend",
    resendRequest: "Resend request",
    reset: "Reset",
    review: "Review",
    revokeAccess: "Revoke access",
    save: "Save",
    saveAndExit: "Save and exit",
    saveChanges: "Save changes",
    saved: "Saved",
    saving: "Saving...",
    searchForMobile: "Search for mobile",
    seeDetails: "Detailed overview",
    select: "Select",
    selectAnotherDate: "Select another date",
    selectFileFromComputer: "Select from computer",
    selectPlaceholder: "Please select",
    send: "Send",
    shareFile: "Share",
    shareFroms: "share forms",
    showResults: "Show results",
    skip: "Skip",
    start: "Start",
    stopSearch: "Stop search",
    submit: "Submit",
    tryAgain: "Try again",
    undo: "Undo",
    undoActionCompletion:
      "We have undone the action and the request has assumed its previous state.",
    undoConfirm: "Your changes have been undone.",
    unknownId: "Patient ID unknown",
    update: "Update",
    uploadFile: "Upload",
    validate: "Validate",
    validateAndGrantAccess: "validate and grant access",
    validationError: "Some fields are missing",
    validationErrorMissingOrInvalid: "Some fields are missing or are invalid",
    valueEmtpy: "not filled yet",
    yes: "Yes",
    youCanUpload: "You can upload a file",
  },
  address: {
    address: "Address",
    alternativeSearchLocationCheckbox:
      "Patient address is not the starting point of the search",
    anotherAddress: "Another address",
    city: "City",
    clinicAddress: "Clinic address",
    country: "Country",
    enterAddress: "Please enter here the address",
    houseNumberBlockedIE:
      "As you’re using Internet Explorer, you have to share the house number with the receiver via the messenger. Please use a more up-to-date browser like Google Chrome, Mozilla, Firefox or Microsoft Edge to directly add the house number here.",
    invalidAddress: "The address you entered is not valid",
    location: "Location",
    locationOfPatient: "Treatment location",
    noNeedForAddress:
      "You only need to enter the zipcode for this type of transfer.",
    number: "House number",
    patientAddress: "Patient Address",
    pickupAddress: "Pick up address",
    pleaseAddHouseNumber:
      "Please search for an address - don't forget to add the house number.",
    street: "Street",
    streetNumber: "Street and number",
    zipcode: "Zipcode",
    zipcodePlaceHolder: "Enter zipcode",
    zipcodeSelectPlaceholder: "Please type or select one or multiple",
  },
  assessments: {
    formValidation: {
      careDurationMissing: "Please select expected care duration",
      careDurationTitle: "Expected care duration *",
      characterLimit: (args: { valueLength: string; characterLimit: string }) =>
        template(
          "value length entered ({{valueLength}}) is greater than character limit {{characterLimit}}",
          args,
        ),
      dateOfBirthMissing: "Please enter the date of birth",
      dateOfBirthTitle: "Date of birth *",
      genderMissing: "Please select gender",
      mandatoryExplanation: "* marks a mandatory field.",
      patientAddressMissing: "Please enter the patient address",
      patientAddressTitle: "Patient address *",
      publicPensionMissing: "Please select the applicable pension insurance",
      singleSelect: "Please select one of the following options *",
      singleSelectError: "Please select one option to continue.",
      specialisationMissing: "Please choose at least one specialisation.",
      specializationsMissing: "Please select a specialization",
      specializationsTitle: "Specializations *",
      startDateMissing: "Please choose a valid date in the future.",
      startDateTitle: "Start Date *",
      supplementaryInsuranceDetailsMissing:
        "Please enter the details about the supplementary insurance.",
      treatmentLocationMissing: "Please enter the treatment location",
      treatmentLocationTitle: "Treatment Location *",
      typeOfCareMissing: "Please select at least one type of care.",
      typeOfCareTitle: "Type of care *",
    },
    htohinfobox:
      "We filter recipients to contact within the search radius around this postal code. ",
    medicalSupplies: {
      deliveryAnotherAddress: "Delivery to another address",
      deliveryDetails: "Delivery address",
      deliveryHospital: "Delivery to hospital",
      deliveryInfo:
        "Please note that the desired supply is bound to the address you select here. You have already selected the expected start date. If you would like to have a supply at another time and/or at another address, you can do this in an additional search.",
      deliveryPatientsAddress: "Delivery to patients address",
      medicalSupplies: "Medical supplies & home care",
      prescription: "Prescription",
      prescriptionDoctor:
        " Name and position of the doctor issuing the prescription",
      prescriptionIssued: "Prescription has been issued",
    },
    onePageAssessment: {
      activateOnePageAssessment: "Activate one page assessment",
      additionalInformation: "Additional information",
      caution: "Use with caution",
      disclaimer:
        "This feature is in the work in progress state and shall be used for testing purposes only",
    },
    rehab: {
      singerUploadInfo:
        "You can upload the SINGER assessment as a file category in the file upload section.",
    },
    transportSearch: {
      additionalInformationPlaceholder: " E.g. special needs or requirements",
      addTransportAfterValidatedSearchExplanation:
        "A provider has been attributed. You now have the opportunity to also book the patient's transport via Recare.",
      addTransportAfterValidatedSearchTitle:
        "Would you like to book a transport for this patient?",
      endTimePlaceholder: "09:00",
      minimumTimeWindow: "Minimum 1 hour pick-up window",
      monitoringHeart: "ECG",
      monitoringNeeded: "Monitoring equipment requirements",
      monitoringPulse: "Pulse oximeter",
      onsiteSupportRequired:
        "The patient does not need any support or on-site patient care is ensured.",
      oxygenNeeded: "Does the patient require oxygen?",
      pickUpTime: "Desired pick-up time",
      searchDestination: "Destination",
      startTimePlaceholder: "08:00",
      titleTransportSearch: "Transport Search ",
      transportStyle: "Transport style",
      transportStyleCarryingChair: "Carrying chair",
      transportStyleLyingDown: "Lying down",
      transportStyleSeated: "Seated",
      transportStyleWheelchair: "Wheelchair",
      transportType: "Transport type",
      visualImpairement: "Is the patient visually impaired?",
    },
  },
  auctionRequest: {
    accept: "Accept request",
    acceptAnotherDateRequest: "Accepting patient on another date",
    acceptInfo:
      "By clicking on ACCEPT you can set the details of your offer and suggest a different start date if needed",
    acceptInfoNewChat:
      "Respond to this request (admission criteria are adjustable)",
    acceptModalTitle: "Accepting patient request",
    acceptOptionalMessagePlaceholder: "Enter your message here (optional): ",
    acceptRequest: "Accepting patient request",
    acceptTestRequest: 'Type eg. "Test" here',
    accessEncryptedDataHint:
      "The clinic needs to give you access to this private patient data",
    answerTestRequest:
      "To complete your free registration, please click on ACCEPT or REJECT. In test request, it does not matter which answer you choose.",
    arrivalTime: "Arrival time",
    automaticDeclineWithoutTime:
      "If you select this option, it will be stored in the system that you have no capacity for new patients until the selected date. This means that you will not receive any requests (except requests based on your patient searches) until this date. You can change the information about your capacity in the settings at any time.",
    automaticDeclineWithTime:
      "You will not receive any further requests and all requests up to and including the date will be rejected. You can change your capacity at any time in the settings",
    backToRequests: "Back to requests",
    bcpName: "Pflegefinder Bayern",
    bcpProfileName: "Profile",
    cancelConfirmation: "Are you sure you want to cancel this action?",
    capacityDate: "Select a date",
    capacityTime: "Select a time",
    capacityTimeOptional: "Select time (optional)",
    catchmentComment:
      "As soon as it's approved in the system, your requests will be adapted.",
    commentWhichServicesOptional:
      "Optional: Which of the services don’t you offer?",
    coronaOnlyAcceptHint:
      "Accepting this request will automatically attribute this patient to you.",
    decline: "Decline request",
    declinedReasonOther: "We decline because of other reasons",
    declinedReasonPayer: "We decline because of the payer",
    destination: "Destination",
    emptyMessageWarning: "Write a message",
    encryptedMessage: "Encrypted message",
    entryDate: "Date",
    entryDateTimeExplainer: "Please select the possible start date and time",
    entryDateTimeExplainerOptional:
      "Please select the possible start date and time (optional)",
    errorAlert: {
      text: "Failed to answer the request",
      title: "Oops, something went wrong",
    },
    explainOtherReason: "Please explain",
    explainOtherReasonOptional: "Additional comments (optional)",
    explainWhyYouDecline:
      "You're about to decline the request, please tell us why",
    fakeData: "FAKE DATA",
    geographicallyUnattractive: "We don't operate in the desired area",
    germanyWideSearchWarning:
      "This is a Germany-wide search, which is why you have received this request even though it may be outside your catchment area.",
    hospitalName: "Clinic name",
    lastWorkedOn: (args: { time: string; personResponsible: string }) =>
      template("Last worked on: {{time}}, {{personResponsible}}", args),
    leaveAComment: "Please leave your message here:",
    loadingError: "Could not access request",
    mapCaption:
      "The location of the patient is inside the blue radius. The destination is inside the yellow radius.",
    messengerTab: "Messages",
    mostImportantTransferDetails: "Most important transfer details",
    noAdditionalInformationProvided: "No additional information was provided.",
    noCapacity: "We don't have capacity",
    noCapacityExplainer:
      "Recare does not want you to do any unnecessary work.\nPlease let us know when you will be able to accept new patients again:",
    noCapacityForPatient:
      "We don't have capacity for this patient. We want to continue receiving requests.",
    noCapacityForPatientReason: "We currently don’t offer the needed services",
    noCapacityHint: 'You can update this at any time in your "Settings".',
    noCapacityOther: "Other",
    noCapacityReason: "We do not have capacity for new patients at this time",
    noCapacityReasonExplainer:
      "When will you be able to accept new patients again?",
    noGermsInfections: "The patient has currently no infections/germs.",
    offeringMultipleSolutions:
      "This provider offered multiple solutions. Please select one that best responds to the patient's needs.",
    onSitePatientCareEnsured:
      "The patient does not need any support or on-site patient care is ensured.",
    other: "Other",
    pageUnavailable: "This page is not available anymore",
    patientAdmissionDetails: "Patient admission details",
    patientInfoTab: "Patient",
    patientNoLongerAvailable:
      "The patient is no longer available which is why the request can not be answered.",
    patientUpdatedExplanation:
      "The patient profile has been updated. Newly added information is marked green, deleted information is marked red.",
    pickUpAddress: "Pick-up address",
    pickUpInformation: "Pick-up information",
    pickuptime: " Desired pick-up time",
    placeholderTimeField: "hh:mm",
    pleaseExplainOptional: "Please explain (optional)",
    pleaseLogin: "Please log in",
    pleasePickAReason: "Please pick a reason",
    preferredSelection: (args: { receiverTypeSingularPreferred: string }) =>
      template(
        "You have been selected as {{receiverTypeSingularPreferred}}",
        args,
      ),
    preWaitingClinicAnswer: "Please write your message",
    providerNotes: {
      add: "ADD",
      typeHere: "Type any notes you have about the patient here...",
    },
    rehabSpecific: {
      acceptModalTitle: "Reservation",
      acceptRequest: "Reserve",
    },
    requestAccepted:
      "You've accepted the request. The request can still be found under the pending tab.",
    requestAcceptedBedCapacity:
      "You've accepted the request and will get the patient. Your bed capacity was reduced by 1.",
    requestAcceptedInProgress:
      "You've accepted the request. The request can still be found under the in progress tab.",
    requestAnswered: (args: { socialWorker: string }) =>
      template("Your message was sent to {{socialWorker}}.", args),
    requestAutoValidated:
      "The patient was attributed to you. You can find the request in the Attributed tab.",
    requestDeclinedReasonNoCapacity: (args: { date: string }) =>
      template(
        "You've declined this request. We will send you new requests from {{date}}.",
        args,
      ),
    requestDeclinedReasonOther:
      "You've declined this request, you can still find it in Declined tab.",
    requestDeclinedReasonOtherTab:
      "You've declined this request, you can still find it in other tab.",
    requestDeclinedReasonUnattractiveLocation:
      "You've declined this request. Your catchment area has been updated.",
    reservationFeature: {
      date: "Date",
      expiredEvent: "Reservation expired",
      extendCTA: "Extend reservation time +24h",
      reservationExtended: "Reservation time was extended",
      reservedEvent: (args: { expirationDate: string }) =>
        template("Spot reserved until {{expirationDate}}", args),
      time: "Time",
      title: "Spot reserved until",
      tooltip:
        "If the clinic does not answer until the given date, the acceptance will be cancelled automatically.",
    },
    reserveInfo:
      "By clicking on RESERVE you can set the details of your offer and suggest a different start date if needed",
    selectDeclineReason: "Select your decline reason",
    selectFile: "Select file",
    sendMessage: "Send message",
    testPendingRequestTitle:
      "Please answer this TEST request. No matter what you select, nothing will be sent to the clinic. The only purpose is to complete your registration :)",
    testPendingRequestTitleGeriatrics: "Please answer this test request.",
    testRequestHeader:
      "This is just a test example from Recare Solutions to finalize your registration",
    testRequestHint:
      "For real requests, you will be able exchange messages before accepting or rejecting.",
    time: " ",
    tooltipSentByCostpayer: "The request was sent by cost payer",
    transportDate: "Transport date",
    treatmentWithin: "We treat patients within",
    treatWithin: "We treat patients: ",
    tryAgain: "An error occurred. Please try again or contact Recare.",
    typeMessageHere: "Type your message here",
    typeMessageHereOptional: "Type your message here (optional)",
    typeMessageNotPossible: "Message exchange not possible",
    typeOfTransfer: "Type of transfer",
    undoAlert: "Are you sure you want to undo this action?",
    uploadAFile: "Please upload here your file:",
    uploadDelete: "Delete file",
    uploadError: "An error occurred - please try again.",
    uploadInProgress: "Your file is being uploaded...",
    uploadOk: "Your file has been successfully uploaded.",
    validateProvider: (args: { providerName: string }) =>
      template("You're about to validate {{providerName}}", args),
    validationDateChangeCare: (args: { date: string }) =>
      template(
        "This provider can only accept the patient on {{date}}. If you validate this provider, expected start date will update accordingly.",
        args,
      ),
    validationDateChangeRehab: (args: { date: string }) =>
      template(
        "This clinic can only accept the patient on {{date}}. If you validate this provider, expected start date will update accordingly.",
        args,
      ),
    waitingClinicAnswer: "Your message has been sent to the clinic",
    waitingClinicAnswerCaption:
      "If your issue is solved, you can accept or decline the request",
    youMustLogin:
      "You need to log in to see encrypted messaged or be able to send new ones",
    youMustLoginToAccept:
      "You need to log in to be able to accept a request or ask a question",
    youPreferredSelection: {
      care: "You were selected as a preferred provider",
      geriatrics: "You were selected as a preferred clinic",
      valdidateInfo: "Please respond to the careprovider",
    },
    yourComment: "Your message",
  },
  auctionResponse: {
    accessDenied:
      "You do not have access to this data. Please contact Recare for further information.",
    accessDeniedError:
      "An error occured. Recare was informed about it and will look at it.",
    accessDeniedPending:
      "Currently this content cannot be displayed. In order to see the message history, one of the following people has to log in to Recare:",
    addressingNeeds: "Services addressing this patient’s needs",
    answerQuestion: "Answer question",
    areYouSureNotAddInfo:
      "Are you sure you don't want to add more information?",
    areYouSureResendRequest: "Are you sure you want to resend this request?",
    decline: "Reject this careprovider",
    declined: "This provider declined",
    declinedReasonNoCapacity: (args: { date: string }) =>
      template("We only take patients from {{date}} onwards", args),
    declinedReasonNoCapacityForPatient:
      "We currently have no capacity for this patient",
    declinedReasonNoCapacityForPatientUntilDate: (args: { date: string }) =>
      template(
        "We currently have no capacity for this patient until {{date}}",
        args,
      ),
    declinedReasonNoCapacityNoDate: "We don't have free spots",
    declinedReasonOther: (args: { reasonDescription: string }) =>
      template("Declined Reason: {{reasonDescription}}", args),
    declinedReasonPayer: "We decline because of the payer",
    declinedReasonUnattractiveLocation:
      "The requested location is not part of our catchment area",
    feedbackExplainer:
      "Please, note: this feedback will only be forwarded internally to Recare and will not be displayed to the provider.",
    feedbackReceived: "Thank you, we received your feedback!",
    fileName: "File name",
    furtherServices: "Further services",
    genderOfCaregiver: "Gender of caregivers",
    giveAccess: "Grant Access",
    giveAccessEncryptedData: "Grant access to patient data",
    giveProviderFeedback: "Give us feedback on this provider!",
    internalNotes: "Internal notes",
    noCapacityReasons: {
      femaleRoom: "Female room",
      maleRoom: "Male room",
      noCapacityFor: "No capacity for",
      noCapacityForA: "No capacity for a",
      reasonCancelDeclination:
        "Automatic message: We now have capacity again and will take patients again.",
      treatmentOfPatientsWith: "Admission of patients with",
      treatmentOfPatientsWithCarelevel: "Admission of patients with care level",
      treatmentOfPatientsWithoutCarelevel:
        "Admission of patients without care level",
      weDontTakePatientsWithCarelevel: "We don't take patients with carelevel",
      weDontTakePatientsWithoutCarelevel:
        "We don't take patients without a carelevel",
    },
    notEncryptedTooltip:
      "Refrain from sending any sensitive data. No messages are encrypted.",
    offeringSolutions: "You are offering the following solution(s):",
    otherSpecialisations: "Further specialisations",
    possibleEntryDate: (args: { date: string }) =>
      template("Possible entry date: {{date}}", args),
    possibleEntryDateInfo: "Possible entry date: ",
    possibleEntryTime: "Possible entry time: ",
    preDeclined: (args: { providerName: string }) =>
      template("You're about to reject {{providerName}}", args),
    preDeclinedDocumentationSubtitle:
      "Add rejection reasons to your Documentation:",
    preDeclinedHint: "Leave message (optional)",
    preDeclinedMessageSubtitle:
      "Share your rejection reasons with the provider:",
    preDeclinedPlaceholder: "Type rejection reasons (optional)",
    preDeclinedPlaceholderLabel:
      "Share your rejection reasons with the provider (optional):",
    preDismissHint: "Type your reasons here",
    preDismissTitle: "You’re about to dismiss the preferred clinic",
    preSendRequestHint: "Type a note (optional)",
    preSendRequestTitle: "Here, you can comment on your decision",
    preValidate: (args: { providerName: string }) =>
      template("You're about to validate {{providerName}}", args),
    preValidateHint: "Leave message (optional)",
    preWaitingProviderAnswerHint: "Type your message here",
    providerProfile: "Provider profile",
    recipientHint: "You are messaging",
    requestReservedAnotherProvider:
      "Request has been reserved by another receiver",
    reviewSuggestionTitle:
      "Review this suggestion and decide if the request should be sent to provider",
    roomType: "Room type",
    send: "Send",
    sendEncryptedMessage: "Send encrypted message",
    sendEncryptedMessageHint:
      "Sensitive patient data can be securely exchanged. All messages are encrypted.",
    sendEncryptedMessageTitle: "You can send a message",
    sendFaxHint:
      "Provider not active on Recare, but we will try to send a fax to this receiver if the fax number is available to us.",
    sendFaxTitle: "A fax will be sent to this provider",
    sendFeedback: "Send to Recare",
    sendFile: "Send File",
    sendFileTitle: "You can send a file",
    sendMessage: "Send message",
    sendMessageHint: "Type your message here",
    sendMessageTitle: "You can send a message before accepting or rejecting",
    spokenLanguages: "Spoken languages",
    startDate: "Possible start date",
    toggleShowMap: "Show map",
    toggleShowPhotos: "Show photos",
    toggleShowPictures: "Show pictures",
    tooltipDisabledValidate:
      "In order to validate this Receiver, please first review all the Receivers suggested by the clinic",
    waitingClinicAnswer: "More information was requested. Please respond:",
    waitingForReview: "Waiting for the insurance to review this request",
    warningMessage: (args: { providerName: string }) =>
      template(
        "Are you sure you want to grant {{providerName}} access to the following private patient data?",
        args,
      ),
    writeFeedbackTitle:
      "Please, write down your feedback here about this provider:",
  },
  bToC: {
    disclaimer:
      "All information is provided by the provider or the consultant itself. Recare, as the provider of the Weser Ems Portal, does not guarantee the accuracy of the information.",
    emailAddress: "info@recaresolutions.com",
    emailReportProvider: "Report provider",
    filterDrawer: {
      age: "Age of the person to be cared for",
      ageRangePlaceholder: "Select age range",
      capacityFilterTitle: "Availability",
      chipShortTermStaticCare: "Short Term Static Care",
      chipStaticCare: "Full Static Care",
      furtherFilters: "Further filters",
      intensiveCare: "Intensive care",
      palliativeCare: "Palliative Care",
      psychiatricCare: "Psychiatric Care",
      searchRadius: "Radius",
      searchRadiusPlaceholder: "Type zipcode or city",
      serviceMobile: "Services",
      shortTermsStaticCare:
        "Short term care in a home (Short term static care)",
      showFreeCapacity: "Show only providers with free capacity",
      showResults: "Show results",
      solution: "Solution",
      solutionMobile: "Mobile solutions",
      solutionStatic: "Static solution",
      startDate: "Start of care",
      startDatePlaceholder: "Select start date",
      staticCare: "Longterm care in a home (Full Static Care)",
      titleFilters: "Filters",
      treatmentLocation: " Location",
    },
    footer: {
      aboutRecare: "About Recare",
      imprint: "Imprint",
      privacyPolicy: "Privacy Policy",
      support: "Support",
      website: "Website",
    },
    getStartedButton: "Get Started!",
    linkToSPN:
      "No providers with capacity? Contact your  {select:Senioren- oder Pflegestützpunkt} for further advice on how to find them!",
    navbar: {
      title: "Pflegeportal Weser-Ems",
    },
    noResultsWithState: (args: { state: string }) =>
      template(
        "No results found. Please enter a valid postcode or location within {{state}} for your search",
        args,
      ),
    onboardingScreens: {
      buttonAsap: "ASAP",
      buttonAtHome: "At home",
      buttonBasicCare: "Basic care",
      buttonBasicMedicalCare: "Basic medical care",
      buttonCareHome: "In a care home",
      buttonDailyLivingSupportServices: "Daily living support services",
      buttonDontKnow: "I don’t know yet",
      buttonForMyself: "For myself",
      buttonForSomeoneElse: "Someone else",
      buttonHelpAtHome: "Help at home",
      buttonInDays: "In a few days",
      buttonInMonths: "In a few months",
      buttonInWeeks: "In a few weeks",
      buttonLongterm: "Longterm",
      buttonSkip: "Skip",
      buttonTemporary: "Temporary",
      dailyLivingSupportServices: {
        pageContent: {
          bannerInformation:
            "If so, please contact your {select:senior citizens and care support centre}, your care insurance fund or use the search function of the care insurance funds yourself.",
          bannerSubtitle: "Search offers from the care insurance funds:",
          bannerTitle:
            "Are you looking for offers to support you in everyday life?",
          bulletEight: "Services of the offers for support in everyday life",
          bulletFive: "Day and night care",
          bulletFour:
            "Domestic services in the immediate vicinity of the person in need of care",
          bulletOne:
            "Care and supervision (in individual or group supervision)",
          bulletSeven:
            "Services provided by outpatient services within the meaning of Section 36 SGB XI",
          bulletSix: "Short-term care",
          bulletThree: "Care support for relatives",
          bulletTwo: "Everyday support for those in need of care",
          hintText:
            "Note: You are leaving the page of the Weser-Ems care portal and are being redirected to an external page.",
          linkAok: "AOK Care Navigator",
          linkBkk: "BKK PflegeFinder",
          linkVdek: "vdek-Pflegelotse",
          paragraphFive:
            "For more information, please contact the {select:Lower Saxony Ministry for Social Affairs, Labour, Health and Equality}.",
          paragraphFour:
            "All people who have been diagnosed with a need for care and who still live at home are entitled to a relief contribution of 125 euros per month in accordance with § 45 b SGB XI. The relief contribution can be used for:",
          paragraphOne:
            "If a person is determined to be in need of care in accordance with the German Social Security Code XI, it is no longer guaranteed that the person in need of care will be able to care for themselves completely. ",
          paragraphThree:
            "The offers for support in everyday life (AZUA) can be a building block in the care of the person in need of care and his/her relatives in order to provide support in everyday life. However, everyday support services are not care services. Offers for support in everyday life can be, for example:",
          paragraphTwo:
            "The person in need of care requires assistance from, for example, family members, close relatives or the outpatient care service in everyday life in order to be able to remain in their own home.",
        },
        title: "Daily living support services",
      },
      hintZipcodeOrCity: "Zipcode or city",
      infoMobileCare: {
        infoBasicCare:
          "Includes activities such as shopping, cooking meals, cleaning the home, washing dishes and changing and washing clothes.",
        infoBasicMedicalCare:
          "Includes all activities that are carried out on a doctor's prescription. This includes, for example, wound care, changing bandages, administering medication or measuring blood pressure and blood sugar.\n\n",
        infoDailyLivingSupportServices:
          "Includes support and relief for those in need of care and relatives in coping with everyday life. The types of services offered include individual care, group care, relief for carers, relief in everyday life through individual assistance, relief in everyday household management.",
        infoHelpAtHome:
          "Includes activities such as shopping, cooking meals, cleaning the home, washing dishes and changing and washing clothes.",
        title: "Which care type includes which services?",
      },
      placeholderZipcodeOrCity: "Type zipcode or city",
      subtitleWelcome:
        "We will guide you through the next steps to find suitable care home for you or your loved ones.",
      titleForWhom: "For whom are you looking for a care home?",
      titleLoadingScreen: "You’re all set! We’re generating the results...",
      titleTemporaryOrLongterm:
        "Are you looking for a temporary or a longterm care home?",
      titleWelcomeWeserEms:
        "Welcome to the care portal of the Weser-Ems Region",
      titleWhatMobileCare: "What kind of care do you need?",
      titleWhenStartDate: "When are you looking for a spot?",
      titleWhereToSearch: "Where are you looking for care or support?",
      titleWhichCare: "Where should the care take place?",
      yourChoice: "Your choice",
    },
    preprodLogin: "Login",
    providerDetailPage: {
      additionalMaterials: "Additional materials",
      address: "Address",
      capacityavailable: "Capacity available",
      capacityOnRequest: "Capacity on Request",
      contactDetails: "Contact details",
      fax: "Fax:",
      hasCapacity: "Has capacity for new patients",
      lastUpdated: "Last updated:",
      noCapacity: "No capacity for new patients until: ",
      noCapacityavailable: "No Capacity",
      noCapacityShort: "No capacity",
      noCapacityUpdateYet: "Last updated: not specified",
      reportProvider: "Report this provider",
      services: "Services",
      telephone: "Tel:",
      tooltipCapacity:
        "This information may differ from the current capacity of this provider. Please, contact them for more details.",
      unknownCapacity: "No info about capacity for new patients available",
      watermarkTextExampleImage: "Example Image",
    },
    providerList: {
      away: "away",
      ctaNudgeGotIt: "Got it",
      errorBadFilters:
        "You are using an old version of the app, which does not support all functionality. Please upgrade the app",
      filterChipFreeCapacity: "Free capacity",
      filtersButton: "Filters",
      hintNudge:
        "Here you can further refine the list of providers by setting the filters.",
      infoNoResults: "No results",
      showMoreButton: "Show more",
      solutions: {
        helpAtHome: "Help at home",
        mobileCare: "Mobile Care",
        shortTermStaticCare: "Short term static care",
        staticCare: "Static Care",
      },
      title: "List of providers",
      titleWithCount: (args: { count: string }) =>
        template("List of providers ({{count}})", args),
    },
    reset: {
      buttonResetResults: "Reset results",
      cancelButton: "Cancel",
      resetButton: "Reset",
      subtitleModal:
        "The current provider list will be lost. You will go through the steps to set up the filters anew.",
      titleModal: "Are you sure you want to reset the results?",
    },
  },
  capacityDetailPage: {
    bedsAvailableTotal: (args: { availableBedsOfProvider: string }) =>
      template("{{availableBedsOfProvider}} bed/s available", args),
    unknownTooltip:
      "The information is not up to date. Despite that, your requests will still be sent to this provider. Please, contact them directly to find out about their capacity.",
  },
  capacitySearch: {
    clearFilters: "Clear all",
    onlyAvailableProviders:
      "Only display providers with available beds who fit the selected criteria",
    placeholderDefaultSearch: (args: {
      totalCountOfProviders: string;
      totalAmountOfBeds: string;
    }) =>
      template(
        "There are {{totalCountOfProviders}} provider/s on the platform - {{totalAmountOfBeds}} bed/s",
        args,
      ),
    placeholderModule: "Select module/s",
    placeholderNoResults: "No results",
    placeholderRadius: "Type search radius",
    placeholderServices: "Select service/s",
    placeholderZipcode: "Type zipcode",
    table: {
      availableBeds: (args: { availableBedsOfProvider: string }) =>
        template("Beds available ({select:{{availableBedsOfProvider}}})", args),
      bedsAvailable: (args: { availableBedsOfProvider: string }) =>
        template("Beds available: {select:{{availableBedsOfProvider}}}", args),
      distance: "Distance",
      lastUpdate: "Last update",
      module: "Module",
      moreDetails: "More details",
      provider: (args: { amountOfFilteredProviders: string }) =>
        template("Provider ({select:{{amountOfFilteredProviders}}})", args),
    },
    title: "Find providers nearby using the search tool below",
    unknownBedcount: "Unknown",
  },
  careprovider: {
    createCareprovider: "Add a receiver in our system",
    createProviderError: "error occurred",
    providerNotAvailable: "Provider not available",
  },
  careproviderAccount: {
    centralEmail:
      "Would you like to receive requests to a central booking address?\nThen please click on {select:Email preferences}.",
    changePassword: "Change password",
    hintPersonalNotification:
      "Do you want to receive requests to this address? {select:Click here} to set your preferences.",
    hintWeightSetting:
      "You might also receive requests for patients that weigh up to 9 kg more than the weight limit you are choosing.",
    receiveDailyDigest:
      "Only send one requests summary per day (no other mails)",
    receiveRequests: "Receive for each request an email",
    receiveRequestsSMS: "Receive for each request a SMS",
    saved: "Information has been saved",
    title: "My account",
    titlePersonalDetails: "Personal details",
  },
  careproviderApp: {
    accountMigration: {
      addMembers: "Add members",
      addMembersBanner:
        "Engage your colleagues to manage patient requests easily together.",
      completeRegistration:
        "Please check your inbox and follow the instruction to complete your registration.",
      completeRegistrationInfo:
        "In case you did not receive anything or if provided email address is incorrect, contact us at +49 30 56 79 60 57.",
      createAccountBanner:
        "From 01.02.2020 onwards Recare can only be used with a personal user account - create your free account now!",
      createAccountModal:
        "Create your Recare account and get full access for free!",
      createAccountSubtitle:
        "From 01.02.2020 onwards you can only use Recare with a personal account.",
      finishSignupBanner:
        "Almost done! To complete your registration follow the instructions in the email. No email received? Contact us at +49 30 56 79 60 57.",
      finishSignupModal: {
        subtitle:
          'To access Recommendations, complete your registration following the instructions in the email titled "Willkommen bei Recare". No email received? Contact us at +49 30 56 79 60 57.',
        subtitleReminder:
          'To use Recare, you have to complete your registration following the email titled "Willkommen bei Recare". No email received? Contact us at +49 30 56 79 60 57.',
        title: "Almost done!",
      },
      inviteContactsPopup: {
        footer:
          "After adding colleagues, they will receive an invitation via email to set up their account.",
        invite: "Invite",
        skip: "Skip",
        subtitle:
          "Engage your colleagues to manage patient requests easily together.",
        title: "Invite your colleagues!",
      },
      resendSignupEmail: "Resend mail",
      signUp: "Sign up",
      signupEmailResent: "The email has been successfully sent!",
      signupPopup: {
        administration: {
          title: "Account administration:",
        },
        benefitTitle: "Your benefits of a personal Recare account",
        contactUs: "If you have questions contact us at +49 30 56 79 60 57.",
        encryption: {
          text: "e.g. exchange of patient data, unlimited message and file transfer",
          title: "Future end-to-end encryption of transmitted data:",
        },
        recommendations: {
          text: "proactively state your capacity on additional patient profiles that match your range of services",
          title: "Recommendations:",
        },
        selectOrTypeEmail: "Select or type your email address to upgrade",
        upgrade: "Upgrade",
      },
    },
    archive: {
      title: "Archived requests",
    },
    areYouSureArchiveRequests:
      "Are you sure you want to archive the selected requests?",
    areYouSuredeclineAllRequests:
      "Are you sure you want to decline the selected requests?",
    attributed: {
      title: "Attributions",
    },
    bookmark: {
      removeBookmark: "Remove from priority list",
      removeBookmarkToast:
        "This request has been removed from the priority list.",
      setBookmark: "Set as priority",
      setBookmarkToast:
        "This request has been set as priority. It can now be viewed on top of your list in the dashboard.",
    },
    capacityManagement: {
      addBed: "Add bed",
      bannerCapacity: (args: { availableBedsOfProvider: string }) =>
        template(
          "You have {{availableBedsOfProvider}} bed/s available. Please update your capacities to ensure efficient transfers.",
          args,
        ),
      bedCount: (args: { numberOfBeds: string }) =>
        template("You offer {{numberOfBeds}} bed/s in total", args),
      bedCountPerSpecialization: (args: {
        amountOfBedsPerSpecialization: string;
      }) => template("{{amountOfBedsPerSpecialization}} bed/s available", args),
      capacityAvailable: "You have capacity for new patients",
      columnNumberOfBeds: "Number of beds",
      columnServices: "Services",
      declinePanelBanner:
        "Currently, you keep receiving new requests. Update your capacity on a regular basis.",
      hintTextAddAnotherBed: "Add another bed with same characteristics",
      hintTextRemoveBed: "Click here to remove the bed",
      infoCommentSection:
        "Help institutions find out more about your capacities and modalities for Coronavirus (COVID-19) patients. Your information will be displayed for all relevant institutions.",
      modalBedSettingsTitle: "What services do you offer?",
      noCapacity: "You don't have capacity for new patients",
      placeholderTextComment: "Additional information",
      serviceIntensiveCare: "Isolation",
      serviceVentilation: "Ventilation",
      snoozeFeature: {
        countdownTimer: "Capacity is valid until",
        duration: "Capacity duration",
        hours: "hour/s",
        lastUpdatedDate: "Last updated",
        snoozeTooltip:
          "After time runs out, you will receive new requests. Click update or add/remove beds to trigger the countdown again",
        toastUpdate: "Your changes have been updated.",
        validUntilDate: "Valid until",
      },
      toastCommentSaved: "Your information has been successfully saved.",
      updateCapacity:
        "Because of your capacity, you will continue receiving new requests. We recommend updating the capacity regularly.",
    },
    commentOnProfile: {
      missingInformation: "You need more information about the patient?",
      modalCaption:
        "Your suggestions will be sent directly to Recare and considered carefully. Please, point out what useful information is missing, so that we can improve it in the future.",
      modalPlaceholder: "Type what’s missing",
      modalTitle: "What kind of patient information are you missing?",
      subtitleInfo:
        "Your comment will be sent to the hospital. They should provide you with the missing details via Messenger. Thank you for helping us improve!",
    },
    continueToNext: "Continue to next request",
    coronaEligibilityBanner:
      "Due to the Coronavirus (COVID-19), rehab transfers don’t need any approval from the public health insurances until 31.05.2020. You can find more information {select: here}. Nevertheless, please make sure to still send the completed rehab forms to the appropriate cost payer.",
    coronaEligibilityBannerEnd:
      ". Nevertheless, please make sure to still send the completed rehab forms to the appropriate cost payer.",
    coronaEligibilityLink: "here",
    dashboard: {
      all: "All",
      archived: "Archived",
      archiveSelected: (args: { selectedRequests: string }) =>
        template("Archive ({{selectedRequests}})", args),
      archiveWithCount: "Archive",
      attributed: "Attributed",
      attributedHint:
        "Please note, attributed requests older than 30 days can be found under the archived tab.",
      attributedHintOtherTab:
        "Please note, attributed requests older than 30 days can be found under the other tab.",
      bcpInformation:
        "You are listed on Pflegefinder Bayern and therefore get requests from citizens directly.",
      bcptab: "Direct requests",
      bulkFilter: {
        allRequests: (args: { allRequests: string }) =>
          template("All ({{allRequests}})", args),
        none: "None",
        visibleRequests: (args: { visibleRequests: string }) =>
          template("Visible requests ({{visibleRequests}})", args),
      },
      capacityBanners: {
        canAccept:
          "Based on your capacity settings, you can accept the following patients.",
        canNotAccept:
          "Based on your capacity settings, you cannot accept any of the following patients.",
        declineAll: (args: { qtdRequests: string }) =>
          template("Decline all ({{qtdRequests}})", args),
      },
      capacityHint: "Capacity currently available",
      capacityNotification: {
        noButton: "No",
        subtitleAvailable:
          "You have currently indicated free capacities. Is that correct?",
        subtitleNotAvailable:
          "You have not indicated any free capacities at the moment. Is that correct?",
        title: "Is your capacity up to date?",
        yesButton: "Yes",
      },
      cards: {
        attributed: "Patient attributed to you",
        careLevelOnCards: "Carelevel:",
        declined: "Declined",
        lastWorkedOn: "Last worked on:",
        noRequests: "No requests",
        pending: "Pending",
        sentByCostpayer: "Sent by cost payer",
        transitionalCare: "with transitional care",
        unavailable: "Unavailable",
        youAccepted: "You accepted",
        youDeclined: "You declined",
      },
      checkOurRecommendations: "Check our recommendations",
      clearAllFilters: "Clear all",
      columns: {
        patient: "Patient",
        received: "Received",
        referring: "Referrer",
        transfer: "Transfer",
      },
      declined: "Declined",
      declinedHint:
        "Please note, declined requests older than 10 days can be found under the archived tab.",
      declineSelected: (args: { selectedRequests: string }) =>
        template("Decline ({{selectedRequests}})", args),
      inProgressTab: "In progress",
      linkBcp: "Click here to open Pflegefinder Bayern in a new window",
      markAsSeen: "Mark as seen",
      newTab: "New",
      noCapacityHint: (args: { capacityDate: string }) =>
        template("Capacity available again from {{capacityDate}}", args),
      noRequestFilterResults: "There is nothing to display",
      noRequestsForCapacity: "No pending requests you have capacity for?",
      noRequestsMessage: {
        all: "You have no requests yet",
        archived: "There are no archived requests",
        attributed: "You have no attributed requests yet",
        clinicNameBcp: "Pflegefinder Bayern",
        declined: "You have no declined requests yet.",
        direct: "You have no new direct requests.",
        inProgress: "You have no requests in progress.",
        new: "You have no new requests.",
        other: "You have no other requests yet.",
        pending: "You have no pending requests yet",
        unavailable: "There are no unavailable requests",
      },
      otherTab: "Other",
      pending: "Pending",
      referrerDirect: "Direct request",
      toastArchiveSuccess:
        "Request/s have been archived. You can find them in the other tab.",
      toastBulkDeclineSuccess:
        "Request/s have been declined. You can find them in the other tab.",
      toastBulkSeenSuccess:
        "Request/s have been marked as seen. You can find them in the other tab.",
      unavailable: "Unavailable",
      unavailableHint:
        "Please note, unavailable requests older than 10 days can be found under the archived tab.",
    },
    inactiveProfile: {
      titleBottom: "Please call Recare under +49 30 56 79 60 57‬.",
      titleTop: "is currently not active.",
    },
    menu: {
      archive: "Archive",
      capacityCorona: "Capacities",
      recommendations: "Recommendations",
      requests: "Requests",
      search: "Search",
      settings: "Settings",
    },
    navBar: {
      dashboard: "Dashboard",
      reverseSearch: "Search",
    },
    notifications: {
      markAllAsRead: "Mark all as read",
      noNotifications: "There are no notifications",
      notificationsDisabledHint:
        "You disabled the notifications. Please enable them in your phone settings.",
      specialization: "Specialisation",
      title: "Recent notifications",
      type: "Solution",
    },
    patientDataImport: {
      importModalHeadline: "Importing patient data from HIS",
    },
    patientShopping: {
      empty: "Currently there are no recommendations available.",
      seeRecommendations: "See recommendations",
    },
    requestsAfterCapacityDate: {
      plural: (args: { date: string }) =>
        template("Requests after the {{date}}", args),
      singular: (args: { date: string }) =>
        template("Request after the {{date}}", args),
    },
    requestUnavailable: "Request unavailable",
    requestUnavailableContinueToNext:
      "Unfortunately, this request is no longer available. Would you like to continue to the next available request?",
    responsiblePerson: {
      modalEncryptionWarning:
        "Refrain from sending any sensitive patient data. No messages are encrypted in this case.",
      modalPlaceholder: "Your message to the selected person",
      modalTitle: (args: { assignedName: string }) =>
        template(
          "New responsible person has been saved successfully. Do you want to notify {{assignedName}} about the assignment via email?",
          args,
        ),
      titleResponsiblePerson: "Person responsible:",
      tooltipTitle: "Assignee:",
    },
    search: {
      noEntry: "At the moment you have no requests",
    },
    settings: {
      accounts: {
        activateAccount: "Activate this account",
        activateAccountToast:
          "You have changed the status for this account. We sent an email to this address with an activation link.",
        addMemberModalActiveEmailError: "This email is already active.",
        addMemberModalCentralEmailError:
          "This email address is already used as a central booking address.",
        addMemberModalEmailAlreadyInvited:
          "This email address has already received an invitation. You can send one again.",
        addMemberModalEmailDontEnterInfo:
          "The central email address (ie. info@ or contact@) should not be entered here, but the personal email address for an account.",
        addMemberModalSend: "Send",
        addMemberModalTitle: "Send invitation email",
        columnMemberDetails: "Member details",
        columnStatus: "Status",
        deactivateAccount: "Deactivate this account",
        deactivateAccountToast: "You successfully deactivated this account.",
        deactivateMemberModalTitle:
          "Are you sure you want to deactivate this member? ",
        deleteMemberModalTitle: "Are you sure you want to delete this member?",
        editModalTitle:
          "You are about to edit information about this team member",
        editTooltip: "Edit information about this team member",
        hintTextRegisteredMembers:
          "Only registered members can exchange secured messages with the clinic. After adding a member, he will receive an invitation via email to set up his account.",
        membersCardAddMember: "Add a member",
        membersCardCaption1:
          "Only registered members can exchange secured messages with the clinic.",
        membersCardCaption2:
          "After adding a member, he will receive an invitation via email to set up his account.",
        membersCardEmail: "Email address",
        membersCardName: "Name",
        membersCardSendAgain: "Resend",
        membersCardStatus: "Status",
        membersCardStatusActive: "Active",
        membersCardStatusInactive: "Inactive",
        membersCardStatusSent: "Invitation sent",
        membersCardTitle: "Registered members in your team",
        receivedInvitationToast: (args: { email: string }) =>
          template("{{email}} has received an invitation to register.", args),
        resendActivationMail: "Resend Activation Mail",
        roleClinic:
          "This employee should be shown to the clinic as a contact person",
        teamTitle: "Registered members",
        title: "Accounts",
        youMarker: "(you)",
      },
      bottomSectionAriaLabel: "Other navigation options",
      browserNotifications: {
        checkboxInfo: "Allow for all requests, messages and attributions",
        completedManualSteps:
          "Browser notifications granted and subscribed to Recare",
        deviceStatusComplete:
          "All steps complete, you should now receive Recare notifications to your device",
        explainer:
          "To receive browser notifications from Recare, please follow the below steps",
        notificationsDeactivatedExplanatoryText:
          "To receive real-time alerts for requests, messages and attributions, and manage permissions here, please allow notifications from Recare under “Data Protection and Security” in your browser settings, or contact customer service at {select:030 5679 6057} for help.",
        notificationsDeactivatedInfo: "Currently blocked through your browser",
        pleaseUseSafari:
          "Please use Safari to receive browser notifications in MacOS",
        previouslyEnabled:
          "If you have previously denied notification permission for this app, allowing permissions in your settings, closing and reopening the app should renable notifications.",
        pushManagerErrorStatus:
          "No push subscription, close and reopen the app, failing that, please contact recare with your debug information.",
        stepOne: "Step 1, allow Browser notifications",
        stepTwo: "Step 2, allow notifications from recare",
        title: "Browser Notifications",
      },
      capacity: {
        acceptNewPatientsWhen: "We will be able to accept new patients by:",
        banner: {
          button: "Yes, we still have capacity",
          confirmation: "Thank you for confirming.",
          subtitle:
            "You have currently indicated free capacities. Is this still correct?",
          title:
            "It has been two weeks since you last updated your capacity information.",
        },
        calendarWarning: "Please choose a date in the 60 days.",
        capacityAvailable: "We currently have capacity",
        capacityTitle: "Our capacity",
        dateInFuture: "Date and time needs to be in the future",
        exceptions: "Exceptions:",
        explanation:
          'Please keep your capacity information up to date. The more up-to-date the information is, the more efficient the process is for everyone involved. Changes can be made at any time and are immediately transferred to the Recare platform.\nException with advantages: If you start an active patient search/multiple searches with specific criteria via "Search", you will receive requests for this, even if you have selected "We have no capacity"',
        hintCapacityAvailable:
          "You are currently receiving new patient requests due to your availability.",
        hintCapacityAvailableExcept:
          "You are currently receiving new patient requests except patients with one of the mentioned characteristics.",
        hintCapacityNotAvailable:
          "You are currently not receiving new patient requests.",
        instructions:
          "To change your capacity settings, please click on one of the options below: ",
        lastUpdated: (args: { date: string }) =>
          template("Last updated: {{date}}", args),
        saveButton: "Save changes",
        tileCapacity: {
          text: "In this way, you will receive enquiries that match your range of services. ",
          title: "We have capacity",
        },
        tileNoCapacity: {
          dateInformation: "We will be able to accept new patients from",
          errorDateInPast: "Please enter a valid date in the next 60 days.",
          errorTimeInPast: "Please enter a valid time.",
          errorTimeInPastDateOkay:
            "Please enter a valid date in the next 60 days or change the time to a future time",
          text: "Enter the date until which you do not wish to receive any new requests. You will continue to receive requests for the days after this date.",
          title: "We don’t have capacity",
        },
        title: "Capacity",
        titleNew: "Capacity settings",
        toastMessageHasCapacitySaved:
          "Information successfully saved - you have capacity",
        toastMessageHasNoCapacitySaved:
          "Information successfully saved - you have no capacity",
        toggleNoYes: "No / Yes",
      },
      dPA: {
        downloadContract: "Download the contract",
        fileName: "Data_processing_agreement.pdf",
        hintFileRestriction:
          "Only one file allowed. File can’t be changed once you click confirm.",
        hintHowTo:
          "Please download the Data Processing Agreement and print it out. After signing it, please upload it back to Recare.",
        modalTitleSure: "Are you sure you want to delete this file?",
        signScan: "Sign and scan",
        titleDPA: "Data Processing Agreement",
        titleOtherDocuments: "Other documents",
        uploadContract: "Upload back to Recare",
      },
      email: {
        addressAlreadyLinked:
          "This email address is already linked to an account.",
        advanced: "Advanced",
        centralAddress: "Central email address for registrations",
        centralAddressHint:
          "If you have a central email address for registration (like info@ or contact@), please activate it here.\nPlease note: this address is only an inbox and cannot be an active email account.",
        centralAddressToast: (args: { email: string }) =>
          template("You will receive requests at {{email}}", args),
        companyInbox: "Company Inbox",
        companyMailAddress: (args: { companyMail: string }) =>
          template("Company mail address {{companyMail}}", args),
        deactivateAll: "I don't want to receive any emails at all",
        hideAdvancedSettings: "Hide Advanced settings",
        hintSetUpCompanyMail:
          "{select:Click here} to set up your company email address to receive request in one place.",
        modalDeactivateEmailSubtitle:
          "This might significantly reduce the efficiency of communication between you and the clinics, i.e. the only way to know about new request will be through checking the app.",
        modalDeactivateMailTitle: "Are you sure you want to receive no emails?",
        noCentralAddress: "We don't have a central email address",
        notificationsTitle: "Receive notifications to",
        personalInbox: "Personal Inbox",
        personalMailaddress: (args: { userAddress: string }) =>
          template("Personal mail address {{userAddress}}", args),
        receiveOnePerDay:
          "I only want to receive one email a day with all requests",
        saveAndSend: "Save and send invitation",
        showAdvancedSettings: "Show Advanced settings",
        title: "Email preferences",
        toggleOffOn: "Off / On",
        withCentralAddress:
          "We have a central email and want to receive requests here",
      },
      extensions: {
        connected: "Connected",
        connectionStatusActive: "Active since:",
        connectionStatusInactive: "Inactive since:",
        connext: {
          additionalInfo:
            "By connecting your Connext Vivendi account to Recare you will automatically receive all patient data directly into your software on patient attribution.",
          title: "Connext Vivendi",
        },
        ctaConnect: "Connect",
        infoExtensions:
          "Connect to your software partner to facilitate data transfer from Recare to your tool.",
        korian: {
          additionalInfo:
            "The Korian CRM is automatically connected to Recare. Data and patient requests originating from Recare are directly submitted into your software.",
          title: "Korian CRM",
        },
        title: "Extensions",
      },
      facility: {
        additionalCosts:
          "As a private facility, we charge some additional costs",
        additionalCostsPlaceholder: "Please specify",
        availableEquipmentTitle: "Available",
        cardiovascularExplorationTitle: "Cardiovascular exploration",
        coronaService: "We take Coronavirus (SARS-CoV-2) patients",
        coronaTitle: "Coronavirus (SARS-CoV-2)",
        ctaAddAnotherDay: "Add another day",
        digestiveEndoscopies: "Digestive endoscopy",
        endTime: "Hour",
        furtherInformationCardTitle: "Further information",
        healthInsuranceFrance: "Health insurance of patients",
        healthInsuranceMandatoryFrance: "Mandatory",
        healthInsuranceOptionalFrance: "Optional",
        hintCompanyMailNotification:
          "Do you want to receive requests to this address? {select:Click here} to set your preferences.",
        hintWorkingHours:
          "If you don’t specify your working hours, we will assume them to be 7 am to 5 pm. Your opening hours will be displayed to the clinics.",
        imageryTitle: "Imagery",
        institutionsNotPartOfSearch:
          "Currently the following institutions are not part of the search yet. Please select the correct ones as additional information:",
        notSetYet: "not set yet",
        organization: "Organization",
        patientCharacteristicsCardTitle: "Patients characteristics",
        rangeServicesCardTitle: "Range of services",
        receiverPartOfOrganization: (args: { organizationName: string }) =>
          template(
            "You're part of the organization {select:{{organizationName}}}",
            args,
          ),
        receiversOfSameOrganization:
          "The following receivers are part of the same organization:",
        socialWorkerOnSite: "Social worker on site",
        startTime: "Hour",
        technicalEquipmentTitle: "Technical equipment",
        title: "Facility",
        titleCompanyInformation: "Company information",
        titleWorkingHours: "Working hours",
        updateInformationModalTitle: "Update your general information",
      },
      language: {
        en: "English",
        fr: "French",
      },
      payers: {
        explanationSubtitle:
          "For patients with the following payers we accept pre-reservations",
        infofieldRehabShortInsurances:
          "The short profile is reduced to core information, which are needed to contact potentially matching receivers. In order to transfer relevant medical information, please upload and forward relevant documents (medical diagnosis / AHB form) as well.\n",
        titlePayers: "Payers",
      },
      relatedCareseekers: {
        activeSince: "Active since",
        distanceToClinic: (args: { distance: string }) =>
          template("{{distance}} away", args),
        name: "Name",
        sectionTitle: "Overview of all nearby partner clinics",
        soonAvailable: "Soon available",
        title: "Partner clinics",
      },
      reversePatientSearch: {
        addSearch: "Add new search",
        betaBanner:
          "This feature is currently in early access beta and might change in function and appearance over time.",
        chipBasedOnSearch: "Based on search",
        deleteSearch: "Delete",
        editingTitle: "Editing search",
        editSearch: "Edit",
        filtersTitle: "Adding new search",
        hintNoCapacity:
          "You have indicated that you currently have no capacity for new patients. Therefore, you will receive requests only for patients that match your search criteria. If you want to receive requests for other patients, too, please change this in your capacity settings.",
        hintTextCapacityMode:
          "You can run a detailed search based on “Gender“, “Age“, “Expected start date“ and “Carelevel” or keep it flexible by simply setting one search criteria. Searches can be edited, paused or deleted at any time. You can also run multiple searches in parallel. You will receive requests for patients matching your search criteria, even if your overall capacity settings are turned off.",
        hintTextInformation:
          "You can run a detailed search based on “Gender“, “Age“, “Expected start date“ and “Carelevel” or keep it flexible by simply setting one search criteria. Searches can be edited, paused or deleted at any time. You can also run multiple searches in parallel.",
        loadingError: "Error loading search data",
        pauseSearch: "Pause",
        searchAdded: "Search was successfully added",
        searchDeleted: "Search was successfully deleted",
        searchEdited: "Search successfully edited",
        searchPaused: "Search was paused",
        searchStarted: "Search was started",
        searchStatus: "Search status",
        searchStatusActive: "active",
        searchStatusPaused: "paused",
        showResults: "Show results",
        startSearch: "Start",
        subtitle: "xxx",
        subtitleInformationText:
          "Do you currently have or are expecting to have free capacity? You can use the search function to create searches for patients matching your expected capacity in a couple of easy steps. You will receive an e-mail once a patient meeting these criteria is available in the system. In addition, you will see the patient as usual in the “Request” tab of your dashboard (patients meeting your search criteria can be distinguished with the “Based on search” tag).",
        subtitleSearchesAvailable:
          "Is there a specific type of patient you are currently looking for? \nBelow you can see your current search/es, which you can edit, delete, or pause at any time. By clicking on “add new search” you can add additional searches.",
        subtitleSearchesNotAvailable:
          "Is there a specific type of patient you are currently looking for?\nSet the filters below accordingly to be notified immediately if a patient like that is created.",
        title: "Patient search",
        toastError: "Something went wrong, please try again",
        toastErrorEmptyFilters:
          "Please add at least one option to be able to save your search",
        toastShowResultsError: "Something went wrong. Please try again",
        toastShowResultsSuccess: "The result of your search is displayed",
      },
      searchCareprovider: "Search careprovider",
      sidebar: {
        ariaLabelChangePassword: "Click here to set a new password",
        ariaLabelLogout: "Click here to Logout",
        ariaLabelReportContent: "Click here to report content",
        avContract: "AV-Contract",
        avContractAddendum: "Updated addendum data processing agreement",
        companyProfile: "Company profile",
        faq: "FAQ",
        findPatients: "Find patients",
        legal: "Legal",
        myAccount: "My account",
        notifications: "Notifications",
        ourCapacity: "Our capacity",
        ourServices: "Our services",
        partnerClinics: "Partner clinics",
        payers: "Payers",
        personalDetails: "Personal details",
        privacyPolicy: "Privacy policy",
        reportContent: "Report content",
        search: "Search",
        services: "Services",
        team: "Team",
        teamManagement: "User management",
        title: "Settings",
      },
      switchCareprovider: "Careprovider",
      topSectionAriaLabel: "Settings pages navigation",
      uploadFiles: {
        confirmImageRights:
          "I confirm that the image may be uploaded and used by me and is free from conflicting rights of third parties and does not violate the terms of use. In the event of violations, the image may be removed.",
        errorFileNameExistsAlready:
          "File name already exists - please change the name of the file and upload it again",
        errorMaxFiles: (args: { maxCount: string }) =>
          template(
            "You can only upload a maximum of {{maxCount}} files.",
            args,
          ),
        errorMaximumFiles: "You can only upload a maximum of 5 files.",
        errorPermittedFormat:
          "Please only upload files in the permitted format.",
        imageDeletionDisclaimer:
          "Recare reserves the right to remove images at any time should they infringe rights of use or other rights.",
        images: {
          imageMaxResolution: "max resolution: 4000 x 3000",
          imagesMaxSize: "max image size: 15 MB",
          permittedFormat: "permitted format: .png, .jpg, .jpeg",
          subtitle:
            "Please add pictures of your facility to your profile to provide a visual impression of your range of services. These images will be displayed to care seekers.",
          title: "Photos (max. 5 images)",
          uploadImages: "Upload photos",
        },
        marketing: {
          documentsMaxSize: "max file size: 15 MB",
          infoUploadFiles:
            "Upload useful material that provides the clinic with more details about your institution. The files will be displayed in the order of the list.",
          permittedFormat: "permitted format: .pdf, .doc, .docx",
          title: "Marketing material (max. 5 files)",
          uploadFiles: "Upload files",
        },
        orderSuccessfullyChanged: "The order has successfully been changed.",
        toastConfirmCheckbox:
          "Please confirm the above checkbox first before uploading.",
        toastFileDeletedSuccess: "Your file has been deleted.",
      },
    },
    showElectivePatient: "Elective Patient",
    status: {
      requestSent: "Answer",
    },
    topLevelNavBar: {
      faq: "FAQ",
      newsletter: "Newsletter",
    },
    warningAcceptedRequestsSelected:
      "Some of the requests you selected have been accepted.",
  },
  careproviderCard: {
    age: "Age",
    contactInformation: "Contact information",
    costCoverage: {
      applied: "Applied for",
      approved: "Approved",
      declined: "Declined",
      declineHint: "(will not be shared)",
      declineReason: "Denial reason",
      noAppliedYet: "Not applied for yet",
      sendNotification: "Send a notification to the clinic",
      title: "Cost coverage status",
      updateStatus: "Please update the status",
    },
    distanceFromPatient: (args: { distance: string }) =>
      template("{{distance}} away from patient", args),
    healthInsurance: "Health insurance",
    otherServices: "On top of this, we also offer the following services:",
    ourServices: "Our services",
    patientCharacteristics: "Patients characteristics",
    patientServices: "In response to this patient’s needs, we offer:",
    weight: "Weight",
  },
  careproviderProfile: {
    acceptedWeight: "Weight of patients",
    addMoreLanguages: "Add further languages",
    capacity: {
      declineExpirationDate: "Select date",
      declineExpirationDateInfo:
        "Please note, not selecting any date will result in further receiving patient requests with selected characteristics.",
      declineExpirationOptional: "Select date (optional)",
      noCapacityForGivenReasonUntil: (args: {
        reasons: string;
        date: string;
      }) => template("{{reasons}} until {{date}}", args),
      weCouldReceiveNewPatients: "We could receive new patients",
      weCurrentlyHaveNoCapacity: "We currently have no available capacity",
      youAreNotTakingPatients: "You are currently not taking any new patients",
      youAreReceivingNewRequests: "You are receiving new requests by email",
      youChangedYourCapacitySettings: "You changed your capacity settings.",
      youCouldReceiveNewPatients: "You could receive new patients",
      youCouldReceiveNewPatientsExceptFor:
        "We could receive new patients except for:",
      youHavePendingRequests: {
        plural: (args: { number: string }) =>
          template("You have {select:{{number}} pending requests}", args),
        singular: (args: { number: string }) =>
          template("You have {select:{{number}} pending request}", args),
      },
      youHavePendingRequestsBefore: {
        plural: (args: { number: string; date: string }) =>
          template(
            "You have {select:{{number}} pending requests} starting before the {{date}}",
            args,
          ),
        singular: (args: { number: string; date: string }) =>
          template(
            "You have {select:{{number}} pending request} starting before the {{date}}",
            args,
          ),
      },
      yourCapacity: "Your capacity",
      youWillNotReceiveRequestsThatContain:
        "You will not receive new requests that contain",
      youWillNotReceiveRequestsTill: (args: { date: string }) =>
        template("You will not receive new requests before the {{date}}", args),
      youWillNotReceiveRequestsWithFollowingCharacteristics:
        "You won’t receive any patient requests with the following characteristics until the selected date:",
    },
    clinicContactInformation: (args: { careseekerName: string }) =>
      template("{{careseekerName}} contact information", args),
    companyName: {
      care: "Company name",
      geriatrics: "Clinic's name",
    },
    contact: "Contact",
    contactDetails: "Contact details",
    contactPersonInHospital: "Contact person",
    contactSendEmails:
      "Please check the box if you want this contact to receive requests by emails. All contacts will be displayed to the social workers of the hospital.",
    duplicateIkNumber: "This number has already been entered.",
    errorMissingMandatoryFields: "Please fill in all mandatory fields.",
    extraNotes: {
      hint: "Type your notes…",
      info: "Your notes will not be displayed to the receiver. You can add some useful but missing information, e.g. fax number, availability etc. You can refer to your notes any time.",
      title: "Add more information about this receiver",
      titleEdit: "Edit information about this receiver",
    },
    generalInformation: "General information",
    healthInsurance: "Health insurance of patients",
    hintAdditionalInformation:
      "Please complete your profile with a description of your facility and your range of services. This information will be displayed to care seekers.",
    ikNumberAlreadyTaken: (args: { ikNumber: string }) =>
      template("IK number {{ikNumber}} is not available anymore", args),
    ikNumberEmpty: "Please fill out the field",
    ikNumberErrorDigits: "This field must contain exactly 9 digits.",
    ikNumberHint: "placeholder",
    ikNumberIntegration:
      "As the provider is part of an integration, the IK number cannot be changed - please contact support",
    ikNumberLabel: "IK number",
    ikNumberSuccess: "IK numbers successfully updated",
    isNewCareProvider: "NEW at Recare",
    languagesSpoken: "Spoken languages",
    letUsKnowAnyRemarks: "Let us know if you have any other comments",
    loadingError: "Could not load careprovider",
    loadingErrorRedacted: "Account has been redacted and cannot be loaded",
    locationOfPatient: "Treatment location",
    max: "Maximum",
    min: "Minimum",
    myAccount: "My account",
    otherRemarks: "Additional information about your institution",
    patientTypeLabel: "Patient Type",
    placeholderAdditionalInformation:
      "e.g. admission times, other requirements, etc.",
    prerequisites:
      "Is this a prerequisite to take patients? If yes, you will only receive requests for this need.",
    profileCardTitle:
      "This information is necessary for us and the clinic to contact you.",
    providerNotAlreadyAdded: "Provider already added to search",
    receiverName: (args: { receiverTypeSingular: string }) =>
      template("Name of the {{receiverTypeSingular}}", args),
    servicesPsychiatricSpecialisation: "Psychiatric specialisation",
    sexOfNurses: "Gender of caregivers",
    toTreatPatientRadius: "To Treat patients in a specific radius (km)",
    toTreatPatientZipcodes:
      "To treat patients in a specific catchment area (zipcodes)",
    weTreatPatientFederalStateWide: "federal state-wide",
    weTreatPatientGermanyWide: "Germany-wide",
    weTreatPatientRadius: "within the following route distance (km)",
    weTreatPatientZipcodes: "within the following zipcodes",
    weTreatPatientZipcodesStartingWith: "within zipcodes starting with",
    years: "years",
    yourProfile: "General information on your company",
    yourProfileLink: "Your profile",
  },
  careseeker: {
    billingEmail: "Billing email address",
    billingEndDate: "End of billed phase",
    billingName: "Billing name",
    billingPeriod: "Billing period",
    billingStartDate: "Beginning of billed phase",
    billingTitle: "Billing information",
    checkProtectedDescription:
      "Please use the health insurance number to check whether this patient is an employee. Employee data may only be viewed by specially authorized persons.",
    checkProtectedTitle: "Checking for sensitive patient data",
    contractInformation: "Contract information",
    contractNotes: "Contract notes",
    contractStartDate: "Contract start date",
    customerId: "Customer ID",
    extensionContractLink: "Extension contract",
    financeOffice: "Finance office address",
    financeOfficeName: "Finance office name",
    flatRate: "Monthly fee",
    generateInvoice: "Generate Invoice",
    generateInvoiceForClinic: (args: { careseekerName: string }) =>
      template("Generate invoice for {{careseekerName}}", args),
    informationCostPayerValidation:
      "By clicking on ACCEPT you are confirming a legally not binding and provisionally reservation. ",
    invoiceComment: "Further invoice information",
    invoiceDelivery: "Invoice delivery",
    invoicesTitle: "Invoices",
    invoiceType: "Type",
    invoiceViaEmail: "via email",
    invoiceViaPost: "via post",
    linkToPdf: "Link to PDF",
    longMobileAsssessment: "Long patient profile necessary",
    maintenancePAge: "App currently under maintenance, please check back soon.",
    paymentTitle: "Payment information",
    pricePerPatient: "Automatic success",
    protectedCheckQuestion: "Is this sensitive employee data?",
    protectedPatients: "Protected Patients",
    remoteWorkshopFee: "Remote workshop fee",
    serviceDate: "Service date",
    setupFee: "Setup fee",
    shortMobileAssessment: "Short patient profile is sufficient",
    signedContractReceived: "Signed contract received on the",
    totalAmount: "Total (€)",
    trialEndDate: "End of test phase",
    trialStartDate: "Beginning of test phase",
    workshopFee: "Workshop fee",
  },
  coronaTesting: {
    additionalComments: "Additional comments on covid",
    additionalCommentsNo:
      "Additional comments, e.g. that patient does not show any COVID-19 symptoms",
    additionalCommentsYes:
      "Additional comments, e.g. when was the patient tested, etc.",
    auctionLabel: "Coronavirus (SARS-CoV-2)",
    coronaBannerAddMobile:
      "Due to the current spread of the Coronavirus (COVID-19), most short-term and static care places are no longer available. We therefore strongly recommend to look for a mobile facility at the same time, to curb further spread of the virus. {select:Click here} to add Mobile care to your search.",
    hasBeenVaccinated: "Patient has been fully vaccinated",
    hasPatientBeenTested:
      "Has patient been tested for coronavirus (SARS-CoV-2)?",
    negative: "Negative",
    patientNotTested: "Patient has not been tested",
    pending: "Pending",
    pleaseSpecifyVaccination: "Please specify",
    positiveCured: "Positive, cured",
    positiveUncured: "Positive, not cured",
    results: "Results",
    testResultLabel: "Test result",
    unknown: "No information whether a test has been made",
    vaccinationUnknown: "Vaccination status was not filled",
  },
  crisp: {
    support: "Support",
  },
  csat: {
    csatCommentPlaceholder: "Type your feedback",
    csatCommentQuestion: "How can we improve?",
    csatQuestion: "How satisfied are you with Recare?",
    csatThanksSubtitle:
      "We’re here for you. Your feedback is very important because it helps us to continuously improve Recare.",
    csatThanksTitle: "Thank you for your feedback!",
    dissatisfied: "Dissatisfied",
    neutral: "Neutral",
    satisfied: "Satisfied",
    scaleNotAtAll: "not at all",
    scaleVeryMuch: "very much",
    veryDissatisfied: "Very Dissatisfied",
    verySatisfied: "Very Satisfied",
  },
  faxRequest: {
    coverPage: {
      dearSirOrMadam: "Dear Sir or Madam",
      disclaimer: {
        explainationLineTwo:
          "Due to data privacy reasons, patient data is not allowed to be sent via fax.",
        explanation:
          "If you want to see the full patient profile, {select:register for free} on our platform {select:www.recaresolutions.com}. If you have any questions, please do not hesitate to call us on +49 30 56 79 60 57.",
        explanationLineOne:
          "Recare Deutschland GmbH is acting as a software and contract-partner of the sending hospital.",
        title: "This is not advertisement",
      },
      hospitalInformation: {
        contactPerson: "Contact person:",
        email: "Email:",
        telephoneNumber: "Tel.:",
        title: "Contact information",
      },
      infoBodyTextRequest:
        "a patient is looking for a suitable care provider. Do you have free capacities for this patient? A pseudonymised patient profile can be found attached (page 2).",
      infoBodyTextValidation:
        "You have been validated as a receiver from the hospital. We therefore inform you that a patient will be transferred to you. Please find a pseudonymized profile attached (page 2).",
      infoTextPatientRequest:
        "If you have capacity to take in the patient or need more information, please contact the contact person of the hospital and state the Recare-identification number {select:patientId}.",
      infoTextPatientRequestLineOne:
        "You have received a patient request. Please find the pseudomized patient profile attached.",
      infoTextPatientValidation:
        "For more information, please contact the contact person of the hospital and state the Recare-identification number {select:patientId}.",
      infoTextPatientValidationLineOne:
        "A patient was attributed to you. Please find the pseudomized patient profile attached.",
      subtitleHospitalName: (args: { senderName: string }) =>
        template("from {{senderName}}", args),
      titlePatientRequest: "Patient request",
      titlePatientValidation: "Patient validation",
    },
    faxEvents: {
      faxBounced: "Fax failed to be delivered",
      faxDelivered: (args: { receivername: string }) =>
        template("Fax successfully delivered to {{receivername}}", args),
      faxEventTitle:
        "Please mark this provider as validated if they’ve been chosen",
      faxNumberNotAvailable: (args: { receivername: string }) =>
        template("Fax number of {{receivername}} not available", args),
      faxSent: (args: { receivername: string }) =>
        template("Fax sent to {{receivername}}", args),
    },
    faxIntroBodyOne: (args: { patientId: string }) =>
      template(
        "The clinic is looking for suitable care for a patient in need of follow-up care - and is asking you specifically about available capacity. Please give the clinic your feedback, quoting the patient identification number {{patientId}} of the Recare platform.",
        args,
      ),
    faxIntroBodyTwo:
      "Receive more free clinic enquiries that match your range of services with the Recare platform. Register directly:\nhttps://recaresolutions.com/nachversorger-registrierung/.",
    faxIntroSalutation: "Dear Sir or Madam,",
    fromSender: (args: { clinicName: string }) =>
      template("from {{clinicName}}", args),
    optOutOfReceivingFax:
      "Receiver receives faxes (for status INACTIVE or CREATED)",
    optOutOfSendingFax:
      "Do not send faxes to inactive receivers (Care & Rehab)",
    recareId: (args: { patientId: string }) =>
      template("Recare-ID: {{patientId}}", args),
    titleNew: "Enquiry for a patient requiring after care",
  },
  fileHisUploadComponent: {
    title: "Please select the files you wish to upload and share",
  },
  fileSection: {
    cannotDelete: "This file cannot be deleted as it was not uploaded by you.",
    dragAndDrop: {
      disclaimerFileSize: "max. file size: 20 MB",
      disclaimerFileType: "supported file formats: PDF",
      instructionDragAndDrop: "Drag and drop file here",
      selectBetweenDragAndDropOptions: "Or",
      title: "Upload from your computer",
    },
    eligibilityPage: {
      sendFileLabel: "Go to file feature",
      sendFileToolTip: "Try the new file feature",
    },
    events: {
      documentIsBeingUploaded: "Your document is being uploaded...",
      fileWasSharedWith: (args: { fileName: string }) =>
        template("{{fileName}} was shared with:", args),
    },
    explanationIcon:
      "This icon indicates that the file is end-to-end encrypted.",
    explanationText:
      'Upload PDF files of up to 20 MB here and share them securely via end to end encryption directly with corresponding receivers. To do this, click on the "Share" option. In addition, you can also see here all the files you have received from receivers. Once a provider has been attributed, the files can no longer be downloaded by non-attributed providers. The file name will also be encrypted.',
    fileDeleteModal: {
      title: "Are you sure you want to delete this file?",
      toastSuccess: "File successfully deleted",
    },
    fileFaxComponent: "Do you want to fax the file to the cost payer?",
    fileIntegration: {
      fileNotYetImported: "Not yet imported",
      import: "HIS import",
      importedFromHis: "Imported from HIS",
      title: "Please select the file/s you wish to import",
      toastSuccess:
        "Your file/s have been successfully imported. You will find them in the files tab after completing the assessment.",
    },
    fileListComponent: {
      category: "Category",
      dateSourceColumn: "Date and Source",
      descriptionFilesAvailable: "These files can be uploaded and shared ",
      descriptionFilesUnsupported:
        "These files cannot be uploaded as they do not meet the file standards",
      descriptionNewFileVersionAvailable:
        "A newer version of this file is available",
      employeeColumn: "Employee",
      fileErrorReason: "Error reason",
      fileName: "File name",
      placeholderNothingReceived: "You haven’t received any files yet.",
      placeholderNothingSent: "You haven't sent any files yet.",
      placeholderNothingUploaded: "You haven’t uploaded any files yet.",
      reason: "Reason",
      reloadButton: "Reload",
      shareDate: "Date",
      sharedBy: "Shared by",
      sharedWith: "Shared with",
      shareOptions: {
        allReceivers: "All receivers",
        allReceiversHospital: "All receivers + hospital",
        allReceiversInsurance: "All Receivers + health insurance",
        clinic: "Hospital",
        insurance: "Health insurance",
        noOne: "Not shared",
        selectedReceivers: "Selected receivers",
        selectedReceiversHospital: "Selected receivers + hospital",
        selectedReceiversInsurance: "Selected receivers + health insurance",
      },
      source: "Source",
      sourceComputerInfo: "From computer",
      sourceHIS: "-",
      sourceHISInfo: "From HIS",
      status: "Status",
      statusFormatError: "Format Error",
      statusSizeError: "File Size",
      titleFileReceived: "test",
      titleFilesAvailable: "Available files",
      titleFilesReceived: "Received files",
      titleFilesSent: "Sent files",
      titleFilesUnavailable: "Unsupported  files",
      titleFilesUnsupported: "Unsupported files",
      titleFilesUploaded: "Uploaded Files",
      uploadDate: "Uploaded at",
      uploadedBy: "Uploaded by",
      validation: {
        tooltipHisFileNoDeletion:
          "This file only exist in the HIS and therefore cannot be deleted",
      },
    },
    fileShareComponent: {
      shareOptions: {
        allPotentialReceivers: "All potential receivers",
        noone: "No one",
        noResults:
          "No requests sent yet - please select another option for now",
        selectedReceivers: "Selected receivers",
        shareOptionsLegalDisclaimer:
          "Documents may contain personal information, which have not been made available through the Recare search. Please ensure ahead of sharing that all potential receivers are allowed to have access to the document. Once a provider has been attributed, the files can no longer be downloaded by non-attributed providers. The file name will also be encrypted.",
      },
      shareWithCostpayerTitle:
        "Do you want to share the file with the cost payer?",
      shareWithHospitalTitle:
        "Do you want to share the file with the hospital?",
      shareWithReceiverTitle: "With whom do you want to share this file?",
      successPage: {
        explanationText: "You have shared your files with:",
        title: "Sharing successful!",
      },
      toastSuccess: "Your file change has been successfully saved",
    },
    fileUploadComponent: {
      descriptionFilesReceived: "Files shared with you",
      encryptedFieldSecure: "This information is encrypted",
      fileCategory: "Select category",
      fileName: "File name",
      infoSectionOneText:
        "You can use the functionality to share files with all potential receivers (“all potential receivers” mode).",
      infoSectionOneTitle: "Share with all potential receivers",
      infoSectionThreeText:
        "Uploaded file can be deleted at any time by clicking on the three dots. The file will be automatically deleted for all file recipients. You can also alter the share mode by clicking on the three dots to share or unshare a file.",
      infoSectionThreeTitle: "File deletion and changing of access rights",
      infoSectionTwoText:
        "Should you like to only exchange files with one receiver you can use the messenger to securely exchange files. You can reach the messenger by going to search and clicking on the receiver you intend to share a file with. The files tab in the messenger contains the files you have sent and received akin to what is shown in file overview (only on the level of one receiver). The file share activities from the messenger are also recorded in file overview as shared with “selected receivers”.",
      infoSectionTwoTitle: "Share with one receiver only",
      selectFile: "Select file",
      titleModal: "Please verify the file name and select a category",
      toastSuccess: "Your file has been successfully uploaded.",
      uploadFile: "Upload file",
    },
    fileUploadStates: {
      cancelled: (args: { numberOfFiles: string }) =>
        template("{{numberOfFiles}} upload was cancelled", args),
      cancelUpload: (args: { numberOfFiles: string }) =>
        template(" Cancel {{numberOfFiles}} upload", args),
      descriptionFilesAvailable:
        "Diese Dateien können hochgeladen und geteilt werden",
      error: (args: { numberOfFiles: string }) =>
        template(" Uploads failed - {{numberOfFiles}} ", args),
      errorRetryPrompt: "Upload failed",
      loading: (args: { numberOfFiles: string }) =>
        template("Uploading of {{numberOfFiles}} in progress", args),
      statusNotShared: "Unshared",
      statusShared: "Shared",
      success: (args: { numberOfFiles: string }) =>
        template("{{numberOfFiles}} successfully uploaded ", args),
      uploadStopped: "Upload cancelled",
    },
    headline: "File overview",
    seeDocument: "See document",
    tabTitle: "Files",
    toastError: "An error occurred. Please try again or contact Recare.",
    toastErrorTitle: "Upload error",
  },
  fileUploader: {
    emptyFileError: "The file is 0 bytes and therefore could not be uploaded.",
    fileCouldNotFetch: "Upload failed. Please retry",
    fileError: "Please select a file",
    fileSizeError: "File exceeds the allowed limit of 20 MB",
    fileTypeError:
      "This functionality only allows uploading and sharing of PDF documents",
    fileUploadError: "The file could not be uploaded",
    titleError: "Please enter a file name",
  },
  filterBar: {
    checkboxTransitionalCare: "Show only searches with transitional care",
    chipTransitionalCare: "Transitional care",
    clearAll: "clear all",
    filterBy: "Filter by",
    saveAsDefault: "Save filters as default",
    senderClinic: "Sender clinic",
    showLess: "Show less",
    showMore: "Show more",
    showOnlyElective: "Elective Patients",
    showPendingEligibility: "Only show patients with pending eligibility",
    specialization: "Specialisation",
    titleEligibility: "Eligibility",
    titleTransitionalCare: "Transitional care",
  },
  general: {
    and: "and",
    asap: "ASAP",
    aUser: "a user",
    banner: {
      cta: {
        noEncryptionAccessAlert:
          "We could not connect your account to our secure encrypted environment. This will prevent you from accessing encrypted data and creating patients. Please log out and in again. If the problem persists please contact our customer support.",
        noEncryptionAccessAlertAction: "Logout",
        noEncryptionAccessAlertTitle: "Encryption not available",
        noSealdConnectionAlert:
          "Your connection to seald.io is not available, which is preventing end-to-end encryption. Please check your connection {select:here} or contact our customer support team to resolve the problem.",
        partialEncryptionAccessInfo:
          "The encrypted data is currently not available for a short period of time. Please be patient and contact your recare contact person if the problem persists.",
        sunsetInternetExplorer: "Change your browser",
      },
      internalSealdError:
        "Encryption (Seald) is not working, probably due to using one of our shared accounts at the same time as someone else in different test environments. Please logout, clear cache/cookies, and login again.",
      noAccessPatientDataInternetExplorer:
        "We cannot securely process data on Internet Explorer. To view the data, please switch browsers.",
      noAccessPatientDataInternetExplorerTitle:
        "Patient data locked due to your browser",
      refreshSealdButtonClicked: "Refresh button clicked",
      searchCategoryMoved:
        "This search was initially started for a different category. Please be aware that the documentation only covers the current search type.",
      sunsetInternetExplorer:
        "Recare will soon stop supporting Internet Explorer as it’s no longer maintained by Microsoft. For more information and help click on the following button",
      sunsetInternetExplorerSenders:
        "Recare will stop supporting Internet Explorer as of September 1st, 2021, as it’s no longer maintained by Microsoft. ",
    },
    captionDateNotInPast: "Note: both dates can't be in the past.",
    characterCounter: (args: { count: string; maxLength: string }) =>
      template("{{count}} of {{maxLength}} characters left", args),
    colon: ":",
    columnHeader: "Column header",
    confirmation: "Confirmation",
    containsLink: "For data privacy reasons, one cannot enter a link here.",
    date: "Date",
    disablePatientCreateButton:
      "Your account does not have encrypted access to your organisation. Retry or contact Recare support.",
    error: "Sorry, there was an error. Please try again later.",
    errorDateInPast: "Date can't be in the past",
    errorIELink:
      "{select:You can find out here} how to switch your browser and to a standard browser. Please contact your IT if that is not possible.",
    errorNotSupportIE:
      "Recare does not support Internet Explorer anymore. Please use a different browser to continue (e.g. Firefox, Edge or Chrome)",
    errorNotSupprtIEPatient:
      "We're sorry but this patient profile cannot be opened because you are currently using Internet Explorer and personal data cannot be transmitted securely on it. ",
    errorRequestLimit:
      "You have reached your request limit for this action. Please try again later.",
    fax: "Fax",
    fieldsMissing: "Some fields are missing",
    for: "for",
    formValidationError: "Please make sure everything is filled in correctly",
    formValidationErrorOnlyNumbers: "Only numbers are allowed",
    from: "from",
    fromAge: "from",
    fromDate: "from",
    group: "group",
    helloName: (args: { name: string }) => template("Hello, {{name}}!", args),
    itChecklist: "IT Checklist",
    lessThan: "under",
    loading: "Loading",
    loginPostIdentityReset:
      "Your account security was updated. Please login again and, when requested, enter the new verification code sent to you by email.",
    logOut: "Log out",
    missing: "Missing",
    moreThan: "over",
    navbarAccount: "Account",
    navbarBeamerItem: "What's new on Recare?",
    navbarClinicSwitcherPlaceholder: "Select clinic",
    navbarLogoAlt: "Recare logo - click to go to home page",
    navbarMenu: "Menu",
    never: "never",
    new: "New",
    no: "No",
    none: "None",
    noneR: "None",
    noPreference: "No preference",
    noResult: "No result",
    notAuthorizedToAccessThisPage:
      "You currently don't have the rights to view that page.",
    notAuthorizedToPerformAction:
      "You are not authorized to perform this action",
    notSpecified: "Not Specified",
    oneMoment: "One moment",
    oops: "Oops",
    or: "or",
    other: "Other",
    printingPleaseWait: "Printing is being prepared. Please wait...",
    printModal: {
      receiver: {
        chatHistory: "Chat history",
        patientProfile: "Patient information",
      },
      sender: {
        chatHistory: "Chat history",
        informationAboutProvider: (args: { providerName: string }) =>
          template("Information about {{providerName}}", args),
      },
      title: "What would you like to print out?",
    },
    promptFilterSet: "The filters you have set have been applied.",
    redactEncryptedData: {
      info: "Encrypted data has been deleted for privacy reasons.",
      placeholder: "deleted",
    },
    refreshToSeeChanges: "Refresh to see changes",
    reportContent: "Report content",
    required: "Required",
    retry: "Retry",
    secure: "Secure",
    selectDefaultPlaceholder: "Please type or select one or multiple",
    selectDefaultPlaceholderShort: "Please type or select",
    smallerThan: "smaller than",
    solutionsMissing: "[solutions missing]",
    sort: "Sort",
    status: {
      error: "Something went wrong",
      loading: "Loading, please wait",
      new: "New",
    },
    success: "Information has been successfully saved",
    switchToReceiver: "Go to Receiver App",
    switchToSender: "Go to Sender App",
    tallerThan: "taller than",
    to: "to",
    toAge: "up to",
    today: "Today",
    toEditPleaseContactRecare: "Please contact Recare to change this field.",
    tomorrow: "Tomorrow",
    toRecipient: "To",
    toWeight: "to",
    transferTo: "Transfer to",
    tryAgain: "Try again",
    unknown: "Unknown",
    untilDate: "until",
    upload: "UPLOAD",
    uploadFailed:
      "Your upload failed, please try again or contact a recare representative",
    websiteUrl: "https://www.recaresolutions.com",
    whatsNew: "Recare news",
    yesterday: "Yesterday",
    you: "You",
  },
  hintImportIE:
    "The import of patient data is not accessible with Internet Explorer or a version of Microsoft Edge below 80. Please use a more up-to-date browser like Google Chrome, Mozilla Firefox or Microsoft Edge (version 80 or higher).",
  insuranceApp: {
    approveEligibility:
      "Are you sure you want to approve this patient’s eligibility?",
    banners: {
      bannerStartPrereservation:
        "You are in charge of starting the pre-reservation process. Start the search or Review providers suggested by the clinic to begin. AHB form will be shared with you after the surgery.",
      bannerUploadForms:
        "Cost payer needs the AHB forms to make a decision about patient eligibility. Please fill the forms after the surgery to speed the process up.",
      completeProfile: "Complete patient profile",
      patientEligibilityPending: "Patient eligibility pending",
      patientIsEligible: "Patient is eligible for Rehab",
      patientNotEligible: "Patient is not eligible for Rehab",
    },
    captionAssessment:
      "The insurance of your patient is a partner of Recare and, therefore, they will take over the search after Patient profile is complete.",
    captionHandOverToInsurance:
      "The insurance of your patient is a partner of Recare. They will be responsible for making a pre-reservation. Please, click the button below to begin:",
    captionInsuranceEditSearch:
      "You can edit some of the above search criteria here:",
    confirmDeleteModal: "Are you sure you want to remove the file?",
    confirmDeleteModalText:
      "You can always fill out the form again or upload a new file.",
    deleteForm: "Delete form",
    eligibility: "Eligibility",
    eligibilityEvents: {
      transferFailedCodeMissing:
        "Form transfer failed - missing ICD-10 Code/s in medical form",
      transferFailedErrorUnknown: "Form transfer failed - error unknown",
      transferSuccessful: "Forms successfully transferred",
    },
    eligibilityHeaderToBeProcessed:
      "After you have provided all the necessary information, click the SHARE FILES button to send it to the cost payer.  ",
    eligibilityTab: {
      eligibilityCard: {
        approve: "Approve",
        captionTextProcessed:
          "Once the forms are uploaded and shared with the cost payer they will make a decision about patient’s eligibility.",
        clinicDidntSendYet:
          "The clinic has not sent the forms yet. Please contact the clinic if you have any questions.",
        pleaseShareFormsInfo:
          "Please share the forms with the insurance, so that the decision about patient eligibility can be made.",
        reject: "Reject",
        rejectModalTitle: "Why do you reject this patient’s eligibility?",
        subtitleEligibilityNotSet: "Patient eligibility has not been set yet",
        titleApproved: "approved",
        titleAwaitsCostPayerPartOne: "Patient eligibility",
        titleAwaitsCostPayerPartTwo: "awaits cost payer’s decision",
        titleClinicInformation: "Clinic contact information",
        titleCostPayerInformation: "Cost payer contact information",
        titleHasBeen: "Patient eligibility has been",
        titleInProcessPartOne: "Patient eligibility is",
        titleInProcessPartThree: "please take action",
        titleInProcessPartTwo: "in progress",
        titleRejected: "rejected",
        titleToBeProcessed: "Please set patient eligibility",
      },
      hintModalShareFroms:
        "Sharing the forms with the the cost payer is necessary to finalise the patient transfer.",
      medicalFormName: "Medical Form",
      replySection: {
        newResponse: "New response",
        noReplyInfo:
          "Once you have submitted the documents, you will be able to access the insurance company's response here.",
        openedResponse: "Answer received",
        printDocument: "Print document",
        replySectionTitle: "Reply from insurance",
        viewDocument: "View document",
      },
      requestFormName: "General form",
      sendDocuments: {
        cancelButton: "Cancel",
        confirmationSubtitle:
          "Documents can be withdrawn after they have been sent.",
        confirmationTitle:
          "Are you sure you want to send the documents to the health insurance fund?",
        errorMessage: "The forms could not be submitted.",
        otherInsuranceLink:
          "The name of the health insurance company is not correct",
        sendButton: "Send now",
        submitFormButton: "Send information to the health insurance",
        subtitle:
          "To send the form to the health insurance company, please fill out the following two forms and save them as a PDF.",
        successMessage:
          "The forms were successfully submitted to the health insurance company.",
        title: "Submit documents to the health insurance company",
        toFormLink: "Go to form",
        tryAgain: "Try again",
        withdrawButton: "Withdraw request",
        withdrawModal: {
          otherPlaceholder: "Tell us more (maximum 600 characters)",
          otherReason: "Other",
          reasonFour: "Insured person has died",
          reasonOne: "Insured person waives the rehabilitation benefit",
          reasonThree: "Rehab is not promising",
          reasonTwo: "Insured person is not capable of rehabilitation",
          withdrawSubtitle:
            "Please select the applicable reason (mandatory field). The selected reason will be forwarded to the health insurance company.",
          withdrawTitle: "Why do you want to withdraw your request?",
        },
      },
      sunsetEligibilityMessengerText:
        "You can easily create the relevant documents for cost coverage here. Simply click on ‘Complete form’ next to the relevant document. Please note that only one document can be selected in the ‘general form’ section and in the ‘medical form’ section.",
      sunsetEligibilityMessengerTitle: "Create cost coverage documents",
      titleEligibility: "Patient eligibility for Rehab",
      titleModalShareFroms:
        "You have completed the forms. Do you want to share them with the cost payer now?",
      tooltipEncryption:
        "The information in the forms is encrypted and are securely sent.",
    },
    formDeleteFailure:
      "Failed to delete the form, please try again or contact a Recare representative.",
    guidanceToNextSteps:
      "To start the search go to {select:Search tab}. You may also want to review the forms to decide about their eligibility in the {select:Eligibility tab}.",
    infoModalEditSearchCriteria:
      "Please edit these search criteria if it’s necessary. Clinic will be notified about the changes you have made.",
    rejectEligibilityReasons: {
      otherReason: "Other reason",
      otherReasonLabel: "Please specify",
    },
    requestCancelled: "Sorry, the suggestion was withdrawn from the clinic",
    searchWasStopped:
      "The search was stopped by the clinic. The clinic can resend you the case if necessary.",
    sunsetCostpayerFlow: {
      body: "Due to regulatory changes related to the introduction of the DTA procedure we will sunset our search offering for AHB/reha searches. Depending on the costpayer your ability to start searches and access the  Recare platform will be removed on 01.04.2023 oder 01.05.2023 (please contact our support if you are unsure, which date applies to you). All running searches will be automatically stopped ahead of the deadlines. Please prepare for this change and if relevant contact the relevant authorities or oversight committees. ",
      title:
        "Important Information: Sunsetting of reha searches for costpayers",
    },
    titleModalEditSearchCriteria: "Edit search criteria",
    undoEligibility:
      "Are you sure you want to undo this patient’s eligibility?",
    yourPartner: "This provider is one of your partners ",
  },
  landmarks: {
    aside: "Complementary Content",
    footer: "Footer",
    header: "Header",
    main: "Main Content",
    section: (args: { sectionName: string }) =>
      template("{{sectionName}} Section", args),
  },
  legal: {
    agreedDpa: "I have read and agree to the data processing agreement",
    agreedDpaLink:
      "I have read and agree to the {select:data processing agreement}",
    agreedTermsAndConditions:
      "I have read and agree to the terms and conditions",
    bannerCtaLegalSection: "Confirm documents here",
    bannerDocumentsToSign:
      "Please note that some legal documents still require your confirmation.",
    bannerDocumentsToSignBcp:
      "Important contract documents still require your confirmation. In order to receive additional direct inquiries from citizens via Pflegefinder Bayern, please also accept the updated General Terms and Conditions. Processing only takes a few minutes.",
    bannerDocumentsToSignOnlyBToB:
      "Important contract documents still require your confirmation. Please do this now - it only takes a few minutes.",
    bannerDocumentsToSignWeserEms:
      "Important contract documents still require your confirmation. Please do this now - it only takes a few minutes.",
    cannotBeChanged: "Revocation only possible via our customer service",
    cannotBeChangedWithLink:
      "Revocation only possible via our {select:customer service}",
    churchOwnedQuestion:
      "For legal reasons, please first select whether your institution is owned by a church.",
    clickOnLinkToAgree: "Click on the link above to agree to the DPA",
    clickOnLinkToAgreeTC:
      "Click on the link above to read and agree to the T&C",
    differencesInTCVersions:
      "Recare has made an adjustment to its terms and conditions. This will enable providers to participate in any future platforms not only for hospitals and institutional customers, but also for citizens as end users. All other provisions of the previous T&Cs remain in place.",
    isChurchOwned:
      "my institution is a charitable institution and owned by the church.",
    isNotChruchOwned:
      "my institution is not affiliated with or owned by the church.",
    legalAgreements: "Legal Agreements",
    printableVersion: "Printable version",
    readOnly: "Read only",
    toViewClickOnLink:
      "To view the agreement, please click on the following link to open the PDF file",
    uploadedDateDPA: (args: { date: string }) =>
      template("Uploaded on {{date}}", args),
    uploadedOn: (args: { date: string }) =>
      template(
        "Uploaded on {{date}}. To upload a different file, please contact customer service.",
        args,
      ),
    viewFileInNewTab: "View file in new tab",
  },
  login: {
    biometricfailedAlert: {
      failed: "Error. Please use username/password.",
      falsePasswordLimitReached: (args: { timer: string }) =>
        template(
          "Your account has been temporally locked due to too many incorrect login attempts. Please try again in {{timer}} minutes.",
          args,
        ),
      noCredentials: "No credentials saved, please login once first",
      resetPasswordLimitReached: (args: { timer: string }) =>
        template(
          "You have reached the maximum number of password resets. Please try again in {{timer}} minutes. ",
          args,
        ),
    },
    buttonSetNewPassword: "Set new password",
    care: {
      changeToSender: "You want to log in as hospital,",
      loginTitle: "Login for receivers",
    },
    challengeScreen: {
      infoText:
        "Please verify your account. We’ve sent you an email with a code. Please enter that code below to continue.",
      infoTextTimestamp: (args: { datetimestring: string }) =>
        template("Your code was generated at {{datetimestring}}.", args),
      placeholder: "Your verification code",
      title: "Account verification",
      verificationInvalid:
        "Code invalid - please enter the code from your email.",
      verificationInvalidTimestamp: (args: { datetimestring: string }) =>
        template(
          "Code invalid - please enter the code from your email sent at {{datetimestring}}.",
          args,
        ),
    },
    changeEnv: "Change env",
    changePassword: "Change Password",
    clinic: {
      bannerDemo:
        "You are currently logging in to the test environment. To switch to the production environment, please {select:click here}.",
      changeToReceiver: "You want to log in as receiver,",
      clickHere: "click here.",
      loginTitle: "Login for senders",
      loginTitleDemo: "Login for senders - Demo",
      loginTitlePreprod: "Login for senders - Preprod",
    },
    confirmNewPasswordPlaceholder: "Enter your password again",
    confirmYourPasswordTitle: "Confirm your new password",
    contactUrl: "https://www.recaresolutions.com/kontakt",
    contactUs: "Contact us",
    currentPassword: "Your current password",
    emailAddress: "Your email address",
    encryptionAccountWarning:
      "There is currently no other active Recare user account in your organization. To ensure that you can access the encrypted patient data after resetting the password, make sure that there is another active Recare user account.",
    encryptionGeneralWarning:
      "Encrypted patient data may be unavailable for a short period of time. ",
    enterNewPasswordPlaceholder: "Enter your password",
    enterNewPasswordTitle: "Your new password",
    errorNewPasswordInvalid: "Please enter a valid password.",
    errorPasswordMissing: "Please enter your password",
    failedAlert: {
      text: "Please check your email and password again",
      title: "Login failed",
    },
    forgotPassword: "Password forgotten?",
    forgotPasswordDetails:
      "Please enter your email address. We will send you an email to reset your password.",
    forgotYourPassword: "Forgot your password?",
    haveAnAccountYet: "Don’t have an account yet?",
    hintTemporaryLossOfEncryptedData:
      "Encrypted patient data may be unavailable for a short period of time. Please be patient.",
    linkExpired: {
      activationExpiredReceiver:
        "Unfortunately, the link to activate your Recare account is no longer valid as it only remains active for 5 days for security reasons. Please get in touch with your known Recare contact person to receive a new link.",
      activationExpiredSender:
        "Unfortunately, the link to activate your Recare account is no longer valid as it only remains active for 5 days for security reasons. Please contact your hospital's user manager or the Recare contact person you know in order to receive a new link.",
      buttonToLogin: "To login page",
      linkExpiredTitle: "This link has expired",
      passwordLinkExpiredReceiver:
        "Unfortunately, the link to reset your password is no longer valid as it only remains active for 5 days for security reasons. Please click on “Forgot password?” on the login page to receive a new link. Alternatively, you can ask the Recare contact person for help.",
      passwordLinkExpiredSender:
        "Unfortunately, the link to reset your password is no longer valid as it only remains active for 5 days for security reasons. Please click on “Forgot password?” on the login page to receive a new link. Alternatively, you can ask the user manager at your hospital or the Recare contact person for help.",
    },
    linkExpiredBackToLogin: "Back to login",
    linkExpiredExplanation:
      "Apologies, it seems the activation link has expired as it's only valid for 24 hours. Please initiate a new request for access to your account by contacting your user manager or Recare contact person.",
    linkExpiredTitle: "The activation link has expired!",
    loading: {
      dontLeavePage:
        "This might take a few seconds. Please do not leave or refresh this page.",
      settingEnvironment: "We are setting up your secure environment!",
    },
    loggedAs: "Logged as",
    login: "Login",
    loginPage: {
      accountVerificationInfo:
        "Please verify your account by entering the code we have sent to your email. Enter the code in the field below to continue.",
      challengeCodeValidation:
        "Please enter the confirmation code that was sent to you by e-mail. In the meantime, please do not close this window.",
      clickHere: "Click here",
      clinicInfo: "No provider?",
      clinicLink: "Log in as a clinic",
      confirmNewPasswordValidation:
        "Please re-enter your new password to confirm.",
      contactInfo: "No account yet?",
      contactInfoLink: "Contact us",
      currentPasswordValidation: "Please enter your current password.",
      demoInfoText:
        "You are logging into the demo environment. To switch to the production environment use this link:",
      email: "E-mail address",
      emailPlaceholder: "Enter your e-mail address",
      emailValidation: "Please enter your valid e-mail address",
      encryptionWarning:
        " Encrypted patient data may be unavailable for a short period of time. Please be patient.",
      environmentInfo:
        "You are logging into the demo environment. To switch to the Production environment,",
      errorInactiveAccount:
        "The password cannot be reset as the account is inactive. Please get in touch with your user manager or your Recare contact person.",
      errorInvalidMail: (args: { email: string }) =>
        template(
          "The e-mail address {{email}} you entered is invalid. Please check your entry and try again.",
          args,
        ),
      forgotPassword: "Forgot password?",
      hidePassword: "Hide password",
      loginButton: "Login",
      logInNow: "Log in now",
      newPasswordMatchValidation: "The new passwords you entered do not match.",
      newPasswordValidation: "Please enter a new password.",
      password: "Password",
      passwordAttemptError:
        "You have entered the wrong password too often. Try again later.",
      passwordChangedInfo:
        "Your new password is now active. Please log in with your new credentials.",
      passwordChangedTitle: "Password successfully changed",
      passwordChangeError:
        "Your account has been temporally locked due to too many password changes. Please try again later.",
      passwordChangeInfo:
        "Use a minimum of 8 characters, including numbers (0-9), lower case letters (a-z) and upper case letters (A-Z).",
      passwordIndicatorAriaLabel: "Indicator for password strength",
      passwordMedium: "Medium",
      passwordPlaceholder: "Enter your password",
      passwordResetInfo:
        "No problem! We will send you an email to reset it. Please enter the email address you use to sign in to Recare.",
      passwordResetWrongEmailReceiver:
        "This e-mail address is not associated with a provider account.",
      passwordResetWrongEmailSender:
        "This e-mail address is not associated with a clinic account.\n",
      passwordStrong: "Strong",
      passwordValidation: "Please enter your current password.",
      passwordVeryStrong: "Very strong",
      passwordVeryWeak: "Very weak",
      passwordWeak: "Weak",
      privacyPolicyInfo: "We make no compromises here:",
      productionLink: "Switch to production",
      receiverInfo: "No clinic?",
      receiverInfoLink: "Log in as a provider",
      saveNewPasswordButton: "Save new password",
      sendResetEmailButton: "Send reset email",
      setNewPasswordButton: "Set new password",
      showPassword: "Show password",
      ssoErrorEmailMismatch:
        "Single-Sign-On User [E-Mail2] does not match with initial E-Mail Address at login [E-Mail1]. Please try again.",
      ssoErrorGeneric: "SSO Log in failed, please try again or contact Recare.",
      ssoInfo: "Single sign-on enabled",
      titleAccountVerification: "Account verification",
      titleAcp: "Login ACP",
      titleChangePassword: "Change password",
      titleChangePasswordFromEmail: "Set new password",
      titleDemo: "demo",
      titleReceiver: "Login receiver",
      titleResetPassword: "Forgotten your password?",
      titleSender: "Login clinic",
      toDashboardLink: "Back to dashboard",
      toggleIconAriaLabel: "Click this icon to show the password",
      verificationCode: "Your verification code",
      verificationCodePlaceholder: "Enter code here",
      verificationCodeValidation: "Please enter a valid verification code",
      verificationInfo:
        "Please verify your account by entering the code we have sent to your email. Enter the code in the field below to continue.",
      weakPasswordError:
        "Your password is too weak. Please ensure it's at least medium strength by using at least 8 characters with a mix of letters, numbers, and symbols.",
    },
    loginSender: {
      contactInfo: "No account yet? Contact us",
      email: "E-mail address:",
      emailPlaceholder: "Enter your e-mail address",
      emailValidation: "Please enter a valid e-mail address",
      loginButton: "Login",
      passwordValidation: "Please enter your current password",
      privacyPolicyInfo: "We make no compromises here: Privacy Policy",
      ssoInfo: "Single sign-on enabled",
      title: "Login clinic",
    },
    loginTitle: "LOGIN",
    loginWithTouchId: "or login with biometric ID",
    mfa: {
      deviceVerified: "Your device has been verified. You can now login.",
      invalidToken:
        "The verification attempt has been expired. Please login again for to restart the verification process.",
      newDevice: "New device, please check your email.",
    },
    newPassword: "Your new password",
    newPasswordConfirmation: "Confirm your new password",
    password: "Your password",
    passwordAlert:
      "To reset your password and access the app, please open Recare in a browser",
    passwordChanged: "Password has already been changed",
    passwordChangeFailure: "The current password is invalid.",
    passwordChangeSuccess: "Password changed successfully",
    passwordChangeSuccessLogin:
      "Password changed successfully, please log in again.",
    passwordConfirmation: "Confirm your new password",
    passwordForgottenSuccess:
      "If an account exists with this email address, an email has been sent to you. Please follow the instructions in the email.",
    passwordForgottenTitle: "PASSWORT FORGOTTEN",
    passwordRequirements: "The password must",
    passwordResetError:
      "Please verify the entered email address, since it is not a user account",
    passwordResetSuccessfully:
      "Your new password is now active. Please log in with your credentials.",
    passwordsMustMatch: "Passwords must match",
    passwordValidationLength: "be min. 8 characters long",
    passwordValidationLowerCase: "include lower case letters (a-z)",
    passwordValidationNumeric: "include numbers (0-9)",
    passwordValidationUpperCase: "include upper case letters (A-Z)",
    passwordVisibility: {
      hide: "Hide password",
      show: "Show password",
      ssoErrorGeneric: "SSO login failed, please try again or contact recare.",
    },
    privacyPolicy: "Privacy policy",
    sendResetEmail: "Send email",
    setNewPassword: (args: { email: string }) =>
      template("Enter your new password for {{email}}", args),
    setNewPasswordEncryptionInformation:
      "Encrypted patient data may be unavailable for a short period of time. Please be patient.",
    setNewPasswordInstructions:
      "Use a minimum of 8 characters, including numbers (0-9), lower case letters (a-z) and upper case letters (A-Z). Make sure both passwords match.",
    setNewPasswordLoading: "Setting your new password",
    setNewPasswordNoMatch: "The passwords do not match.",
    setNewPasswordTitle: "Set new password",
    ssoEnabled: "Single sign-on enabled",
    toastInactiveProvider:
      "Your account is not linked to an active institution - please contact Recare.",
    tokenExpired: "Link has expired",
    tokenInvalid: "Link is invalid",
    tokenInvalidContactRecare:
      "This link is no longer valid. Please contact Recare to get a new one: +49 30 229 571 50",
    tooManyAttempts: (args: { time: string }) =>
      template("Too many attempts, try again in {{time}}.", args),
    welcome: "Welcome to Recare!",
    wrongEmailPassword: "Wrong Email or Password",
  },
  menu: {
    addPatientButton: "Add patient",
    faq: "FAQ",
    more: "More",
    navBarSender: {
      ariaLabelMainNavigation: "Main navigation",
      ariaLabelTopLevelNavigation: "Top level navigation",
      subtitleGateway: "Placeholder subtitle Gateway",
      subtitleReport: "Subtitle placeholder report",
      subtitleSearches: "Placeholder subtitle dashboard",
      subtitleUserManagement: "Subtitle user management placeholder",
      titleGateway: "Gateway",
      titleReport: "Report",
      titleSearches: "Dashboard",
      titleUserManagement: "User Management",
    },
    newsletterSubscription: "Newsletter",
    onPremise: "Gateway Administration",
    planning: "Planning",
    privacyPolicy: "Privacy policy",
    providerTraining: "Provider training",
    rehabproviderTraining: "Rehab provider training",
    report: "Report",
    searchBar: "Search patient",
    termsAndConditions: "Terms & Conditions",
    users: "User Management",
  },
  messenger: {
    accessDeniedNeedAccess: (args: { name: string }) =>
      template(
        "The content can currently not be displayed because another user from {{name}} needs to log in to provide access. For further information please contact Recare.",
        args,
      ),
    accessibility: {
      emojiButtonLabel: "Open emojis",
      messageInputFieldLabel: "Enter a message",
      messageLabel: "with message",
      messageSeenNotification: "Your message has been read",
      rejectionInputFieldLabel: "Enter rejection reasons",
      responseRejected: "undo button",
    },
    careStartDateIsInThePastBannerMessage:
      "In case an acceptance is only possible on a later date, please click ACCEPT and enter the desired date.",
    fileReceiverHint: (args: { facility: string }) =>
      template("Your file will be sent to {select:{{facility}}}", args),
    fileReceiversHint: (args: { facility: string; secondFacility: string }) =>
      template(
        "Your file will be sent to {select:{{facility}}} and {select:{{secondFacility}}}",
        args,
      ),
    fileTransferEncrypted:
      "Files can be securely sent. The file transfer is encrypted.",
    messageReceiverHint: (args: { facility: string }) =>
      template("Your message will be sent to {select:{{facility}}}", args),
    messageReceiversHint: (args: {
      facility: string;
      secondFacility: string;
    }) =>
      template(
        "Your message will be sent to {select:{{facility}}} and {select:{{secondFacility}}}",
        args,
      ),
    needToRequestAccess:
      "Currently this content cannot be displayed. In order to see the message history, please request access here",
    notAvailableForTransport: "Action unavailable for transport requests",
    notEncryptedHint:
      "Refrain from sending any sensitive patient data. No messages are encrypted in this case.",
    notSeen: "not seen yet",
    receiver: {
      commissionFee: (args: { price: string }) =>
        template("User fee: {select:{{price}}} EUR", args),
      commissionInfo:
        "Enjoy our free pilot phase - until the 31.12.2022 we do not charge commission fees for the procurement of orders.",
      commissionTooltip:
        "Enjoy our free pilot phase - until the 31.12.2022 we do not charge commission fees for the procurement of orders.",
      fileRestrictionHint:
        "This hospital does not allow to receive files. For text information, please use the chat function.",
      solutionsOffered:
        "Please select which solutions you would like to offer to this patient:",
    },
    receiverHint: "Your message will be sent to",
    requestAccess: "Request access",
    seen: "seen",
    sender: {
      chooseSolution: (args: { careproviderName: string }) =>
        template(
          "{{careproviderName}} is offering the following solutions for this patient. Please select which solution you would like to attribute the patient to: ",
          args,
        ),
      clinic: "Clinic",
      costPayer: "Cost Payer",
      fileRestrictionHint:
        "Your organization has restricted the reception of files",
      geriatrics: "Hospital",
      provider: "Provider",
      rehabClinic: "Rehab Clinic",
    },
    solutionsLabel: "Please select a form of care (required)",
    yourChoice: "Your choice (required) *",
  },
  nativeApp: {
    contactUs: "Contact Recare",
    errorCode: "Error code",
    menu: {
      archived: "Archived",
      attributed: "Attributed",
      dashboard: "Requests",
      details: "Details",
      messages: "Messages",
      pending: "Pending",
      settings: "Settings",
    },
    messages: {
      dateAlert: "Please enter date",
      downloadAlert: "To download this file, please open Recare in a browser",
      inputPlaceholder: "Type here...",
      messageAlert: "Please enter a message",
      timeAlert: "Please enter time",
    },
    pushNotifications: {
      attribution: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} was attributed to you", args),
      },
      attributionCancelled: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("Attribution was cancelled for patient {{patientId}}", args),
      },
      availableAgain: {
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} is available again", args),
      },
      newFile: {
        body: (args: { file: string }) => template("File name: {{file}}", args),
        title: (args: { patientId: string }) =>
          template("New file for patient {{patientId}}", args),
      },
      newMessage: {
        body: (args: { message: string }) => template("{{message}}", args),
        title: (args: { patientId: string }) =>
          template("New message for patient {{patientId}}", args),
      },
      newMessageForClinic: {
        body: (args: { careprovider: string }) =>
          template(" {{careprovider}} has sent you a new message", args),
      },
      newRequest: {
        body: (args: { transferDate: string; diagnosis: string }) =>
          template(
            "Transfer date: {{transferDate}}\nDiagnosis: {{diagnosis}}",
            args,
          ),
        title: (args: { patientId: string; clinic: string }) =>
          template("New patient {{patientId}} from {{clinic}}", args),
      },
      notAvailableAnymore: {
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} is not available anymore", args),
      },
      notAvailableAttribution: {
        body: "Another provider was found",
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} is not available anymore", args),
      },
      notAvailableSearchStopped: {
        body: (args: { reasonOrOther: string }) =>
          template("Search was stopped: {{reasonOrOther}}", args),
        title: (args: { patientId: string }) =>
          template("Patient {{patientId}} is not available anymore", args),
      },
      profileEdited: {
        title: (args: { patientId: string }) =>
          template("Profile was edited for patient {{patientId}}", args),
      },
    },
    settings: {
      capacity: {
        capacityAvailable: "Capacity currently available",
        capacityNotAvailable: (args: { capacityDate: string }) =>
          template("Capacity available again from {{capacityDate}}", args),
        detailPage: {
          ableToAcceptPatients: "We will be able to accept new patients by:\n",
          capacityAvailableInfo: "We currently have capacity",
          hintText: {
            capacityAvailable:
              "You are currently receiving new patient requests due to your availability.\n",
            noCapacityWithoutTimeSelect:
              "You will not receive any further requests and all requests up to the entered date will be rejected. You can change your capacity at any time in the settings.\n",
            noCapacityWithTimeSelect:
              "You will not receive any further requests and all requests up to and including the date will be rejected. You can change your capacity at any time in the settings",
          },
          title: "Your capacities",
          toggle: "No / Yes",
          toggleNoYes: "No / Yes",
        },
        title: "Capacity",
        toastSuccess: "Successfully saved",
      },
    },
    somethingWentWrong: "Something went wrong",
    updateNeeded: {
      pleaseUpdate: "Please update the app",
      skipVersion: "Skip this version",
      updateInfo:
        "We improved our app and data security. To continue using Recare on this device, please update the app to the latest version via the app store.",
    },
  },
  navigations: {
    patient: "Patient Navigation",
    primary: "Primary Navigation",
  },
  networkChecker: {
    retry: "RETRY",
    retryingMessage: (args: { count: string }) =>
      template("A network problem has occured. Retrying in {{count}}...", args),
  },
  newOnboarding: {
    account: {
      caption: "Your account",
      dontEnterCentralEmailCaption:
        "The central email address (ie. info@ or contact@) should not be entered here, but the personal email address for an account.",
      errorEmailDoesntExist:
        "This email address is not yet assigned to a user account. Please register a new user account below.",
      errorEmailExists:
        "This email address is already linked to an active account. Please log in at the top of the page.",
      loginCaption:
        "Please contact Recare under ‭+49 30 56 79 60 57‬ if you encounter any problems with the login.",
      title: "Your contact details",
      titleNoAccount:
        "If you do not have an account, please enter your contact details:",
      titleWithAccount:
        " Do you already have a Recare account? If yes, please log in here:",
    },
    activateAccountTitle: "Activate your Recare account",
    company: {
      caption: "Your company",
      title: "General information",
    },
    congratulations: {
      benefits:
        "You can now enjoy all the benefits of being a registered member of Recare. We are looking forward to supporting you in your everyday life!",
      contactUs:
        "Contact us at +49 30 56 79 60 57 in case of any further questions.",
      goToProfile: "Go to my profile",
      thankYou:
        "Thank you, we are looking forward to a successful collaboration!",
      title: "Congratulations!",
      yourTeam: "Your Recare team",
    },
    password: {
      caption: "Your password",
      cardTitle: "Please create your password",
      checkInbox:
        "Please check your inbox and follow the instruction to complete the last steps of your onboarding.",
      didOpenSetPassword:
        "Thank you for opening the link for registration. You can now close this window.",
      emailSent: (args: { email: string }) =>
        template("We have sent an email to {select:{{email}}}", args),
      goBack: "previous page",
      title: "You've received an email",
      wrongEmail: "Wrong email address? Go back to the",
    },
    personalOnboarding: {
      newsletterSubscription:
        "Yes, I want to subscribe to the monthly newsletter and therefore gain exclusive insights into Recare's digital world, health industry news and more.",
      title: "Create your Recare account for",
    },
    services: {
      caption: "Your services",
    },
    testrequest: {
      caption: "Test request",
    },
    welcome: {
      getStarted: "Get started",
      recareIsFreeEnd: "or call us at",
      recareIsFreeHere: "here",
      recareIsFreeNumber: "+49 30 56 79 60 57.",
      recareIsFreeStart:
        "Recare is completely free for you. You can find more information about us",
      thanksToRecare: "Thanks to Recare you will:",
      title: "Welcome!",
      updateInformation:
        "You will be able to update your information any time in your settings.",
      willAccept: "accept or decline patients requests in 1 click",
      willCommunicate:
        "communicate via secured messages with hospital staff to discuss patients requests",
      willReceive:
        "receive targeted patients requests via email from clinics near you",
    },
    welcomeToRecareTitle: "Welcome to Recare",
    welcomeToTestTitle:
      "Welcome to the test request: in order to complete your registration, please click on ACCEPT or DECLINE.",
  },
  newOnboardingTwo: {
    account: {
      alreadyHaveAccount:
        "Already have a Recare account? {select:Click here} to log in",
      dontEnterCentralEmailCaption:
        "The central email address (ie. info@ or contact@) should not be entered here, but the personal email address for an account.",
      dontHaveAccount:
        "Don't have an account yet? {select:Click here} to register.",
      errorEmailDoesntExist:
        "This email address is not yet assigned to a user account. Please register a new user account below.",
      errorEmailExists:
        "This email address is already linked to an account. Please log in at the top of the page, choose another email address or {select:reset the password for this account}.",
      genericError: "Something went wrong, please contact Recare",
      goToLogin: "Go to login",
      goToRegister: "Go to account creation",
      hintAlreadyHaveAnAccount: "Already have a Recare account? ",
      hintDontHaveAccount: "Don't have an account yet? ",
      loginToAccount: "Login to your account",
      title: "Personal details",
    },
    additionalnformation: {
      facilitySize: {
        employees: "1-5 employees",
        facilitySizeTitle: "What is the size of your facility?",
        large: "20+ employees",
        medium: "5-20 employees",
      },
      informationSystem:
        "Which Information System does your facility work with?",
      introductionText:
        "In order to optimise the use of the platform for your institution, you have the option to provide further information about your institution. All information is voluntary. They will have no influence on your care options and will not be visible to clinics or other facilities. Please support us with your information for the purpose of a general evaluation so that we can make the platform as user-friendly as possible despite the diversity of facilities.",
      newFacilityOpened:
        "Has your facility had a new opening in the last 6 months?",
      responsiblePerson: {
        lessThanTwo: "Less than 2",
        moreThanTwo: "More than 2",
        none: "None",
        responsiblePersonTitle:
          "How many people are responsible for patient admission at your facility?",
      },
      useOfWaitlists: "Does your facility work with waiting lists?",
    },
    companyDetails: {
      disclaimerLegalDocuments:
        "Please note: Confirmation of documents may only be carried out by employees who have the internal authorisation to do so.",
      errorRequiredDataProcessingAgreement:
        "Please accept the data processing agreement ",
      title: "General information about your company",
    },
    congratulations: {
      benefits:
        "You can now enjoy all the benefits of being a registered member of Recare. We are looking forward to supporting you in your everyday life!",
      contactUs:
        "Contact us at +49 30 56 79 60 57 in case of any further questions.",
      getStarted: "Get started",
      thankYou:
        "Thank you, we are looking forward to a successful collaboration!",
      title: "Congratulations!",
      yourTeam: "Your Recare team",
    },
    errors: {
      invalidToken: "Error: invalid token",
      sealdError: "Error: external Seald server error",
      unknown: "Error: unknown",
    },
    password: {
      errorRequiredTermsAndConditions:
        "Please accept the Terms and Conditions.",
      termsAndConditions:
        "I have read and agree to the {select:Terms and Conditions}",
      title: "Create your password",
    },
    receivedMail: {
      checkInbox:
        "Please check your inbox and follow the instruction to complete the last steps of your onboarding.",
      didOpenSetPassword:
        "Thank you for opening the link for registration. You can now close this window.",
      emailSent: (args: { email: string }) =>
        template("We have sent an email to {select:{{email}}}", args),
      goBack: "previous page.",
      title: "You've received an email!",
      wrongEmail: "Wrong email address? Go back to the",
    },
    selfOnboardingEmployee: {
      account: {
        providerInfo:
          "You are about to create an account for {select: providerName}",
        title: "Create your Recare account",
      },
    },
    services: {
      title: "Range of services",
    },
    stepper: {
      account: "Your account",
      additionalInformation: "Additional Information",
      additionalinformation: "Additional Information",
      company: "Company details",
      password: "Your password",
      services: "Offered services",
      step: "Step",
      testrequest: "Test request",
      yourcompany: "Your company",
      yourteam: "Your team",
    },
    welcome: {
      getStarted: "Get started",
      recareIsFree:
        "Recare is completely free for you. You can find more information about us {select:here} or call us at +49 30 56 79 60 57.",
      thanksToRecare: "Thanks to Recare you will:",
      title: "Welcome!",
      updateInformation:
        "You will be able to update your information any time in your Settings.",
      willAccept: "accept or decline patients requests in one click",
      willCommunicate:
        "communicate via secured messages with hospital staff to discuss patients requests",
      willReceive:
        "receive targeted patients requests via email from clinics near you",
    },
  },
  notification: {
    error: "Error",
    info: "Info",
    success: "Success",
  },
  npsSurvey: {
    npsCommentPlaceholder: "Please specify",
    npsCommentSend: "Send",
    npsCommentTitleDetractors: "How can we improve your experience?",
    npsCommentTitlePromoters: "What do you like most/least about Recare?",
    npsGoal:
      "Our goal is to create the best possible product, and your thoughts, ideas, and suggestions play a major role in helping us identify opportunities to improve.",
    npsSurveyScoreHigh: "Extremely likely",
    npsSurveyScoreLow: "Not at all likely",
    npsSurveyTitle:
      "How likely are you to recommend Recare to your colleagues?",
    npsThankYou: "Thanks for your feedback.",
  },
  onpremSetup: {
    connectionSuccessfull: "connection to gateway successfull",
    currentVersion: "installed version",
    installation: {
      dockerVersion: "Gateway as docker container",
      documentation: "Documentation",
      downloadBinary: "Download installer as zip",
      downloadCompose: "Download docker-compose file",
      gatewayInstallation: "Gateway Installation",
      latestVersion: "latest version",
      windowsVersion: "Gateway as installer für Windows",
    },
    logs: {
      acpCta: "Send Logs",
      acpDesc: "Send today's logs summary to Datadog",
      acpTitle: "Send Logs to Datadog",
      errorNoLogs: "There have been no logs in the last 48 hours",
      message: "Message",
      timestamp: "Timestamp",
      title: "Logs",
    },
    newVersionAvailable: "A new version is available",
    pastImports: {
      globalStats: (args: { num: string }) =>
        template("Global stats (last {{num}} imports)", args),
      imports: "Past Imports",
    },
    testImport: {
      explanationGetFiles:
        "Here you can view all files currently stored on the gateway. To display the files, please click on ‘Show files stored on gateway’.",
      explanationImportPatient:
        "Please first enter the case number of the case to be tested and then click on ‘Import’. The imported data fields will then be displayed at the bottom of the page. It is also possible to test the file connections for this patient.",
      explanationLastMessages:
        "The last HL7v2 messages received for this case are displayed here (max. 5).\nThese messages are only displayed here, they are not transmitted to Recare.",
      explanationSendFiles:
        "To check whether the connection from the gateway to your HIS is working, you can send test files to the HIS here. To do this, click on ‘Send test files to HIS’.",
      filesSection: "Document transfer gateway <> web app",
      getFiles: "Show files stored on gateway",
      guardians: "Guardians",
      importPatient: "Import Patient",
      insuranceMapping: "Mapping in app",
      lastMessages: "Last Messages",
      mapping: "mapping in config",
      mappingFailed: "mapping failed",
      missingData: "missing data",
      noDataProvided: "no data provided",
      noMappingConfig: "no mapping in config",
      patientContact: "Patient Contact Info",
      relatives: "Relatives",
      sendFile: "Send testfiles to HIS",
      testHisImport: "HIS Import - Test",
    },
  },
  ontologies: {
    academicTitle: {
      key: "Title",
      values: {
        doctor: "Dr.",
        doctorDoctorMedicine: "Dr. Dr. med.",
        doctorMedicine: "Dr. med.",
        doctorMultiplex: "Dr. mult.",
        professor: "Prof.",
        professorDoctor: "Prof. Dr.",
        professorOrdinarius: "Univ. Prof.",
      },
    },
    accountRole: {
      key: "Role",
      values: {
        accountManager: "ACCOUNT MANAGER",
        admin: "ADMIN",
        adminEditCareprovider: "EDIT PROVIDER",
        adminEditCareseeker: "EDIT HOSPITAL",
        adminEditConsultants: "Edit consultants",
        adminReadOnly: "READ ONLY",
        adminTranslations: "TRANSLATIONS",
        admissions: "Admissions",
        analytics: "ANALYTICS",
        care: "CARE",
        careseekerIT: "IT",
        careseekerSSOUserManagement: "SSO USER MANAGEMENT",
        careseekerUserManagement: "USER MANAGEMENT",
        costApprover: "Cost Approver",
        edminEditConsultants: "Edit consultants",
        homeCare: "Home care ",
        hospital: "HOSPITAL",
        it: "IT CONTACT",
        jourFixe: "JOUR FIXE CONTACT",
        management: "MANAGEMENT",
        medicalSupplies: "MEDICAL SUPPLIES + HOMECARE",
        projectManager: "PROJECT MANAGER",
        protectedPatient: "Protected Patient",
        provider: "ACCESS",
        providerContact: "CONTACT",
        rehab: "REHAB",
        report: "REPORT",
        sales: "SALES",
        senderApi: "Sender API",
        transport: "TRANSPORT",
        voice: "VOICE",
      },
    },
    accountRoleShort: {
      key: "RoleShort",
      values: {
        accountManager: "ACCOUNT MANAGER",
        admin: "ADMIN",
        adminEditCareprovider: "EDIT PROVIDER",
        adminEditCareseeker: "EDIT HOSPITAL",
        adminReadOnly: "READ ONLY",
        adminTranslations: "TRANSLATIONS",
        care: "CARE",
        careseekerSSOUserManagement: "SSO USER MANAGEMENT",
        careseekerUserManagement: "USER MANAGEMENT",
        costApprover: "Cost Approver",
        edminEditConsultants: "EDIT CONSULTANTS",
        homeCare: "Home care ",
        hospital: "HOSPITAL",
        it: "IT",
        jourFixe: "JF",
        management: "Mgmt",
        medicalSupplies: "MEDICAL SUPPLIES + HOMECARE",
        projectManager: "PM",
        protectedPatient: "Protected Patient",
        provider: "ACCESS",
        providerContact: "CONTACT",
        rehab: "REHAB",
        report: "REPORT",
        sales: "SALES",
        senderApi: "API Sender",
        transport: "TRANSPORT",
      },
    },
    accountStatus: {
      key: "Status",
      values: {
        active: "Active",
        created: "Created",
        inactive: "Inactive",
      },
    },
    accountType: {
      key: "Account Type",
      values: {
        api: "External",
        bToC: "B2C",
        callcenter: "Callcenter",
        careprovider: "Careprovider",
        clinic: "Clinic",
        external: "External",
        staff: "Recare staff",
        tool: "Tool",
      },
    },
    ageOfPatient: {
      key: "Age of patients",
      values: {
        children: "Children",
        patientsBelow70: "Patients below 70",
        patientsOver70: "Patients over 70",
      },
    },
    blacklistReceiverRules: {
      key: "Sender Receiver Rules",
      values: {
        age: "Age",
        blacklistedInsurance: "Blacklisted Insurance",
        capacityBackoff: "Capacity Backoff",
        capacityStartDate: "Capacity Start Date",
        contacted: "Contacted",
        distance: "Distance",
        filterContacted: "Contacted",
        noBedAvailable: "No Bed Available",
        prerequisite: "Prerequisite",
        receiverBlacklisted: "Receiver Blacklisted",
        removedSolution: "Removed Solution",
        senderBlacklisted: "Sender Blacklisted",
        service: "Service",
        solution: "Solution",
        specialization: "Specialization",
        status: "Status",
        throttle: "Throttle",
        zipcodes: "Zipcodes",
      },
    },
    candidatesProgress: {
      key: "Candidate calculation progress",
      values: {
        failed: "Candidate calculation failed",
        inProgress: "Determining potential providers",
        notStarted: "Candidates calculation not started",
        success: "Candidate calculation succeeded",
      },
    },
    cardiovascularExploration: {
      key: "Cardiovascular exploration",
      values: {
        cardiacMri: "Cardiac MRI",
        coronarography: "Coronarography",
        coronaryScanner: "Coronary scanner",
        exerciseTest: "Exercise test",
        holterEcg: "Holter ECG",
        rhythmology: "Rhythmology",
        toe: "TOE",
        tte: "TTE",
      },
    },
    careDurations: {
      key: "Care durations",
      values: {
        fiveMonths: "5 Months",
        fourMonths: "4 months",
        oneDay: "1 day",
        oneMonth: "1 month",
        oneWeek: "1 week",
        sixMonthsPlus: "6+ months",
        threeMonths: "3 months",
        threeWeeks: "3 weeks",
        twoMonths: "2 months",
        twoWeeks: "2 weeks",
        unknown: "Unknown",
      },
    },
    carelevel: {
      key: "Carelevel",
      values: {
        five: "5",
        four: "4",
        none: "none",
        one: "1",
        three: "3",
        two: "2",
        unknown: "unknown",
      },
    },
    careproviderStatus: {
      key: "Status",
      values: {
        active: "Active",
        created: "Created",
        duplicate: "Duplicate",
        filledProfile: "Filled profile",
        inactive: "Inactive",
        invalid: "Invalid",
        notAnswered: "not answered",
        pendingAccountActivation: "Pending password activation",
        test: "test",
        testNotAnswered: "Test not answered",
        testSent: "Test sent",
        testtest: "Test2",
        trash: "Trash",
      },
    },
    careseekerModuleStatus: {
      key: "Careseeker - Module Status",
      values: {
        active: "Active",
        created: "Created",
        inactive: "Inactive",
        onboarding: "Onboarding",
        test: "Test phase",
      },
    },
    careseekerPriority: {
      key: "Priority",
      values: {
        high: "High",
        low: "Low",
        medium: "Medium",
      },
    },
    careseekerStatus: {
      key: "Status",
      values: {
        active: "Active",
        created: "Created",
        duplicate: "Duplicate",
        inactive: "Inactive",
        lost: "Lost",
        notAnswered: "Not answered",
        onboarding: "Onboarding",
        profileFilled: "Profile filled",
        salesa: "Sales",
        test: "Test phase",
        trash: "Trash",
      },
    },
    communication: {
      key: "Patient's communication",
      values: {
        bad: "Bad",
        good: "Good",
        moderate: "Moderate",
      },
    },
    companyStatus: {
      key: "Company status",
      values: {
        created: "Created",
        duplicate: "Duplicate",
        inactive: "Inactive",
        inProgress: "In Progress",
        missingInformation: "Missing Information",
        no: "Invalid",
        notReached: "Not reached",
        yes: "Yes/Go",
      },
    },
    companyType: {
      key: "Company Status",
      values: {
        facility: "Facility",
        organization: "Organization",
      },
    },
    compressionType: {
      key: "Compression type",
      values: {
        bandage: "Bandage",
        socks: "Socks",
      },
    },
    confession: {
      key: "Confession",
      values: {
        catholic: "Catholic",
        jewish: "Jewish",
        muslim: "Muslim",
        protestant: "Protestant",
      },
    },
    consultantStatus: {
      key: "Consultant status",
      values: {
        active: "Active",
        created: "Created",
        inactive: "Inactive",
      },
    },
    declinedReason: {
      key: "Declined reason",
      values: {
        geographicallyUnattractive: "Geographically Unattractive",
        noCapacity: "No capacity",
        noCapacityForPatient: "No capacity for this patient",
        other: "Other",
        payer: "We decline because of the payer",
      },
    },
    dependencyLevel: {
      key: "Help during Activities of daily living",
      values: {
        aLot: "A lot",
        none: "None",
        some: "Some",
      },
    },
    digestiveEndoscopy: {
      key: "Digestive endoscopies",
      values: {
        colonoscopy: "Colonoscopy",
        endoscopicUltrasound: "Endoscopic ultrasound",
        ercp: "ERCP",
        gastroscopy: "Gastroscopy",
      },
    },
    eligibilityRejectReason: {
      key: "Reason",
      values: {
        anotherFormRehaSought: "Another form of Rehab is sought",
        differentCostPayerResponsible: "A different cost-payer is responsible",
        otherReason: "Other reason",
        patientNotEligible: "Patient is not eligible",
      },
    },
    emailType: {
      key: "Mail type",
      values: {
        accountAdded: "Receiver: Account added",
        accountInvited: "Receiver: Account invited",
        assigneeChanged: "Patient assignee changed",
        attributionCancellation: "Attribution cancellation",
        attributionConfirmation: "Attribution confirmation",
        auctionRequest: "Auction Request",
        encryptedMessage: "Encrypted message sent",
        feedbackSurvey: "Feedback survey",
        newAnswer: "Sender: Accept notification",
        newMessageFromClinic: "Receiver: Message from clinic",
        onboarding: "Self-onboarding email",
        passwordChanged: "Password changed",
        profileEdited: "Patient profiled edited",
        receiverAccepted: "Provider: Accept confirmation",
        receiverRejected: "Receiver: Rejection notification",
        resetPassword: "Passwort reset",
        searchReminder: "Search reminder",
        senderRejectionConfirmation: "Sender: Rejection confirmation",
        testRequest: "Test request",
        testValidation: "Test validation",
        welcomeEmail: "Welcome email",
      },
    },
    employmentStatus: {
      key: "Status of employment",
      values: {
        civilServant: "Civil servant",
        employee: "Employee",
        masterForeman: "Master foreman",
        notEmployed: "Not employed",
        selfEmployed: "Self employed",
        semiSkilledWorker: "Semi skilled worker",
        skilledWorker: "Skilled worker",
        trainee: "Trainee",
        unskilledEmployee: "Unskilled employee",
      },
    },
    facility: {
      key: "Type of institution",
      values: {
        ambulance: "Ambulance",
        ambulanceCarryChair: "Ambulance - Carrying chair",
        ambulanceLyingDown: "Ambulance - Lying down",
        ambulanceWheelchair: "Ambulance - Wheelchair",
        assistedLiving: "Assisted Living",
        companionship: "Help at home",
        dailyLivingSupportServices: "Daily living support services",
        dayCare: "Day care",
        dayHospitalization: "Day Hospitalization",
        fullHospitalization: "Full Hospitalization",
        fullTimeCompanionship: "24 hours Companionship (mobile)",
        fullTimeMobileCare: "24 hours mobile care (mobile)",
        hospice: "Hospice association",
        hospiceMobile: "Mobile hospice",
        hospicePartiallyMobile: "Partially mobile hospice",
        hospiceStatic: "Static hospice",
        medicalSupplies: "Medical Supplies",
        medicalSuppliesHomeCare: "Home Care",
        mobileCare: "Mobile Care",
        nightCareMobile: "Mobile night care",
        nightCareStatic: "Static night care",
        pureIntensiveCare: "Pure Intensive Care",
        rehabDayHospitalization: "Day Hospitalization",
        rehabDayHospitalizationWithTransport:
          "Day Hospitalization with transport",
        rehabFullHospitalization: "Full Hospitalization",
        rehabMobile: "Mobile",
        sharedFlat: "Shared Flat",
        shortTermStaticCare: "Short term static care",
        staticCare: "Static care",
        taxi: "Taxi",
        taxiCarryChair: "Taxi - Carrying chair",
        taxiLyingDown: "Taxi - Lying down",
        taxiWheelchair: "Taxi - Wheelchair",
      },
    },
    fileCategory: {
      key: "Type of file category",
      shortValues: {
        ad: "AD",
        and: "OTH",
        apf: "CLC",
        bix: "BIX",
        btr: "BTR",
        cc: "CC",
        ctb: "CTB",
        ecg: "ECG",
        err: "ERR",
        fso: "FSC",
        gi: "GI",
        img: "IMG",
        iso: "ISC",
        mdl: "MDL",
        mp: "MP",
        mr: "MR",
        msr: "MSR",
        pcr: "PCR",
        pr: "PR",
        pre: "PRE",
        rba: "RBA",
        rer: "RER",
        sn: "SN",
        wp: "WP",
      },
      values: {
        ad: "Administrative document",
        and: "Other",
        apf: "Cover letter care home",
        bix: "Barthelindex",
        btr: "Blood test result",
        cc: "Cost coverage",
        ctb: "Care transition document",
        ecg: "ECG",
        err: "ER report",
        fso: "Form social station",
        gi: "Germ infection",
        img: "Images/X-rays",
        iso: "Information social center",
        mdl: "Medical discharge letter",
        mp: "Medication Plan",
        mr: "Medical Report",
        msr: "Medical supplies regulation (Muster 16)",
        pcr: "PCR COVID",
        pr: "Previous report",
        pre: "Prescription Template (16)",
        rba: "Rehab application",
        rer: "Remedies regulation (Muster 13)",
        sn: "SINGER",
        wp: "Wound protocol",
      },
    },
    fileError: {
      key: "File Error",
      values: {
        fileSize: "Invalid file size",
        fileType: "Invalid file type",
      },
    },
    fileShareMode: {
      key: "File share mode",
      values: {
        all: "All potential receivers",
        none: "No one",
        selected: "Selected receivers",
      },
    },
    foodHelp: {
      key: "Type of help",
      values: {
        gastrointestinalProbe: "Gastrointestinal probe",
        helpWithEating: "Help with eating",
        mealPreparation: "Meal preparation",
      },
    },
    formYesNo: {
      key: "Choose if true",
      values: {
        no: "no",
        yes: "yes",
      },
    },
    gender: {
      key: "Gender",
      values: {
        diverse: "Diverse",
        female: "Female",
        male: "Male",
      },
    },
    genderExtended: {
      key: "Gender",
      values: {
        diverse: "Diverse",
        female: "Female",
        male: "Male",
        withoutEntry: "without entry",
      },
    },
    genderLetter: {
      key: "GenderLetter",
      values: {
        female: "F",
        male: "M",
        other: "*",
      },
    },
    grantStatus: {
      key: "Grant status",
      unselectedItems: "Unselected items:",
      values: {
        applied: "Applied for",
        exists: "Exists",
        notApplied: "Not applied for yet",
      },
    },
    grantsType: {
      key: "Payout",
      values: {
        combinationOfBoth: "Combination of both",
        fixedPayout: "Fixed payout",
        serviceBasedPayout: "Service-based payout",
      },
    },
    healthInsuranceType: {
      key: "Health insurance type",
      values: {
        private: "Private",
        public: "Public (licensed partner of public health insurances)",
      },
    },
    imagery: {
      key: "Imagery",
      values: {
        ctScan: "CT Scan",
        dopplers: "Dopplers",
        interventionalRadiology: "Interventional radiology",
        mri: "MRI",
        ultrasound: "Ultrasound",
        xRay: "X-Ray",
      },
    },
    incontinenceHelpType: {
      key: "Type",
      values: {
        diapers: "Diapers",
        katheter: "Katheter",
        stoma: "Stoma",
      },
    },
    infectionAndGerms: {
      key: "Current Infections",
      values: {
        clostridien: "Clostridien",
        four_mrgn: "4MRGN",
        mrsa: "MRSA",
        requires_isolation: "Requires isolation",
        three_mrgn: "3MRGN",
        vre: "VRE",
      },
    },
    informationSystems: {
      key: "Information system",
      values: {
        connextVivendi: "Connext Vivendi",
        curasoft: "Curasoft",
        danProdukte: "Dan Produkte",
        godoSystems: "GODO Systems",
        medifox: "Medifox",
        myneva: "myneva",
        noventiCare: "NOVENTIcare",
        other: "Other",
        senso: "SENSO",
        sinfonie: "Sinfonie",
        snap: "snap",
      },
    },
    injectionType: {
      key: "Injection type",
      values: {
        muscular: "muscular",
        subcutunae: "subcutunae",
      },
    },
    invoiceStatus: {
      key: "Status",
      values: {
        invoiceCreated: "Created",
        invoiceInvalidated: "Invalidated",
        invoicePaid: "Paid",
        invoiceSent: "Sent",
      },
    },
    invoiceType: {
      key: "Type",
      values: {
        remoteWorkshop: "Remote workshop",
        services: "Services",
        servicesAdvance: "Services Advance",
        setupFee: "Setup",
        workshop: "Workshop",
      },
    },
    language: {
      key: "Language",
      values: {
        afghan: "Afghan",
        albanian: "Albanian",
        amharic: "Amharic",
        arabic: "Arabic",
        azerbaijani: "Azerbaijani",
        bengal: "Bengal",
        bosnian: "Bosnian",
        bulgarian: "Bulgarian",
        cantonese: "Cantonese",
        croatian: "Croatian",
        czech: "Czech",
        danish: "Danish",
        dutch: "Dutch",
        english: "English",
        finnish: "Finnish",
        french: "French",
        georgian: "Georgian",
        german: "German",
        greek: "Greek",
        hebrew: "Hebrew",
        hindi: "Hindi",
        hungarian: "Hungarian",
        indonesian: "Indonesian",
        italian: "Italian",
        japanese: "Japanese",
        korean: "Korean",
        kurdish: "Kurdish",
        lithuanian: "Lithuanian",
        mandarin: "Mandarin",
        norwegian: "Norwegian",
        persian: "Persian/Farsi",
        polish: "Polish",
        portuguese: "Portuguese",
        romanian: "Romanian",
        russian: "Russian",
        serbian: "Serbian",
        sign: "Sign language",
        slovenian: "Slovenian",
        spanish: "Spanish",
        swahili: "Swahili",
        swedish: "Swedish",
        thai: "Thai",
        tigrinya: "Tigrinya",
        turkish: "Turkish",
        ukrainian: "Ukrainian",
        urdu: "Urdu",
        vietnamese: "Vietnamese",
      },
    },
    livingSituation: {
      key: "Living situation",
      values: {
        livingSituationAlone: "Alone",
        livingSituationCarehome: "In an elderly home or carehome",
        livingSituationInElderlyHome: "In elderly home",
        livingSituationOther: "Other",
        livingSituationSharedFlat: "In a shared flat/assisted living",
        livingSituationWithFamily: "With family",
      },
    },
    maritalStatus: {
      key: "Marital status",
      values: {
        divorced: "Divorced",
        married: "Married",
        single: "Single",
        widowed: "Widowed",
      },
    },
    meals: {
      key: "meals",
      values: {
        breakfast: "breakfast",
        dinner: "dinner",
        lunch: "lunch",
      },
    },
    mentalHealthCommunication: {
      key: "Patient Communication",
      value: {
        bad: "Bad",
        good: "Good",
        moderate: "Moderate",
      },
      values: {
        bad: "bad",
        placeholder: "placeholder",
      },
    },
    mobility: {
      key: "Patient's mobility",
      values: {
        crutch: "Crutch",
        medicalisedBed: "Medicalised bed",
        rollator: "Rollator",
        walkingFrame: "Walking frame",
        walkingStick: "Walking stick",
        walkWithHelp: "Walk with help",
        walkWithoutHelp: "Walk without help",
        wheelChair: "Wheel chair",
      },
    },
    motivation: {
      key: "Patient's motivation",
      values: {
        bad: "Bad",
        good: "Good",
        moderate: "Moderate",
      },
    },
    onboardingStatus: {
      key: "Onboarding Status",
      values: {
        administrationPhase: "Proposal Sent",
        callBackLater: "Call back later",
        chain: "Part of a chain",
        complicated: "Complicated",
        considerationPhase: "Consideration Phase",
        infoPhase: "To Call",
        later: "Later",
        manuallyAdded: "Manually added by sender",
        missingInformation: "Missing information",
        noActionFromCallcenter: "No action from callcenter",
        noComment: "No comment",
        noInterest: "No interest",
        notReached: "Not reached",
        notReachedCallCenter: "Not reached - CC",
        other: "Other",
        preOnboarding: "Leads",
        profileFilled: "Form filled",
        resendOnboardingEmail: "Resend onboarding email",
        selfOnboarding: "Self-onboarding",
        technicalIssues: "IT issues",
        toCall: "To Call",
      },
    },
    orientation: {
      key: "Patient's orientation",
      values: {
        disoriented: "Disoriented",
        limited: "Limited",
        orientated: "Orientated",
      },
    },
    otherTechnicalEquipment: {
      key: "Available",
      values: {
        dialysis: "Dialysis",
        monitoringBreathing: "Monitoring: Breathing",
        monitoringCirculation: "Monitoring: Circulation",
        monitoringMetabolism: "Monitoring: Metabolism",
      },
    },
    paginationResultCount: {
      key: "Pagination count",
      values: {
        fifty: "50",
        ten: "10",
        thirty: "30",
        twenty: "20",
      },
    },
    patientType: {
      key: "PatientType",
      values: {
        care: "CARE",
        homeCare: "HOME CARE",
        hospital: "HOSPITAL",
        medicalSupplies: "MEDICAL SUPPLIES",
        rehab: "REHAB",
        transport: "TRANSPORT",
      },
    },
    position: {
      key: "position",
      values: {
        accountManagement: "Account Management",
        aufnahmemanagement: "Aufnahmemanagement",
        belegungsmanagement: "Belegungsmanagement",
        casemanagement: "Casemanagement",
        chefarztIn: "Chefarzt*in",
        chefarztsekretariat: "Chefarztsekretariat",
        controlling: "Controlling",
        direktorIn: "Direktor*in",
        disposition: "Disposition",
        einrichtungsleitung: "Einrichtungsleitung",
        einsatzleitung: "Einsatzleitung",
        einzugsberatung: "Einzugsberatung",
        fachbereichsleitungOrRegionalleitung:
          "Fachbereichsleitung/Regionalleitung",
        geschaftsfuhrung: "Geschäftsführung",
        gesellschafter: "Gesellschafter",
        inhaber: "Inhaber",
        it: "IT",
        keyAccountManagement: "Key Account Management",
        oberarztIn: "Oberarzt*in",
        patientenaufnahme: "Patientenaufnahme",
        pflegedienstleitung: "Pflegedienstleitung",
        pflegefachkraft: "Pflegefachkraft",
        projektManager: "Projekt Manager",
        qualitatsmanagement: "Qualitätsmanagement",
        rehaplanung: "Rehaplanung",
        sachbearbeiterIn: "Sachbearbeiter*in",
        sozialdienst: "Sozialdienst",
        stellvPflegedienstleitung: "stellv. Pflegedienstleitung",
        teamleitung: "Teamleitung",
        vertriebOrVertriebsleitungOrMitarbeiterVertrieb:
          "Vertrieb/Vertriebsleitung/Mitarbeiter Vertrieb",
        verwaltung: "Verwaltung",
        wohnbereichsleitung: "Wohnbereichsleitung",
        zentraleAufnahme: "Zentrale Aufnahme",
      },
    },
    priorEmploymentStatus: {
      key: "Previous profession or before incapacity for work",
      values: {
        fullTimeNoShifts: "Full time without shifts",
        fullTimeWithShifts: "Full time with shifts",
        householdCare: "Household care",
        notEmployedRegistered: "Not employed registered",
        notWorking: "Not working",
        partTimeLessThanHalfDay:
          "Part time job, less than half of normal working day",
        partTimeMoreThanHalfDay:
          "Part time job, more than half of normal working day",
        wihtNightShifts: "Work with night shifts",
        workAtHome: "Work at home",
        workshopForDisabledPeople: "Workshop for disabled people",
      },
    },
    productFeature: {
      key: "Product Feature",
      values: {
        recareAdmissions: "Recare Admissions",
        recareAnalytics: "Recare Analytics",
        recareVoice: "Recare Voice",
        transitionalCare: "Transitional Care",
      },
    },
    providerSearchNavTitle: {
      key: "providerSearchNavTitle",
      values: {
        bavaria: "Pflegefinder Bayern",
      },
    },
    providerSearchRequestStatus: {
      key: "Provider search request status",
      values: {
        accepted: "Accepted",
        attributed: "Attributed",
        created: "Pending",
        declined: "Declined",
        rejected: "Rejected",
        sent: "Pending",
        unavailable: "Cancelled",
      },
    },
    receiverInactiveReason: {
      key: "Inactive Reason",
      values: {
        asklepiosInhouseSolution: "Asklepios Inhouse Solution",
        churned: "was active, not now",
        closed: "Closed",
        higherLevelDecision: "Higher-level decision",
        medianInhouseSolution: "Median Inhouse Solution",
        neverReachedAgain: "Never reached",
        noAccount: "No Account",
        noInterestDoNotContact: "No interest - Do not contact",
        noInterestMaybeLater: "No interest - Maybe later",
        temporaryClosed: "Temporary closed / paused",
        unneededChainCenter: "headquarters inactive - subsidiary active",
        unneededChainSubsidiary: "headquarters active - subsidiary inactive",
        unsupportedFacility: "Unsupported facility",
      },
    },
    receiverTypePlural: {
      key: "Receiver Type (Plural)",
      values: {
        care: "providers",
        homeCare: "Suppliers",
        hospital: "hospitals",
        medicalSupplies: "Suppliers",
        rehab: "clinics",
        transport: "transport providers",
      },
    },
    receiverTypePluralPreferred: {
      key: "Receiver Type (Plural)",
      values: {
        care: "preferred providers",
        homeCare: "preferred suppliers",
        hospital: "preferred hospitals",
        medicalSupplies: "preferred suppliers",
        rehab: "preferred clinics",
        transport: "preferred transport providers",
      },
    },
    receiverTypeSingular: {
      key: "Receiver Type (Plural)",
      values: {
        care: "provider",
        homeCare: "Supplier",
        hospital: "hospital",
        medicalSupplies: "Supplier",
        rehab: "clinic",
        transport: "transport provider",
      },
    },
    receiverTypeSingularPreferred: {
      key: "Receiver Type (Plural)",
      values: {
        care: "preferred provider",
        careAb: "patient's wish",
        homeCare: "preferred supplier",
        hospital: "preferred hospital",
        medicalSupplies: "preferred supplier",
        rehab: "preferred clinic",
        transport: "preferred transport provider",
      },
    },
    rehabilitationPotential: {
      key: "Rehabilitation Potential",
      values: {
        evaluationExtremelyNegative: "Extremely Negative",
        evaluationExtremelyPositive: "Extremely Positive",
        evaluationModerate: "Moderate",
        evaluationNegative: "Negative",
        evaluationPositive: "Positive",
      },
    },
    reliefServices: {
      key: "Relief services",
      values: {
        paragraphA: "According to §45a SGB XI",
        paragraphB: "According to §45b SGB XI",
      },
    },
    roomType: {
      key: "Type of room",
      values: {
        accompanyingChild: "Adult Rehab: Children",
        accompanyingPerson: "Rooms suitable for an accompanying person",
        accompanyingSibling: "Children Rehab: Siblings",
        apartment: "Apartment",
        barrierFree: "Barrier-free rooms",
        double: "Double room",
        no_preference: "No preference",
        single: "Single room",
      },
    },
    rowsPerPage: {
      key: "Rows per page",
      values: {
        fifteenRows: "15 per page",
        fiftyRows: "50 per page",
        tenRows: "10 per page",
        thirtyRows: "30 per page",
      },
    },
    salutation: {
      key: "Salutations",
      values: {
        madame: "Ms",
        monsieur: "Mr",
        other: "*",
      },
    },
    searchPageTableTitle: {
      key: "Table titles",
      values: {
        accepted: "Accepted",
        contacted: "Contacted",
        declined: "Treatment not possible",
        filtered: "Filtered",
        potential: "Potential",
        rejected: "Rejected",
        validated: "This provider has been chosen",
      },
    },
    searchTableStatusChips: {
      key: "searchTableStatusChips",
      values: {
        accepted: "Accepted",
        faxSent: "Fax sent",
        newFile: "New file",
        newMessage: "New message",
        notSeen: "Not seen",
        ongoingChat: "Ongoing chat",
        providerInactive: "Inactive",
        requestNotSeen: "Request not seen",
        requestSeen: "Request seen",
        seen: "Seen",
        sentMessageNotSeen: "Message not seen",
        sentMessageSeen: "Message seen",
      },
    },
    service: {
      key: "Services",
      values: {
        basicCare: "Basic care (SGB XI)",
        basicMedicalCare: "Basic medical care (SGB V)",
        children: "Children",
        closedArea: "Protected area / Closed station",
        companionship: "Companionship",
        corona: "Corona",
        dementia: "Dementia care",
        dialysis: "Dialysis",
        enteralNutrition: "Enteral nutrition",
        hearingImpaired: "Suitable for deaf people",
        helpToBreathe: "Tracheostomy",
        helpWithEating: "Help with eating",
        invasiveVentilation: "Invasive ventilation",
        monitoringBreathing: "Monitoring: Breathing",
        monitoringCirculation: "Monitoring: Circulation",
        monitoringMetabolism: "Monitoring: Metabolism",
        noneOfTheFollowingServices: "None of the following services",
        nonInvasiveVentilation: "Non-invasive ventilation",
        obesity: "Obesity care",
        oxygenTherapy: "Oxygen therapy",
        palliativeCare: "Palliative",
        palliativeCertificate: "Palliative certificate (SAPV)",
        parenteralNutrition: "Parenteral nutrition",
        patientsBelow70: "Patients below 70",
        patientsOver70: "Patients over 70",
        peopleWhoAreDangerous:
          "Suitable for people who are dangerous to self or others",
        peopleWithAddictions: "Suitable for people with addictions",
        peopleWithOrientationDisorder:
          "Suitable for people with orientation disorder",
        protectedArea: "Protected area",
        psychiatricCare: "Psychiatric care",
        specialisedIntensiveNurse: "Intensive care",
        staticCareDisabledPatients: "Static care for disabled patients",
        takingOverBodyCare: "Taking over body care",
        visuallyImpaired: "Suitable for visually impaired people",
      },
    },
    serviceExplanations: {
      key: "Service Explanations",
      values: {
        basicCare:
          "Basic care is carried out by nursing staff when people can no longer cope with their everyday tasks independently. This includes the areas of personal hygiene (e.g. showering, dental care, going to the toilet), nutrition (e.g. preparing and eating food in bite-sized sizes) and mobility (e.g. getting up, getting dressed, walking).",
        basicMedicalCare:
          "Basic care is carried out by nursing staff when people can no longer cope with their everyday tasks independently. This includes the areas of personal hygiene (e.g. showering, dental care, going to the toilet), nutrition (e.g. preparing and eating food in bite-sized sizes) and mobility (e.g. getting up, getting dressed, walking).",
        companionship:
          "The term care is broad and the exact offering can vary greatly among care providers. If you have specific questions about the care offering, please contact the provider directly or post a question in the chat with your request.",
        corona:
          "Patients with a current positive test for the coronavirus (SARS-CoV-2) can be admitted here.",
        dementiaCare:
          "Dementia care means various types of assistance for people with dementia. They range from instructive and nursing activities to 24-hour dementia care for seniors.",
        intensiveCare:
          "Intensive care is 24-hour care for people with life-threatening or life-limiting illnesses. Care by specially trained nursing staff can be provided both on an outpatient and inpatient basis.",
        palliativeCare:
          "Palliative care accompanies seriously ill and dying people at the end of their lives and improves their quality of life. It enables dignified support until the end of life without fear and pain.",
        protectedArea:
          "For people with an accommodation decision (e.g. if there is a risk of running back and forth due to dementia, mental illnesses that endanger themselves or others) who are not allowed to leave certain living areas independently for their own safety. These areas are specifically tailored to the needs of the residents and are therefore also called “protective living areas”.",
        psychiatricCare:
          "Mental illnesses or serious illnesses require a special type of care. Psychiatric care enables mentally impaired people to live in their homes within their means and to shape their lives as best as possible. This way, hospital stays can be avoided.",
        sapv: "Specialized outpatient palliative care (SAPV) can replace hospitalization and allow patients to die in peace where they lived. The teams required for this usually consist of medical staff, nursing staff and therapeutic staff and have appropriate additional training.",
        staticCareForDisabledPatients:
          "Facilities offering this service are specifically designed for people with disabilities in need of care.",
        tracheostomy:
          "A tracheostomy is an artificial opening in the windpipe in the neck that is created through surgery. Through this opening, a patient is either fully ventilated or supported in breathing. Breathing air enters the lungs while saliva or food is kept away from the trachea. A tracheostomy is essential for the survival of affected patients.",
      },
    },
    shareModes: {
      key: "Share mode",
      values: {
        all: "Shared with all",
        none: "Shared with None",
        selected: "Shared with Selected",
      },
    },
    shortTermGrant: {
      key: "Short Term Grants",
      values: {
        combination:
          "Other: combination of preventative care and short term care",
        paragraph39: "According to §39c SGB V",
        paragraph42: "According to §42 SGB XI",
      },
    },
    solution: {
      key: "Solutions",
      shortValues: {
        ambulance: "Ambulance",
        ambulanceCarryChair: "Ambulance - Carrying chair",
        ambulanceLyingDown: "Ambulance - Lying down",
        ambulanceWheelchair: "Ambulance - Wheelchair",
        assistedLiving: "Assisted Living",
        dailyLivingSupportServices: "Daily Living Support Services",
        dayCare: "Day care",
        dayHospitalization: "Day Hospitalization",
        fullHospitalization: "Full Hospitalization",
        fullTimeMobileCare: "24hr mobile",
        helpAtHome: "Help at home",
        homeCare: "Home care",
        hospice: "Hospice association",
        hospiceMobile: "Mob. hospice",
        hospicePartiallyMobile: "Part. mobile hospice",
        hospiceStatic: "Static hospice",
        medicalSupplies: "Medical supplies",
        mobileCare: "Mobile",
        nightCareMobile: "Mobile night care",
        nightCareStatic: "Stat. night care",
        rehabDayHospitalization: "Day Reha",
        rehabDayHospitalizationWithTransport: "Day Reha with transport",
        rehabFullHospitalization: "Full Reha",
        rehabMobile: "Mobile Reha",
        sharedFlat: "Shared Flat",
        shortTermStaticCare: "Short term static care",
        staticCare: "Static",
        taxi: "Taxi",
        taxiCarryChair: "Carrying chair",
        taxiLyingDown: "Lying down",
        taxiWheelchair: "Wheelchair",
      },
      values: {
        ambulance: "Ambulance",
        ambulanceCarryChair: "Ambulance - Carrying chair",
        ambulanceLyingDown: "Ambulance - Lying down",
        ambulanceWheelchair: "Ambulance - Wheelchair",
        assistedLiving: "Assisted living",
        dailyLivingSupportServices:
          "Daily Living Support Services (category expansion planed)",
        dayCare: "Day care",
        dayHospitalization: "Day Hospitalization",
        fullHospitalization: "Full Hospitalization",
        fullTimeMobileCare: "24hr mobile",
        helpAtHome: "Help at home",
        homeCare: "Home care",
        hospice: "Hospice Association",
        hospiceMobile: "Mobile hospice",
        hospicePartiallyMobile: "Partially mobile hospice",
        hospiceStatic: "Static hospice",
        medicalSupplies: "Medical supplies",
        mobileCare: "Mobile",
        nightCareMobile: "Mobile night care",
        nightCareStatic: "Static night care",
        rehabDayHospitalization: "Day Reha",
        rehabDayHospitalizationWithTransport: "Day Reha with transport",
        rehabFullHospitalization: "Full Reha",
        rehabMobile: "Mobile Reha",
        sharedFlat: "Shared Flat",
        shortTermStaticCare: "Short term static care",
        staticCare: "Static",
        taxi: "Taxi",
        taxiCarryChair: "Taxi - Carrying chair",
        taxiLyingDown: "Taxi - Lying down",
        taxiWheelchair: "Taxi - Wheelchair",
      },
    },
    solutionBToC: {
      key: "Solutions",
      values: {
        helpAtHome: "Help at home",
        mobileCare: "Mobile Care",
        shortTermStaticCare: "Short term static care",
        staticCare: "Static Care",
      },
    },
    specializations: {
      key: "Specializations",
      values: {
        acuteGeriatrics: "Acute Geriatrics",
        addiction: "Addiction",
        addictology: "Addictology",
        adipositas: "Adipositas",
        allergology: "Allergology",
        anesthesiology: "Anesthesiology",
        angiology: "Angiology",
        cardiologicalEarlyRehabilitation: "Cardiological Early Rehabilitation",
        cardiology: "Cardiology",
        cognitiveBehavioralUnit: "Cognitive-behavioral unit",
        cognitiveBehavioural: "Cognitive and behavioural Unit",
        comaVigil: "Coma vigil",
        compressionTherapy: "Compression therapy",
        decubitusProphylaxis: "Decubitus prophylaxis",
        dermatology: "Dermatology",
        diabeticFoot: "Diabetic foot",
        diabetology: "Diabetology",
        dialysis: "Dialysis",
        digestiveSurgery: "Digestive Surgery",
        earlyRehab: "Early Rehab",
        endocrinology: "Endocrinology/Metabolic Diseases",
        ent: "ENT",
        enteralNutrition: "Enteral nutrition",
        gastroenterology: "Gastroenterology",
        generalSurgery: "General Surgery",
        geriatrics: "Geriatrics",
        gynaecology: "Gynaecology",
        haematology: "Haematology",
        hepatology: "Hepatology",
        homecareOncology: "Oncology",
        icuCardiology: "ICU Cardiology",
        icuCorona: "ICU - Low Care",
        icuECMOCorona: "ICU - ECMO",
        icuGastrology: "ICU Gastrology",
        icuHighCorona: "ICU - High Care",
        icuNeurovascular: "ICU Neurovascular",
        incontinence: "Incontinence absorbing",
        incontinenceDischarging: "Incontinence discharging",
        infectiousDisease: "Infectious Disease",
        infusionTherapy: "Infusion therapy",
        internalMedicalEarlyRehabilitation:
          "Internal Medical Early Rehabilitation",
        internalMedicine: "Internal medicine",
        laryngectomyTherapy: "Laryngectomy therapy",
        maxillofacialSurgery: "Maxillofacial surgery",
        medicalReanimation: "Medical reanimation",
        monitoring: "Monitoring",
        nephrology: "Nephrology",
        neurology: "Neurology",
        neuroPediatry: "Neuropaediatrics",
        neurosurgery: "Neurosurgery",
        normalStation: "Normal station",
        nuclearMedicine: "Nuclear Medicine",
        obesity: "Obesity",
        oncology: "Oncology",
        oncologyBreastGlands: "Oncology - breast gland",
        oncologyCentralNervousSystem: "Oncology - central Nervous System",
        oncologyDigestiveOrgans: "Oncology - digestive organs",
        oncologyFemaleGenital: "Oncology - female Genital",
        oncologyLocomotorOrgans: "Oncology - locomotor organs",
        oncologyMalignantSystemicDiseases:
          "Oncology - malignant systemic diseases",
        oncologyPharync: "Oncology - pharynx, larynx",
        oncologyRespiratoryOrgans: "Oncology - respiratory organs",
        oncologySkin: "Oncology - skin",
        oncologyThyroid: "Oncology - thyroid gland",
        oncologyUrinaryKidneyMaleGenitals:
          "Oncology - kidney, urinary tract and male genitals",
        ophthalmology: "Ophthalmology",
        orthogeriatrics: "Orthogeriatrics",
        orthopedics: "Orthopedics",
        orthopedicsLowerLimbs: "Orthopedics - Lower limbs",
        orthopedicsSosMains: "Orthopedics - SOS Hands",
        orthopedicsSpine: "Orthopedics - Spine",
        orthopedicsUpperLimbs: "Orthopedics - Upper limbs",
        oxygen: "Oxygen",
        paediatrics: "Pediatrics",
        painMedicine: "Pain Medicine",
        painTherapy: "Pain therapy",
        palliativeCare: "Palliative care",
        parenteralNutrition: "Parenteral nutrition",
        parentsAndChildRehab: "Parents and child rehab",
        pediatrics: "Pediatrics",
        phaseB: "Neurology Phase B",
        phaseC: "Neurology Phase C",
        phaseCplus: "Neurology Phase C+",
        phaseD: "Neurology Phase D",
        physicalMedicine: "Physical medicine and rehabilitation ",
        plasticSurgery: "Plastic surgery",
        pneumologicalEarlyRehabilitation: "Pneumological Early Rehabilitation",
        pneumology: "Pneumology",
        psychiatry: "Psychiatry",
        psychosomatic: "Psychosomatic",
        radiology: "Radiology",
        respiratoryTherapy: "Respiratory therapy",
        rheumatism: "Rheumatism",
        rheumatology: "Rheumatology",
        rhythmology: "Rhythmology",
        stoma: "Stoma",
        surgicalResuscitation: "Surgical resuscitation",
        thoracicSurgery: "Thoracic surgery",
        tracheostomyTherapy: "Tracheostomy / laryngectomy therapy",
        traumaSurgery: "Trauma surgery",
        urology: "Urology",
        vascularSurgery: "Vascular surgery",
        ventilationOxygen: "Ventilation ",
        weaning: "Weaning",
        woundCare: "Wound care",
      },
    },
    tableSorting: {
      key: "tableSorting",
      values: {
        sortByDefault: "Default",
        sortByDistance: "Distance",
        sortByName: "Name",
        sortByStatus: "Status",
      },
    },
    technicalEquipment: {
      cardiovascularExploration: {
        cardiacMri: "Cardiac MRI",
        coronaryScanner: "Coronary scanner",
        exerciseTest: "Exercise test",
        holterEcg: "Holter ECG",
        rhythmology: "Rhythmology",
        toe: "TOE",
        tte: "TTE",
      },
      digestiveEndoscopy: {
        colonoscopy: "Colonoscopy",
        endoscopicUltrasound: "Endoscopic ultrasound",
        ercp: "ERCP",
        gastroscopy: "Gastroscopy",
      },
      imagery: {
        ctScan: "CT Scan",
        dopplers: "Dopplers",
        interventionalRadiology: "Interventional radiology",
        mri: "MRI",
        ultrasound: "Ultrasound",
        xRay: "X-Ray",
      },
      key: "key",
      otherAvailableEquipment: {
        dialysis: "Dialysis",
        monitoringBreathing: "Monitoring: Breathing",
        monitoringCirculation: "Monitoring: Circulation",
        monitoringMetabolism: "Monitoring: Metabolism",
      },
      values: {
        test: "-1",
      },
    },
    trafficLight: {
      key: "Traffic Light",
      values: {
        green: "Green",
        orange: "Orange",
        red: "Red",
      },
    },
    transitionalCareRequestStatus: {
      key: "Request status",
      values: {
        accepted: "Accepted",
        declined: "Declined",
        rejected: "Rejected",
        sent: "No response",
      },
    },
    transportType: {
      key: "Type of transport",
      values: {
        ambulanceLyingDown: "Ambulance lying down",
        ambulanceSitting: "Ambulance sitting",
        emergencyVehicle: "Emergency vehicle",
        helicopter: "Helicopter",
        personalCar: "Personal car",
        publicTransport: "Public transport",
        taxi: "Taxi",
      },
    },
    universalSearch: {
      key: "Resource",
      values: {
        account: "Account",
        company: "Company",
        consultant: "Consultant",
        receiver: "Receiver",
        sender: "Sender",
      },
    },
    vigilance: {
      key: "Vigilance",
      values: {
        agitated: "Agitated",
        awake: "Awake",
        calm: "Calm",
        comatose: "Comatose",
        responsive: "Responsive",
        somnolent: "Somnolent",
        soporific: "Soporific",
      },
    },
  },
  patient: {
    abortCreation: {
      actionButton: "Quit creating patient",
      modalSubtitle:
        "After you quit adding this patient, the profile will be archived. You can also save the profile as draft and finish filling it up later.",
      modalTitle: (args: { patientId: string }) =>
        template(
          "Are you sure you want to quit creating patient {{patientId}}?",
          args,
        ),
      quitAndArchive: "Quit and archive",
      saveAsDraft: "Save as draft",
      test: "test",
    },
    accompanyingPerson: "Accompanying person",
    addCareprovider: {
      addTitle: (args: { receiverTypeSingular: string }) =>
        template("Add another {{receiverTypeSingular}} manually", args),
      contactAll: "contact all",
      contactingByFax:
        "If you select this receiver, Recare tries to send the request via fax to this receiver if the fax number is available to us as the provider is not yet an active part of the platform",
      distance: "Distance to the patient",
      enterNamePlaceholder: {
        care: "Enter provider's name",
        geriatrics: "Enter preferred clinic's address or name",
      },
      errorContactRecare: "Please contact Recare",
      errorNoSolution: (args: { solution: string }) =>
        template("Does not offer {{solution}}", args),
      errorProviderCannotBeContacted: "The provider is no longer active",
      errorProviderCreated: 'Status "waiting onboarding"',
      errorProviderNotActive: "Provider is not active",
      errorRequestAccepted: "This request was accepted.",
      errorRequestDeclined: (args: { message: string }) =>
        template('This request was declined: "{{message}}"', args),
      errorRequestRejected:
        "Was rejected\nPlease go to the answer and cancel your rejection",
      errorRequestSent: (args: { date: string }) =>
        template("A request was sent on {{date}}, but not answered yet", args),
      errorRequestSentAutomaticSearch:
        "A request was sent, but has not been answered yet.",
      errorRequestWaitingResponse: "You have received a message",
      errorWhitelisted: "This receiver is not part of the platform.",
      explanation: "You can search by name or address.",
      explanationSearchStopped: (args: { receiverTypeSingular: string }) =>
        template(
          "The {{receiverTypeSingular}} can be added here. It is advisable to search for the address first.",
          args,
        ),
      form: {
        explanation: (args: { receiverTypeSingular: string }) =>
          template(
            "Unfortunately we could not find the {{receiverTypeSingular}} in our system. Please give us sufficient information so we can add the receiver to our system as soon as possible.",
            args,
          ),
        title: (args: { receiverTypeSingular: string }) =>
          template(
            "Data from {{receiverTypeSingular}} is required. Please indicate this",
            args,
          ),
      },
      formExplanation: {
        care: "Unfortunately we could not find the careprovider in our system.\nPlease give us sufficient Information so we can add it asap to our system.",
        geriatrics:
          "Unfortunately we could not find the clinic in our system.\nPlease give us sufficient Information so we can add it asap to our system.",
      },
      formTitle: {
        care: "Please enter provider's information",
        geriatrics: "Please enter clinic's information",
      },
      goBack: "Back to search",
      goToAnswer: "Go to answer",
      insuranceBlacklisted:
        "This receiver does not take in patients with the selected cost payer",
      lookForAdditionalSpecialsations: "Look for additional specialisations",
      medicalCare: "Medical Care",
      noBedsAvailable: "No beds available",
      notFound: {
        care: "Cannot find the provider?",
        geriatrics: "Cannot find the clinic?",
      },
      placeholderEnterName: "Search by name or address:",
      pleaseGoToAnswer: "Please go to the answer and accept the provider there",
      providerAddedExplanation:
        "We have received the information you provided.",
      providerAddedExplanation1: {
        care: "We have received information regarding your preferred provider.",
        geriatrics:
          "We have received information regarding your preferred clinic.",
      },
      providerAddedExplanation2: {
        care: "Recare will add this careprovider to the system asap. No request will be sent yet.",
        geriatrics:
          "Recare will add this clinic to the system asap. No request will be sent yet.",
      },
      providerAddedExplanationBis: (args: { receiverTypeSingular: string }) =>
        template(
          "Recare will add this {{receiverTypeSingular}} to the system asap. No request will be sent yet.",
          args,
        ),
      providerAddedTitle: {
        care: "Provider has been saved",
        geriatrics: "Clinic has been saved",
      },
      providerNotFound: (args: { receiverTypeSingular: string }) =>
        template("Cannot find the {{receiverTypeSingular}}?", args),
      providerSavedTitle: (args: { receiverTypeSingular: string }) =>
        template("The {{receiverTypeSingular}} has been saved", args),
      providersFiltered: "providers filtered",
      providerThrottled: "Receiver has received too many requests",
      reason: "Reason",
      rejectedByCostPayer: "This clinic has been rejected by the cost payer",
      requestDate: "Date",
      requestsSent: "requests sent",
      searchBarPlaceholder: "Enter provider's address or name",
      searchCareproviderExplanation: {
        care: "Please add your desired provider here. It is recommended to search first for the address of the provider.",
        geriatrics:
          "Please add your desired clinic here. It is recommended to search first for the address of the clinic.",
      },
      searchCareproviderExplanationSearchStopped: {
        care: "Please add your desired provider here. It is recommended to search first for the address of the provider.",
        geriatrics:
          "Please add your desired provider here. It is recommended to search first for the address of the provider.",
      },
      searchNotStartedHint:
        "The search has not been started yet. If you would like to contact all available providers, click contact all or just this provider, click select",
      selectProviderNotStartedSearch:
        'The search has not been started yet. Click "Select" to send the request to this provider and start the search.',
      selectProviderToSendRequest:
        'Please click on "Select" to add this provider to the search.',
      selectSolutionExplanation: "Please select the type of care wanted.",
      sendRequest: "Send request",
      smartSuggestionsWarningHint:
        'If you want to add this provider to your search despite this information, click on "Select" below.',
      stopSearchWarningHint:
        'If you want to attribute this provider despite this information, click on "Confirm" below.',
      title: {
        care: "Select a preferred provider",
        geriatrics: "Select a preferred clinic",
        warningWeight: (args: { maxWeight: string }) =>
          template(
            "The provider only takes patients with a weight of up to {{maxWeight}} kg.",
            args,
          ),
      },
      warningAge: (args: { minAge: string; maxAge: string }) =>
        template(
          "The provider only takes patients between {{minAge}} and {{maxAge}}",
          args,
        ),
      warningBlacklist: "Provider not contacted",
      warningDistance: "Provider is not in the radius of the search",
      warningDistanceCareproviderRadius:
        "Search outside of provider treatment radius",
      warningNoCapacity: (args: { startDate: string }) =>
        template("No capacity until {{startDate}}", args),
      warningNoService: (args: { service: string }) =>
        template("Following services are not provided: {{service}}", args),
      warningNotCatchment: "Entered address is not in catchment area",
      warningPrerequisite: (args: { service: string }) =>
        template("Following services are required: {{service}}", args),
      warningSpecialisation:
        "This receiver only takes in patients of another specialisation",
      warningWhitelist: "Receiver ist not part of the platform",
      willRestartSearchHint:
        "Sending to this provider will restart the search.",
      willStartSearchHint: "Sending to this provider will start the search.",
    },
    additionalExaminations: "Additional examinations necessary",
    additionalInformation: "Additional information",
    admissionDate: "Admission date",
    age: "Age",
    agitationOrientation:
      "Patient is being agitated / aggressive / disoriented",
    alertTitle: "Failed to load patient",
    autonomy: "Autonomy",
    backfillStationNumber: {
      hint: "Please enter the station phone number",
      link: "Number missing or incorrect?",
      placeholder: "Station phone number",
      title: (args: { station: string }) =>
        template("Edit station phone number of {{station}}", args),
    },
    barrierFreeRoom: "Barrier-free room",
    candidates: "Potential receivers",
    careDuration: "Care duration",
    carelevel: "Carelevel",
    careNeeds: {
      accessDrainages: {
        accessAndDrainages: "Access and drainages",
        centralVenousCatheter: "Central venous catheter (CVC)",
        drainage: "Drainage",
        gastricTube: "PEG, PEJ, gastric tube",
        inputPlaceholder:
          "e.g. since when, when was it last changed, type and size, etc.",
        other: "Other",
        otherAccessDrainages: "Other",
        otherPlaceholder: "Please specify",
        peripheralVenousCatheter: "Peripheral venous catheter (PVC, Viggo)",
        permanentCatheter: "Permanent catheter",
        suprapubicCatheter: "Suprapubic catheter (SPC)",
        trachealCannula: "Tracheal cannula",
      },
      baseCare: "Base care",
      baseCarePlaceholder:
        "e.g. frequency, time, prepare for daycare, body care, nutrition, etc.",
      breathing: "Breathing",
      carePaidByHealthInsurance: "§37.1 SGB V",
      circulation: {
        label: "Circulation",
        placeholder: "e.g. Blood pressure, ECG",
      },
      companionship: "Companionship",
      companionshipGrant: "§45b SGB XI",
      companionshipPlaceholder:
        "e.g. accompanying visits to the doctor, stimulating and supporting social contacts, etc",
      compression: "Compression therapy",
      compressionPlaceholder: "e.g. frequency, socks, bandage, etc.",
      dialysis: "Dialysis",
      dysphagia: {
        label: "Dysphagia",
        placeholder: "eg. type of food, help with eating, etc.",
      },
      enteralNutrition: {
        label: "Enteral nutrition",
        placeholder: "eg. frequency, PEG, PEJ, nutrition manufacturer, etc.",
      },
      fecalIncontinence: "Fecal incontinence",
      fecalIncontinencePlaceholder: "e.g. Colostomy, Anus Praeter, etc.",
      helpAtHome: "Help at home",
      helpAtHomePlaceholder:
        "e.g. shopping, cooking, washing the dishes, changing the sheets, etc.",
      hemodialysis: {
        label: "Hemodialysis",
        placeholder: "eg. frequency",
      },
      incontinence: "Incontinence",
      injection: "Injection",
      injectionPlaceholder: "e.g. frequency, time, subcutunae, muscular, etc.",
      medicalCare: "Medical Care",
      medicalCarePlaceholder:
        "e.g. drug administration, insulin injection, wound treatment",
      medication: "Medication",
      medicationPlaceholder:
        "e.g. frequency, medication is provided weekly, etc.",
      metabolism: {
        label: "Metabolism",
        placeholder: "e.g. Blood sugar",
      },
      monitoring: "Monitoring",
      needSupport: "Patient needs support",
      nutrition: "Nutrition",
      oxygenTherapy: {
        label: "Oxygen therapy",
        placeholder:
          "eg. oxygen machine was prescribed and ordered, High Flow therapy, etc.",
      },
      parenteralNutrition: {
        label: "Parenteral nutrition",
        placeholder: "eg. frequency, nutrition manufacturer, etc.",
      },
      peritonealDialysis: {
        label: "Peritoneal dialysis",
        placeholder: "eg. frequency",
      },
      placeholderImportantInformationCare: "e.g. Frequency of care, tasks",
      psychiatric_care: {
        label: "Psychiatric care",
        placeholder: "eg. Psychotic illness, borderline, etc.",
      },
      remarks: "Additional information",
      respiration: {
        label: "Respiration",
        placeholder: "e.g. CO2 content of the breathing air",
      },
      stepperTitle: "State of the patient",
      stepperTitleCare: "Care needs",
      takesChemoTherapy: "Chemo therapy medication",
      takesExpensiveMedication: "Expensive/special medication",
      titleImportantInformationCare: "Important information for care",
      tracheostomy: {
        label: "Tracheostomy",
        placeholder: "eg. organisation of the medical supplies, etc.",
      },
      urinaryIncontinence: "Urinary incontinence",
      urinaryIncontinencePlaceholder: "e.g. Diapers, Catheter, etc.",
      withBodyCare: "With body care",
      withDressing: "With dressing and undressing",
      withEating: "With eating",
      woundCare: "Wounds/decubitus",
      woundCarePlaceholder:
        "e.g. localisation, size, therapy, frequency of care, etc.",
    },
    careNeedsRequired: "Care needs required",
    caseIdTitle: "Please provide the patient's case ID number",
    closedAreaNeeded: "Protected area / closed station required",
    complexityPatient: "Complexity of the patient profile",
    comprehensiveRehabPatientProfile: "Long patient profile necessary",
    coronaWarning:
      "Due to the current spread of COVID-19, most short-term care places are no longer available. We therefore strongly recommend (at least at the same time) to look for a mobile facility in order to curb further spread",
    costPayerPartnerInfoBox: {
      bodyAvailable:
        "This payer takes over the search for a rehab place after you have created the patient profile and handed it over to the cost payer. Messages, documents, etc. can be shared digitally directly with the cost payer and the rehab clinic via Recare.",
      bodyNotAvailable:
        "Unfortunately, this cost payer is not yet part of the Recare platform. You start the search yourself and can digitally exchange messages and documents with the rehab clinic and make a pre-reservation. The sharing of documents with the payer must happen in the traditional way.",
      linkToFaq:
        "For more information about our partner payers who are already on board, {select:visit our FAQs}.",
      titleAvailable: "Costpayer is a partner of Recare",
      titleNotAvailable: "Cost payer is not a partner of Recare",
    },
    costpayerSunsetInfoBox: {
      body: "From 01.03.2023 we are stopping the option to hand over AHB-/reha searches to costpayers. Until this date the functionality is working as expected for starting new searches. All running searches will be live until 31.03.2023; from this date onward all searches will be automatically stopped. Please prepare for this change and contact, if relevant, the related authorities and oversight committee. The ability to search for AHB-/reha places without handover to costpayers remains and is not impacted by this change.",
      title: "Important information about handing searches over to costpayers",
    },
    countrySearch: "Germany-wide search",
    covidPositive: "Covid-19 positive",
    created: "created",
    createPatient: "Add a new Patient",
    createPatientConfirmation:
      "Please note the Patient ID in your file or system",
    createPatientDatesError:
      "Admission date cannot be after expected discharge date.",
    createPatientError: "Impossible to create patient",
    createPatientLoading: "We appreciate your patience during patient creation",
    dateOfBirth: "Date of birth",
    dateOfBirthFormat: "DD/MM/YYYY",
    declarationOfConsent: "The patient's consent has been obtained",
    deleteAuction: {
      stopSearch: "Stop search",
    },
    deletePatient: {
      confirmText: (args: { patientName: string }) =>
        template("Do you really want to archive {{patientName}}?", args),
      title: "Archive patient",
    },
    dementia: "Dementia",
    dichargeDate: "Discharge date",
    diffFields: {
      careNeeds: {
        care: "Care needs",
        geriatrics: "State of the patient",
      },
      diagnosis: "Medical diagnosis",
      mentalHealth: "Mental health",
      mobility: "Mobility",
      patientCondition: "Condition of the patient",
      profile: "Patient Information",
      searchRadius: "Search radius",
      socialWorker: "Social worker name",
      solutions: "Type of transfer",
      station: "Station",
      transport: "Transport",
    },
    directTransfer: "Direct transfer",
    directTransferNecessary: "Direct transfer necessary",
    dischargeConfirmation: {
      centralEmail: "Central email address",
      contactInformation: "Contact information",
      footer: {
        confirmation:
          "Go to www.recaresolutions.com/beratung to schedule a free care advice from Recare.\n\nRecare is our partner for coordination and organisation of post-hospital services that go beyond the statutory discharge management offered by hospitals.\n\nFor example, Recare helps you with your application for useful entitlements from the care insurance, such as stair lifts, bathroom renovations, meals on wheels or free care products with minimal effort.\n\nThe consultation by Recare is not a task of the hospital's discharge management according to § 39 Abs. 1a SGB V. The use of a consultation by Recare is optional and additionally possible.",
        confirmationOther:
          "Go to https://www.recaresolutions.com/beratung to schedule a free care advice from Recare.\n\nRecare is our partner for coordination and organisation of post-hospital services that go beyond the statutory discharge management offered by hospitals.\n\nFor example, Recare helps you apply for useful, additional care options such as meals on wheels or hourly care at home with minimal effort.\n\nThe consultation by Recare is not a task of the hospital's discharge management according to § 39 Abs. 1a SGB V. The use of a consultation by Recare is optional and additionally possible.",
        title: "Do you need help regarding the need for care?",
        titleOther: "Do you need help with your post-hospital care?",
      },
      startDate: "Start date",
      transferConfirmation: (args: {
        patientId: string;
        providerName: string;
      }) =>
        template(
          "Confirmation for {select:Patient {{patientId}}} being transfered to {select:{{providerName}}}",
          args,
        ),
    },
    distanceFromYou: (args: { distance: string }) =>
      template("{{distance}} from you", args),
    doctorInCharge: "Doctor in charge",
    eatAlone: "Can eat alone",
    editAssessmentMessage: "Information has been successfully saved",
    editAssessmentMessageGeneric:
      "Changes successfully saved. Please note that changes may affect the providers who can be contacted.",
    editSearchProviderListUpdateBody:
      "Please note that these changes might affect the providers who can be contacted",
    editSearchProviderListUpdateTitle: "Changes in patient profile",
    editSearchWarning:
      "The changes you have made to this patient profile will result in excluding the following receivers from the search:",
    electivePatientNotInHospitalYet: "Patient is not in the hospital yet",
    eligibilityTitle: {
      eligible: "Patient eligibility has been approved",
      inProcess: "Patient eligibility is in progress",
      rejected: "Patient eligibility has been rejected",
      toBeProcessed: "Patient eligibility has not been set yet",
    },
    encryptedFieldSecure:
      "This information is encrypted. It will be only be communicated to the receivers if you grant them access to it.",
    encryptionWithIE:
      "Entering optional encrypted data is not available for Internet Explorer. Please use a more up-to-date browser like Google Chrome, Mozilla Firefox or Microsoft Edge.",
    enterGermanPensionInsuranceName: "Enter German pension insurance name",
    enterHealthInsuranceName: "Enter health insurance name",
    errorLoading: "Impossible to load patient",
    errorLoadingUrlChanges: "Request could not be executed",
    errorSaveAuction: "Impossible to save care request",
    expectedAdmitDate: "Expected admit date",
    expectedDischargeDate: "Expected discharge date",
    expectedStartDate: "Expected start date",
    expectedStartDateBeforeDischargeDateError:
      "Expected discharge date cannot be after the expected start date",
    expectedStartDateBeforeDischargeDateErrorForStartDate:
      "Expected start date cannot be before the expected discharge date.",
    expeditedRequest: "Expedited request",
    externalId: "Internal patient ID",
    facilityType: "Type of facility",
    firstName: "First name",
    flexible: "(flexible)",
    flexibleDate: "Flexible start date possible",
    formGeneration: {
      alreadySent: "Already sent",
      alreadySentModal: {
        hint: "This information will be displayed to the cost payer.",
        title: "When and how has the form been sent?",
      },
      bedOverlength: "Bed for tall people",
      beforeRetirementPension:
        "There are regular benefits until the beginning of a retirement pension",
      beforeRetirementPensionTypeOfService: "Type of service",
      birthDate: "Birthdate",
      birthDateDateFormat: "(DD.MM.YYYY)",
      cancelModal: {
        areYouSure: "Are you sure you want to leave this page?",
        changesWontBeSaved:
          "Any changes you have made will not be saved automatically.",
        confirmNo: "BACK TO THE PAGE",
        confirmYes: "LEAVE",
        content: "Any changes you have made will not be saved automatically.",
        title: "Are you sure you want to leave this page?",
      },
      catheter: "Catheter",
      chemiotherapy: "Chemotherapy",
      complicationCauseCardiovascular: "Cardiovascular complications",
      complicationCauseCardiovascularLabel: "e.g. heart attack, embolism",
      complicationCauseMaindiagnosis:
        "The complications were directly related to the  leading diagnosis for the rehabilitation",
      complicationCauseMaindiagnosisLabel:
        "e.g. postoperative dislocation of the endoprosthesis",
      complicationCauseOther: "Other complications",
      complicationCauseOtherLabel: "e.g. pneumonia",
      complications: "Complications during the treatment",
      complicationsAndRiskFactors:
        "Disease course, complications, risk factors",
      contributionsToForeignInsurance:
        "Contributions to social insurance abroad are still paid",
      contributionsToForeignInsuranceInfo:
        "Contributions to social insurance abroad were paid",
      contributionsToForeignInsuranceInfoFromTo: "From - To",
      contributionsToForeignInsuranceInfoState: "State",
      currentTherapy: "Other therapies conducted in the hospital",
      currentTherapyDkg: "Other therapies conducted in the hospital",
      degree: "Degree",
      degreeDoctor: "Degree",
      downloadForm: "Download form",
      editForm: "Edit form",
      fieldsNotFromAssessmentModalTitle:
        "Provide form values for fields not present in assessment",
      formGenerationErrorMessage:
        "An error occurred, form could not be generated. Please try again.",
      furtherTherapeuticDiagnosticMeasures:
        "Further therapeutic or diagnostic measures",
      furtherTherapeuticDiagnosticMeasuresDescription: "Description",
      furtherTherapeuticDiagnosticMeasuresFinishedOn: "Finished on",
      furtherTherapeuticDiagnosticMeasuresFrom: "From",
      furtherTherapeuticDiagnosticMeasuresTo: "To",
      generateFormButton: "Generate Form",
      generateFormTitle: "Generate form for the payer as a pdf:",
      generateMedicalFormButton: "Generate Medical Form",
      generateMedicalFormTitle: "Generate medical form for the payer as a pdf:",
      guardianIsAurhorizedRepresentative: "Authorized representative",
      guardianIsBetreuer: "Supervisor",
      guardianIsCustodian: "Guardian",
      guardianIsLegalRepresentative: "Legal representative",
      guardianPhoneNumber: "Phone number",
      guardianPostalCode: "Postal code",
      guardianStreetHouseNumber: "Street, house number",
      guardianTelefaxEmail: "Fax, Mail",
      guardianTown: "Town",
      height: "Height",
      indicationCardiology: "Cardiology",
      indicationGeriatrics: "Geriatrics",
      indicationMusculoskeletalDiseases: "Musculoskeletal diseases",
      indicationNeurology: "Neurology",
      indicationOther: "Other indications",
      indicationOtherLabel: "Please specify",
      interimProsthesisFitted:
        "Adaptation of an interim prosthesis has taken place?",
      jobCareRelatives: "Care of relatives",
      jobChildRaising: "Child-raising periods",
      jobMinijob: "Mini-job",
      jobPensionAdjustment: "Pension rights adjustment",
      jobStatutoryPensionFarmersFrom: "If applicable, due to",
      kisConnection: {
        addFromRecare: "Add from Recare",
        addManually: "Add manually",
        buttonCancel: "Cancel",
        buttonImport: "Import",
        importError:
          "Form data could not be imported. Please add it manually or try again.",
        importFormData: "Import Form Data",
        infoAssessmentNotUpdated:
          "Patient data from Recare patient profile will not be updated",
        modalTitleNotPossibleToConnect:
          "It is currently not possible to connect to your HIS. You can still do it manually or add patient data from the assessment.",
        placeholderExternalID: "Please type the internal case ID",
        subtitleImportFormData:
          "All the available data is about to be automatically transferred from HIS to the AHB form.",
        titleImportFormData: "Importing form data from HIS",
        titleImportForms:
          "Do you want to import data from the AHB forms from the HIS?",
        toastErrorImport:
          "Form data could not be imported. Please add form data manually or try again.",
        tooltipConnected:
          "Recare is connected to your HIS. You can import patient data automatically.",
        tooltipNotConnected:
          "Recare is currently unable to connect to your HIS. You still can add patient data manually.",
      },
      lastEmployment: "Last employment",
      latestAssessmentTitle: "Check or add the latest assessment",
      legProthesis: "Thigh/lower leg prosthesis",
      maxLettersValidation: (args: { max: string }) =>
        template("Field cannot have more than {{max}} letters long", args),
      namePhoneFax: "Last name, first name, Phone, Fax",
      nationality: "Nationality",
      needsPegTube: "PEG",
      patientBankAccountHolder: "Account holder",
      patientBankIban: "IBAN",
      patientBankIbanInvalid:
        "This IBAN is inadmissible: please enter a german IBAN that starts with DE and is followed by 20 digits.",
      patientBankIbanPlaceholder:
        "German IBAN (22 digits) ie. DE09 0909 0909 0909 9900 00",
      patientBankName: "Bank name",
      patientFirstName: "First name",
      patientHouseNumber: "Street and house number",
      patientInsuranceNoResults: (args: { emailMessage: string }) =>
        template(
          "No results? If the insurance is missing, {select:{{emailMessage}}}",
          args,
        ),
      patientInsuranceNoResultsLink: "please send us an email",
      patientInsuranceNumber: "Insurance number",
      patientLastName: "Last name",
      patientName: "Patient last name, first name",
      patientPhoneNumber: "Phone number",
      patientStatutoryPensionInsuranceNumber: "Pension insurance number",
      patientUnableToWorkBeforeHospitalization:
        "Already unable to work before the hospital treatment",
      patientUnableToWorkBeforeHospitalizationSince: "Since:",
      postalCodeDoctor: "Postal code of the treating doctor",
      readyForm: "Ready to be shared",
      recommendedRehaTypeAmbulant: "day hospitalization",
      recommendedRehaTypeAmbulantMobile: "mobile",
      recommendedRehaTypeAmbulantMobileLabel:
        "Reason, e.g. severe orientation disorder, running away",
      recommendedRehaTypeStationary: "full hospitalization",
      recommendedRehaTypeStationaryLabel:
        "Reason, e.g. immobility, deficits in self-sufficiency",
      rehabReasoLastAntineoplasticTherapy: "Last antineoplastic therapy",
      rehabReason: "Reasons for conducting rehab",
      rehabReasonCausedByThirdParty: "Possible damage by third parties",
      rehabReasonEventDate: "Incident date",
      rehabReasonLastRadiation: "Last irradiation",
      rehabReasonNone: "Not applicable",
      rehabReasonOtherIncident: "Other incident",
      rehabReasonWorkAccident: "Work accident, occupational disease",
      rehaCapacityFull: "fully capable",
      rehaCapacityOther: "further particularities with regard to the capacity",
      rehaCapacityPartial: "partially capable",
      rehaCapacityPartialLabel: "partially capable with",
      relativeCare: "Additional admission of relatives in need of care",
      requirementArtificialRespiration: "Artificial respiration",
      requirementImmunosuppressants: "Immunosuppressants",
      requirementOther: "Other requirements",
      requirementOtherLabel: "Please specify",
      requirementRehaClinic:
        "Special requirements regarding the rehabilitation clinic are needed",
      requirementRehaClinicLabel: "because of:",
      retirementEarningCapacityCompany:
        "Pension insurance institute (of the reduction in earning capacity pension)",
      retirementPensionApplied: "Retirement pension has been applied",
      retirementPensionAppliedDkg: "Retirement pension has been applied for",
      retirementPensionCarrierName: "Name of pension insurance carriers",
      retirementPensionEarningCapacity:
        "Pension because of a reduction in earning capacity is being received",
      retirementPensionEarningCapacityApplied:
        "Pension because of a reduction in earning capacity has been applied for",
      retirementPensionInsuranceCompanyName: "Name and address of the company",
      retirementPensionInsuranceCompanyNameDKG: "Pension insurance institute",
      retirementPensionReceived: "Retirement pension is being received",
      retirementPensionReceivedDkg: "Retirement pension is being received",
      selectForm: "Select form",
      severeSenseImpairment: "Severe hearing, visual and speech impairment",
      shareWithInsurance: "Share forms",
      stableDuringExercise: "stable during exercise",
      streetHouseNumberDoctor: "Address of the treating doctor",
      threats:
        "There is a risk of or long-term (>6 months) impairments relevant to everyday life (assessment at the time of diagnosis)",
      threatsAreasLife: "Important areas of life",
      threatsCommunication: "Communication",
      threatsCommunicationLabel: "e.g. when speaking, etc.",
      threatsCommunityLife: "Community life",
      threatsCommunityLifeLabel:
        "e.g. participation in sporting, cultural and religious activities, etc.",
      threatsDomesticLife: "Domestic life",
      threatsDomesticLifeLabel:
        "e.g. shopping, preparing meals, doing chores, etc.",
      threatsGeneralTasks: "Tasks of daily life",
      threatsGeneralTasksLabel: "e.g. in the daily routine, etc.",
      threatsLearning: "Learning and application of knowledge",
      threatsLearningLabel:
        "e.g. impairments in listening/viewing, acquisition of skills, etc.",
      threatsMobility: "Mobility",
      threatsMobilityLabel:
        "e.g. when running longer distances or when using hands and arms, etc.",
      threatsRelationships: "Interpersonal interactions and relationships",
      threatsRelationshipsLabel:
        "e.g. building and maintaining family relationships/intimate/informal and formal social relationships, etc.",
      threatsSelfSufficiency: "Self-sufficiency",
      threatsSelfSufficiencyLabel:
        "e.g. washing, dressing and undressing, etc.",
      townDoctor: "City of the treating doctor",
      tracheostoma: "Tracheotomy",
      transferReason: "Reason for the desired transfer date",
      vtwo: {
        alreadySentTitle: {
          medicalForm: "The medical form has already been sent",
          requestForm: "The general form has already been sent",
        },
        complete: "Complete",
        formsCanBeUpdatedInfo:
          "Please note, the clinic may update the documents at any time, this will be communicated to you via Messenger. Contact the clinic directly if you have not received the forms or in case of further questions.",
        formsNotReceived: "Forms have not been received yet",
        formsReceived: {
          medicalForm: "The medical form has been received",
          requestForm: "The general form has been received",
        },
        generateButtons: {
          medicalForm: "Medical Form",
          requestForm: "General Form",
        },
        herebyConfirm: {
          formValidationConfirm:
            "Please select this checkbox to complete the form.",
          medicalForm:
            "I hereby confirm that the information I provided is correct.",
          requestForm:
            "I hereby confirm that the application for follow-up rehabilitation and the information provided therein has been made in consultation with the patient.",
        },
        lockIcon: {
          costPayer: "The information in the forms is encrypted",
          dataProtection:
            "Sensitive patient data can be securely exchanged. All messages are encrypted.",
          patientCreator:
            "The information in this form is encrypted and can securely be sent to the cost payer.",
        },
        markAsSent: "Mark as sent",
        pleaseCompleteTitle: {
          medicalForm: "Please, complete the medical form",
          requestForm: "Please, complete the general form",
        },
        postShareTooltip:
          "All changes made to the documents will now be automatically shared with the insurance",
        readyTitle: {
          medicalForm: "The following medical form is ready to be shared",
          requestForm: "The following general form is ready to be shared",
        },
        saveAsDraft: "Save as draft",
        shareWithInsuranceDisabledTooltip:
          "To share information with the insurance, both forms need to be completed or uploaded",
        shareWithInsuranceDisabledTooltipBeforeHandedover:
          "To share information with the insurance, please hand over the search.",
      },
      weaning: "Weaning",
      weight: "Weight",
      woundClosed: "The wound is unremarkable / closed ",
      zipcodeState: "Postcode, county",
    },
    gender: "Gender",
    grants: "Financing",
    hasAppliedforCarelevel: "Has applied for carelevel",
    hasAppliedforHigherCarelevel: "Has applied for higher carelevel",
    hasHealthInsurance: "Patient has health insurance",
    hasHelpAtHome: "Patient has help at home (nurse, life assistant)",
    hasNoHealthInsurance: "Patient does not have health insurance",
    hintAdditionalExaminations: "e.g. blood test, Scan",
    hintIntravenousInjection: "Please specify the medication",
    hintIsNotBarrierFree:
      "e.g. stairs indoors and outdoors, access to bathtub not possible",
    hintMedicalHistory:
      "Please specify the most important facts for the receiver",
    hintRehabIE:
      "This module is not accessible with Internet Explorer. Please use another browser such as Google Chrome or Mozilla Firefox.",
    hintSpecialisedAdvices: "Please choose up to three",
    hintTakesBloodThinner: "Please specify the medication",
    hospitalStay: "Hospital stay",
    insuranceNumber: "Insurance number",
    insuranceProvider: "Health insurance",
    insuranceShortProfile: "Short patient profile",
    integrationMissingFieldsModal: {
      body: "Please fill the following mandatory fields in the patient profile in order to share the forms.",
      editPatient: "Edit patient profile",
      title: "Missing information in patient profile",
    },
    interestedStayLongTerm: "Patient might be interested to stay long term",
    interestedStayLongTermRequest: "long term stay possible",
    intravenousInjection: "Intravenous injection",
    isNotBarrierFree: "Patient’s housing situation is not barrier-free",
    kis: {
      addPatientTooltip:
        "All the available data is about to be automatically transferred from HIS to Recare.",
      getFilesError:
        "Files could not be fetched - check if the internal patient ID is correct and files are available",
      headlineImportPatientData: "Importing patient data from HIS",
      headlineReimportPatientData:
        "Do you want to import the newest data from HIS?",
      idPlaceholder: "Please type the internal patient ID",
      importError:
        "Patient data could not be imported. Please type in the external patient ID again and retry or contact Recare.",
      importErrorPopup:
        "The import was canceled. Please allow pop-ups to be opened in your browser so that the patient data can be imported or contact Recare.",
      importPatientData: "Import patient data",
      importSuccessfulToast: "The import was successful",
      noDataForInternalId:
        "No data can be found with this internal patient ID.",
      reimportPatientData: "Reimport",
      reimportPatientDataHint:
        "The imported data will replace the information that is currently in the patient profile. Apart from this update, no other data will be overwritten.",
      tooltipConnected:
        "Recare is connected to your HIS. You can import patient data automatically.",
      tooltipNotConnected:
        "Recare is currently unable to connect to your HIS. You still can add patient data manually.",
      tooltipNotConnectedReimport:
        "Recare is currently unable to connect to your HIS. You still can edit the patient data manually.",
    },
    lastName: "Last name",
    loadingError: "Could not access patient ",
    mainSpecializations: "Main specialisation",
    map: {
      mapButton: "Show map",
      mapReceiverText: "Click on “Show map” to see the location.",
      mapSenderText: "Click on “Show map” to see the location of the provider.",
      title: "Map",
    },
    medicalDiagnosis: {
      addictions: "Addictions",
      allergies: "Allergies",
      assessmentDate: "Assessment date",
      assessmentDateValidation: "Please choose a assessment date in the past",
      backToProfile: "BACK TO PROFILE",
      barthelDate: "Date:",
      barthelHeadingResultPage: (args: {
        patientName: string;
        recareId: string;
      }) => template("Barthel-Index: {{patientName}} {{recareId}}", args),
      barthelIndexPlaceholder: "Barthel-Index sum",
      barthelLink: "Open Barthel-Index overview page",
      barthelNoValue:
        "There is no result that can be displayed here yet. Go back to the patient profile and fill in the barthel index to display results.",
      barthelScore: "Total score:",
      barthelSubtitle:
        "Please select all applicable options to calculate the total score",
      barthelValidation: "Please enter a number between 0 and 100",
      chronicPains: "Chronic pains",
      conductedEarlyRehab:
        "Early-stage complex treatment has already been performed in the hospital",
      conductedEarlyRehabLabel:
        "i.e. when and where or in which phase the treatment took place",
      currentInfectionsError: "Please select an option",
      dependencyLevel: "Barthel-Index",
      details: "Details",
      diagnosis: "Diagnosis",
      diagnosisType: {
        admissionDiagnosis: "Admission diagnosis",
        admissionDiagnosisDepartment: "Admission diagnosis department",
        ambulanceDiagnosis: "ambulance diagnosis",
        ambulantDischargeDiagnosis: "Discharge diagnosis ambulant",
        anaesthesiaDiagnosis: "Anaesthesia Diagnosis",
        anamnesis: "Anamnesis",
        careDiagnosis: "Care diagnosis",
        causeOfDeath: "Cause of death",
        childBirthDiagnosis: "Child birth diagnosis",
        childDischargeTransferDiagnosis: "Discharge transfer diagnosis child",
        childMorbidity: "Child morbidity",
        conservativeDiagnosis: "Conservative diagnosis",
        cumulativeProcedures: "Cumulative Procedures",
        departmentDiagnosis: "Department Diagnosis",
        departmentMainDiagnosis: "Department main diagnosis",
        dischargeDiagnosis: "Discharge diagnosis",
        dischargeDiagnosisDepartment: "Discharge diagnosis department",
        evolution: "Evolution",
        examinationDiagnoses: "Examination diagnoses",
        hospitalMainDiagnosis: "Hospital Main Diagnosis",
        infectionControlDiagnosis: "Infection Control Diagnosis",
        insuranceExtensionDiagnosis: "Extension diagnosis (insurance)",
        invoicingDiagnosis: "Invoicing Diagnosis",
        medicalInstructions: "Medical instructions",
        operationDiagnosis: "Operation - diagnosis",
        otherDiagnosis: "Other Diagnosis",
        postoperativeDiagnosis: "Postoperative diagnosis",
        preoperativeDiagnosis: "Preoperative diagnosis",
        principleDRG: "Principle DRG",
        recommendedRoutine: "Recommended Routine",
        referralDiagnosis: "Referral diagnosis",
        rehabMeasures: "Rehab Measures",
        secondaryDiagnosis: "Secondary diagnosis",
        secondaryDRG: "Secondary DRG",
        transferDiagnosis: "transfer diagnosis",
        treatmentDiagnosis: "Treatment diagnosis",
        treatmentDiagnosisDepartment: "Treatment diagnosis department",
      },
      doctorInChargeInHospital: "Name of doctor in charge",
      doctorInChargeInHospitalPhone: "Phone number of doctor/station in charge",
      earlyRehabBarthelIndexPlaceholder: "Early rehab Index sum",
      earlyRehabDateWarning: "Please enter a date",
      earlyRehabForm: "Early rehab index",
      earlyRehabIndex: {
        behaviouralDisorder:
          "Behavioural disorder subject to supervision (and dangerous to self or others)",
        communicationDisorder: "Severe communication disorder",
        dateTitle: "Date:",
        earlyRehabLink: "Open Early Rehab overview page",
        earlyRehabValidation: "Please enter a number between -325 and 0",
        headingResultPage: (args: { patientName: string; recareId: string }) =>
          template("Early Rehab Index: {{patientName}} {{recareId}}", args),
        intensiveMonitoring: "Condition requiring intensive medical monitoring",
        intermittentVentilation: "Intermittent ventilation",
        noValue:
          "There is no result that can be displayed here yet. Go back to the patient profile and fill in the early rehab index to display results.",
        orientationConfusion:
          "Orientation disorder subject to supervision (confusion)",
        scoreTitle: "Total score:",
        subtitle:
          "Please select all applicable options to calculate the total score.",
        swallowingDisorder: "Supervised swallowing disorder",
        tracheostoma: "Tracheostoma requiring aspiration",
      },
      form: "Form",
      hintSecondaryDiagnoses: "relevant diagnoses",
      hospitalStay: "Hospital stay ",
      icdCodeMainPatientInfoLabel: "Relevant for rehab",
      icdCodes: "ICD Codes",
      icdCodesAdditional: "Up to 5 additional ICD-10 relevant for the receiver",
      icdCodesAdditionalPatientInfoLabel: "Further ICD‌-10",
      icdCodesMain: "ICD 10 for rehab main diagnosis",
      infections: "Current Infections",
      infectionsClostridien: "Clostridien",
      infectionsFourMRGN: "4MRGN",
      infectionsHint: "Please specify",
      infectionsMRSA: "MRSA",
      infectionsOther: {
        errorMissing: "Please specify",
        label: "Other infection",
        placeholder: "eg. ESBL, Norovirus, etc.",
      },
      infectionsRemarks:
        "Remarks eg. localisation, has the remediation started, etc.",
      infectionsRequiresIsolation: "Requires isolation",
      infectionsThreeMRGN: "3MRGN",
      infectionsVRE: "VRE",
      limitations: "Limitations",
      limitedCapacity: "Limited capacity to actively take part in the therapy",
      limitedCapacityOther: "Other",
      limitedCognitiveCapacity: "Limited cognitive capacity",
      limitedPsychicalCapacity: "Limited physical capacity",
      limitedPsychologicalCapacity: "Limited psychological capacity",
      limitedSocialBehaviour: "Limited social behaviour",
      mainDiagnosis: {
        infectionsHint: "Please specify",
        label: "Main diagnosis",
        placeholder:
          "ie. pneumonia, thigh fracture, acute renal insufficiency, etc.",
        validationError:
          "Please either select the ICD-10 code or enter the main diagnosis in the text field.",
        validationErrorMainOnly:
          "Please enter the main diagnosis in the text field. ",
      },
      multiMorbidity: "Multimorbidity",
      multiMorbidPatient: "Multimorbid patient",
      operationDate: "Event that justifies AHB",
      operationDescription:
        "e.g. surgery, acute event, last radiation treatment, accident at work, ... ",
      palliativeCertificateNeeded: "SAPV",
      rehabilitationGoals: "Rehabilitation goals/treatment goals",
      rehabilitationPotential: "Rehabilitation potential",
      rehabilitationPotentialDescription:
        "Description of rehabilitation potential",
      remarks: "Additional Information",
      secondaryDiagnosesCostPayer: "Secondary diagnoses for cost payer",
      secondaryDiagnosis: "Secondary diagnoses",
      shortTerm: "Returning home possible in the short term",
      singerIndexPlaceholder: "SINGER-patient profile sum",
      singerPatientProfil: "SINGER patient profil",
      singerPatientProfile: {
        assessmentDatePlaceholder: "Assessment date",
        assessmentDateValidation:
          "Please choose an assessment date in the past",
        buttonClearContent: "Reset content",
        buttonDelete: "Delete",
        buttonEdit: "Edit",
        buttonFill: "Fill in form",
        communicationListening: "Listening comprehension",
        communicationTalk: "Talk",
        confirmationDelete:
          "Are you sure you want to delete the form? All entries in the form will be reset.",
        creationDate: "Date of creation",
        creationDateError: "Please select a date in the past",
        dateTitle: "Date:",
        detailedForm: "Fill in detailed form",
        domesticLifeHousehold: "Household Management",
        headingResultPage: (args: { patientName: string; recareId: string }) =>
          template("SINGER Index: {{patientName}} {{recareId}}", args),
        inputValidation: "Select a value",
        learnRead: "Read and understand",
        learnWrite: "Write",
        mobilityStairs: "Climbing stairs",
        mobilityTransfer: "Transfer",
        mobilityWalk: "Walk",
        mobilityWheelchair: "Wheelchair use",
        noValues:
          "There is no result that can be displayed here yet. Go back to the patient profile and fill in the SINGER patient profile to display results.",
        scoreIndependent: "Independent",
        scoreIndependentFive: "Without help (5 Points)",
        scoreIndependentFour: "With help (4 Points)",
        scorePoints: "Points",
        scoreProfessionalHelp: "Professional help",
        scoreProfessionalHelpOne: "Contact help (1 Point)",
        scoreProfessionalHelpZero: "Total (0 Points)",
        scoreSupporter: "Supporter",
        scoreSupporterThree: "Assistance (3 Points)",
        scoreSupporterTwo: "Contact help (2 Points)",
        scoreTitle: "Total score:",
        scoreTotalScore: "Total score",
        selfsufficiencyDress: "Dressing and undressing",
        selfsufficiencyEat: "Eat, drink",
        selfsufficiencyHygiene: "Personal hygiene",
        selfsufficiencyShower: "Washing, showering",
        selfsufficiencyStool: "Stool control",
        selfsufficiencyToilet: "Using the toilet",
        selfsufficiencyUrine: "Urine control",
        singerDomainCommunication: "Communication",
        singerDomainDomesticLife: "Domestic life",
        singerDomainLearn: "Learning und applying knowledge",
        singerDomainMobility: "Mobility",
        singerDomainSelfsufficiency: "Self-sufficiency",
        singerDomainSocialInteraction: "Social interaction",
        singerDomainTask: "General tasks and requirements",
        singerLink: "Open SINGER patient profile overview page",
        singerSum: "SINGER sum",
        singerSumValidation: "Please enter a number between 0 and 100",
        socialInteraction: "Social interaktion",
        subtitle:
          "Please select the appropriate number of points for all items to calculate the total score.",
        taskConcentration: "Permanent concentration",
        taskOrientation: "Orientation/memory",
        taskPlanning: "Planning and solving problems",
        title: "SINGER-Patient profile",
      },
      stepperTitle: "Medical diagnosis",
      totalScore: "Total Score",
    },
    medicalHistory: "Medical history",
    medicalHistoryPlaceholder:
      "Specify the most important information for the recipient",
    medicalSupplies: {
      existingMedicalSupplies: "Existing Medical Supplies",
      neededMedicalSupplies: "Needed medical supplies",
      prescribedMedicalSupplies: "Prescribed medical supplies",
      specificMedicalSupplies: "Please specify",
    },
    mentalHealth: "Mental health",
    mentalState: {
      aggressivity: "Aggressivity",
      anxiety: "Anxiety",
      closed_area_needed: {
        label: "Closed area needed",
        placeholder: "eg. has run away several times from home, etc.",
      },
      closedStationRequired: "Protected area / Closed station required",
      dangerous: "Dangerous to self or others",
      dementia: {
        label: "Dementia",
        placeholder: "eg. instructions needed, manageable, etc.",
      },
      depression: "Depression",
      facilityCharacteristics: "Facility characteristics",
      fixationRequired: "Fixation required",
      mentalIssues: "Mental issues",
      mentalIssuesOther: "Other mental disorders and further information",
      nightwatch: {
        label: "Nightwatch",
        placeholder: "eg. situation of the patient, etc.",
      },
      stepperTitle: "Mental state",
    },
    menuAssigneePlaceholder: "Select assignee",
    mobileShort: {
      stepTitle: "Short profile",
    },
    mobility: {
      assistiveEquipment: "Assistive Equipment",
      auditive_impairment: {
        label: "Auditive impairment",
        placeholder: "eg. has a hearing aid, etc.",
      },
      orthese: {
        label: "Orthosis",
        placeholder: "ie. part of the body, since when, etc.",
      },
      prothese: {
        label: "Prothesis",
        placeholder: "ie. part of the body, since when, etc.",
      },
      stepperTitle: "Mobility",
      visual_impairment: {
        label: "Visual impairment",
        placeholder: "ie. glasses, glass eye, etc.",
      },
    },
    needsHelpEating: "Patient requires support with feeding",
    needsHelpWalking: "Patient requires walking aid",
    newResponsibleMessagePlaceholder: "Your message to the selected person",
    noAuctionsError:
      "Something went wrong during the patient creation process, please retry",
    noCandidates:
      "Currently, due to selected criteria, there is no available providers to contact",
    nonDeclarationOfConsent:
      "The patient's consent has not been obtained or the information has not been provided",
    noPersonalDataWarning:
      "Please, add sensitive patient information only to the encrypted fields.",
    notifySocialWorker: {
      assigneeChangeFailed:
        "Your request to change the assignee of this patient request has failed, please refresh and try again. ",
      title: (args: { assignedName: string }) =>
        template(
          "New responsible social worker has been saved successfully. Do you want to notify {{assignedName}} about the assignment via email?",
          args,
        ),
    },
    palliativeCare: {
      label: "Palliative care",
      placeholder: "eg. deterioration, psychological supervision, etc.",
    },
    patient: "Patient",
    patientAlreadyInHospital: "Patient is already in the hospital",
    patientId: "Recare ID",
    patientInformation: {
      acceptsPrivatePayment: "Private payment possible",
      addictionsPlaceholder: "e.g. Type, medications, etc.",
      allergiesPlaceHolder: "e.g. Type, treatment, etc.",
      chronicPainsPlaceHolder: "e.g. Localisation, treatment, etc.",
      civilServantsAid: "Civil servants aid",
      civilServantsAidDescription: "Civil servants aid details",
      civilServantsAidPlaceholder: "e.g. relevant aid office",
      contactIsPatient: "Patient is contact person",
      contactIsPatientPlaceholder:
        "e.g. contact information, phone number, email address",
      currentDomesticSituation: "The current domestic situation is safe",
      decisionsLanguages: " Contacts and communication",
      encryptionExplanation:
        "This information is encrypted. Ask the clinic to share the patient data.",
      financing: "Financing",
      financingNotSelected: "No financing options were selected.",
      floor: "Floor",
      freeFromSupplementaryPayment: "Free from supplementary payment",
      generalInformation: "General information",
      generalPractitionerContactDetailsPlaceholder: "Contact details of the GP",
      generalPractitionerNamePlaceholder: "Name of GP",
      generalPractitionerPlaceholder:
        "e.g. specialty, phone number, address, etc.",
      generalPractitionerTitle: "General practitioner",
      grantsMoreInformation: "Further financing information",
      guardianContactInformation: "e.g. contact information of the guardian",
      guardianRequested: "Guardianship was requested",
      hasGuardian: "Patient has / needs a guardian",
      hasGuardianPlaceholder:
        "e.g. guardian or legal representative for specific areas",
      insurance: "Insurance",
      isLiftAvailable: "Lift is available",
      liftAvailable: "Lift available",
      livingSituation: "Living situation",
      mobilityPlaceholder:
        "Additional information about mobility, e.g. insecure walking, walking distance, carrying weight, etc.",
      monitoringNeeds: "Monitoring equipment requirements",
      needsOxygen: "The patient requires oxygen",
      patientCannotCommunicateInGerman: {
        label: "Patient cannot communicate in german",
        placeholder: "e.g. which language, is there a translator, etc.",
      },
      patientIsLiving: "Patient is living",
      patientUsesSignInLanguage: {
        label: "Patient uses sign-language",
        placeholder:
          "e.g. a sign interpreter is available, a communication aid is needed, etc.",
      },
      payers: "Payers",
      payersInsurance: "Insurance (public or private)",
      payersMoreInformations: "Further information",
      payersOther: "Other payer",
      payersOtherDescription: "Other payer details",
      payersOtherPlaceholder: "Please specify",
      payout: "Payout",
      placeholder: "Please specify",
      placeholderCurrentDomesticSituationUnknown: "Please specify",
      placeholderLiftAvailableNo: "e.g. floor, steps in the flat",
      placeholderLiftAvailableUnknown: "Please specify",
      publicAccidentInsurance: "Public accident insurance",
      publicAccidentInsuranceDescription: "Public accident insurance details",
      publicAccidentInsurancePlaceholder:
        "e.g. relevant employers' association",
      publicPension: "Public pension",
      publicPensionDescription: "Public pension details",
      relativesAvailable: "Relatives / other contact persons are available",
      relativesContactInformation:
        "e.g. name, relationship, phone number, address",
      relativesLookingSameTime:
        "Relatives are looking for a care spot at the same time",
      selfPayer: "Self-payer",
      selfPayerDescription: "Self-payer details",
      selfPayerPlaceholder: "e.g. payment of maximum...",
      socialServiceDepartment: "Social service department",
      socialServiceDepartmentDescription: "Social service department details",
      socialServiceDepartmentPlaceholder: "e.g. relevant social service office",
      specialRequirements: "Special requirements",
      stepperTitle: "Patient information",
      supplementaryInsurance: "Supplementary insurance",
      supplementaryInsuranceDescription: "Supplementary insurance details",
      supplementaryInsurancePlaceholder:
        "e.g. name of the supplementary insurance",
      supplementaryPayment: "Supplementary payment",
      supplementaryPaymentDescription: "Supplementary payment details",
      supplementaryPaymentPlaceholder: "e.g. 10€/day still has to be paid",
      unsafeCurrentDomesticSituation: "Current domestic situation is not safe",
      unsafeCurrentDomesticSituationPlaceholder:
        "e.g. patient cannot take care of the household himself",
      visualImpairment: "The patient is visually impaired",
    },
    patientProfile: "Patient Profile",
    patientSearchLabel: "Recare/internal ID",
    patientType: "Type of transfer",
    payers: "Cost payer",
    personalId: "Personal ID",
    physicalHealth: "Physical health",
    placeholderBarrierFreeUnknown: "Please specify",
    pleaseNoteId: (args: { patientId: string }) =>
      template(
        "Please note the Recare-ID ({{patientId}}) as a primary reference in your files or system",
        args,
      ),
    preventativeCare: "Preventative care",
    profile: "Profile",
    profileBanners: {
      noProviderFound: {
        subtitle: "You can add receivers to the search manually.",
        title: "There are no potential receivers to contact.",
      },
      profileComplete: {
        subtitle: "You can start the search.",
        title: "Patient profile is complete.",
      },
      profileIncomplete: {
        subtitle: "Please edit the information.",
        title: "Patient profile is not complete.",
      },
    },
    publicCareInsuranceStatus: "Public care insurance currently received",
    reimbursmentForHelpAtHome: "Reimbursement for help at home",
    reimbursmentSecured: "Reimbursment for medical-care secured",
    reliefServices: "Relief services",
    responsiblePerson: "Person responsible",
    savedWithSolutionChange: (args: { listOfSolutions: string }) =>
      template(
        "Changes successfully saved. You are now searching for {{listOfSolutions}}.",
        args,
      ),
    savedWithSolutionChangeGeneric: (args: { listOfSolutions: string }) =>
      template(
        "Changes successfully saved. You are now searching for {{listOfSolutions}}. Please note that changes may affect the providers who can be contacted.",
        args,
      ),
    searchOnlyApplyToSolutions: (args: { solutions: string }) =>
      template("This filter will only apply to {{solutions}}", args),
    searchPausedAutomaticallyWarning:
      'Due to improvements to the search function, it is no longer possible to pause a search. Your paused search has been stopped automatically. Click "Restart the search" to resume it.',
    searchRadius: "Search radius",
    searchType: "Patient type",
    shortenedRehabPatientProfile: "Shortened patient profile is sufficient",
    shortTermCareGrant: "Short term care grant",
    singerIndex: {
      dressingUndressing: "Dressing, undressing",
      eatingDrinking: "Eating, Drinking",
      personalHygiene: "Personal hygiene",
      selfSufficiency:
        "Self sufficiency\n\n\n\nSelf sufficiency\n\n\n\n\n\nSelf sufficiency\n\n\n\nSelf sufficiency\n\n\n\n",
      stoolControl: "Stool control",
      urineControl: "Urine control",
      washingShowering: "Washing, showering",
    },
    socialHelpRecipient: "Social help recipient",
    socialWorkerName: "Social worker name",
    solutionLabel: "Type of care",
    specialisedAdvices: "Specialised advices necessary",
    specialistDoctor: "Specialist doctor",
    station: "Station",
    stationPhoneNumber: "Station phone number",
    stopAuction: {
      automaticallyStopped:
        "Search automatically stopped due to inactivity for more than 14 days",
      dischargeDelayed: "Discharge is delayed",
      dischargedWithoutProvider: {
        care: "Patient was discharged without provider",
        geriatrics: "No suitable clinic could be found",
      },
      foundWithoutRecare: (args: { receiverTypeSingular: string }) =>
        template(
          "A {{receiverTypeSingular}} was found via another route or confirmed by phone/fax",
          args,
        ),
      insuranceWorkerOrganised: "By insurance worker",
      internalTransfer: "Transfer within the hospital",
      notNeeded: (args: { receiverTypeSingular: string }) =>
        template("A {{receiverTypeSingular}} is no longer needed", args),
      options: {
        foundWithoutRecare: (args: { receiverTypeSingular: string }) =>
          template(
            "A {{receiverTypeSingular}} was found via another route or confirmed by phone/fax",
            args,
          ),
        subtitle:
          "State the reason as accurately as possible and help us improve the platform",
        transferNotNeeded: (args: { receiverTypeSingular: string }) =>
          template("A {{receiverTypeSingular}} is no longer needed", args),
      },
      other:
        "I want to archive the patient for other reason and stop the search",
      otherReason: "Other reason",
      patientDied: "Patient died",
      patientTransfered: {
        care: "Patient was transfered (e.g. Geriatrics)",
        editProfile:
          "If you want to edit and update the patient profile during the search, {select:click here}.",
        geriatrics: "Transfer was not needed anymore",
      },
      providerFound: {
        care: "A provider has been found",
        geriatrics: "A clinic has been found",
      },
      providerFoundWithoutRecare: {
        care: "A provider has been found",
        geriatrics: "A clinic has been found",
      },
      providerNotNeeded: "Patient does not need a provider anymore",
      providerWasFound: (args: { receiverTypeSingular: string }) =>
        template("A {{receiverTypeSingular}} has been found", args),
      providerWillBeInformedOfUnavailability:
        "Please note that the following will be informed by email that this request is not available anymore:",
      relativesOrganised: "By the relatives",
      selectClosestReason:
        "State the reason as accurately as possible and help us improve the platform",
      socialworkerOrganised: "By the social worker",
      title: "I want to cancel the search because...",
      transferred: "Patient was transferred",
      withoutProvider: (args: { receiverTypeSingular: string }) =>
        template(
          "Patient was discharged without {{receiverTypeSingular}}",
          args,
        ),
    },
    stopAuctionAddCareprovider: {
      providerName: (args: { receiverTypeSingular: string }) =>
        template("We know {{receiverTypeSingular}}’s name", args),
      title: (args: { receiverTypeSingular: string }) =>
        template("A {{receiverTypeSingular}} has been found", args),
      unknownProvider: (args: { receiverTypeSingular: string }) =>
        template("We don’t know {{receiverTypeSingular}}’s name", args),
      viaPatientFamily: "Via patient's family",
    },
    stopAuctionStop: {
      placeholder: "Please explain",
      title: "Why do you want to stop the search?",
    },
    stopSearch: {
      cancelButton: "Cancel",
      confirmButton: "Confirm",
      firstStep: {
        firstStepText:
          "Once the search has ended, we automatically inform all providers that the search is inactive. Therefore, there is no need for you to take any further action.",
        firstStepTitle: (args: { providerNumber: string }) =>
          template(
            "For this search {{providerNumber}} providers were contacted",
            args,
          ),
      },
      nextButton: "Next",
      secondStep: {
        dropdownButton: "Provider cannot be found",
        mandatoryFieldInformation: "Please select the appropriate reason",
        mandatoryFieldInformationProvider:
          "Please enter the name of the provider",
        mandatoryFieldInformationSpecialisation:
          "Please select the specialisation",
        optionOne: "Provider was found via a different route",
        questionText: "Please select the appropriate reason (mandatory field)",
        requestAlreadyAccepted:
          "This request has already been accepted by the provider. \n{select:Click here} to view the validation in Messenger and see the details on the request page.",
        subtitle:
          "Please state the reason as accurately as possible to help us improve the platform.",
        title: "Why are you stopping this search?",
      },
    },
    takesBloodThinner: "Blood thinner",
    takesBloodThinnerPlaceholder: "Blood thinner placeholder",
    titleBarrierFree: "Housing situation is barrier-free",
    titleLengthRehabAssessment: "Scope of the patient profile",
    tooltipPatientMenu: {
      potentialReceivers: "Potential receivers:",
      searchRadius: "Search radius:",
      specialisation: "Specialisations:",
      startDate: "Start date:",
      treatmentLocation: "Treatment location:",
      typeOfTransfer: "Type of transfer:",
    },
    transfer: "Transfer",
    transferChanges:
      "You can add or edit your solutions below. This may result in excluding some receivers from the search if they don’t offer selected solutions.",
    transferDateOlderThanTenDaysWarning:
      "Planned start date is over 7 days in the past. Please stop the search if it's not longer needed.",
    transferDetails: {
      hospitalInformation: "Hospital information",
      phoneNumber: "Phone number",
      specialisationsRequired: "Specialisations required",
      station: "Station",
      title: "Transfer details",
    },
    transferSearchInProgress:
      "Transfer - You can add or edit your solutions below",
    transitionOfPausedSearchesWarning:
      'We are working to improve your search experience. As part of the changes, as of August 22, 2023, it will no longer be possible to pause searches. All paused searches will be automatically stopped by us on that date. Searches can be manually resumed at any time by clicking "Restart the search".',
    transport: {
      accompanimentMedicallyNecessary: "Accompaniment is medically necessary",
      accompanyingPersonNecessary: "Accompanying person necessary",
      dailyTransportNeeded: "Daily transportation is needed",
      labelNameAndContact:
        "Name and contact information of the accompanying person",
      organizedBy: {
        label: "ie. transport has to be organized, particularities, etc.",
        title: "Further information regarding the transportation",
      },
      patientNeedsTransportation: "Patient needs transportation",
      stepTitle: "Transport",
      timeHasPassed: "Chosen time slot is in the past",
      titleTransportInformation: "Information regarding the transport",
      typeOfTransport: "Type of transport",
      weightLimitAdditionalInfo: (args: { weight: string }) =>
        template("Patient transports are limited to {{weight}} kg", args),
      weightLimitExceeded: "Max. weight exceeded",
      weightLimitExceededToast:
        "Patiententransporte sind aktuell nur bis zu 180 kg möglich",
    },
    treatmentLocation: {
      privacyDescription:
        "Because of data privacy, the exact address cannot be displayed. The location of the patient is inside the blue radius.",
      privacyDescriptionEncrypted:
        "The location of the patient is inside the blue radius.",
      title: "Treatment location",
    },
    upgradePlan: "Your plan does not support this patient type",
    walkAlone: "Can walk alone",
    warningLeavingAssessment:
      "Are you sure you want to leave this page?\nAny changes you have made will not be saved automatically.",
    yearOld: "years old",
  },
  patientForms: {
    buttonImport: "Import application data",
    buttonRefreshData: "Update data",
    buttonSave: "Save and create PDF",
    buttonSaveOverwrite: "Save and overwrite PDF",
    connectionFailed: {
      buttonTryAgain: "Try again",
      title:
        "Recare cannot currently establish a connection to your HIS. However, you can enter patient data manually.",
    },
    dateField: {
      title: "Date of transmission to the health insurance company",
      warning: "Please enter a valid date in the future.",
    },
    drvGeneralForm: {
      description:
        "To help you fill out the form, you can click on ‘Import application data’ to import data that is already available into the form. Please check this data and add any missing information.",
      doctorFirstName: "First name",
      insuranceInformation: {
        addressHealthInsurance:
          "Name and address of the health insurance company",
        addressHospital: "Name and address hospital",
        addressPensionInsurance: "Address of the pension insurance institution",
        informationAhbFacility:
          "Address of the AHB facility (if required by the procedure with the pension insurance institution)",
        informationSocialWorker:
          "Surname, first name, telephone number, fax number",
        pensionInsuranceNumber: "Pension insurance number",
        subtitleHospital: "Hospital",
        subtitleRehabClinic: "AHB facility",
        subtitleSocialWorker:
          "Contact details of the contact person at the hospital (e.g. social services/case management)",
      },
      sectionEight: {
        contributionsAbroad:
          "9.2 Have you paid social security contributions abroad?",
        contributionsPension:
          "9.1 Have you paid contributions to the German pension insurance scheme?",
        contributionsState: "State",
        currentContributionsAbroad:
          "9.3 Do you currently pay social security contributions abroad?",
        optionNo: "No",
        optionYes: "Yes",
        timeline: "From - until",
        title: "9. Social security contributions",
      },
      sectionEleven: {
        attached: "Attached",
        authorisedRepresentative: "Authorised representative",
        carer: "Carer",
        city: "City",
        email: "Fax, e-mail",
        guardian: "Guardian",
        lackOfCapacity:
          "Proof of the insured person's / insured person's lack of capacity to understand / consent",
        legalGuardianType: "In the role as",
        legalRep: "Legal representative",
        nameRep:
          "Surname, first name / office (file number) of the representative",
        optionNo: "No",
        optionYes: "Yes",
        phone: "Phone number",
        street: "Street, house number",
        submittedLater: "Will be submitted later",
        subtitle: "12.1 Is the application made by other persons?",
        title: "12. Application by another person",
        zipCode: "Zip code",
      },
      sectionFive: {
        fulltimeWithNightShifts: "Full-time work with night shift",
        fulltimeWithoutShifts: "Full-time work without rotating shifts",
        fulltimeWithShifts: "Full-time work with alternating shifts",
        homeOffice: "Home office",
        houseWife: "Exclusively househusband / housewife",
        parttime: "Part-time work, less than half the usual working hours",
        partTimeUsual:
          "Part-time work, at least half of the usual working hours",
        registeredUnemployed: "Registered unemployed",
        title:
          "6. Work before application or before current incapacity for work",
        unemployed: "Not employed",
        workshopDisabilities:
          "Employment in a workshop for people with disabilities",
      },
      sectionFour: {
        apprentice: "Apprentice",
        civilServant: "Civil servant",
        employee: "Employee",
        foreman: "Foreman",
        selfEmployed: "Self-employed",
        skilledLabourer: "Skilled labourer",
        skilledWorker: "Semi-skilled labourer",
        subtitle: "Employment status",
        title: "5. Current position in professional / working life",
        unemployed: "Unemployed",
        unskilledWorker: "Unskilled worker",
      },
      sectionNine: {
        jobCenter: "Name of Job center, file number",
        optionNo: "No",
        optionYes: "Yes",
        subtitle:
          "Do you receive or have you received unemployment benefit II in the current calendar year?",
        title: "10. Receiving unemployment benefit II",
      },
      sectionOne: {
        inpatient: "Inpatient",
        oncologicalRehab:
          "Follow-up rehabilitation (AHB) as a service for oncological rehabilitation for relatives",
        outpatient: "All-day outpatient",
        rehabForInsured: "Follow-up rehabilitation (AHB) for insured persons",
        title: "1. Requested service",
      },
      sectionSeven: {
        doctorCity: "City",
        doctorPhone: "Phone number",
        doctorStreet: "street, house number",
        doctorZipCode: "Zip code",
        title: "8. Attending physician",
      },
      sectionSix: {
        doctorName: "Name",
        insuranceInformation: "7. Health insurance",
        insuranceName: " Name of the insurance",
        optionNo: "No",
        optionYes: "Yes",
        patientInsurance: "Patient insurance",
        privatelyInsured: "Privately insured",
        publiclyInsured: "Statutorily insured",
        sinceDate: "Since",
        subtitleHospitalStay:
          "Were you already unfit for work immediately before the hospital treatment?",
      },
      sectionTen: {
        accidentOrPerson:
          "11.5 Is the reduction in or significant risk to your earning capacity leading to the application for rehabilitation wholly or partly the result of an accident or caused by other persons?",
        benefitsType: "Type of benefits",
        compensatedOn: "On",
        compensationClaims: "Claims for compensation",
        damageClaims:
          "Have claims for damages been asserted (e.g. with private insurance companies)?",
        fileNumber: "File number",
        healthIssues: "Which health issues?",
        namePensionInsurance: "Name of the pension insurance provider",
        optionNo: "No",
        optionYes: "Yes",
        otherBenefits:
          "Do you receive a benefit that is paid regularly until the start of an old-age pension (e.g. company pension, early retirement benefit, miners' equalisation benefit)?",
        penisonApplication:
          "Do you receive a pension or have you currently applied for one?",
        pensionBenefits:
          "11.2 Pension benefits from the statutory pension insurance scheme",
        pensionInformation:
          "11.1 Are you employed in an occupation that guarantees entitlement to a pension under civil service law or equivalent regulations or are you exempt from statutory pension insurance due to drawing a pension because you have reached an age limit?",
        pensionOldAgeBenefits:
          "11.3 Benefits until the start of an old-age pension",
        recognisedBy: "Recognised by whom?",
        submittedApplication:
          "Have you currently submitted a corresponding application?",
        submittedWhere: "Submitted where?",
        title: "11. other information",
        workRelatedAccident:
          "11.4 Have any health problems been recognised in your case as a result of an accident at work or an accident on the way to work, as an occupational disease, as a result of a military or civilian service injury, as a result of a health impairment during deployment abroad as a soldier or civilian, as a result of an act of violence within the meaning of the Act on Compensation for Victims of Violent Crimes, as a persecution-related illness, as vaccination damage?",
      },
      sectionThirteen: {
        additionalFormat:
          "Due to my disability, please send me additional documents in a form that I can perceive:",
        audio: "as an audio medium",
        brailleFull: "in Braille (full text)",
        brailleShort: "in Braille (shorthand)",
        cd: "as CD",
        confirmation:
          "I request that the documents be sent exclusively in electronic form to my De-Mail mailbox. This eliminates the need to send the documents in paper form.",
        description: "I have opened a De-Mail mailbox with a De-Mail provider",
        germanMailAddress: "De-Mail",
        largePrint: "as large print",
        subtitleEmail: "14.1 Via De-email",
        title: "14. Document access",
        visuallyImpaired: "14.2 For visually impaired people",
      },
      sectionThree: {
        divorced: "Divorced",
        fieldLabelProfession: "Last occupation / profession exercised",
        married: "Married",
        single: "Single",
        subtitle:
          "4.1 Marital status (also applies to registered civil partnerships within the meaning of the Civil Partnership Act)",
        subtitleProfession:
          "4.2 Last occupation / last profession (as precise as possible)",
        title: "4. Information on the patient's marital status and occupation",
        widowed: "Widowed",
      },
      sectionThreeNew: {
        subtitle: "Is it necessary to take a person in need of care with you?",
        title:
          "3. Taking along a person in need of care (only for inpatient medical rehabilitation)",
        yesIncludeForm: "Yes",
      },
      sectionTwelev: {
        aids: "Are you required to use a sign language interpreter or other suitable communication aids or do you use a recognised aid (e.g. wheelchair, guide dog or assistance dog) due to your disability?",
        descriptionAids: "I am dependent on",
        optionNo: "No",
        optionYes: "Yes",
        title: "13. communication aids and recognised aids",
      },
      sectionTwo: {
        city: "City",
        dateOfBirth: "Date of Birth",
        diverse: "Diverse",
        female: "Female",
        firstName: "First name",
        formerNames: "Former names",
        gender: "Gender",
        lastName: "Last name",
        maidenName: "Maiden name",
        male: "Male",
        nationality: "Nationality",
        phone: "Phone number",
        street: "Street, house number",
        title: "2. Information about the patient",
        unknown: "Without entry",
        zipCode: "Zip code",
      },
      title: "DRV - Antrag G0250",
    },
    drvMedicalForm: {
      addressHospital: "Name and address of the hospital",
      description:
        'To help you fill out the form, you can click on "Import application data" to import data that is already available into the form. Please check this data and add any missing information.',
      descriptionHistory:
        "Including histology for malignancy, ejection fraction for cardiological disease, lung function parameters for pneumological diseases or similar",
      sectionFive: {
        accompanyingPerson: "Accompanying person",
        carelevelDescription: "Carelevel",
        carelevelLabel:
          "Is a care level recognized by the nursing care insurance fund?",
        catheters: "Are catheters / drainage tubes / probes in place?",
        cathetersDescription: "Which?",
        eat: "eat without help?",
        germs: "Colonisation with multi-resistant germs?",
        germsDescription: "Which germs?",
        incontinence: "Is there faecal incontinence?",
        lyingDown: "Ambulance",
        medicalReason:
          "A medical justification is required for (not possible for outpatient AHB):",
        move: "move around the ward alone?",
        optionNo: "No",
        optionYes: "Yes",
        orientation:
          "Is the patient oriented in terms of situation / location / time?",
        orientationDescription:
          "More detailed information (neurological ability profile, Barthel Index)",
        sitting: "Sitting",
        taxi: "Taxi",
        title: "5. Is the patient able to",
        travel: "Travelling is possible:",
        travelCar: "car",
        travelHelicopter: "Helicopter",
        travelPublic: "Public transport",
        urinalIncontinence: "Is there urinary incontinence?",
        wash: "wash and dress themselves?",
        wheelchair: "Wheelchair?",
        wound: "Wound without irritation / closed?",
      },
      sectionFour: {
        height: "Height in cm",
        title: "4. Clinical and medical-technical findings",
        weight: "Weight in kg",
      },
      sectionOne: {
        descriptionDiagnosis:
          "Diagnosis (for malignancy including TNM classification)",
        diagnosisIcd: "Diagnosis according to ICD-10-GM",
        drgGroup: "DRG case group",
        title: "1. Diagnosis for rehabilitation (AHB)",
      },
      sectionSeven: {
        remarksDescription:
          "For example: additional information on point 5, limited knowledge of the German language, presence of a substance-related addiction, status of prosthesis fitting",
        title: "7. Remarks",
      },
      sectionSix: {
        accountHolder: "Account holder",
        address: "Street, house number, postcode, city",
        addressPensionInsurance: "Address of the German Pension Insurance",
        bankName: "Financial institution (name, place)",
        iban: "IBAN",
        ikNumber: "IK number",
        subtitleBankAccountInformation: "Business account of the payee:",
        title: "6. Invoicing of fees for the medical report",
      },
      sectionThree: {
        descriptionIllnessess:
          "There are other diseases relevant to treatment, functional limitations, degree of severity",
        diagnosisIcd: "Diagnosis according to ICD-10-GM",
        nyhaStages:
          "for example NYHA stages, paresis, cognitive / mnestic disorders",
        optionNo: "No",
        optionYes: "Yes",
        title:
          "3. Other diseases relevant to treatment, functional limitations, degree of severity",
      },
      sectionTwo: {
        descriptionComplications:
          "Course of the disease, complications, risk factors",
        descriptionMeasures: "Description",
        descriptionTherapies:
          "Current therapies, results (remission status for oncological diseases)",
        endsOnDate: "Ends on",
        fromDate: "From",
        optionNo: "No",
        optionYes: "Yes",
        otherMeasures: "Other necessary therapeutic or diagnostic measures",
        title: "2. Course of the disease, complications, risk factors",
        untilDate: "Until",
      },
      sectionZero: {
        accidentWork: "Accident at work, occupational disease",
        acuteEvent: "Other acute event",
        addressAhb:
          "Address of the AHB facility (if required by the procedure with the competent pension insurance institution)",
        addressPatient: "Current address (street, house number)",
        ahbEventCheckboxGroupLabel: "Event justifying AHB",
        ahbEventDate: "Event justifying AHB Date",
        ahbStartDate: "Planned date of transfer to AHB",
        antineoplasticTherapy: "Last antineoplastic therapy",
        city: "City",
        contactPersonInformation:
          "Surname, first name, telephone number, fax number",
        dateBirthPatient: "Date of birth",
        directDischarge:
          "Direct discharge (only for insured persons of Deutsche Rentenversicherung Bund / Deutsche Rentenversicherung Mitteldeutschland)",
        dischargeDate: "Expected day of discharge",
        instructionAfterExamination:
          "Instruction after examination by the pension provider",
        insuranceNumber: "Insurance number",
        intakeDate: "Admission day at the hospital",
        mobile: "all-day mobile",
        notApplicable: "not applicable",
        patientName: "Last name, first name",
        radiation: "Last radiation treatment",
        static: "Static",
        subtitleAhb: "AHB facility",
        subtitleContactPerson:
          "Contact person with telephone number and fax number",
        subtitleHospital: "Hospital",
        subtitleHospitalAddress: "Address of the hospital",
        subtitlePatientInformation: "Personal details of the patient",
        subtitleSuggestedRehab: "Proposed form of rehabilitation",
        surgery: "Surgery",
        thirdPartyDamage: "Possible damage by third parties",
        typeOfProcedure: "Type of procedure",
        zipCodePatient: "Postcode",
      },
      title: "DRV - Medical report G0260/G0600",
    },
    editSavedDocument: "Edit",
    emptyPage:
      "Please select the document you need from the menu on the left to start.",
    emptyPageTitle: "There are no saved documents.",
    fileDeletion: {
      cancelButton: "Cancel",
      confirmButton: "Delete now",
      text: "This action cannot be undone. If necessary, you can recreate the file.",
      title: "Are you sure you want to delete the file?",
    },
    generalForm: {
      buttonImportData: "Import data",
      buttonRefreshData: "Refresh data",
      buttonSaveAndCreate: "Save and create PDF",
      buttonSaveAndOverwrite: "Save and overwrite PDF",
      description:
        'To help you fill out the form, you can import data that is already available into the form by clicking on "Import application data". Please check this data and add any missing information.',
      insuredPerson: {
        city: "Zip code, city",
        dateOfBirth: "Date of birth",
        healthInsurance: "Insurance",
        insuranceNumber: "Insurance number",
        name: "Insured/insured person Surname, first name",
        phone: "Phone number",
        street: "Street and house number",
      },
      optionNo: "No",
      optionYes: "Yes",
      savedFile: {
        description:
          "This document was created automatically using data from the Recare platform and may have been supplemented or adapted by employees of the clinic. Recare therefore accepts no liability for the completeness and accuracy of the data.",
        print: "Print",
        savedAt: (args: { savedAt: string }) =>
          template("Saved at {{savedAt}}", args),
        title: "PDF documentation",
      },
      sectionI: {
        accessibilityAtHomeSituation:
          "Do you live alone or with somebody at home?",
        accessibilityGuardianType:
          "Which of these roles applies to the person representing you?",
        currentHomeCareEnsured: "Is home care currently ensured?",
        gpCity: "City",
        gpName: "Name, first name",
        gpPhoneNumber: "Phone number",
        gpStreet: "Street, house number",
        gpZipCode: "Zip code",
        labelSpecialRequirements:
          "e.g. list a desired rehabilitation facility with reasons",
        livingSituationElevator: "Is a lift available? ",
        livingSituationFloor: "Which floor do you live on?",
        livingSituationSpecifications:
          "Special features of your home situation (e.g. indoor and outdoor stairs): ",
        optionAssistedLiving: "in assisted living ",
        optionAtHome: "At home",
        optionAtHomeAlone: "alone",
        optionAtHomeWithRelatives: "with relatives/with other persons",
        optionCareHome: "in a retirement/nursing home",
        optionCarer: "Carer",
        optionHealthCareRep: "Authorised health care representative",
        optionLegalRep: "Legal representative",
        placeholderLabelInsuranceName: "Pension insurance provider",
        questionGp: "5. Treating general practitioner (if known)",
        questionGuardian:
          "6. Questions about guardianship, health care proxy or legal representation",
        questionHomeCare:
          "4. What is your current social and domestic care situation?",
        questionLivingSituation: "3. Questions about the living situation",
        questionNeedForCare:
          "Did you apply for long-term care insurance benefits during this hospital stay?",
        questionPensionApplication:
          "Has a pension application been submitted in the last 6 months?",
        questionSpecialRequirements:
          "7. Special requirements and individual wishes for the rehabilitation centre",
        subtitleAddressGp: "Address (if known)",
        subtitleHomeCare: "Do you live",
        subtitlePensionReducedEarning:
          "Has a pension application for reduced earning capacity been submitted in the last 6 months?",
        subtitleRetirementPension:
          "Has an application for an retirement pension been submitted in the last 6 months?",
        title: "I. Details of the insured person",
        titleNeedForCare: "1. Details of the need for care",
        titlePensionApplication: "2. Questions about pension applications",
      },
      sectionTwo: {
        communicationNo: "No",
        communicationNoGerman: "In which language is communication possible?",
        communicationWithPatient:
          "2. Is it possible to communicate with the patient in German?",
        communicationYes: "Yes",
        costCoverage:
          "3. Have you already enquired about a rehabilitation centre, subject to the coverage of costs?",
        costCoverageNo: "No",
        costCoverageYes: "Yes",
        detailsSocialWorker:
          "1. Contact details of the contact person at the hospital (e.g. social worker / case management):",
        hospitalAddressCity: "Zip code, city",
        hospitalAddressStreet: "Street, house number",
        hospitalName: "Hospital",
        patientId: "Patient ID",
        rehabDetails: "Name, address and telephone number",
        rehabIntakeDate: "Earliest possible admission date",
        socialWorkerFax: "Fax number",
        socialWorkerName: "Last name, first name",
        socialWorkerPhone: "Telephone number",
        title:
          "II Information from the hospital (e.g. to be completed by the social worker / case management)",
      },
      title: "General application - Application for follow-up rehabilitation",
    },
    goToForm: "Fill out form",
    medicalForm: {
      description:
        'To help you fill out the form, you can click on "Import application data" to import data that is already available into the form. Please check this data and add any missing information.',
      sectionFive: {
        addictiveDisorders: "Addictive disorders",
        amputation:
          "Major amputation of the lower extremity (proximal to the foot)",
        backPain: "Specific back pain with a high risk of becoming chronic",
        bloodDiseases: "Diseases of the blood and haematopoietic organs",
        bloodVessels: "Diseases of the blood vessels",
        bypass:
          "Coronary artery bypass surgery, also in combination with heart valve replacement",
        endocrineDiseases: "Endocrine diseases",
        endoprostheticTreatment:
          "Endoprosthetic treatment of the knee or hip joint",
        gastroenterologicalDiseases: "Gastroenterological diseases",
        geriatricRehab:
          "geriatric follow-up rehabilitation for patients with typical geriatric multimorbidity and advanced age (70 years or older)",
        geriatrics: "Geriatrics",
        gynaecologicalDiseases: "Gynaecological diseases",
        heart: "Diseases of the heart and circulation",
        heartFailure: "Heart failure from NYHA II",
        inflammatoryDiseases: "Inflammatory rheumatic diseases",
        mentalIllnesses: "Mental illnesses",
        mobile: "mobile",
        mobileOutpatient:
          "ambulant mobil, weil (z. B. schwere Orientierungsstörung, Wanderungstendenz)",
        musculoskeletalSystem:
          "Conservatively and surgically treated diseases of the musculoskeletal system",
        myocardialInfarction: "Myocardial infarction",
        nephrologicalDiseases: "Nephrological diseases",
        neurologicalDisease: "Neurological diseases",
        neurologicalDiseases:
          "Neurological diseases including operations on the brain, spinal cord and peripheral nerves, if a phase assignment to phase D (according to the SFA phase model) can be clearly derived from the findings in the medical report and the application documents",
        oncologicalDisease: "Oncological diseases",
        oncologicalDiseases: "Oncological diseases",
        others: "Others",
        otherTextfieldLabel: "Please specify in the field below",
        psychosomaticDisorders: "Psychosomatic psychovegetative disorders",
        rehabDirectiveTypeLabel: "Please specify the case further",
        rehabFocus: "1. Focus of the rehabilitation programme",
        rehabRl:
          "3. Based on the assessment carried out and the information provided above, the hospital doctor considers this to be a case in accordance with Section 16 of the Rehabilitation Directive",
        respiratoryDisease:
          "Diseases of the respiratory organs including operations",
        respiratoryDiseases: "Diseases of the respiratory organs",
        skinDiseases: "Skin diseases",
        spinalCanal: "Spinal canal stenosis/Enger spinal canal/recess stenosis",
        static: "Inpatient, because (e.g. immobility, deficits in self-care)",
        title: "V. Summarised evaluation and assessment",
        transplants: "Organ transplants and support systems",
        typeOfRehab: "2. Which type of rehabilitation is recommended?",
        urologicalDiseases: "Urological diseases",
      },
      sectionFour: {
        rehabGoals:
          "What are the realistic rehabilitation goals that are relevant to everyday life, taking into account the progress to date and the individual's available or supportable resources?",
        title: "IV. Rehabilitation goals/forecast",
      },
      sectionOne: {
        accessibilityGender: "Please select the gender of the insured person",
        admissionRehab:
          "4. Admission to follow-up rehabilitation should take place",
        dischargeDate: "Discharge date",
        earliestDatePossible: "Earliest on",
        earlyRehabMeasures:
          "3. Are neurological, geriatric or multidisciplinary early rehabilitation measures currently being carried out?",
        earlyRehabMeasuresNo: "No",
        earlyRehabMeasuresYes: "Yes",
        firstName: "First name",
        genderDiverse: "Diverse",
        genderFemale: "Female",
        genderMale: "Male",
        hospitalStayInformation:
          "2. Hospital treatment, including early mobilisation and wound treatment if necessary",
        informationInsuredPerson: "1. Personal details of the insured person",
        intakeDate: "Intake date",
        lastName: "Last name",
        medicalReasons: "Medical reasons",
        optionDirect: "Directly",
        outsideTwoWeeks: "outside the statutory period of 14 days",
        title: "I. General information",
        withinTwoWeeks: "within the statutory period of 14 days",
      },
      sectionSix: {
        accompanyingPerson:
          "Is an accompanying person required for the journey?",
        ambulance:
          "ambulance, as medical-technical care and/or equipment is necessary due to",
        ambulanceDescriptionLabel: "Please state the reason",
        car: "car",
        catheter: "Catheter",
        chemotherapy: "Chemotherapy",
        contactInformationDoctor:
          "3. Contact details of the hospital doctor providing treatment",
        decubitus: "Pressure ulcer, stage",
        dialysis: "Dialysis, which procedure",
        immunosuppressants: "Immunosuppressants",
        impairment: "Severe hearing, visual and speech impairment",
        isolation: "Isolation obligation",
        lyingDown: "Lying transport",
        nameDoctor: "Name, first name",
        obesity: "Obesity",
        optionPublicTransport: "Public transport",
        other: "other",
        overlengthBed: "Overlength bed",
        peg: "PEG",
        phoneDoctor: "Telephone number for queries",
        prosthesis: "OS/US prosthesis",
        relatives:
          "Admission of relatives in need of care; degree of care (if known)",
        specialRequirements:
          "1. Are there any special requirements for the rehabilitation centre?",
        specialRequirementsSelectOptionsLabel:
          "Please select the special rehab requirements below",
        stretcher: "Stretcher transport",
        taxi: "Taxi/rental car",
        taxiOptionLabel: "Please specify the option further",
        title: "VI. Further relevant information for the rehabilitation",
        tracheostoma: "Tracheostoma",
        transportation:
          "2. Which means of transport is likely to be medically necessary for the journey?",
        ventilation: "Ventilation",
        weaning: "Weaning",
        weightBed: "Heavy-duty bed up to",
        wheelChair: "Wheel chair",
      },
      sectionThree: {
        behaviouralDisorder:
          "Behavioural disorder requiring supervision (with danger to self and/or others)",
        communicationDisorder: "Severe communication disorder",
        earlyRehabIndex:
          "3. Criteria from the Early Rehabilitation Index (FR Index)",
        fully: "Fully",
        intensiveCareMonitoring:
          "Condition requiring intensive care monitoring",
        intermittentVentilation: "Intermittent ventilation",
        musculoskeletalSystem:
          "2. Further information on resilience during operations on the musculoskeletal system",
        orientationDisorder:
          "Orientation disorder requiring supervision (confusion)",
        partially: "partially",
        prosthesis: "An interim prosthesis has been fitted",
        resilience:
          "1. Do you have sufficient physical and mental/cognitive resilience to take part in the therapy?",
        specifics: "Specifics",
        stable: "stable during exercise",
        swallowingDisorder: "Swallowing disorder requiring supervision",
        title: "III. Rehabilitation capability",
        tracheostoma: "Tracheostoma requiring suction",
      },
      sectionTwo: {
        cause: "Cause",
        complications: "3. Complications in the current course of treatment",
        complicationsDescriptionLabel: "Add more information here",
        diagnosisDescription: "Functional diagnoses",
        footnoteOne:
          "The diagnosis codes given here include the diagnoses at the time of application and may differ from the discharge diagnoses",
        functionDiagnoses:
          "1. Functional diagnoses relevant to the application in order of importance",
        germs: "Multi-resistant germs?",
        germsUnknown: "Unknown",
        icdCode: "ICD-10-GM1",
        longTermImpairments:
          "4. Are there any long-term (>6 months) impairments relevant to everyday life?",
        optionCardiovascular: "cardiovascular (e.g. heart attack, embolism)",
        optionFive: " 4 = Consequences of war, civilian or military service",
        optionFour:
          "3 = Damage caused by the influence of third parties, e.g. consequences of an accident",
        optionOne: "0 = 1-5 does not apply",
        optionOtherComplication: "other (e.g. pneumonia, decubitus)",
        optionSix: "5 = Notifiable disease (e.g. IfSG)",
        optionThree: "2 = Occupational disease",
        optionTwo: "1 = Accident at work incl. commuting accident",
        otherTreatments: "Other treatments",
        performedTreatments: "2. Treatments performed",
        relatedToDiagnosis:
          "in direct connection with the diagnosis leading to AR (e.g. postop, dislocation of the endoprosthesis)",
        singer: "Please complete the Singer patient profile:",
        surgeries: "Surgery",
        surgeryDate: "Surgery on",
        surgeryDescription: "Which surgeries?",
        surgeryOps: "OPS",
        title: "II. Need for rehabilitation",
        treatmentsDescription: "Which treatments?",
        typeOfGerms: "Which germs?",
        wound: "Wound is closed and without irritation?",
      },
      title: "Standardised application - medical report",
    },
    menu: {
      buttonDelete: "Delete",
      buttonEdit: "Edit",
      buttonPrint: "Print",
      drvGeneralForm: "DRV - Antrag G0250",
      drvMedicalForm: "DRV - Ärztlicher Befundbericht G0260/G0600",
      fillOutForm: "Fill out form",
      maximumFormHint:
        "You can only create one PDF. If you need to switch forms, you have to delete the one you have already saved.",
      rehabGeneralForm:
        "Einheitlicher Antrag - Antrag auf Anschlussrehabilitation",
      rehabMedicalForm: "Einheitlicher Antrag - Ärztlicher Befundbericht",
      saveDocuments: "Saved documents",
      subtitleEligibility: "Eligbility",
      subtitleGeneralForm: "GENERAL FORM",
      subtitleMedicalForm: "MEDICAL FORM",
      subtitleTransitionalCare: "Transitional care",
      title: "Select patient form",
      transitionalCare: "Transitional care",
    },
    savedDocumentsDescription:
      "These documents were created automatically using data from the Recare platform and may have been supplemented or adapted by employees of the clinic. Recare therefore accepts no liability for the completeness and accuracy of the data.",
    savedDocumentsTitle: "Saved Documents",
    tabTitle: "Transitional care",
    tabWithTransitionalCare: "with TRANSITIONAL CARE",
    transitionalCareForm: {
      buttonPdf: "Create PDF",
      contactInformation: {
        confirmedWithLegalRep:
          "The legal representative / carer has consented to the processing / disclosure of their data.",
        footnoteInsuranceNumber:
          "For privately insured persons, the insurance number (if ascertainable)",
        hospitalCity: "City",
        hospitalIkNumber: "Institution identifier (IK)",
        hospitalName: "Name",
        hospitalStreet: "Street",
        hospitalStreetNumber: "Street number",
        hospitalZipCode: "Zip code",
        legalRepCity: "City",
        legalRepDataProcessing:
          "The carer has consented to the processing / disclosure of his/her data.",
        legalRepFirstName: "First name",
        legalRepName: "Surname",
        legalRepStreet: "Street",
        legalRepStreetNumber: "Street number",
        legalRepZipCode: "Zip code",
        patientBirthDate: "Birth date",
        patientCity: "City",
        patientFirstName: "First name",
        patientInsuranceNumber: "Insurance number",
        patientName: "Surname",
        patientStreet: "Street",
        patientStreetNumber: "Street number",
        patientZipCode: "Zip code",
        socialWorkerEmail: "E-mail address",
        socialWorkerFax: "Fax number",
        socialWorkerFirstName: "First name",
        socialWorkerName: "Surname",
        socialWorkerPhone: "Phone number",
        titleHospital: "Contact information hospital",
        titleLegalRep: "Contact information legal representative",
        titlePatient: "Contact details patient",
        titleSocialWorker: "Contact details social worker",
      },
      createNewBanner:
        'To help you fill out the form, you can click on "Import data" to import data that is already available into the form. Please check that this data is correct and complete the form fields that have not yet been filled in as far as possible.',
      deleteForm: "Clear content",
      descriptionEmptyForm:
        "Please start the search for providers first in order to start the transitional care process on the Recare platform.",
      descriptionExistingForms:
        "The document was created automatically using data from the Recare platform and may have been supplemented or adapted by employees of the clinic. Recare therefore accepts no liability for the completeness and accuracy of the data.",
      downloadSection: {
        bodyText:
          "The document was created automatically using data from the Recare platform and may have been supplemented or adapted by clinic employees. Recare therefore assumes no liability for the completeness and accuracy of the data.",
        hisButton: "Send to HIS",
        hisError:
          "The form could not be sent to the HIS. Please try again later.",
        hisSuccess: "Successfully sent to HIS",
        printButton: "Print",
        savedAt: (args: { savedAt: string }) =>
          template("Saved at {{savedAt}}", args),
        title: "Documentation of transitional care",
      },
      encryptionInformation:
        "This document and the resulting PDF are end-to-end encrypted.",
      errorDateInTheFuture: "Please select a date in the future.",
      errorDateInThePast: "Please select a date that is in the past.",
      errorDischargeBeforeIntake:
        "The discharge date cannot be earlier than the intake date.",
      explanationTransitionalCare:
        "Note: In order to bill the statutory health insurance for transitional care in hospital in accordance with Section 39e SGB V, it is necessary to document the enquiry with at least 20 after-care providers. In this way, you can prove that the necessary post-hospital care in particular is not available or not available on the planned discharge date. The Recare platform automatically documents all the necessary information (name, date and result of the enquiry, etc.) for you.",
      hospitalStay: {
        departmentCode: "Department code",
        endDateTransitionalCare: "Discharge date",
        footnoteOne:
          "Location identifier in accordance with Section 293 (6) SGB V of the location where the transitional care was provided",
        footnoteTwo:
          "Specialist department codes in accordance with Section 5 (3) of the agreement",
        id: "Internal hospital identification number of the insured person",
        intakeDate: "Intake date",
        locationId: "Location identifier",
        releaseDate: "Discharge date",
        startDateTransitionalCare: "Intake date",
        titlePriorStay: "Prior hospital treatment",
        titleTransitionalCare: "Transitional care in the hospital",
      },
      mandatoryFieldInformation: "* Marks a mandatory field",
      notPurchased: {
        advantageFive:
          "Embedding: Rely on the intuitive integration into your usual editing process on the Recare platform.",
        advantageFour:
          "Data security: The end-to-end encryption of all form fields and the generated PDF makes the patient data of your aftercare cases secure.",
        advantageOne:
          "Automation: Your documentation is carried out in accordance with health insurance requirements, including proof of all up to 20 contact requests.",
        advantageThree:
          "Flexibility: Add forms manually if parallel processes via fax/telephone cannot be avoided in parallel with digital documentation.",
        advantageTwo:
          "HIS interface: The data import simplifies your documentation, increases data quality and enables updates at the touch of a button.",
        cta: "Make an appointment now",
        paragraphOne:
          "Do you consider transitional care (ÜGP) in accordance with Section 39e SGB V to be a relevant topic for your hospitals, but are put off by the manual effort and complexity of the documentation requirements? Then we have the right solution for you with our new transitional care add-on.",
        paragraphTwo:
          "Would you like to find out more about transitional care or add the add-on directly to your Recare Basic or Recare ONE package? Then arrange an appointment directly.",
        subtitleAdvantages: "Your advantages at a glance",
        title: "Benefit from our new add-on",
      },
      numberOfProviders:
        "If there are fewer than 20 providers, the documented enquiry from all existing providers will be used as a substitute.",
      pdfCreated: "Your created PDF can be found at the top of the page",
      savedDate: (args: { date: string }) =>
        template("Saved on {{date}}", args),
      sectionA: {
        dateLabel: "(usually by involving the social worker) on",
        title: "a) Start of the organisation of the after-care provider",
      },
      sectionB: {
        dateFieldLabel: "Coordination date",
        dropdownNumberOfProviders: "Number of potential after care providers",
        textFieldExplanationLabel:
          "Description of why there was no confirmation",
        textFieldLabel:
          "Description of the suitable after care (requirements for the after care provider due to a complex or special care need as well as the desired location and radius of the care)",
        title: "b) Coordination of the appropriate after care provider",
        toggleConfirmedWithPatient:
          "The appropriate  after care has been agreed with the patient and, if applicable, the patient's relatives or legal representative or carer.",
        toggleNumberOfProviders:
          "More than 20 after care providers were considered suitable",
      },
      sectionC: {
        afterCareRequired:
          "After care is required for care services in accordance with SGB XI (including short-term care in accordance with SGB XI)",
        applicationDate: "Date of the application",
        applicationSubmitted:
          "The application for this after care was submitted/issued",
        careLevel: "Care level 2 or higher",
        careLevelApplied:
          "An application for a care level of 2 or higher has been submitted",
        careLevelAppliedDate: "Date of application",
        medicalRehab: "medical Rehab (§ 40 SGB V)",
        mobileCare: "mobile care (§ 37 SGB V)",
        shorttermCare: "short-term care (§ 39c SGB V)",
        textFieldDescription:
          "Description of the need for after care and reasons why no application/order was submitted/issued",
        textFieldLabel:
          "Description of the need for aftercare and reasons why no application was submitted or whether an objection was lodged",
        title: "c) Determining the need for aftercare",
      },
      sectionD: {
        dateFieldLabel: "and transmission of the required information on",
        title: "d) Early involvement of the health insurance company",
      },
      sectionE: {
        addMoreProviders: "Add provider manually",
        deleteButtonAria: "Delete button",
        deletionScreenReaderAnnouncement: (args: { providerName: string }) =>
          template("Request to provider {{providerName}} was deleted", args),
        editButtonAriaLabel: "Edit button",
        editCity: "City",
        editDate: "Request date",
        editName: "Name",
        editStatus: "Status",
        editStreet: "Street",
        editStreetNumber: "Street number",
        editTime: "Sent time",
        editZipCode: "Zip code",
        importButton: "Import providers from Recare",
        importedProvider: "Imported",
        internetbasedPortals: "Internet-based portals were used",
        mandatoryFieldsInformation: "* Marks a required field",
        manuallyAddedProvider: "Manually added",
        refreshButton: "Refresh provider list",
        saveButton: "Apply changes",
        title: "e) Contact requests to care providers",
      },
      sectionF: {
        bulletFour:
          "Upon discharge from transitional care, there was no further need for after care",
        bulletOne:
          "The necessary after care could already be ensured during hospital treatment (for a transition to follow-up care within the time frame for transitional care)",
        bulletThree:
          "The transition to after could only be ensured for a full stop outside the entitlement to transitional care (maximum 10 days per hospital treatment)",
        bulletTwo:
          "The necessary after care could be ensured during transitional care (for a transition to follow-up care within the time frame for transitional care)",
        dateFieldLabel: "Date of the start of after care",
        title:
          "f) Indication of whether the necessary after care could be ensured after completion of transitional care or was no longer required",
      },
      subtitleEditForm: "Edit form",
      subtitleEmptyForm: "Please start a provider search first",
      subtitleExistingForms: "Generated PDF",
      subtitleNew: "Create new",
      tabTitle: (args: { tabName: string }) =>
        template("{{tabName}} - with TRANSITIONAL CARE", args),
      title: "Transitional Care",
      titleDocumentationTransitionalCare:
        "PDF Documentation of transitional care",
      toTheTop: "To the top",
      validationErrorMessage: "Please complete this field.",
    },
    warningNotSaved: {
      leavePage: "Leave",
      savePage: "Save and leave",
      subtitle: "There are unsaved changes that will be lost.",
      title: "Are you sure you want to leave the page without saving?",
    },
    warningOverwritePdf: {
      buttonCancel: "Cancel",
      buttonConfirm: "Create PDF now",
      subtitle: "Would you still like to create the PDF?",
      title: "The existing PDF file is overwritten.",
    },
  },
  people: {
    centralPhoneNumber: "Central phone number",
    companyMailAddress: "Company mail address",
    companyMailAddressPlaceholder: "e.g., info@, contact@",
    emailAddress: "Email address",
    faxNumber: "Fax number",
    firstName: "First name",
    ikNumber: "IK Number",
    invalidEmailAddress: "Please enter a valid email address",
    invalidPhoneNumber: "Enter a valid number and country code.",
    jobCategories: {
      admissionBedMgmt: "Admission/Bed Management",
      chiefDoctor: "Chief doctor",
      disposition: "Disposition/Control Center",
      doctor: "Doctor",
      managementAdministration: "Management/Administration",
      medicalStaff: "Medical staff",
      other: "Other",
    },
    jobCategory: "Job category",
    lastName: "Last name",
    mailShort: "Email:",
    mobile: "Mobile:",
    mobilePhoneNumber: "Mobile phone number",
    phoneNumber: "Phone number",
    position: "Position",
    tel: "Tel.",
    website: "Website",
  },
  planning: {
    loadingError: "Impossible to refresh planning data",
    planningTabTerminated:
      "On the 01.07.2020 this Planning page will be removed. We recommend you using filters on the dashboard to keep your workflow organized.",
  },
  prompts: {
    continueWithoutSaving: {
      explanationText: "All unsaved changes will be lost.",
      title: "Are you sure you want to continue?",
    },
    saveChanges: {
      explanationText: "Your changes will be lost if you don't save them.",
      title: "Do you want to save the changes you made?",
    },
  },
  providersearch: {
    accountPage: {
      acceptedPrivacyPolicy: (args: { date: string }) =>
        template("You have agreed to the privacy policy on {{date}}.", args),
      acceptedPrivacyPolicyAdditional: "Here you can find further information:",
      acceptedPrivacyPolicyLink:
        "You can send all other data protection enquiries to {select: pflege-finder-bayern@recaresolutions.com}.",
      acceptedTermsOfUse: (args: { date: string }) =>
        template("You have agreed to the terms of use on {{date}}.", args),
      accountCreationDate: (args: { date: string }) =>
        template("Your account was created on {{date}}.", args),
      accountDeactivation: "Account information",
      accountDeactivationLink:
        "To delete your account please send an e-mail to {select:pflege-finder-bayern@recaresolutions.com}.",
      buttonReadDocument: "Read",
      dataExportRequest: "Request data export",
      dataExportRequestLink:
        "To request an export of your data please send an e-mail to {select: pflege-finder-bayern@recaresolutions.com}",
      emailAddress: "E-mail address",
      linkOpenFile: "Open file",
      privacyPolicy: "Privacy policy",
      subtitleAccount: "Account",
      subtitleLegal: "Legal",
      subtitlePersonalDetails: "Personal details",
      termsOfUse: "Terms of use",
      title: "My account",
    },
    assessment: {
      ageGroupWarning:
        "The specified age group does not match the provider's criteria. You can still send the request.",
      errorAgeGroup: "Please select age group",
      errorCareDuration: "Please select expected care duration",
      errorCareLevel: "Please select care level",
      errorGender: "Please select gender",
      informationText:
        "This information is used by the provider to process your request. Your data will only be shared with service providers you contact. You can find more information about how we process your data in our {select:data protection information}.",
      informationTitle: "Why do we need this information?",
      mandatoryFieldInformation: "* marks a mandatory field.",
      missing: "Please select an option",
      nextButton: "Next",
      overweightService: (args: { weight: string }) =>
        template("Overweight (> {{weight}} kg)\n", args),
      questionAgeGroup: "Age group (years)",
      questionCareDuration: "Expected care duration",
      questionCareLevel: "Care level",
      questionCareNeeds: "Special care needs",
      questionGender: "Gender",
      specialCareNeedsWarning:
        "The care needs indicated do not meet the provider's criteria. You can still send the request.",
      title: "Create a new profile for a search",
    },
    auctionCreationModal: {
      cancelButton: "Cancel",
      nextButton: "Start request",
      startDate: "Start date",
      title:
        "You are starting a non-binding request with the following criteria",
      typeOfCare: "Type of care",
      warningDate:
        "The provider has no availability on this date. You can still send a request.",
      warningDateInPast: "Please choose a valid date in the future.",
      warningZipCode:
        "This postcode is outside the provider's catchment area. You can still contact the provider.",
      warningZipCodeMissing: "Please select a zip code or city",
      zipCode: "Care in zip code",
    },
    auctionResponsePage: {
      buttonLabel: "To the search dashboard",
      chatLabels: {
        buttonCancel: "Cancel request",
        buttonValidate: "Select provider",
      },
      contactDetailsProvider: {
        address: "address",
        email: "Email",
        telephone: "Phone",
        title: "Contact information",
        website: "Website",
      },
      patientInformation: {
        ageGroup: "Age group",
        careDuration: "Expected care duration",
        careLevel: "Care level",
        careNeeds: "Special care needs",
        gender: "Gender",
        profileId: "Profile ID",
        title: "Information about the patient",
      },
      print: "Print",
      requestStatus: {
        buttonUndoValidation: "Undo validation",
        informationUndoValidation:
          'You can revert the validation by clicking "undo validation".',
        requestWasAccepted: (args: { date: string }) =>
          template("Request was accepted on {{date}}", args),
        requestWasDeclined: (args: { date: string }) =>
          template("Request was declined on {{date}}", args),
        requestWasRejected: (args: { date: string }) =>
          template("Request was cancelled on {{date}}", args),
        requestWasSent: (args: { date: string }) =>
          template("Request was sent on {{date}}", args),
        requestWasValidated: (args: { date: string }) =>
          template("Request was validated on {{date}}", args),
        testAttributed:
          "You have confirmed the provider and the provider has been informed. This does not constitute a binding booking. Please contact the provider to discuss the admission and the details of the care. You can use the chat for this purpose.",
        textAccepted: "The provider has accepted your contact request!",
        textArchived:
          "The patient profile has been archived and all open contact requests have been automatically canceled. This action cannot be undone.",
        textAuctionStopped:
          "You can contact the provider again by starting a new request.",
        textCancelled: "You have cancelled the contact request.",
        textPending:
          'Please wait until the provider accepts or rejects your request. This may take a few days. Contact requests can be cancelled at any time by clicking on the red button "Cancel request" in the chat. Clicking on the button "Select provider" is non-binding. By clicking the button you do not enter a contract or another legal agreement.',
        textRejected: "The provider has rejected your contact request.",
        title: "Request status",
      },
      searchDetails: {
        startDate: "Start date",
        title: "Search details",
        typeOfCare: "Requested care type",
        zipCode: "Zip code",
      },
      showProviderLink: "Show provider",
      title: "Contact request to",
      undoValidationDialog: {
        buttonCancel: "Cancel",
        buttonConfirm: "Confirm",
        cancelValidationReason: "Manually cancelled",
        subtitle: 'Click on "Confirm" to un-do the validation.',
        title: "Are you sure that you want to cancel the validation?",
      },
    },
    emailAddress: "pflege-finder-bayern@recaresolutions.com",
    errorTooManyRequests:
      "Too many requests at once. Please wait a moment before trying again. Thank you for your patience",
    expiredLink:
      "The link has expired. Please request a new link by logging in or registering.",
    filterPanel: {
      filterChip: {
        distanceRadius: "km radius",
        freeCapacity: "Free capacity",
        withConsultants: "Consultants",
        withProviders: "Providers",
      },
      filterValues: {
        assistedLiving: "Assisted Living",
        dailyLivingSupportServices: "Daily living support services",
        dayCare: "Day care",
        helpAtHome: "Help at home",
        hospiceAssociation: "Hospice association",
        hospiceMobile: "Mobile hospice",
        hospicePartiallyMobile: "Partially mobile hospice",
        hospiceStatic: "Static hospice",
        isConsultant: "Consultants",
        isProvider: "Careproviders",
        mobileCare: "Mobile care",
        nightCareMobile: "Mobile night care",
        nightCareStatic: "Static night care",
        sharedFlat: "Shared flat",
        shortTermStaticCare: "Short term static care",
        staticCare: "Static care",
      },
      hospicePalliativeCareTitle: "Hospice and palliative care",
      partialStaticCareTitle: "Partial static care",
      receiverTypeTitle: "Search for",
      referrer: "Direct request",
      servicesTitle: "Special care needs",
      title: "Request type",
      titleFilters: "Detailed search",
      typeOfCareTitle: "Type of care",
    },
    footer: {
      accessibilityCertificate: "To the BITV test report",
      accessibilityDeclaration: "Accessibility declaration",
      accessibilityDeclarationTooltip:
        "External link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_barrierefreiheitserklarung.pdf (Opens in new window)",
      altTextAccessibilityCertificate:
        "BIK - BITV-compliant (tested pages), to the test report",
      altTextLogoLandesamt:
        "Logo - Bayerisches Landesamt für Pflege - to webpage",
      altTextLogoStaatsministerium:
        "Logo - Bayerisches Staatsministerium für Gesundheit, Pflege und Prävention - to webpage",
      altTextRecareLogo: "Logo  - recare - to webpage",
      copyright: (args: { year: string }) =>
        template(
          "© Recare Deutschland GmbH {{year}} - Alle Rechte vorbehalten",
          args,
        ),
      developedBy: "Development, implementation and maintenance",
      easyLanguage: "Easy Language",
      emailAddress: "E-mail: pflege-finder-bayern@recaresolutions.com",
      impressum: "Impressum",
      impressumTooltip:
        "External link: https://recaresolutions.com/impressum/ (Opens in new window)",
      inCollaborationWith: "Unter Mitwirkung von",
      logoLandesamtTooltip:
        "External link: https://www.lfp.bayern.de/ (Opens in new window)",
      logoRecareTooltip:
        "External link: https://recaresolutions.com/ (Opens in new window)",
      logoStaatsministeriumTooltip:
        "External link: https://www.stmgp.bayern.de/ (Opens in new window)",
      partnersHospitals: "Bayerische Krankenhausgesellschaft e. V.",
      partnersHospitalsTooltip:
        "External link: https://www.bkg-online.de/ (Opens in new window)",
      partnersInsurance:
        "Arbeitsgemeinschaft der Pflegekassenverbände in Bayern",
      phone: "+493031196372",
      phoneNumberLabel: "Phone number: +49 30 31196372",
      privacyPolicy: "Privacy policy",
      privacyPolicyTooltip:
        "External link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_datenschutzinformation.pdf (Opens in new window)",
      reportBarrier: "Report barrier",
      reportBarrierLink:
        "mailto:pflege-finder-bayern@recaresolutions.com?Subject=Barriere%20melden",
      reportBarrierTooltip:
        "External link: pflege-finder-bayern@recaresolutions.com (Opens in new window)",
      reportContent: "Report content",
      reportContentLink:
        "mailto:pflege-finder-bayern@recaresolutions.com?Subject=Report%20content",
      signLanguage: "Sign language",
      tableOfContent: "Table of content",
      termsOfUse: "Terms of use",
      termsOfUseTooltip:
        "External link: https://assets.recaresolutions.com/recare/pflegefinder_bayern_nutzungsbedingungen.pdf (Opens in new window)",
    },
    genericAriaLabelEmailLink: "Mail address - opens in a new window",
    genericAriaLabelLink: "Opens new window",
    genericAriaLabelPDFLink: "PDF - opens in a new window",
    genericAriaLabelPhoneLink: "Phone number",
    genericErrorMessage: "Something went wrong",
    genericLoadingNotification: "Loading",
    glossary: {
      buttonPrint: "Print",
      careLevels: {
        bulletPoints: {
          eight: "Household management.",
          five: "Coping with and independently dealing with illness- or therapy-related demands and stresses",
          four: "Self-sufficiency",
          one: "Mobility",
          seven: "Activities outside the home",
          six: "Organising everyday life and social contacts",
          three: "Behavioural and psychological problems",
          two: "Cognitive and communicative skills",
        },
        descriptions: {
          applied: "{select:Applied:} A care level has been applied for.",
          levelFive:
            "{select:Care level 5:} Severe impairment of independence with special care requirements",
          levelFour: "{select:Care level 4:} Severe impairment of independence",
          levelOne: "{select:Care level 1:} Minor impairment of independence",
          levelThree:
            "{select:Care level 3:} Severe impairment of independence",
          levelTwo:
            "{select:Care level 2:} Significant impairment of independence",
          none: "{select:None:} There is no care level.",
          unknown: "{select:Unknown:} The care level is not known. ",
        },
        introduction:
          "The decisive factor in determining the need for care is the restriction of independence or abilities in the care-relevant areas of daily life. When calculating the level of care, the following areas are taken into account in a standardised nationwide procedure:",
        paragraphOne:
          "The following areas are also assessed as part of the appraisal",
        paragraphThree:
          "According to a points system, five care levels result from the restrictions on independence determined in the assessment:",
        paragraphTwo:
          "However, the latter are not included in the calculation of the care level.",
      },
      descriptionsConsultants: {
        dementiaCounselling: "Dementia counselling",
        descriptionDementiaCounselling:
          "Counselling is provided to those affected by a rare dementia disease and their relatives as well as institutions such as care facilities.",
        descriptionSpecialisedOffice:
          "The specialist centres for family carers are advice and contact points for family carers of older people in need of care and support them with psychosocial counselling, (long-term) support and relief services (work with family members).",
        descriptionSupportPoint:
          "The care support centres offer free advice on all topics relating to care and are open to all citizens.",
        specialisedOffice: "Specialised offices for relatives",
        supportPoint: "Care support point",
      },
      errorNotFound: "Error: Glossary item not found",
      glossaryAriaLabel: "Glossary",
      linkToGlossary: "You can find more information in our {select:glossary}",
      navigationCareLevels: "Care levels",
      navigationCareNeeds: "Care needs",
      navigationConsultants: "Consultants",
      navigationSolutions: "Type of care",
      navigationTitle: "Topics",
      pageDescription: "Glossary of commonly used medical terms",
      pageTitle: "Glossary",
      solutions: {
        assistedLiving: "Assisted living",
        assistedLivingDescription:
          "Service living is aimed at people who are not in need of care, or only to a limited extent, and who can live independently in their own home. The offers range from flats attached to a care facility to flats suitable for senior citizens in normal residential buildings and hotel-like offers. As a rule, additional support services can be booked, such as a home emergency call, cleaning service, laundry service or the arrangement of care services. Assisted living therefore does not offer all-round care and is less suitable for people with severe care needs or advanced dementia.",
        dailySupportServices: "Daily support services",
        dailySupportServicesDescription:
          "Insured persons in need of long-term care can take advantage of services to support them in their everyday lives, including care services, services to relieve the burden of everyday life and services to relieve carers.",
        helpAtHome: "Help at home",
        helpAtHomeDescription:
          "Includes support services in everyday life, such as shopping, cooking, cleaning and heating the home, washing up and changing and washing laundry and clothes. Such services are provided, for example, by nursing or care services or everyday support programmes. However, domestic support is also an elementary component of full inpatient care.",
        hospice: "Hospice and palliative care",
        hospiceBulletFour:
          "Outpatient hospice is largely supported by the volunteer hospice carers who work there. They accompany people on their final journey and support their relatives and loved ones.",
        hospiceBulletOne:
          "Full inpatient hospice refers to small facilities with a family atmosphere. This is where seriously ill and dying people, whose expected life expectancy is limited to a few days or weeks and whose outpatient care at home can no longer be guaranteed, are cared for until death.",
        hospiceBulletThree:
          "Partial inpatient hospices offer seriously ill and dying people care and nursing during the day by a multi-professional team and thus enable those affected to participate in social life and network with other guests.",
        hospiceBulletTwo:
          "Hospice organisations organise, among other things, the voluntary support of seriously ill and dying people. Hospice associations also offer training and further education for hospice counsellors.",
        hospiceDescription:
          "Hospice and palliative care is aimed at seriously ill and dying people in their last phase of life.",
        mobileCare: "Mobile Care",
        mobileCareDescription:
          "Means care in the home environment, e.g. by outpatient care services and/or by home carers (e.g. family members, friends, neighbours). The aim is to live with a high degree of independence and personal responsibility.",
        partiallyStaticCare: "Partially static care",
        partiallyStaticCareBulletOne:
          "Day care provides temporary care during the day in a care facility, usually during the week. They return to their home environment at night and at weekends.",
        partiallyStaticCareBulletThree:
          "Outpatient night care means the night-time care of the person in need of care in their home environment by an outpatient care service.",
        partiallyStaticCareBulletTwo:
          "Inpatient night care refers to a care facility in which those in need of care are looked after and cared for overnight and return home in the morning.",
        partiallyStaticCareDescription:
          "Partial inpatient care primarily serves to relieve the burden on family carers.",
        sharedFlat: "Shared flat",
        sharedFlatDescription:
          "Enables people in need of care to live in a shared household and receive care and support services as required, e.g. from a care service.",
        shortTermCare: "Short-term care",
        shortTermCareDescription:
          "This is temporary care and support for people in need of care in a fully inpatient care facility who would otherwise be cared for at home or are in need of care for the first time.",
        staticCare: "Static care",
        staticCareDescription:
          "Is a place to live in a facility for people in need of care who require comprehensive, guideline-compliant and humane care and support throughout the day and night. A stay in a fully inpatient care facility may be suitable if home and/or semi-residential care is no longer sufficient.",
      },
    },
    header: {
      altTextGutePflegeLogo:
        "Logo - Gute Pflege Daheim in Bayern - to start page",
      glossary: "Glossary",
      homePage: "Home page",
      logout: "Logout",
      mainNavigationAriaLabel: "Main navigation",
      myAccount: "My account",
      register: "Register",
      requestDashboard: "Searches",
      title: "Pflegefinder Bayern",
    },
    listingPage: {
      accessibility: {
        altTextLogo: "Logo - Gute Pflege Daheim in Bayern - to start page",
        consultantIconAltText: "Consultant icon",
        filterLabel: "Filter",
        headline: "Overview of providers and consultants",
        markerAltText: "Map marker",
        notAvailableIconAltText: "Not available icon",
        paginationCurrentPage: (args: { pagenumber: string }) =>
          template("You are currently on page {{pagenumber}} ", args),
        paginationFirstPageLabel: "Go to first page",
        paginationGeneralLabel: (args: { page: string }) =>
          template("Go to page {{page}}", args),
        paginationLastPageLabel: "Go to last page",
        paginationNavigationLabel: "Pagination navigation",
        paginationNextPageLabel: "Go to next page",
        paginationPreviousPageLabel: "Go to previous page",
        permanentFilterLabel: "Permanent filter",
        productTourHeading:
          "Welcome! This is a tip to get you started on the Pflegefinder Bayern",
        providerIconAltText: "Provider icon",
        providerSearchBarLabel: "Search for provider",
        suggestionsAriaLabel: "Show suggestions",
        zipcodeFieldLabel: "Enter zip code or city",
      },
      actionBar: {
        filter: {
          filterCta: "Detailed search",
        },
      },
      appliedFilters: {
        clearFilters: "Clear all filters",
        title: "Applied filters",
      },
      consultantCard: {
        addressDistance: (args: { zipcode: string }) =>
          template("from {{zipcode}}", args),
        agency: (args: { sponsorName: string }) =>
          template("Project sponsor: {{sponsorName}}", args),
        appointmentInformation: "Contactable for appointments",
      },
      consultantFacility: "Counselling services",
      consultantsNearby: "Consultants nearby",
      noResultsText:
        "Check your applied filters. If there are still no results, you can contact a counselling centre and make an appointment.",
      noResultsTitle: "No results could be found for these search criteria.",
      print: "Print",
      printColumnTitleFacilities: "Range of services",
      printTitle: "Pflegefinder Bayern",
      productTour: "Here you can refine the results by setting filters.",
      productTourButton: "Okay",
      providerCard: {
        addressDistance: (args: { distance: string; zipcode: string }) =>
          template("{{distance}}km from {{zipcode}}", args),
        capacity: {
          capacityUnknown: "Availability on request",
          capacityUpdatedAt: (args: { updatedAt: string }) =>
            template("Updated on {{updatedAt}}", args),
          freeCapacity: "Available",
          noCapacity: "Not available",
        },
        moreProviders: "and more services",
      },
      providerSearchBarNoResult: "No result",
      providerSearchBarPlaceholder: "Search for provider name",
      result: "result",
      results: "results",
      zipCodeModal: {
        body: "Based on the zip code, a list of providers and consultants will be generated and sorted by distance. Further search criteria can be set afterwards.",
        privacyPolicy: "Privacy policy",
        startButton: "Start search",
        termsOfUse: "Terms of use",
        title: "Please enter a postcode or location.",
      },
    },
    loginDropDown: {
      bulletFour: "Clarify questions via messenger",
      bulletOne: "Continue current searches",
      bulletThreee: "Create profiles and save time",
      bulletTwo: "Interact securely with care providers and exchange data",
      createAccountButton: "Create free account",
      loginButton: "Log in",
      providersInformation:
        "We look forward to receiving your e-mail to {select:pflege-finder-bayern@recaresolutions.com}",
      providersTitle: "Do you want to register as provider?",
      title: "Your free account allows you to",
    },
    loginModal: {
      accessibilityHelp:
        "Please enter your email address in order to continue.",
      createAccount: {
        acceptDPA:
          "I consent to the data processing as described in the {select:Data protection information}.",
        acceptDPAError:
          "Please accept the data protection information to create an account. ",
        acceptTerms: "I accept the {select:Terms of use}.",
        acceptTermsError:
          "Please accept the Terms of use to create an account.",
        accountCreationGuide: "Step-by-step guide to creating an account",
        alreadyExistsError:
          "An account with this email address exists already. Please {select:click here} to log in.",
        alreadyExistsMessage:
          "An account with this e-mail address already exists. ",
        confirmEmailPlaceholder: "Confirm email address",
        emailErrorNotEmail: "Field must contain an e-mail address",
        emailErrorNotMatch: "Emails do not match",
        emailOnePlaceholder: "Enter your email address",
        infoText:
          "You can also consent for third parties, e.g. a relative. However, this is only possible if you are authorised to do so.",
        logInLinkInfo: "{select:Log in with an existing e-mail address.} ",
        registerAccessibilityHelp:
          "Please enter your email address twice and confirm the privacy policy and terms of use in order to continue.",
        submitButton: "Continue",
        successCaption: "The link is valid for 24 hours.",
        successText:
          "We sent an email to {select:email} with a sign-up link. Please click on the link in this email to log in to the platform. You may also need to check the SPAM folder.",
        successTitle: "Successful account creation",
        title: "Create account",
      },
      loginModal: {
        errorAccountDoesNotExist:
          "An account for this email address does not exist.",
        errorAccountNotActive:
          "There is no active account for this e-mail address.",
        errorGeneric: "Something went wrong. Please try again.",
        registerLinkInfo: "{select:Register with a new e-mail address.} ",
        successCTA: "Understood",
        successText:
          "We have sent you an email with a login link. Please click on the link to log in to the platform. The link is valid for 24h. You may also need to check the SPAM folder.",
        successTitle: "Login link has been sent",
        title: "Login",
      },
      needHelp: "Do you need help? {select:Contact us here}",
    },
    loginSuccessful: "You have logged in successfully.",
    logoutModal: {
      buttonCancel: "Cancel",
      buttonLogout: "Logout",
      subtitle: "You can log in again at any time.",
      title: "Are you sure that you want to log out? ",
    },
    map: {
      grantConsentButton: "Activate map",
      grantConsentMapboxLink:
        "I agree that the map will be displayed to me and that my IP address and location data will be transmitted to {select:Mapbox Inc.} and stored for a maximum of 30 days. ",
      grantConsentText:
        "You can find more information in our {select:data protection information}.",
      grantConsentTitle: "Map · external content",
      legendConsultant: "Consultant",
      legendNotAvailable: "Not available",
      legendProvider: "Provider",
      legendTitle: "Legend",
    },
    notLoggedInError:
      "You are not logged in to view that page, please log in and try again",
    onboardingFunnel: {
      answerOther: "Other",
      answerUnknown: "Don't know yet",
      backButton: "Back",
      firstStep: {
        consultants: "Consultants",
        error: "Please select at least one option to continue.",
        providers: "Care providers",
        question: "What are you looking for?",
        stepCount: "1 out of 4 questions",
        subtitle: "Please select one or both of the following options",
      },
      fourthStep: {
        asap: "As soon as possible",
        days: "In a few days",
        question: "From when onwards do you require care?",
        stepCount: "3 out of 3 questions",
        weeks: "In a few weeks",
      },
      loadingScreen: "Thank you! Your results are being generated...",
      nextButton: "Next",
      secondStep: {
        helpAtHome: "Help at home",
        hospice: "Hospice",
        mobileCare: "Mobile care",
        question: "Which type of care are you looking for?",
        sharedFlat: "Assisted living / shared flat",
        shortTermCare: "Short-term care",
        staticCare: "Static care",
        stepCount: "1 out of 3 questions",
      },
      skipButton: "Skip all and start search",
      startSearchButton: "Start search",
      stepper: (args: { step: string; totalSteps: string }) =>
        template("{{step}} out of {{totalSteps}} questions", args),
      stepperFormatted: (args: { step: string; totalSteps: string }) =>
        template("{select:Question {{step}}} of {{totalSteps}}", args),
      textDontKnowStartSearch:
        'If you have a flexible start time, you can simply click "Start search"',
      textDontKnowWithGlossaryLink:
        'Not sure what you want to search for? Find out more in the {select:glossary} or simply click on "Next".',
      textDontKnw:
        'Not sure what you want to search for? Find out more in the glossary or simply click on "Next".',
      thirdStep: {
        dementia: "Dementia care",
        hospice: "Palliative certificate (SAPV)",
        intensiveCare: "Intensive care",
        psychiatricCare: "Psychiatric care",
        question: "Does the patient have any special care needs?",
        stepCount: "2 out of 3 questions",
      },
    },
    pageTitles: {
      accountPageDescription:
        "Alle Informationen zu Ihrem Pflegefinder-Bayern-Konto ✓ Hinterlegte persönliche Daten ✓ Erläuterung Datenschutz ✚ Datenexport anfordern ✚ Nutzungsbedingungen",
      accountPageTitle:
        "Pflegefinder Bayern – alles rund um Ihr Konto und rechtliche Fragen",
      auctionResponseDescription:
        "Status Ihrer Anfrage über den Pflegefinder Bayern digital einsehen ✓ Fragen per Chat stellen ✓ Patientendaten sicher austauschen ➨ Jetzt Kontakt aufnehmen!",
      auctionResponseTitle:
        "Statusseite zu Ihrer Einzelanfrage und Chatmöglichkeit",
      consultantProfileDescription:
        "Pflege-Beratungsangebote in ganz Bayern finden ✓ Übersichtliche Kontaktinformationen ✓ Digital Kontakt aufnehmen ➨ Jetzt Suche starten!",
      consultantProfileTitle:
        "Profilseite mit Kontaktinformationen des Beratungsangebots",
      listingsDescription:
        "Versorgungsangebote und Beratungsangebote in der Pflege bayernweit finden ✓ Anfragen schnell und sicher versenden ✚ Leicht individualisierbar ➨ Jetzt Suche starten! ",
      listingsTitle:
        "Pflegerische Versorgung und Beratung – Ihre Suchergebnisse",
      loginPageTitle: "Logging in to Pflegefinder Bayern",
      onboardingFunnelDescription:
        "Care providers and counselling services in Bavaria ✓ View availability directly ✓ Send digital enquiries and receive answers ➨ Start now!",
      onboardingFunnelTitle:
        "Pflegefinder Bayern - Start your search now by entering your location",
      printListingPageTitle: (args: { date: string }) =>
        template(
          "Pflegefinder Bayern - your search results from {{date}}",
          args,
        ),
      providerProfileDescription:
        "Pflege-Angebote in ganz Bayern finden ✓ Übersicht über alle Leistungen ✓ Fragen per Chat klären ✚ Einfach und digital ✚ Datenschutzkonform ➨ Jetzt Anfrage stellen!",
      providerProfileTitle:
        "Leistungen und Kontaktinformationen des Versorgungsanbieters",
      searchDashboardDescription:
        "Mehrere Anfragen für pflegerische Versorgung gleichzeitig stellen ✓ Den Überblick behalten ✚ Nachrichten und Daten sicher austauschen ➨ Jetzt Pflegefinder nutzen!",
      searchDashboardTitle:
        "Übersicht über Ihre aktiven und bestätigen Kontaktanfragen",
      searchForDescription:
        "Care providers and counselling services in Bavaria ✓ View availability directly ✓ Send digital enquiries and receive answers ➨ Start now!",
      searchForTitle:
        "Pflegefinder Bayern – Start searching for consultants and providers",
      solutionsDescription:
        "Care providers and counselling services in Bavaria ✓ View availability directly ✓ Send digital enquiries and receive answers ➨ Start now!",
      solutionsTitle:
        "Pflegefinder Bayern - Start your search now by choosing a solution",
      specialNeedsDescription:
        "Care providers and counselling services in Bavaria ✓ View availability directly ✓ Send digital enquiries and receive answers ➨ Start now!",
      specialNeedsTitle:
        "Pflegefinder Bayern - Start your search now by selecting care needs",
      startTimeDescription:
        "Care providers and counselling services in Bavaria ✓ View availability directly ✓ Send digital enquiries and receive answers ➨ Start now!",
      startTimeTitle:
        "Pflegefinder Bayern - Start your search now by selecting a start date",
      zipCodeDescription:
        "Care providers and counselling services in Bavaria ✓ View availability directly ✓ Send digital enquiries and receive answers ➨ Start now!",
      zipCodeTitle:
        "Pflegefinder Bayern - Start your search now by entering your location",
    },
    preprodLogin: "Login",
    providerDetailsPage: {
      backToListing: "Back",
      bannerAlreadyContacted:
        "This provider has already been contacted. You can see these requests on the {select:search dashboard}.",
      contactSection: {
        address: "Address:",
        addressDistance: (args: { distance: string; zipcode: string }) =>
          template("{{distance}} km from {{zipcode}}", args),
        addressDistanceZero: (args: { zipcode: string }) =>
          template("is in {{zipcode}}", args),
        buttonLogin: "Login and start request",
        buttonSignup: "Create account and start request",
        capacity: {
          buttonContact: "Contact",
          capacityUpdatedAt: (args: { updatedAt: string }) =>
            template("(Last updated on {{updatedAt}})", args),
          freeCapacityNoStartDate: "Free capacity",
          freeCapacityWithStartDate: "Free capacity for your chosen date",
          noCapacityNoStartDate: "No capacity",
          noCapacityWithStartDate: "No capacity for your chosen date",
          unknownCapacity: "Capacity on request",
        },
        contactCaption:
          "You can contact the provider without obligation and ask questions via our secure end-to-end encrypted chat.",
        copiedEmailTooltip: "Copied!",
        copyEmailFailedTooltip: "Whoops, copy failed. Try again!",
        copyEmailTooltip: "Click to copy email",
        distance: "Distance",
        email: "Email:",
        logInCaption:
          "Create an account or log in to send a contact request to this provider.",
        openingHours: {
          closed: "Closed",
          heading: "Opening hours",
        },
        telephone: "Telephone:",
        title: "Contact",
        website: "Website:",
        websiteTooltip:
          "External link to the website of the provider (Opens in new window) ",
      },
      defaultImageCaption: "This is an example image",
      descriptionText: {
        careprovider:
          "This provider has not provided a profile description yet.",
        consultant: "This provider has not provided a profile description yet.",
        consultantCaresupportcenter:
          "The care support centers offer free advice on all topics relating to care and are open to all citizens (with statutory or private insurance).",
        consultantDementia:
          "Both those affected by a rare dementia disease and their relatives as well as institutions such as care facilities are advised.",
        consultantSpecialist:
          "The specialist centers for caregiving relatives are advice and contact points for caregiving relatives of elderly people in need of care and support them with psychosocial advice, (long-term) support and relief services (work with relatives).",
      },
      disclaimer:
        "All information is provided by the provider or consultancy itself. Recare, as Pflegefinder Bayern provider, does not guarantee the accuracy of the information. The text elements in Italics are generic text describing types of services. These do not allows have to reflect the particular offering of a provider.",
      overweightServiceExplanation: (args: { weight: string }) =>
        template(
          "Adequate care for people weighing more than {{weight}} kg requires appropriate equipment and trained personnel. This point is therefore specifically mentioned.",
          args,
        ),
      print: "Print",
      providerImageCaption:
        "The image displayed is an image that was uploaded by the provider.",
      servicesHeading: "Services",
      solutionsHeading: "Solutions",
    },
    redactedRequestsInfoCard: (args: { redactedCount: string }) =>
      template(
        "There were {{redactedCount}} further direct request(s) were deleted at the user's request.",
        args,
      ),
    requestConfirmationModal: {
      ageGroup: "Age group",
      backButton: "Back",
      careDuration: "Expected care duration",
      careLevel: "Care level",
      careNeeds: "Special care needs",
      errorStartDate:
        "The provider has no availability on this date. You can still send a request, but it is possible that you will receive a rejection.",
      errorTypeOfCareMissing: "Please select at least one type of care.",
      errorZipCodeMissing: "Please enter a valid zip code.",
      gender: "Gender",
      seeMore: "and more care needs",
      seeRequest: "See request",
      sendButton: "Send request",
      startDate: "Start date",
      title:
        "Please check your details before sending the non-binding request.",
      typeOfCare: "Type of care",
    },
    searchDashboard: {
      acceptedSection: {
        emptyState: "No active contact requests",
        subtitle:
          "Contact requests waiting for a response from you or the provider.",
        title: "Active contact requests",
      },
      accessibility: {
        ariaLabelArchivedProfiles:
          "Click here to view archived profiles and contact requests ",
        ariaLabelProfileTab:
          "Click here to view the contact requests from the profil\n",
        menuLabel: "Menu",
      },
      archivedSection: {
        emptyState: "No inactive contact requests",
        subtitle:
          "All requests cancelled by you and/or rejected by providers are listed here.",
        title: "Archived contact requests",
      },
      backButton: "Back to provider list",
      inactiveStateTooltip:
        "Once you have sent contact requests, you can view them here.",
      informationText:
        "On this page, you can see all non-binding requests you have sent to providers and keep up to date with their responses. To ensure an efficient communication, you can be in contact with a maximum of 3 providers per profile at the same time.",
      pageTitle: "Contact requests",
      patientProfile: {
        activeProfilesSubtitle: "You have not yet created any profiles.",
        activeProfilesTitle: "Active Profiles",
        archivedTab: "Archived",
        archiveFlow: {
          buttonArchive: "Archive",
          buttonCancel: "Cancel",
          subtitle:
            "This action cannot be undone. If necessary, you can create a new profile.",
          title: (args: { id: string }) =>
            template(
              "Are you sure that you want to archive profile {{id}}?",
              args,
            ),
        },
        archiveProfile: (args: { id: string }) =>
          template("Profile {{id}} ", args),
        archiveTab: {
          date: (args: { date: string }) =>
            template("Archived on {{date}}", args),
          profile: (args: { id: string }) => template("Profile {{id}}", args),
          title: "Archived",
        },
        informationId:
          "Please use the profile ID number as a reference for communication with the providers.",
        myProfiles: "My profiles",
        profileName: (args: { id: string }) => template("Profile {{id}}", args),
        seeRequestsButton: "See requests",
        tabProfileTitle: "Your profiles",
      },
      profileArchivedSuccessfully: "Profile has been archived successfully",
      requestCard: {
        buttonDetails: "Details and chat",
        buttonNewMessage: "New message",
        date: (args: { date: string }) => template("On {{date}}", args),
        seeMore: "and more solutions",
        startDate: (args: { date: string }) => template("From {{date}}", args),
        status: "Status",
        subtitleAccepted: "Please complete the search.",
        subtitleCancelled: "You have cancelled this request.",
        subtitlePending: "Please wait for the provider's response.",
        subtitleRejected: "Your contact request was rejected.",
        subtitleValidated: "Your search is complete.",
        titleAccepted: "Accepted by provider",
        titleCancelled: "Cancelled",
        titlePending: "Pending",
        titleRejected: "Rejected",
        titleValidated: "Confirmed",
      },
      sectionHeader: (args: { id: string }) =>
        template("Contact requests for profile {{id}}", args),
      validatedSection: {
        emptyState: "No confirmed requests",
        subtitle: "Contact requests confirmed by both you and the provider.",
        title: "Confirmed contact requests",
      },
    },
    selectPatientModal: {
      buttonAddNew: "Add new profile",
      buttonGoToRequest: "Go to request",
      noServicePlaceholder: "Not specified",
      profileName: (args: { identifier: string }) =>
        template("Profile {{identifier}}", args),
      selectButton: "Select",
      title:
        "For which profile would you like to send this non-binding contact request?",
      warningAlreadyContact:
        "You have already contacted this provider with this profile. Please select a different profile or create a new one in order to send a contact request.",
      warningDoesNotMatch:
        "This profile does not match the provider's criteria. You can send a request anyway.",
      warningMaxNumberOfProfiles:
        'To create another profile, you must first archive an existing profile on the {select: contact request page}. To do this, click on "Archive" below the profiles.',
      warningMaxRequetsSent:
        "The maximum number of requests has already been sent for this profile. Please wait for providers to respond, or cancel requests.",
    },
    startPage: {
      paragraphOne:
        "Ob Sie auf der Suche nach einem Pflegeplatz sind, Unterstützung bei der Pflege zu Hause brauchen oder eine fachliche Beratung wünschen.",
      paragraphTwo:
        "Wir führen Sie durch die nächsten Schritte, um einen passenden Pflegeplatz zu finden.",
      promptZipCode: "Bitte geben Sie eine Postleitzahl oder einen Ort ein:",
      subtitle: "Die Pflegebörse für Bayern",
      title: "Willkommen zum Pflegefinder -",
    },
    tableOfContents: {
      accountPage: "Account page",
      activePatientsSubheading: "Active Patients",
      activeRequestsSubheading: "Active Requests",
      archivedPatientSubheading: "Archived Patients",
      ariaLabel: "Table of content",
      inactiveRequestsSubheading: "Inactive Requests",
      pageDescription: "Provides a table of contents for the application",
      pageTitle: "Table of contents",
      patientDashboard: "Patient dashboard",
      profileIdLink: "Profile ID:",
      requestLink: "Request to careprovider: ",
      startPage: "Start page",
      title: "Table of contents",
      validatedRequestsSubheading: "Validated Requests",
    },
  },
  pwaSettings: {
    chromeInstallInstructionOne:
      "Click the button below to install the app on your device",
    confirmedActions: "Recare app installed and notifications permitted",
    copyDebugInformationToClipboard: "Copy information to clipboard",
    debugTitle: "Device information",
    ensurePermissionsGrantedInSettings:
      "If the notification was not received, please ensure that the app has notification permissions in your settings.",
    ensureToAllowNotifications:
      "Please make sure to allow notifications when prompted if you wish to receive notifications to your device. Failing to do so may mean that you will have to uninstall and repeat this step.",
    errorAskingPermission: (args: { error: string }) =>
      template("There was an error processing your request: {{error}}", args),
    ifAlreadyInstalled:
      "If you have already installed the app, please go to the installed app on your home screen to continue",
    installButtonNotAvailable:
      "Please install app by going to your browsers settings and installing the App",
    installToDeviceButton: "Install to device",
    iOsInstallInstructionOne:
      'Click share {select:icon} and "Add to home screen" to install App',
    iOsVersionError: (args: { version: string }) =>
      template("Below allowed iOS Version {{version}}", args),
    notificationAPIUnspported:
      "Notification API unsupported on this device, please try closing a reopening the application, if that does not work, please contact recare with your debug information below.",
    notificationPermissionGranted: "Notification permission granted",
    pleaseInstallWithChrome:
      "It is recommended to install this application in chrome for best functionality",
    pleaseInstallWithSafari:
      "PWAs must be installed with Safari when using iOS",
    pleaseUpdatePermissions:
      "Please update the permissions of this app to send notifications in your device settings.",
    pleaseUseMobileDevice: "Please use a mobile device to install this app",
    promptDismissed:
      "Installation prompt dismissed, please install app via the Chrome browser settings, or refresh the page.",
    pushManagerError:
      "No push subscription, close and reopen the app, failing that, please reinstall to receive notifications.",
    pushManagerSubscribed: "Push manager: subscribed",
    pwaInstalled: "PWA installed ",
    reestablishPermission:
      "If you have previously denied notification permission for this app, allowing permissions in your settings, closing and reopening the app should renable notifications. If that does not work, please reinstall and ensure you allow permissions in the first step.",
    requestNotificationPermissionButton: "Request notification permission",
    serviceWorkerState: "Service worker: ",
    serviceWorkerSucess: "Service worker installed and active",
    settingsTitle: "PWA (Beta)",
    showDebugSwitchLabel: "Show debug information",
    stepOne: "Step 1, install App to home screen",
    stepThree: "Step 3, allow Recare notifications",
    stepTwo: "Step 2, allow notifications",
    subheading:
      "To install the recare app on your mobile device and receive notifications, please follow the below steps",
    subscribeToRecareNotifications: "Subscribe to recare notifications",
    testNotification: "I'm a test notification",
    testNotificationButton: "Test notification",
    thankYouForInstalling:
      "Thank you for installing the app, please open Recare from your installed apps to continue",
    title: "PWA Settings",
    updateServiceWorker: "Click here to update service worker",
  },
  recare: {
    signature:
      "Recare GmbH\nAlt-Moabit 103\n10559 Berlin\ninfo@recaresolutions.com\n+49 30 56 79 60 57",
  },
  rehaOnboarding: {
    companyDetails: {
      copyAndPaste: "Copy and paste information",
      deleteSpecialization: "Delete specialisation",
      hintCompanyMailAddress:
        "This is your company’s primary email address to receive requests. This is not a login email (we will ask you for it in the next step).",
      hintCompanyName:
        "You are about to create an account for {select: companyName}",
      hintCopyAndPaste:
        "Instead of typing everything again, you can copy-paste identical information to what you already added for another specialisation.",
      hintSpecializations:
        "Please, choose your specialisation and fill in a detailed form about each one underneath.",
      modalCopyAndPasteTitle: "Where do you want to retrieve data from?",
      modalNothingFilledYet: "(Nothing filled yet)",
      modalTitleFillTheFormFor: "Fill the form for",
      specializationsTitle: "Your specialisations",
      title: "Create your Recare account",
      toastCompleteForms:
        "Please, complete all your added specialisation first.",
    },
    congratulations: {
      contactUs:
        "Contact us at +49 30 56 79 60 57 in case of any further questions.",
      infoText:
        "We received all information you provided. You are going to receive an email with the confirmation. ",
      thankYou:
        "Thank you, we are looking forward to a successful collaboration!",
      title: "Congratulations!",
      yourTeam: "Your Recare team",
    },
    copyPasteFabHint:
      "Fill this form with one click, copying previously added information",
    statusCompleted: "Completed",
    statusNotFilled: "Click the arrow to fill this form",
    statusNotFillNoExpansion: "Click here to fill this form",
    yourTeam: {
      contactRole:
        "This employee should be shown to the clinic as a contact person",
      deleteEmployee: "Delete employee",
      hintEmail:
        "This is the personal email address a user should use to log in.",
      hintEmployee: "Please, add yourself and your colleagues to the list.",
      mailPlaceholderText: "Enter your personal email address",
      modalFinishSubtitle:
        "Please confirm your onboarding as completed only if the provided information is correct. You can always add more members manually in Settings, but you will have to contact Recare if you want to add more specialisations.",
      modalFinishTitle: "You have completed the onboarding",
      placeholderEmployee: (args: { countOfcreatedEmployee: string }) =>
        template("Employee #{{countOfcreatedEmployee}}", args),
      title: "Employees in your company",
      titleAccess: "This person has access to",
      toastCompleteEmployee:
        "Please, add at least one employee, delete unfilled employee profiles or complete all forms.",
    },
  },
  report: {
    activeProviders: "Active providers",
    allSearches: "All searches",
    attribution: "Attribution",
    automaticSuccesses: "Automatic successes",
    cancelled: "Cancelled",
    cancelledSearches: "Cancelled searches",
    created: "Created",
    descAttribution: "Average time from start of a search to attribution",
    descHours: "In business hours: 7am to 5pm",
    descOmission: "Searches with multiple solutions are not taken into account",
    descPositive:
      "Average time from start of a search to 1st positive response",
    dischargedWithoutProvider: "Discharged without provider",
    endDate: "Report end date",
    inProgress: "In progress",
    inProgressSearches: "Searches in progress",
    manualSuccesses: "Manual successes",
    numPatients: "Number of patients attributed",
    other: "Other",
    patientCreated: "Patients created:",
    percentagePatients: "% of patients attributed",
    placeholderOnlyAvailableInProd:
      "Report is only visible on production environment",
    positiveResponse: "1st positive response",
    providerFoundWithoutRecare: "Provider found without Recare",
    providerFoundWithRecare: "Provider found with Recare",
    providerName: "Name of providers",
    providersPerSolution: (args: { radius: string }) =>
      template("Number of active providers in a radius of {{radius}}km", args),
    reason: "Selected reason",
    relevant: "Relevant",
    relevantSearches: "Finished searches",
    relevantSearchesStatusTitle: "Finished searches by status:",
    repartitionSolutionsTitle: "Repartition of searches per solutions",
    reportCreated: (args: { date: string }) =>
      template("Report (created on {{date}})", args),
    solution: "Type",
    startDate: "Report start date",
    thirdPartyReasons: "3rd-party cancel reasons",
    timeToSuccessTitle: "Time to success by solution",
    total: "Total",
    transferSuccessesTitle: "Transfers of automatic and manual successes to:",
    unknown: "The name of the provider is unknown",
    unknownAll: (args: { receiverTypeSingular: string }) =>
      template("The name of the {{receiverTypeSingular}} is unknown", args),
  },
  requestEvents: {
    addedTheProvider: {
      myAccount: (args: { providerName: string }) =>
        template("You added {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template("{{accountName}} added {{providerName}}", args),
    },
    answerProviderMessage: "Please respond to the message",
    canceledAcceptance: (args: { providerName: string }) =>
      template(
        "{{providerName}} cancelled the acceptance of the request.",
        args,
      ),
    canceledDeclination: (args: { providerName: string }) =>
      template(
        "{{providerName}} canceled the declination of the request",
        args,
      ),
    canceledProviderRejection: {
      myAccount: (args: { providerName: string }) =>
        template("You canceled the rejection of {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} canceled the rejection of {{providerName}}",
          args,
        ),
    },
    canceledProviderValidation: {
      myAccount: (args: { providerName: string }) =>
        template("You canceled the validation of {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} canceled the validation of {{providerName}}",
          args,
        ),
    },
    mailBounced: "Request was not delivered successfully",
    providerAcceptedTheRequest: (args: { providerName: string }) =>
      template("{{providerName}} accepted the request", args),
    providerDeclinedRequest: (args: { providerName: string }) =>
      template("{{providerName}} declined the request", args),
    rejectedThisProvider: {
      myAccount: (args: { providerName: string }) =>
        template("You rejected {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template("{{accountName}} rejected {{providerName}}", args),
    },
    removedSolution: "Solution has been removed",
    requestAccepted: "Your request was accepted.\nPlease respond to it",
    requestedPatientData: {
      myAccount: "You asked for more information about the patient",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} asked for more information about the patient",
          args,
        ),
    },
    requestSent: (args: { careprovider: string }) =>
      template("Request sent to {{careprovider}}", args),
    requestSomeMailBounced: (args: { count: string; total: string }) =>
      template(
        "Request was successfully delivered to only {{count}} out of {{total}} email addresses",
        args,
      ),
    sentaFile: {
      myAccount: "You sent a file",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} sent a file", args),
    },
    sharedPatient: {
      automatic: (args: { providerName: string }) =>
        template(
          "{{providerName}} received access to the private patient data",
          args,
        ),
      myAccount: (args: { providerName: string }) =>
        template(
          "You gave {{providerName}} access to the private patient data",
          args,
        ),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template(
          "{{accountName}} gave {{providerName}} access to the private patient data",
          args,
        ),
    },
    updatedPatient: {
      myAccount: "You edited the patient profile",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} has edited the profile", args),
    },
    updatedPatientRuledOut:
      "The updated profile does not fit the range of services anymore, because the following was changed:",
    validatedThisProvider: {
      myAccount: (args: { providerName: string }) =>
        template("You validated {{providerName}}", args),
      otherAccount: (args: { accountName: string; providerName: string }) =>
        template("{{accountName}} validated {{providerName}}", args),
    },
    waitingForProviderAnswer: "Request was not accepted or declined yet",
    wrote: {
      myAccount: "You sent a message",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} sent a message", args),
    },
  },
  responseEvents: {
    acceptedMultiSolution: {
      myAccount: "You're offering the following:",
      otherAccount: (args: { providerName: string }) =>
        template("{{providerName}} is offering the following: ", args),
    },
    acceptedTheRequest: {
      myAccount: "You accepted the request",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} accepted the request", args),
    },
    answerTheRequest: "Please respond to the request",
    askAQuestion: "Ask a question",
    aUser: "a user",
    canceledAcceptance: {
      myAccount: "You canceled the acceptance of the patient",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} canceled the acceptance of the patient",
          args,
        ),
    },
    canceledDeclination: {
      myAccount: "You canceled the declination of the patient.",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} canceled the declination of the patient.",
          args,
        ),
    },
    clinicCanceledAttribution: (args: { accountName: string }) =>
      template("{{accountName}} cancelled your attribution", args),
    clinicCanceledAttributionWithReason: (args: {
      accountName: string;
      reason: string;
    }) =>
      template("{{accountName}} cancelled your attribution: {{reason}}", args),
    declinedRequest: {
      myAccount: "You declined the request",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} declined the request", args),
    },
    patientAttributedToYou: "Patient was attributed to you",
    requestArchivedAutomatically: "This request was archived automatically",
    requestArchivedManually: {
      myAccount: "You have archived this request manually.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} has archived the request.", args),
      tools: "The request was archived",
      undo: {
        myAccount: "You have undone the archiving.",
        otherAccount: (args: { accountName: string }) =>
          template("{{accountName}} has undone the archiving.", args),
        tools: "The request was unarchived",
      },
    },
    requestReceived: (args: { socialWorker: string }) =>
      template("Request received from {{socialWorker}}", args),
    searchArchivedAutomatically: "This patient was archived automatically",
    waitingForClinic: "Pending response",
  },
  search: {
    accepted: "Accepted",
    accessibility: {
      searchLabel: "Search for provider",
    },
    accessToPatientDataGrantedTooltip:
      "Access to private patient data has been granted",
    accessToPatientDataNotGrantedTooltip:
      "Provider does not have access to patient data",
    actionRequiredBannerTitle: "Action required",
    addPreferredProvider: (args: { receiverTypeSingularPreferred: string }) =>
      template("Add {{receiverTypeSingularPreferred}}", args),
    addProvider: {
      addAnother: "Add another",
      care: "Add provider",
      contactProviders: "Contact",
      geriatrics: "Add preferred clinic",
      noProviderSelected: "Please select providers",
      restartedMovedToStoppedSunsetCostpayerflow:
        "Your restarted search was automatically stopped on 01.04.2023 due to the sunsetting of the functionality to hand over searches to costpayers as previously announced.",
      successfullySentRequests: (args: { numRequests: string }) =>
        template("{{numRequests}} providers were successfully contacted", args),
      successfullySentSingleRequest: "Provider was successfully contacted",
    },
    allContacted: (args: { receiverTypePlural: string }) =>
      template("All matching {{receiverTypePlural}} have been contacted", args),
    allContactedNotAllAnsweredTitle: {
      care: "All providers have been contacted. Some have not answered yet:",
      geriatrics:
        "All clinics have been contacted. Some have not answered yet:",
    },
    allContactedTitle: {
      care: "All providers have been contacted",
      geriatrics: "All clinics have been contacted",
    },
    allNegativeSendMore:
      "All requests were declined / rejected. Send more requests to providers manually or stop the search if it's no longer needed",
    allNegativeSuggestMore:
      "All requests were declined / rejected. Suggest another provider to the insurance to continue.",
    allProvidersRejected: {
      subtitle: "Please add more providers to the search.",
      title: "All requests were declined/rejected.",
    },
    answerSent: "Your answer was sent",
    archivePatientDescription:
      "When archiving a patient, you can find it under the archived tab or using the search bar.",
    auctionStatusStepper: {
      notStarted: "Search not started",
      providerConfirmed: "Provider confirmed",
      requestsSent: "Requests sent",
      responseReceived: "Answer received",
      searchStarted: "Search started",
      waitingForResponse: "Waiting for a response",
    },
    cancelCardAction: "Cancel",
    cancelValidationPanel: {
      confirm: "Cancel validation",
      hint: "Please note, the reason you state below will be displayed in Documentation for later reference.",
      placeholder: "Please specify",
      title: "Why do you want to cancel validation?",
    },
    candidatesCalculating:
      "Potential candidates are being calculated, please wait",
    canNotStart:
      "Please edit the profile, in order to start the search. All mandatory fields must be filled.",
    cannotStartSearchNoCandidates: {
      care: "There are no potential providers to contact. Search cannot be started. Please check that the information (address etc.) is correct.",
      geriatrics:
        "There are no potential clinics to contact. Search cannot be started. Please check that the information (address etc) is correct.",
    },
    careproviderSearch: "Search provider",
    careproviderUnknown: {
      care: "Provider's name unknown",
      geriatrics: "Clinic's name unknown",
    },
    careproviderUnknownViaFamily: "Provider's name unknown",
    chipPaused: "Paused",
    chosen: "Chosen",
    confirmChooseProvider: (args: {
      careproviderName: string;
      patientId: string;
    }) =>
      template(
        "Are you sure you want to confirm the choice of {{careproviderName}} for {{patientId}}?",
        args,
      ),
    confirmTitleAboutToValidate: (args: { careproviderName: string }) =>
      template("You are about to validate {{careproviderName}}.", args),
    contactedNotAllAnswered: (args: { receiverTypePlural: string }) =>
      template(
        "All matching {{receiverTypePlural}} have been contacted. Some have not yet answered:",
        args,
      ),
    contactedProviders: "Contacted",
    continueSearch: "Continue the search",
    declined: {
      care: "Declined by provider",
      geriatrics: "Declined by receiving clinic",
    },
    draft: "Draft",
    draftMovedToStoppedSunsetCostpayerflow:
      "Your draft was automatically stopped and moved to finished due to sunsetting of the functionality to hand over searches to costpayers.",
    editAcceptedSearch:
      "It is possible to edit the profile after a completed search:",
    editOrRestart:
      "It's also possible to edit the profile or restart the search.",
    editProfileTitle: "Or continue editing:",
    editProfileToBeStarted: "It is possible to edit the profile",
    editRunningSearch:
      "It is possible to edit the profile while the search is running:",
    filteredProviders: "Filtered",
    foundProviderTitle: (args: { receiverTypeSingular: string }) =>
      template("This {{receiverTypeSingular}} has been chosen", args),
    goToSearch: "go to search",
    grantAccessToFollowing:
      "You can now grant this provider access to the following patient data:",
    handoverSearchTitle:
      "Patient profile complete? You can ask the cost payer to start the search:",
    howToStartSearchHint:
      "To start the search you can either manually select providers from the list below and “contact selected”. Or you can click on “contact all” to contact all potential providers.  You can also search for specific providers by entering their name in the search bar.",
    infoAutomaticVSManual:
      "These providers have not been contacted. You can contact a limited pool of providers by selecting them from the list and clicking on “contact selected”. To contact every potential provider please select “contact all”.",
    manuallyAdded: "Manually added",
    manuallyRejected: "Manually rejected",
    manuallyRunning: "Manually running",
    manualNewAnswersBanner:
      "Currently only clinics are being contacted that have been added manually.",
    manualNoPendingRequestsTitle: "All requests were declined/rejected",
    manualSearchInProgressTitle: "Manual search in progress",
    manualTransportBanner:
      "For manual transport searches, we currently have a limit of one provider. If you wish to contact more than one, please choose automatic search.",
    maximumAcceptsReached: (args: { receiverTypePlural: string }) =>
      template(
        "Several {{receiverTypePlural}} already accepted! To spare you from additional work we stop sending more requests. After you rejected one or several, we will continue sending more requests.",
        args,
      ),
    maximumAcceptsReachedSearchMerge: (args: { receiverTypePlural: string }) =>
      template(
        "Several {{receiverTypePlural}} are waiting for your answer. Please provide an answer to at least one of them in order to make the patient available to all other contacted receivers again.",
        args,
      ),
    noCandidatesCannotManuallySearch: (args: { receiverTypePlural: string }) =>
      template(
        "There are no potential {{receiverTypePlural}} to contact. The search can still be started and recipients found when they become available.",
        args,
      ),
    noCandidatesCannotSearch: (args: { receiverTypePlural: string }) =>
      template(
        "There are no potential {{receiverTypePlural}} that can be contacted. Please make sure that the information has been entered correctly (e.g. address).",
        args,
      ),
    noCandidatesStartSearch: (args: { receiverTypePlural: string }) =>
      template(
        "There are no potential {{receiverTypePlural}} to contact. The search can still be started and recipients can be added manually.",
        args,
      ),
    noPotentialReceiversBanner: (args: { receiverTypePlural: string }) =>
      template(
        "To find more available {{receiverTypePlural}}, send requests manually, change the starting point of the search, increase the search radius, edit the patient profile, or contact Recare for assistance.",
        args,
      ),
    noPotentialReceiversNoRequestSentBanner: {
      care: "To find available providers, try to send requests manually, change treatment location, increase search radius, or contact Recare for assisstance",
      geriatrics:
        "To find available clinics, try to send requests manually, change treatment location, increase search radius, or contact Recare for assisstance",
    },
    noSuggestionsYet: {
      insurance:
        "The clinic did not suggest any preferred providers. Please, start the search.",
      shareFiles:
        "While you wait: {select:Click here} to share files with the cost payer",
      socialWorker:
        "The search has been handed over to the insurance. Please wait for them to respond or suggest a preferred provider.",
    },
    notActiveTitle: "Not active on Recare",
    notStarted: "Not started",
    notStartedYet: "Search has not been started yet",
    notStartedYetHints: {
      insurance:
        "Click START THE SEARCH button on the right to send first requests to providers or review potential preferred clinics suggested by the hospital",
      socialWorker:
        "Waiting for the cost payer to start the search and send out first requests",
    },
    onboarding: "Waiting for onboarding",
    oneAcceptInfoBanner:
      "Please validate or reject the acceptance of the receiver in order to make the patient available to other receivers again",
    patientPreferred: {
      dataNotEncrypted:
        "Field is not encrypted. Please don't enter sensitive information.",
      markAs: "Mark as patient wish",
      placeholder: "Patient's reasons",
      reason: "Reason",
      reasonNotEmpty: "Please add your new reason first",
      tooltipRemovePatientPreferred: "Remove marking",
    },
    patientWishInfo: "Patient's wishes are marked with a star.",
    patientWishInfoIcon: "Patient's wish",
    pause: "Pause search",
    paused: "Paused",
    pauseDeprecated:
      "Please note that as all potential providers have been contacted, pausing the search is no longer needed.",
    pauseDeprecatedInformation:
      "Due to recent improvements to the search experience, pausing searches is no longer possible.",
    pausedMovedToStoppedSunsetCostpayerflow:
      "Your paused search was automatically stopped due to the sunsetting of the functionality to hand over searches to costpayers as previously announced.",
    pausedTitle: "Search has been paused",
    pauseTitle: "Are you sure you want to pause the search?",
    pauseWarning:
      "When the search is paused, no automatic requests will be sent anymore.\nYou can still send requests directly.\nAll open requests can be answered.",
    pending: "Pending",
    potentialProviders: "Potential providers",
    printConfirmation: "Print confirmation",
    providerFound: "Receiver found",
    providerFoundTitle: {
      care: "This provider has been chosen",
      geriatrics: "This clinic has been chosen",
    },
    providersWhoAccepted: "Accepted",
    providersWhoDeclined: "Declined by provider",
    providerUnknown: (args: { receiverTypeSingular: string }) =>
      template("Name of {{receiverTypeSingular}} unknown", args),
    receiverDecliner: (args: { receiverTypeSingular: string }) =>
      template("Declined by {{receiverTypeSingular}}", args),
    receiverValidatedEligibilityRejected: {
      insurance:
        "The following receiver has been validated but patient’s eligibility has been rejected. Please stop the search or contact the clinic for more information.",
      socialWorker:
        "The following receiver has been validated, but patient’s eligibility for Rehab has been rejected. Please stop the search or contact the cost payer for more information.",
    },
    receiverValidatedWaitingEligibility: {
      insurance:
        "The following receiver has been validated. For the case to be closed, patient's eligibility status should be updated.",
      socialWorker:
        "The following receiver has been validated. For the case to be closed, patient's eligibility status should be updated.",
    },
    rejected: "Rejected",
    rejectedTitle: "Rejected",
    restartSearch: "Restart the search",
    reviewRequest: "Review",
    running: "Running",
    search: "Search",
    searchHasBeenRunningFor:
      "The search has already been running for 2 hours. There are still no positive answers.",
    searchInProgress: "Running searches",
    searchInProgressTitle: "Search in progress",
    searchMergeNoProviders:
      'To find available providers, send requests manually through "search provider", increase the search radius, or contact Recare for assistance.',
    searchPageTables: {
      accessibility: {
        clickFunctionality: (args: {
          providerName: string;
          tableName: string;
        }) =>
          template(
            "Click to view response page for {{providerName}} from the table {{tableName}}",
            args,
          ),
        deselectAll:
          "Click to de-select all providers in the table potential receivers in order to add specific providers manually ",
        deselectProvider: (args: { providerName: string }) =>
          template(
            "Click to de-select {{providerName}} in the table Potential Receivers",
            args,
          ),
        patientWishAccessibility: {
          addReason: "Click to add an optional reason for the patient wish",
          cancelReason: "Click to cancel input",
          checkedStar: (args: { providerName: string }) =>
            template(
              "Click to de-select {{providerName}}  as patient wish",
              args,
            ),
          confirmReason: "Click to save input",
          disabled:
            "Patient wish can't be edited as this field is currently disabled",
          editReason: "Click to edit patient wish",
          uncheckedStar: (args: { providerName: string }) =>
            template("Click to mark {{providerName}} as patient wish", args),
        },
        selectAll:
          "Click to select all providers in the table potential receivers",
        selectAllIntermediary:
          "Click to select all providers in the table potential receivers",
        selectProvider: (args: { providerName: string }) =>
          template(
            "Click to select {{providerName}} in the table Potential Receivers",
            args,
          ),
        sortingMenu: {
          sortByDefault: "Click default to sort providers by default",
          sortByDistance:
            "Click distance to sort providers by distance from close to far",
          sortByName: "Click to sort by name and list providers alphabetically",
          sortByStatus:
            "Click to sort by status and list providers based on their status",
          title: "Click here to change the current sorting",
        },
      },
      actionMenu: {
        acceptRequest: "Accept",
        declineRequest: "Decline",
        dismissSuggestedProvider: "Dismiss suggested provider",
        giveAccessToData: "Give access to patient data",
        nextRequest: "Next request",
        openRequest: "Open",
        rejectProvider: "Reject",
        sendFax: "Send fax",
        sendFile: "Send File",
        sendMessage: "Send message",
        sendRequestToSuggestedProvider: "Send request to suggested provider",
        setReceiverAssignee: "Set assignee",
        undoAcceptRequest: "Undo acceptance",
        undoDeclineRequest: "Undo declination",
        undoDismissRejectedProvider: "Undo dismissal of suggested provider",
        undoManuallyArchiving: "Undo archiving",
        undoRejectProvider: "Undo rejection",
        undoSuggestProvider: "Undo suggestion",
        validateProvider: "Validate",
      },
      columnNames: {
        dateOfRequest: "Request date",
        declineDate: "Decline date",
        declineReason: "Decline reason",
        distance: "Distance to patient",
        filterReason: "Filter reason",
        hint: {
          patientWish:
            "In this column you can submit the patient's wish as long as the request has not yet been answered.",
        },
        internalRejectReason: "Internal reject reason",
        location: "Location",
        potentialStartDate: "Potential start date",
        providerName: "Provider",
        rejectDate: "Reject date",
        rejectReason: "Reject reason",
        solutions: "Type of transfer",
        startDate: "Start date",
        status: "Status",
      },
      distanceToPatient: (args: { distance: string }) =>
        template("{{distance}} away", args),
      key: "key",
      manuallyAssignedWinner: {
        dataNotAvailable: "Unknown",
        internalTransfer: "Internal Transfer",
        unknownProvider: "Unknown Provider",
      },
      modals: {
        contactAnyway: {
          subtitle: 'Click "confirm" to add the provider to the search.',
          subtitleFax:
            "Please note that this provider is not active on Recare, but will be contacted via fax.",
          title: "Do you want to send a request to this provider?",
        },
        sendFax: {
          buttonCancel: "Cancel",
          buttonConfirm: "I understand the risk",
          buttonSend: "Send now",
          subtitle:
            "Where possible, we recommend choosing alternative communication methods with a higher standard of data protection. Providers listed on the Recare platform can also be contacted securely via our encrypted messenger.",
          title: "This provider is not part of the Recare platform.",
          titleConfirmation: (args: { providerName: string }) =>
            template(
              "Are you sure you want to send a fax to {{providerName}}? ",
              args,
            ),
          warning:
            "Please note that sending a fax is the least preferred option in terms of GDPR compliance.",
        },
      },
      pagination: {
        next: "Next page",
        previous: "Previous page",
      },
      paginationSelectLabel: "Results per page",
      placeholderNoResults: "There are no results yet",
      potentialTableActions: {
        contactAll: "Contact all",
        contactSelected: "Contact selected",
        infoText:
          "These providers have not been contacted. You can contact a limited pool of providers by selecting them from the list and clicking on “contact selected”. To contact every potential provider please select “contact all”.",
        requestPreferredProvider: "Request preferred provider",
      },
      printModalTitle: "Which sections would you like to print?",
      printModalTooManyWarning: (args: { maxCount: string }) =>
        template(
          "We can only print up to {{ maxCount }} providers per section",
          args,
        ),
      sortBy: "Sort by",
      sorting: {
        default: "Default",
        distance: "Distance",
        name: "Name",
        status: "Status",
      },
      status: {
        accepted: "Accepted",
        faxSent: "Fax sent",
        newMessage: "New message",
        notSeen: "Not seen",
        providerInactive: "Inactive",
        seen: "Seen",
      },
      tableNames: {
        accepted: (args: { total: string }) =>
          template("Accepted ({{total}})", args),
        contacted: (args: { total: string }) =>
          template("Contacted providers ({{total}})", args),
        declined: (args: { total: string }) =>
          template("Treatment not possible ({{total}})", args),
        filtered: (args: { total: string }) =>
          template("Filtered providers ({{total}})", args),
        potential: (args: { total: string }) =>
          template("Potential receivers ({{total}})", args),
        rejected: (args: { total: string }) =>
          template("Rejected ({{total}})", args),
        subtitle: {
          contactedTable:
            "There has been no feedback from the providers listed here in the form of an acceptance or rejection.",
          filteredTable:
            "This table lists all providers that were filtered out due to your search criteria, or because they are not yet part of the Recare network.",
          potentialReceiversTable:
            "Click the the top most checkbox to select or de-select all providers, or select providers individually by clicking the checkbox next to the provider name",
          rejectedTable:
            'Overview of all providers rejected by you. If you want to undo the rejection click on the provider row and click "undo" in the chat.',
        },
        validated: "This provider has been chosen",
      },
    },
    searchPausedArchiveTitle:
      "You stopped the search. Do you want to archive the patient?",
    searchPausedTitle: "Search was stopped",
    searchPausedWarning:
      "Please note that if you change information and start the search again, previously contacted providers won't be notified of these changes.",
    searchUpdatedNotification:
      "Updating your patient details may impact the selection of care providers contacted.",
    sendRequestCta: {
      contactAnyway: "Contact anyway",
      contactAnywayAndStartSearch: "Start search and send request",
      contactAnywaySearchNotStarted: "Contact this provider anyway",
      contactAnywaySearchRunning: "Add this provider to the search",
      contactAnywaySearchStopped:
        "You need to restart the search in order to send requests",
      contactAnywaySearchWon: "Cannot send requests once patient is attributed",
      sendFaxAndStartSearch: "Start search and send fax",
    },
    sent: "Request was sent",
    sentTitle: "Sent",
    setUpSearch: "Go to search",
    shareEncryptedData: {
      assessment: {
        checkbox: (args: { receiverTypePlural: string }) =>
          template(
            "Share patient data automatically with {{receiverTypePlural}}",
            args,
          ),
        title: "Share information",
      },
      grantAccess: "Grant access",
      hint: "Alternatively, you can do this at a later time in the respective chat window. As soon as the patient is assigned to a receiver, the data is encrypted again for all other receivers.",
      later: "Later",
      title:
        "Would you like the selected provider/s to be granted access to patient data?",
    },
    showMore: "Show more",
    smartSuggestions: {
      autoSearchSelectedAll: (args: { receiverTypePlural: string }) =>
        template(
          "To selected {{receiverTypePlural}} and automatically to other potential receivers ",
          args,
        ),
      autoSearchSelectedCare:
        "To preferred providers and others, suggested by Recare",
      autoSearchSelectedRehab:
        "To preferred clinics and others, suggested by Recare",
      distanceFromPatient: (args: { distance: string }) =>
        template("{{distance}} away", args),
      hintManuallyAddedAll: (args: { receiverTypeSingular: string }) =>
        template(
          "You have added this {{receiverTypeSingular}} manually.",
          args,
        ),
      hintManuallyAddedCare: "You have added this provider manually.",
      hintManuallyAddedRehab: "You have added this clinic manually.",
      hintManuallyAddedShort: "Manually added",
      manualSearchSelectedAll: (args: { receiverTypePlural: string }) =>
        template("Only to selected {{receiverTypePlural}}", args),
      manualSearchSelectedCare: "Only to preferred providers",
      manualSearchSelectedRehab: "Only to preferred clinics",
      markAsPatientWish: "Mark as patient's wish",
      panelAllTitleManualAndPreferred: (args: { receiverTypePlural: string }) =>
        template(
          "You can add {{receiverTypePlural}} to the search and mark patient wishes",
          args,
        ),
      panelAllTitleManualAndPreferredAb: (args: {
        receiverTypeSingular: string;
      }) =>
        template(
          "Tick the box to contact a specific {{receiverTypeSingular}} (multiple selection possible) or document the patient’s wish by clicking the star",
          args,
        ),
      panelTitleAll: (args: { receiverTypePluralPreferred: string }) =>
        template("Select your {{receiverTypePluralPreferred}}", args),
      panelTitleCare: "Select your preferred providers (optional)",
      panelTitleRehab: "Select your preferred clinics (optional)",
      panelTitleRehaInsurance: "Suggest a preferred clinic to the cost payer",
      panelTitleRehaInsurancePayer: "Select clinics",
      panelTitleRunningAll: (args: { receiverTypePlural: string }) =>
        template("Send more requests to {{receiverTypePlural}}", args),
      panelTitleRunningCare: "Send more requests to providers",
      panelTitleRunningCareAb:
        "Tick the box to add a specific provider to the search or mark the patient’s wish by clicking the star:",
      panelTitleRunningRehab: "Send more requests to clinics",
      searchSelectorHintAll: (args: { receiverTypePlural: string }) =>
        template(
          "When nothing is selected, requests will be sent to all potential {{receiverTypePlural}}",
          args,
        ),
      searchSelectorHintCare:
        "When nothing is selected, requests will be sent to all further potential providers",
      searchSelectorHintRehab:
        "When nothing is selected, requests will be sent to all further potential clinics",
      searchSelectorOptionHintAll: (args: { receiverTypePlural: string }) =>
        template(
          "(note: previously contacted {{receiverTypePlural}} will still be considered)",
          args,
        ),
      searchSelectorOptionHintCare:
        "(note: previously contacted providers will still be considered)",
      searchSelectorOptionHintRehab:
        "(note: previously contacted clinics will still be considered)",
      searchSelectorTitle: "Who do you want to send requests to? *",
      sendMoreRequests: "Send more requests",
      sendRequests: "Send requests",
      titleAll: (args: { receiverTypePlural: string }) =>
        template(
          "Based on your previous activity, you may want to consider adding these {{receiverTypePlural}} as preferred:",
          args,
        ),
      titleAllNoSuggestions: (args: { receiverTypePluralPreferred: string }) =>
        template("You may add {{receiverTypePluralPreferred}} manually", args),
      titleCare:
        "Based on your previous activity, you may want to consider adding these providers as preferred:",
      titleCareNoSuggestions: "You may add preferred providers manually",
      titleRehab:
        "Based on your previous activity, you may want to consider adding these clinics as preferred:",
      titleRehabNoSuggestions: "You may add your preferred clinics manually.",
      titleRehaInsurancePayer:
        "You may want to consider adding the following clinics:",
      titleRehaInsurancePayerNoSuggestions: "You may add clinics manually",
      tooltipPartnerClinic: (args: { receiverTypeSingular: string }) =>
        template("{{receiverTypeSingular}} is a partner of you", args),
      tooltipPatientsReason:
        "Here you can specify a reason for a preferred clinic of the patient. The insurance will then check this in accordance with §8 SGB IX (right of beneficiaries to request and choose).",
    },
    startSearchNoCandidates: {
      care: "There are no potential providers to contact. It is still possible to start the search and add providers manually.",
      geriatrics:
        "There are no potential clinics to contact. It is still possible to start the search and add providers manually.",
    },
    startTheSearch: "Start the search",
    startTheSearchTitle:
      "Patient profile complete? You can start the search here:",
    stopNotEligible: "Cost coverage not possible",
    stopped: "Stopped",
    stoppedSunsetCostpayerflow:
      "Your search was automatically stopped due to the sunsetting of the functionality to hand over searches to costpayers as previously announced.",
    suggestProvider: "Suggest a provider",
    suggestToCostPayer: "Suggest to cost payer",
    summary: {
      accepted: "Accepted",
      approved: "Approved",
      declined: "Declined",
      declinedByProvider: "Treatment not possible",
      dismissedByCostPayer: "Dismissed by cost payer",
      oneAcceptTooltip:
        "You have to validate or reject the accepted request first, in order to send further requests",
      rejected: "Rejected",
      sent: "Sent",
      sentFax: "Sent (via fax)",
      summary: "Summary",
      unavailable: "Unavailable",
      waitingForClinicAnswer: "Waiting for clinic answer",
      waitingForProviderAnswer: "Waiting for provider answer",
    },
    switchOnSearchMergeActivated: "Activate search merge: ",
    titleInfo: {
      confirmGrantAccess: (args: { careproviderName: string }) =>
        template(
          "You are about to validate {{careproviderName}}. Would you like them to be granted access to the following Data?",
          args,
        ),
      onlyPreferred: {
        care: "Currently only preferred providers are being contacted.",
        geriatrics: "Currently only preferred clinics are being contacted.",
      },
      preferredOnlyContacted: (args: { receiverTypePluralPreferred: string }) =>
        template(
          "Currently only {{receiverTypePluralPreferred}} are being contacted.",
          args,
        ),
      searchPaused:
        "Search is currently paused and no new automatic requests are sent out. Already sent requests can still be answered.",
    },
    toBeSentTitle: "To be reviewed",
    toBeStarted: "Handed over to insurance",
    toBeStartedIns: "Search handed over",
    validated: "Validated",
    viaPatientFamily: "Via patient's family",
    willSendRequestShortly: "First requests will be sent shortly",
    zeroPotentialClinicsPlaceholder:
      "Currently, there are no potential clinics in your area or none have capacity to accept patients",
    zeroPotentialProvidersPlaceholder:
      "Currently, there are no providers in your area or none have capacity to accept patients",
    zeroPotentialReceiversPlaceholderAll: (args: {
      receiverTypePlural: string;
    }) =>
      template(
        "Currently, there are no {{receiverTypePlural}} in your area or none have capacity to accept patients.",
        args,
      ),
  },
  termsAndConditions: {
    accept: "I have read and agree to the {select:Terms and Conditions}.",
    agreedAt: (args: { date: string }) =>
      template("Agreed to on {{date}}", args),
    termsAndConditions: "Terms and conditions",
    titleOtherDocuments: "Other documents",
    uploadedOn: (args: { date: string }) =>
      template("Uploaded on {{date}}", args),
  },
  test: "test",
  timeline: {
    addedPatientWishReason: {
      deletedPatientWishReason: "The reason has been deleted.",
      myAccount: "You have updated the reason for the patient's wish",
      otherAccount: (args: { accountName: string }) =>
        template(
          "{{accountName}} has updated the reason for the patient wish",
          args,
        ),
    },
    anotherProviderSelected: "Another provider was found",
    anotherProviderWasSelected: "Another provider was selected",
    assigneeChanged: (args: {
      oldAssigneeName: string;
      newAssigneeName: string;
    }) =>
      template(
        "The case has been transferred from {{oldAssigneeName}} to {{newAssigneeName}}",
        args,
      ),
    auctionAllContacted: "All providers have been contacted",
    auctionCancelled: (args: { accountName: string }) =>
      template("Search was stopped by {{accountName}}", args),
    auctionPaused: {
      automatic: "Search was paused automatically",
      myAccount: "You paused the search",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} paused the search", args),
    },
    auctionStarted: (args: { accountName: string }) =>
      template("{{accountName}} started the search", args),
    auctionSuccess: "Receiver found",
    auctionSuccessProviderKnown: (args: {
      name: string;
      accountName: string;
    }) => template("{{name}} manually picked by {{accountName}}", args),
    auctionSuccessProviderUnknown:
      "Receiving institution found by other means, name is unknown",
    auctionSuccessUnknown: (args: { receiverTypeSingular: string }) =>
      template(
        "Found {{receiverTypeSingular}} in another way, name of receiver is unknown",
        args,
      ),
    auctionToBeStarted: (args: { accountName: string }) =>
      template("{{accountName}} handed over the search", args),
    auctionValidationCanceledUnknown: (args: { accountName: string }) =>
      template(
        "{{accountName}} cancelled the validation of unknown receiver",
        args,
      ),
    auctionValidationCanceledUnknownReason: (args: {
      accountName: string;
      reason: string;
    }) =>
      template(
        "{{accountName}} cancelled the validation of unknown receiver: {{reason}}",
        args,
      ),
    cancelledValidation: (args: {
      workerName: string;
      providerName: string;
      reason: string;
    }) =>
      template(
        "{{workerName}} cancelled the approval of {{providerName}}: {{reason}}",
        args,
      ),
    cancelRejection: (args: { accountName: string; careprovider: string }) =>
      template(
        "{{accountName}} cancelled the rejection of '{{careprovider}}'",
        args,
      ),
    capacityConfirmed: "Capacity was confirmed",
    capacityUpdated: "Capacity was updated ",
    careproviderCreated: "Careprovider created",
    careproviderOnboarding: (args: { name: string }) =>
      template("{{name}} being onboarded. No request has been sent.", args),
    careproviderOnboardingStatusChanged: (args: {
      prevStatus: string;
      newStatus: string;
    }) =>
      template(
        "Onboarding status changed from {select:{{prevStatus}}} to {select:{{newStatus}}}",
        args,
      ),
    careproviderStatusChanged: (args: {
      prevStatus: string;
      newStatus: string;
    }) =>
      template(
        "Status changed from {select:{{prevStatus}}} to {select:{{newStatus}}}",
        args,
      ),
    careproviderUpdated: "Careprovider updated",
    companyCreated: "Company created",
    companyResourceAdded: (args: { resource: string }) =>
      template("{{resource}} added to company", args),
    companyResourceRemoved: (args: { resource: string }) =>
      template("{{resource}} removed from company", args),
    companyStatusChange: (args: { newStatus: string }) =>
      template("Company status changed to {{newStatus}}", args),
    companyUpdated: "Company updated",
    costPayerChanged: "Cost payer was changed",
    documentationTitle: "Documentation of the patient's transfer",
    eligibilityApproved: (args: { accountName: string }) =>
      template(
        "The request for cost coverage was approved by {{accountName}}",
        args,
      ),
    eligibilityBackToNotProcessed: (args: { accountName: string }) =>
      template(
        "The submission of the request was canceled by {{accountName}}",
        args,
      ),
    eligibilityCancelled: (args: { accountName: string }) =>
      template(
        "The status of the cost coverage was reset by {{accountName}}",
        args,
      ),
    eligibilityInProcess: (args: { accountName: string }) =>
      template(
        "The request for cost coverage was sent by {{accountName}}",
        args,
      ),
    eligibilityRejected: (args: { accountName: string }) =>
      template(
        "The request for cost coverage was rejected by {{accountName}}",
        args,
      ),
    filesChanged: {
      fileAccessChangedMyAccount: (args: {
        previousFileShareMode: string;
        newFileShareMode: string;
      }) =>
        template(
          "You changed file access from {{previousFileShareMode}} to {{newFileShareMode}} ",
          args,
        ),
      fileAccessChangedOtherAccount: (args: { accountName: string }) =>
        template("{{accountName}} made file access changes", args),
      fileAccessRevokedMyAccount: (args: { name: string }) =>
        template("You revoked file access for {{name}}", args),
      fileAccessRevokedMyAccountMessenger: "You revoked file access",
      fileAccessRevokedOtherAccount: (args: { name: string }) =>
        template("{{name}} revoked file access", args),
      fileAccessRevokedOtherAccountMessenger: (args: { name: string }) =>
        template("{{name}} revoked file access", args),
      fileDeletedMyAccount: "You deleted a file ",
      fileDeletedOtherAccount: (args: { name: string }) =>
        template("{{name}} deleted a file", args),
      fileSharedMyAccount: (args: { name: string }) =>
        template("You shared a file with {{name}} ", args),
      fileSharedOtherAccount: (args: { accountName: string }) =>
        template("{{accountName}} shared a file", args),
      fileUploaded: "You uploaded a file",
    },
    formSentToInsurance: (args: {
      insuranceName: string;
      accountName: string;
    }) =>
      template(
        "The request for cost coverage was sent to {{insuranceName}} by {{accountName}}. ",
        args,
      ),
    formWithdrawn: (args: { accountName: string; withdrawReason: string }) =>
      template(
        "The request to cover the costs was withdrawn by {{accountName}}. Reason: {{withdrawReason}}.",
        args,
      ),
    insuranceAnswered: (args: { insuranceName: string }) =>
      template(
        "Answer received from the health insurance company {{insuranceName}}. ",
        args,
      ),
    mailFailure: "Email could not be sent",
    markedPatientPreferred: {
      myAccount: (args: {
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "You marked {{providerName}} as {{receiverTypeSingularPreferred}} of the patient",
          args,
        ),
      otherAccount: (args: {
        accountName: string;
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "{{accountName}} has marked {{providerName}} as {{receiverTypeSingularPreferred}} of the patient",
          args,
        ),
    },
    onboardingEmail: {
      notSpecified: "Type: not specified",
      prio: "Type: Prio",
      regular: "Type: Regular",
      reminder: "Type: Reminder",
    },
    onboardingEmailSent: "Onboarding email sent",
    patientArchived: "Patient archived",
    patientArchivedNotification:
      "Patient has been archived - you can find it under the archived tab or using the search bar.",
    patientCreated: "Patient created",
    patientDischarged: "Patient discharged",
    patientUpdated: {
      infoExcludedProviders: (args: { reason: string }) =>
        template(
          "Due to changes in {{reason}}, the list of providers was updated accordingly.",
          args,
        ),
      infoExcludedProvidersNoReason:
        "Due to changes the list of providers was updated accordingly.",
      myAccount: "You edited the profile.",
      otherAccount: (args: { accountName: string }) =>
        template("{{accountName}} has edited the profile.", args),
    },
    pushedToCallcenter: "Sent to Callcenter",
    rehaFormCreated: (args: { formName: string; accountName: string }) =>
      template("{{formName}} was created by {{accountName}}", args),
    rehaFormDeleted: (args: { formName: string; accountName: string }) =>
      template("{{formName}} was deleted by {{accountName}}", args),
    rehaFormUpdated: (args: { formName: string; accountName: string }) =>
      template("{{formName}} was edited by {{accountName}}", args),
    rehaFormUpdatedAlreadySent: (args: {
      formName: string;
      accountName: string;
    }) => template("{{formName}} was marked as sent by {{accountName}}", args),
    rehaFormUpdatedBackToDraft: (args: {
      formName: string;
      accountName: string;
    }) =>
      template("{{formName}} was moved back to draft by {{accountName}}", args),
    rehaFormUpdatedCompleted: (args: {
      formName: string;
      accountName: string;
    }) => template("{{formName}} was completed by {{accountName}}", args),
    removedPatientPreferred: {
      myAccount: (args: {
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "You de-selected {{providerName}} as {{receiverTypeSingularPreferred}} of the patient",
          args,
        ),
      otherAccount: (args: {
        accountName: string;
        providerName: string;
        receiverTypeSingularPreferred: string;
      }) =>
        template(
          "{{accountName}} has de-selected {{providerName}} as {{receiverTypeSingularPreferred}} of the patient",
          args,
        ),
    },
    requestAvailable: "This request is available again",
    requestDismissed: (args: { accountName: string; careprovider: string }) =>
      template(
        "{{accountName}} dismissed the suggestion of '{{careprovider}}'",
        args,
      ),
    requestManuallyAdded: (args: {
      socialWorker: string;
      careprovider: string;
    }) =>
      template("{{socialWorker}} sent the request to '{{careprovider}}'", args),
    requestResent: (args: { careprovider: string }) =>
      template("Request resent to {{careprovider}}", args),
    requestToBeSent: (args: { accountName: string; careprovider: string }) =>
      template("{{accountName}} suggested '{{careprovider}}' to review", args),
    requestUnavailable: "This request is not available anymore",
    requestUnavailableProvider: "This request is not available anymore",
    senderCreated: "Sender clinic created",
    senderUpdated: "Sender profile updated",
    smsSent: "SMS sent",
    statusChanged: (args: { prevStatus: string; newStatus: string }) =>
      template(
        "Status changed from {select:{{prevStatus}}} to {select:{{newStatus}}}",
        args,
      ),
    suggestionCancelled: (args: {
      accountName: string;
      careprovider: string;
    }) =>
      template(
        "{{accountName}} cancelled the suggestion of {{careprovider}}",
        args,
      ),
    testAnswered: "Test successful",
    testSent: "Test Sent",
    title: "Documentation",
    transitionalCareCreated: (args: { accountName: string }) =>
      template(
        "Documentation for transitional care was created by {{accountName}}",
        args,
      ),
    transitionalCareDeleted: (args: { accountName: string }) =>
      template(
        "Documentation for transitional care was deleted by {{accountName}}",
        args,
      ),
    transitionalCareUpdated: (args: { accountName: string }) =>
      template(
        "Documentation for transitional care was edited by {{accountName}}",
        args,
      ),
    you: "you",
  },
  todo: {
    abbreviatedCareLevel: "CL:",
    archived: "Archived",
    cards: {
      answer: "Answer",
      continueEditing: "Continue editing",
      continueSearch: "Continue search",
      insuranceChip: "INS",
      internalId: "Internal ID",
      newMessage: "new Message",
      prereserve: "Pre-reserve",
      restartSearch: "Restart search",
      sendRequests: "Send requests",
      startSearch: "Start the search",
      stopSearch: "Stop the search",
      tooltipFormShared: "AHB form has been shared",
      tooltipInsuranceChip:
        "is part of Recare and takes over the search for you",
      updateProfile: "Update profile",
    },
    categoryElective: "WAITING",
    categoryFinished: "Finished",
    categoryPatients: "Active",
    discharge: "Start information",
    dischargeDate: "Discharge date",
    drafts: "Drafts",
    filteredBy: "Filtered by",
    finishedHint:
      "Please note, stopped searches older than 10 days and validated searches older than 30 days can be found under the archived tab.",
    loadingError: "Impossible to refresh todo data",
    noRequestFilterResults:
      "You have filters set up, please clear filters to see all your requests",
    noRequests: "There is nothing to display",
    patient: "Patient",
    reenterDataPostIncident: {
      body: 'After a routine system upgrade the Recare database encountered an outage for a prolonged period. As a consequence, all actions undertaken on the platform from 19:30 on 29.11.2023 until 12:20 on 30.11.2023 have to be repeated. This includes any patients you may have created or any data you may have imported from the HIS. More information can be found in the "Recare news" section.',
      title: "Data entry required",
    },
    refreshApp:
      "Please refresh the page to use the most recent Recare version.",
    responsiblePerson: "Person responsible",
    running: "Running",
    startDate: "Start date",
    stopped: "Stopped",
    stoppedHint:
      "Please note, stopped searches older than 10 days can be found under the archived tab.",
    titleNavBar: "Searches",
    transfer: "Transfer",
    validated: "Validated",
    validatedHint:
      "Please note, validated searches older than 30 days can be found under the archived tab.",
  },
  tours: {
    accessPatientData: {
      stepA: {
        text: "See directly whether you have already granted the provider access to the patient data. You can decide at any time when in the process you grant access to the provider.",
        title: "Keep an overview",
      },
      stepB: {
        text: 'With a click on "Grant access to patient data" you receive a detailed overview of all data to be shared as usual and can also cancel the process again if desired. You do not have to grant access to the data until the final validation.',
        title: "Grant access to patient data",
      },
      stepC: {
        text: 'The sharing of patient data is displayed in the chat history. The complete overview of which patient data you have shared with which provider can be found, as you are used to, in the "Documentation" tab.',
        title: "Track changes",
      },
    },
    availableFilesToShare: {
      stepA: {
        intro: "Click here to share files or change access rights",
        title: "File sharing",
      },
    },
    files: {
      backButton: "Back",
      doneButton: "Done",
      nextButton: "Next",
      stepA: {
        intro: "Secure PDF document sharing (20 MB file size limit)",
        title: "Available now:",
      },
      stepB: {
        intro:
          "Upload a file and allocate it to a category to make it easier for the receiver to determine what you sent (if you are unsure select “other”).",
        title: "File uploading",
      },
      stepC: {
        intro: "Learn more about the functionality and how to use it.",
        title: "Instructions",
      },
      stepD: {
        intro:
          "File related actions will be recorded in the documentation tab for audit purposes.",
        title: "Documentation",
      },
      stepE: {
        intro:
          "If you would like to share a document with selected providers only rather than all, click on a receiver you would like to share a document with and use the file tab in the messenger for sharing.",
        title: "Sharing with selected providers",
      },
    },
    parallelSearch: {
      stepA: {
        intro:
          "Start multiple searches in parallel with each other and find a provider for your patient even faster. For the modules care, rehab, and hospital, we support one search per patient.",
        title: "Available now:",
      },
      stepB: {
        intro:
          "Combine searches for care, rehab, or hospital transfers by adding another search to an existing search. For example, you can now search for a short-term care provider in parallel to acute geriatrics.",
        title: "Combine different searches for your patients",
      },
    },
    receiverDashboard: {
      stepA: {
        intro:
          "Using the filter function, you can specifically sort requests according to whether they match your capacities and circumstances. For example, you can filter by care level, start date or gender.",
        title: "Use the filter function:",
      },
      stepB: {
        intro:
          'If you tick this box, you can select "one" or "all" requests and reject them collectively without giving a reason. Rejections are also important, as this lets hospitals know that you have seen the requests but that there is no capacity available.',
        title: "Handle requests:",
      },
      stepC: {
        intro:
          'Sometimes the conditions change after acceptances or rejections. Don\'t worry: You can withdraw your acceptances under the menu item "In process" and you can cancel your rejections at any time under the menu item "Other".',
        title: "Change response:",
      },
      stepD: {
        intro:
          'Please enter your availability here on a regular basis to enable effective use of the Recare platform for both you and the clinics. If you indicate that you have no availability, you will not receive any new requests until the date specified. An exception to this are the search requests that you have created under "Search".',
        title: "Set capacities:",
      },
      stepThree: {
        intro:
          'Are you full except for one place and only want to receive matching enquiries? Under the menu item "Search" you can create a patient search for specific criteria and will be informed when a corresponding enquiry is received.',
        title: "Optimise utilisation:",
      },
    },
    receiverSettings: {
      stepA: {
        imagePath: "product-tour-images/receiver_settings_services_en.png",
        intro:
          "You can freely determine from which radius you would like to receive requests from hospitals. Alternatively, you can select individual postcodes from which you would like to receive requests.",
        title: "Set request radius:",
      },
      stepB: {
        imagePath:
          "product-tour-images/receiver_settings_reverse_search_en.png",
        intro:
          "Be proactive. With this feature, you specifically search for patients who fit your capacities or circumstances and thus plan your optimal utilisation. Of course, you will continue to receive parallel requests from hospitals.",
        title: "Search actively:",
      },
      stepC: {
        imagePath: "product-tour-images/receiver_settings_capacity_en.png",
        intro:
          "At this point you can change your capacity settings to receive only those requests that you have specified in the active patient search.",
        title: "Set capacities:",
      },
    },
    reverseSearch: {
      backButton: "Back",
      doneButton: "Done",
      nextButton: "Next",
      stepA: {
        intro: "Start a targeted patient search based on your capacity.",
        title: "Available capacities?",
      },
      stepB: {
        intro: "Targeted patient search based on your capacities.",
        title: "Available now",
      },
      stepC: {
        intro:
          "Design your search flexibly according to your wishes. You can start your search with just one criterion.",
        title: "Set search criteria",
      },
      stepD: {
        intro:
          'Click on "Save" to start your search. You will receive an email, once a patient matching your criteria is found.',
        title: "Start search",
      },
      stepE: {
        intro:
          "Start multiple searches if you have capacity for different patients.",
        introCapacityMode:
          "Start multiple searches if you have capacity for different patients. You will receive requests for patients matching your criteria, even if your overall capacity is turned off.",
        title: "Parallel Search",
      },
      stepF: {
        intro:
          "Start multiple searches if you have capacity for different patients.",
        introCapacityMode:
          "Start multiple searches if you have capacity for different patients. You will receive requests for patients matching your criteria, even if your overall capacity is turned off.",
        title: "Parallel Search",
      },
      stepG: {
        intro:
          'Click on "Add new search" to create an additional, parallel patient search with new criteria. Add at least one criterion to the search.',
        title: "Start an additional search",
      },
    },
    searchMerge: {
      stepA: {
        intro: "All information about your search at a glance",
        title: "Now even more intuitive:",
      },
      stepB: {
        intro:
          'Click on "Contact" to contact all potential providers. You only want to contact certain providers? Then first click on the blue checkbox to deselect them and then select the desired providers individually. Finally, click on "Contact".',
        title: "Start search:",
      },
      stepC: {
        intro:
          "Submit the patient wish by clicking on the star next to the relevant providers. Optionally, you can also specify a reason for the wish. The corresponding provider will be informed.",
        title: "Submit patient wish:",
      },
      stepD: {
        intro:
          "Click on a row to view the respective search and to communicate with the provider via chat.",
        title: "View details:",
      },
    },
    searchMergeContacted: {
      stepA: {
        intro:
          "In this table you can see all the providers that have already been contacted. Click on a row to view the search request.",
        title: "Monitor your search:",
      },
      stepB: {
        intro:
          "Submit the patient's wishes now also for already contacted providers. To do this, click on the star next to the name of the provider and optionally enter a reason. The provider will be informed about this.",
        title: "Consider patient wishes:",
      },
      stepC: {
        intro:
          "The status column informs you about news related to a request, such as an unread chat message or a received document. The latest event will be displayed. ",
        title: "See important news:",
      },
    },
    transitionalCare: {
      stepA: {
        intro:
          'Save time and increase your data quality by importing existing patient data and updating it if necessary. Then complete the form and import up to 20 of the previously contacted providers in section e) using the "Import providers from Recare" button.',
        title: "Import data",
      },
      stepB: {
        intro:
          "The last step is to save the documentation status and create a PDF in accordance with the health insurance company's specifications. The contact attempts with providers are detailed and documented with date and time.",
        title: "Save and create PDF",
      },
    },
  },
  userManagement: {
    accountEdit: "Edit Account",
    accountInputLabel: "Account",
    accountReactivate: "Reactivate Account",
    activationEmailFail:
      "The activation email could not be resent, please try again later.",
    activationEmailSuccess: "The activation email was successfully resent.",
    addAccountFlow: {
      accountEditPreTitle: "This user already exists in our system.",
      accountEditSubTitleCareseeker:
        "The information displayed in these fields is accessible to all clinics connected to this user account. If you make changes, they will be applied to all clinics.",
      backButton: "Back",
      cancelButton: "Cancel",
      confirmButton: "Confirm",
      createButton: "Create",
      emailAddress: "E-mail address",
      mandatoryFieldEmail: "Please enter an e-mail address",
      mandatoryFieldFirstName: "Please enter the first name",
      mandatoryFieldLastName: "Please enter the last name",
      mandatoryFieldRole: "Please select at least one role and permission",
      rolesPermissionTitle: (args: { clinicName: string }) =>
        template(
          "Set roles and permissions for this user in {{clinicName}}",
          args,
        ),
      rolesSectionTitle: "Clinic specific information",
      stepOneSubtitle:
        "Please enter the email address of the user you want to add to this system.",
      stepOneTitle: "Add Account",
    },
    confirmationModalActivationMail:
      "Are you sure that you want to resend the activation email?",
    confirmationModalDeactivateText:
      'Deactivated accounts can be reactivated by clicking "reactivate" in the menu.',
    confirmationModalDeactivateTextSSO:
      "Deactivated accounts can be reactivated by creating a new account in your internal idp.",
    confirmationModalDeactivateTitle:
      "Do you really want to deactivate this account?",
    confirmationModalNormalAccount:
      "Managing the users of this sender should be done by the assigned user manager. Are you sure you want to add new user accounts to this sender?",
    confirmationModalPasswordReset:
      "Are you sure that you want to send the password reset email?",
    confirmationModalReactivateText:
      "You can deactivate reactivated accounts again by clicking on Deactivate.",
    confirmationModalReactivateTitle:
      "Are you sure you want to reactivate this account?",
    confirmationModalResetUserManager:
      "Are you sure you want to reset the user manager role for this account? The uploaded form is deleted and must be uploaded again to assign the user manager role to another account ",
    confirmationModalSetUserManager:
      "Are you sure you want to assign the User Manager role to this account?",
    deactivateButton: "Deactivate",
    deactivationDisabledHover:
      'This is the only active user with the role "User Management". To deactivate this user, at least one other active user of this company needs to have user management permissions.',
    deactivationFail:
      "The account could not be deactivated, please try again later.",
    deactivationSuccess: "The account has been successfully deactivated.",
    email: "Email",
    errorAccountOutsideGroup:
      "The account you are trying to add or edit already exists in another hospital outside your clinic group. Please contact your recare contact person to add or edit this account.",
    errorDomainNotWhitelisted:
      "The domain you entered is not included in the list of whitelisted domains for your company.\nPlease make sure that the spelling is correct. If the problem persists, please contact our support team.",
    errorEmailAlreadyExists: (args: { emailAddress: string }) =>
      template(
        "There is already an account associated with the email address {{emailAddress}}.",
        args,
      ),
    fileInputLabel: "Signed Agreement",
    informationBanner:
      'Here you can manage your users independently and keep track of existing accounts. Click on "Add account" to add new accounts and define the desired roles and authorizations for them by selecting or deselecting the respective modules. You can edit existing accounts by selecting "Edit" in the line of the respective account on the right in the menu with the three dots. No longer need an account? Simply click on "Deactivate". Would you like to undo this? Then click on "Reactivate". Please note that the number of accounts you can add, deactivate and reactivate per day is limited.',
    informationBannerShowMore:
      'Status:\nExisting accounts are always assigned one of the following three statuses:\nActive: Accounts with this status have already logged in to the platform at least once and, depending on their assigned roles and permissions, can use certain functionalities.\nCreated: This status indicates that the account has been created and an activation email has been sent. However, the user has not logged in yet and therefore has not finally activated the account.\nInactive: Accounts with this status have been deactivated. The user can no longer log in to the platform. By reactivating the account, an activation email is sent again and the user receives the status "Created".',
    informationBannerSSO:
      "Here you have an overview of all accounts and can keep an eye on which roles and authorizations are assigned to the respective accounts.  No longer need an account? Simply click on “Deactivate”. \nYour accounts are created and edited via your internal idp. ",
    infoSection: {
      infoSectionFiveText:
        'Accounts with this status have been deactivated. Users can no longer log in to the platform. Reactivating the account sends an activation email again and gives the user the status "Created".',
      infoSectionFiveTitle: "Inactive:",
      infoSectionFourText:
        'Accounts with this state have been deactivated and it is no longer possible to log in to the platform. Each account can be reactivated by clicking on "Reactivate". In this case, an activation email is sent again and the account is given the state "Created" again.',
      infoSectionFourTitle: "Inactive:",
      infoSectionOneText:
        "Existing accounts always have one of the following three states:",
      infoSectionOneTitle: "Status",
      infoSectionThreeText:
        "In this state, the account has been created and an activation email has been sent, but the registration has not been completed. The account is therefore not yet activated. If required, the activation email can be sent again in the menu of the respective account.",
      infoSectionThreeTitle: "Created:",
      infoSectionTwoText:
        "Only in this state is it possible to use the functionalities within the scope of the individually assigned roles and permissions. An account becomes active when you register on the platform.",
      infoSectionTwoTitle: "Active:",
    },
    infoSectionOneText:
      'Existing accounts are always assigned one of the following three statuses:\n\nActive: Accounts with this status have already logged in to the platform at least once and can use certain functionalities, depending on their assigned roles and permissions.\n\nCreated: This status indicates that the account has been created and an activation email has been sent. However, the user has not logged in yet and therefore has not finally activated the account.\n\nInactive: Accounts with this status have been deactivated. Users can no longer log in to the platform. By reactivating the account, an activation email is sent again and the user receives the status "Created".',
    infoSectionOneTitle: "Status",
    infoSectionTitle: "Accounts Overview",
    limitCreationInfo:
      "You have reached the daily limit for creating new accounts. Please try again in 24 hours.",
    limitDeactivationInfo:
      "You have reached the daily limit for deactivating accounts. Please try again in 24 hours.",
    limitReactivationInfo:
      "You have reached the daily limit for reactivating accounts. Please try again in 24 hours.",
    mandatoryFieldInformation: "* marks a mandatory field.",
    name: "Name",
    noAccessInfo: "You don’t have access to the page.",
    numberAccounts: (args: {
      numbersearchresults: string;
      numberexistingaccounts: string;
    }) =>
      template("{{numbersearchresults}} of {{numberexistingaccounts}}", args),
    passwordEmailFail:
      "The password reset email could not be sent, please try again later.",
    passwordEmailSuccess: "The password reset email was sent successfully.",
    permissions: "Permissions",
    reactivateButton: "Reactivate",
    reactivationFail:
      "The account could not be reactivated, please try again later.",
    reactivationSuccess: "The account has been successfully reactivated.",
    resendActicationMailButton: "Resend activation email",
    resetPasswordButton: "Reset password email",
    roles: "Roles",
    searchFieldPlaceholder: "Search by name or e-mail address:",
    searchNoResult:
      "No account with this name or e-mail address could be found. Please check your entry or add a new account.",
    status: "Status",
    tableDescription: "Table description",
    tableTitle: "Accounts",
    title: "User Management",
    userManagementReceiver: {
      description:
        "The information displayed in these fields is accessible to all providers that this account is connected to.",
      editableRoles: "Set user as contact information for the sender",
      rolesModalTitel: "Provider specific roles",
      viewOnlyRoles: "Roles set for this user",
    },
  },
  weekdays: {
    everyday: "Everyday",
    friday: "Friday",
    monday: "Monday",
    saturday: "Saturday",
    sunday: "Sunday",
    thursday: "Thursday",
    timeFormat: "hh:mm",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    weekdays: "Weekdays",
    weekends: "Weekends",
  },
} as const;

export default en;
