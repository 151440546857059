import { GRANT_STATUS_NOT_APPLIED } from "core/consts";
import { GetOntologyType } from "core/types";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import { margin } from "ds_legacy/materials/metrics";
import { Body } from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { EmptiableField } from "dsl/organisms/Infos/PatientInformation/shared";
import { useTranslations } from "translations";
import Translations from "translations/types";

type GrantValue = boolean | number | string;

const isGrantChecked = (value: GrantValue | null | undefined): boolean =>
  (typeof value === "number" && value > 0) ||
  (typeof value === "string" && value.length > 0) ||
  value === true;

const labelMaker = (
  label: string,
  value: GrantValue | null | undefined,
  getOntology: GetOntologyType,
  translations: Translations,
  hasNotApplied?: boolean,
) => {
  if (!value) return null;
  switch (typeof value) {
    case "string":
      break;
    case "boolean":
      value = "";
      break;
    case "number":
      if (value == GRANT_STATUS_NOT_APPLIED && !hasNotApplied) {
        value = "";
      } else {
        value = `${translations.general.colon} ${getOntology({
          type: "grantStatus",
          key: Number(value),
        })}`;
      }
      break;
    default:
  }

  return `${label}${value}`;
};

export default function GrantStatusField({
  getOntology,
  hasNotApplied,
  label,
  oldValue,
  testId,
  value,
  withDiff,
}: {
  getOntology: GetOntologyType;
  hasNotApplied?: boolean;
  label: string;
  oldValue?: GrantValue | null | undefined;
  testId: string;
  value: GrantValue | null | undefined;
  withDiff?: boolean;
}) {
  const isChecked = isGrantChecked(value);
  const print = usePrint();
  const translations = useTranslations();

  const newLabel = labelMaker(
    label,
    value,
    getOntology,
    translations,
    hasNotApplied,
  );
  const oldLabel = labelMaker(
    label,
    oldValue,
    getOntology,
    translations,
    hasNotApplied,
  );

  if (print) {
    if (!isChecked) return null;
    return <Body data-testid={testId}>{newLabel}</Body>;
  }
  return isChecked || (withDiff && isGrantChecked(oldValue)) ? (
    <VerticalLayout margin={margin(0)}>
      <EmptiableField
        testId={testId}
        value={newLabel}
        oldValue={oldLabel}
        withDiff={withDiff}
      />
    </VerticalLayout>
  ) : null;
}
