import { useTheme } from "@mui/material/styles";
import { SEARCH_TYPE_CARE } from "core/consts";
import { getName } from "core/model/accounts";
import { formatDistance } from "core/model/utils/location";
import { hasMobile } from "core/model/utils/services";
import { concatStrings } from "core/model/utils/strings";
import {
  Account,
  Auction,
  AuctionRequest,
  Careseeker as CareseekerType,
  GetOntologyType,
  SocialWorker as SocialWorkerType,
  Station as StationType,
} from "core/types";
import { Banner } from "ds/ui";
import {
  Card,
  CardContentContainer,
  PaperHeader,
} from "ds_legacy/components/InfoCard";
import {
  BLACK,
  BRAND_PRIMARY_MAIN_COLOR,
  WHITE,
} from "ds_legacy/materials/colors";
import {
  Divider,
  HorizontalLayout,
  ResponsiveLayout,
  VerticalLayout,
} from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_14,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_MEDIUM,
  Link,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import PhoneNumber from "dsl/atoms/PhoneNumber";
import { PreferredProviderBanner } from "dsl/molecules/Messenger/Forms/shared";
import Chips from "dsl/organisms/PatientMenu/Chips";
import {
  FieldsHolderType,
  extractPatientFields,
  extractPatientTypeOfTransportFields,
} from "dsl/organisms/PatientMenu/transform";
import cloneDeep from "lodash/cloneDeep";
import { CornerUpRightIcon } from "lucide-react";
import { ReactNode } from "react";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { RightOrnament } from "./ReceiverHeaderOrnament";
import TransportInfoCard from "./TransportInfoCard";
import { CategoryContainer, EmptiableField, StringField } from "./shared";

const OverviewCardChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${dp(-4)};
`;

const EmailLabel = styled.span`
  white-space: nowrap;
  padding: ${padding(0.25)};
`;

function getModAuction(
  auction: Auction | null | undefined,
  solutions: number[],
) {
  const modAuction = cloneDeep(auction);
  if (modAuction) modAuction.solutions = solutions;
  return (modAuction || { patient: {} }) as Auction;
}

function generateChipsValue({
  auction,
  fields,
  noServices,
}: {
  auction: Auction | null | undefined;
  fields: FieldsHolderType | null | undefined;
  noServices?: boolean;
}) {
  return {
    fields,
    services: noServices ? [] : auction?.services || [],
  };
}

function formatDistanceForZipCodeMessage(
  distance: number | null | undefined,
  translations: Translations,
): string {
  if (!distance || distance <= 0) {
    return "";
  }
  return distance > 0
    ? translations.patient.distanceFromYou({
        distance: formatDistance(distance),
      })
    : "";
}

function useGetBackgroundColor({
  isProviderSearchRequest,
  requestAvailable,
}: {
  isProviderSearchRequest: boolean;
  requestAvailable: boolean;
}) {
  const theme = useTheme();
  if (requestAvailable) {
    return isProviderSearchRequest
      ? BRAND_PRIMARY_MAIN_COLOR
      : theme.palette.primary.main;
  } else {
    return undefined;
  }
}

export const ProviderSearchHeader = ({
  print,
  requestAvailable,
}: {
  print?: boolean;
  requestAvailable?: boolean;
}) => {
  const translations = useTranslations();
  return (
    <HorizontalLayout
      data-testid="direct-request-header"
      aligned
      margin={print ? margin(2, 2) : margin(0, 2)}
    >
      <CornerUpRightIcon
        style={{ color: print || !requestAvailable ? BLACK : WHITE }}
        size={14}
      />
      <Body
        fontSize={FONT_SIZE_14}
        color={print || !requestAvailable ? BLACK : WHITE}
        margin={margin(0, 1)}
        fontWeight={FONT_WEIGHT_MEDIUM}
      >
        {translations.careproviderApp.dashboard.referrerDirect} &#183;{" "}
        {translations.auctionRequest.bcpName}
      </Body>
    </HorizontalLayout>
  );
};

function AuctionRequestInfoCard({
  auction,
  auctionRequest,
  children,
  requestAvailable,
}: {
  auction: Auction;
  auctionRequest: AuctionRequest;
  children: ReactNode;
  requestAvailable: boolean;
}) {
  const translations = useTranslations();
  const isProviderSearchRequest = !!auctionRequest?.is_provider_search_request;
  const backgroundColor = useGetBackgroundColor({
    requestAvailable,
    isProviderSearchRequest,
  });

  return (
    <Card margin={margin(0, 0, 2, 0)}>
      <PaperHeader
        backgroundColor={backgroundColor}
        padding={padding(1, 3)}
        flexWrap
      >
        <VerticalLayout margin={margin(isProviderSearchRequest ? 0 : 1, 0)}>
          <Title
            bold
            light={!!requestAvailable}
            margin={isProviderSearchRequest ? margin(0, 2, 1.5) : margin(0, 2)}
            style={
              isProviderSearchRequest ? { fontWeight: FONT_WEIGHT_BOLD } : {}
            }
          >
            {isProviderSearchRequest
              ? translations.auctionRequest.bcpProfileName
              : translations.patient.patient}{" "}
            {auction?.patient?.user_id || ""}
          </Title>
          {isProviderSearchRequest && (
            <ProviderSearchHeader requestAvailable={requestAvailable} />
          )}
        </VerticalLayout>
        <RightOrnament
          auctionRequest={auctionRequest}
          requestAvailable={requestAvailable}
        />
      </PaperHeader>
      {children}
    </Card>
  );
}

export function ChipInfo({
  auction,
  distance = 0,
  getOntology,
  oldAuction,
  requestAvailable = true,
  requestSolutions,
  translations,
}: {
  auction: Auction | undefined;
  distance?: number | null;
  getOntology: GetOntologyType;
  oldAuction: Auction | null | undefined;
  requestAvailable?: boolean | null;
  requestSolutions: number[];
  translations: Translations;
}) {
  const withDiff = oldAuction != null;

  const modAuction = getModAuction(auction, requestSolutions);
  const modOldAuction = getModAuction(oldAuction, requestSolutions);
  const distanceForZipCode =
    requestSolutions?.length && hasMobile(requestSolutions)
      ? formatDistanceForZipCodeMessage(distance, translations)
      : "";

  return (
    <>
      <HorizontalLayout
        flexWrap="wrap"
        margin={margin(0, 0, 0, 0)}
        padding={padding(1, 5)}
      >
        <div
          data-testid="overview-card-chip-info"
          style={{ minWidth: "50%", margin: margin(0, 2, 0, 0) }}
        >
          <HorizontalLayout aligned>
            <Subheading margin={margin(0, 0, 0, 0)} bold>
              {translations.auctionRequest.typeOfTransfer}
            </Subheading>
          </HorizontalLayout>

          <HorizontalLayout flexWrap="wrap">
            <OverviewCardChipsContainer>
              <Chips
                getOntology={getOntology}
                value={generateChipsValue({
                  fields: extractPatientTypeOfTransportFields(modAuction),
                  auction: modAuction,
                  noServices: true,
                })}
                oldValue={generateChipsValue({
                  fields: extractPatientTypeOfTransportFields(modOldAuction),
                  auction: modOldAuction,
                  noServices: true,
                })}
                withDiff={withDiff}
                disabled={!requestAvailable}
                translations={translations}
              />
            </OverviewCardChipsContainer>
          </HorizontalLayout>
        </div>
      </HorizontalLayout>
      <Divider />
      <HorizontalLayout flexWrap="wrap" padding={padding(1, 5)}>
        <Subheading fullWidth margin={margin(0, 0, 0, 0)} bold>
          {translations.auctionRequest.mostImportantTransferDetails}
        </Subheading>
        {auction?.country_wide_search && (
          <div className="my-2 w-full">
            <Banner
              message={translations.auctionRequest.germanyWideSearchWarning}
              color="primary"
            />
          </div>
        )}
        <OverviewCardChipsContainer>
          <Chips
            getOntology={getOntology}
            value={generateChipsValue({
              fields: extractPatientFields(
                modAuction,
                ["solutions", "specializations"],
                { distanceForZipCode },
              ),
              auction: modAuction,
            })}
            oldValue={generateChipsValue({
              fields: extractPatientFields(
                modOldAuction,
                ["solutions", "specializations"],
                { distanceForZipCode },
              ),
              auction: modOldAuction,
            })}
            withDiff={withDiff}
            disabled={!requestAvailable}
            translations={translations}
          />
        </OverviewCardChipsContainer>
      </HorizontalLayout>
    </>
  );
}

const SocialWorkerContactSection = ({
  getOntology,
  social_worker,
  translations,
}: {
  getOntology: GetOntologyType;
  social_worker: SocialWorkerType;
  translations: Translations;
}) => {
  return (
    <CategoryContainer>
      <StringField
        testId="contact_person_in_hospital"
        value={getName(social_worker, getOntology)}
        prefix={translations.careproviderProfile.contactPersonInHospital}
      />
      <PhoneNumber
        phone={social_worker.phone}
        prefix={translations.people.tel}
      />
      <PhoneNumber
        phone={social_worker.mobile_phone}
        prefix={translations.people.mobile}
      />
      {social_worker.email && (
        <Body display="flex">
          <EmailLabel>{translations.people.mailShort}</EmailLabel>
          <Link
            primary
            href={`mailto:${social_worker.email}`}
            style={{ wordBreak: "break-all" }}
          >
            {social_worker.email}
          </Link>
        </Body>
      )}
    </CategoryContainer>
  );
};

export function HospitalContact({
  auction,
  careseeker,
  getOntology,
  oldAuction,
  social_worker,
  station,
  translations,
  withDiff,
}: {
  auction: Auction;
  careseeker: CareseekerType;
  getOntology: GetOntologyType;
  oldAuction: Auction | null | undefined;
  social_worker: SocialWorkerType;
  station: StationType | null | undefined;
  translations: Translations;
  withDiff?: boolean;
}) {
  const hospitalStay = {
    new: auction.patient.diagnosis?.hospital_stay,
    old: oldAuction?.patient.diagnosis?.hospital_stay,
  };
  const isCare = auction.search_type === SEARCH_TYPE_CARE;

  return (
    <>
      <Subheading bold>
        {translations.careproviderProfile.clinicContactInformation({
          careseekerName: careseeker.name || "",
        })}
      </Subheading>
      <ResponsiveLayout>
        <SocialWorkerContactSection
          social_worker={social_worker}
          translations={translations}
          getOntology={getOntology}
        />
        <CategoryContainer>
          <StringField
            testId="station_name"
            value={
              station?.name
                ? concatStrings(station.name, station.description)
                : null
            }
            prefix={translations.patient.station}
          />
          <StringField
            testId="station_phone_number"
            value={station?.phone_number}
            prefix={translations.patient.stationPhoneNumber}
          />
          <EmptiableField
            title={translations.patient.doctorInCharge}
            value={hospitalStay.new?.doctor_in_charge_in_hospital}
            oldValue={hospitalStay.old?.doctor_in_charge_in_hospital}
            withDiff={withDiff}
            noEmptyValue
            testId="doctor_in_charge_in_hospital"
          />
          {!isCare && (
            <EmptiableField
              title={translations.people.tel}
              value={hospitalStay.new?.doctor_in_charge_in_hospital_phone}
              oldValue={hospitalStay.old?.doctor_in_charge_in_hospital_phone}
              withDiff={withDiff}
              noEmptyValue
              testId="doctor_in_charge_in_hospital_phone"
            />
          )}
        </CategoryContainer>
      </ResponsiveLayout>
    </>
  );
}

function RequestUnavailableBanner({
  translations,
}: {
  translations: Translations;
}) {
  return (
    <Banner
      color="primary"
      message={translations.auctionRequest.patientNoLongerAvailable}
    />
  );
}

function PatientDiffBanner({ translations }: { translations: Translations }) {
  return (
    <div className="fixed left-1/2 top-[52px] w-[400px] -translate-x-1/2 transform tablet:w-[600px]">
      <Banner
        withClose
        message={translations.auctionRequest.patientUpdatedExplanation}
        color="warning"
      />
    </div>
  );
}

export default function OverviewCard({
  auction,
  auctionRequest,
  getOntology,
  oldAuction,
  requestAvailable,
}: {
  auction: Auction;
  auctionRequest: AuctionRequest | null | undefined;
  getOntology: GetOntologyType;
  oldAuction: Auction | null | undefined;
  requestAvailable: boolean | null | undefined;
}) {
  const translations = useTranslations();
  const withDiff = oldAuction != null;

  if (!auctionRequest?.solutions?.length) {
    return null;
  }

  const isProviderSearchRequest = !!auctionRequest?.is_provider_search_request;

  const hasSocialWorkerAndCareseeker =
    !!auction?.patient?.social_worker && !!auction?.patient.careseeker;

  return (
    <AuctionRequestInfoCard
      auction={auction}
      auctionRequest={auctionRequest}
      requestAvailable={!!requestAvailable}
    >
      <PreferredProviderBanner auctionRequest={auctionRequest} />
      {withDiff && <PatientDiffBanner translations={translations} />}
      <CardContentContainer noPadding>
        {!requestAvailable && (
          <RequestUnavailableBanner translations={translations} />
        )}
        <VerticalLayout minHeight={dp(84)}>
          <ChipInfo
            getOntology={getOntology}
            requestSolutions={auctionRequest.solutions}
            requestAvailable={requestAvailable}
            distance={auctionRequest?.distance}
            auction={auction}
            oldAuction={oldAuction}
            translations={translations}
          />
        </VerticalLayout>
        {!isProviderSearchRequest && (
          <>
            <Divider />
            <VerticalLayout padding={padding(1, 3)}>
              {hasSocialWorkerAndCareseeker && (
                <HospitalContact
                  getOntology={getOntology}
                  station={auction?.patient.station_full}
                  auction={auction}
                  oldAuction={oldAuction}
                  careseeker={auction.patient.careseeker as CareseekerType}
                  social_worker={auction.patient.social_worker as Account}
                  translations={translations}
                  withDiff={withDiff}
                />
              )}
              <TransportInfoCard
                auction={auction}
                forCareprovider
                getOntology={getOntology}
              />
            </VerticalLayout>
          </>
        )}
      </CardContentContainer>
    </AuctionRequestInfoCard>
  );
}
