import { ASSESSMENT_SLUG_FIRST_STEP, TRACK_EVENTS } from "core/consts";
import { getAuctionStatusString } from "core/model/auctions";
import { hasMobile } from "core/model/utils/services";
import {
  AssessmentSlug,
  Auction,
  AuctionRequest,
  GetOntologyType,
} from "core/types";
import { EditPatientButtonSchema } from "core/validationSchemas/tracking";
import RSButton from "ds_legacy/components/RSButton";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin } from "ds_legacy/materials/metrics";
import { Title } from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import PrintButton, {
  openPrintPage,
  usePreventPrintMenu,
} from "dsl/atoms/PrintButton";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useCareseekerNavigationHandlers } from "dsl/hooks";
import { useScrollToElement } from "dsl/hooks/useScrollTo";
import {
  PrintMessengerFab,
  PrintParams,
} from "dsl/molecules/Messenger/PrintMessenger";
import { ActionBanner, InfosBox } from "dsl/organisms/Infos/shared";
import { PencilIcon, PrinterIcon } from "lucide-react";
import React, { useContext } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import CareNeedsCard from "./CareNeedsCard";
import InformationCard from "./InformationCard";
import MedicalCard from "./MedicalCard";
import MedicalSuppliesSearchCard from "./MedicalSuppliesSearchCard";
import OverviewCard from "./OverviewCard";
import PatientInfoMissing from "./PatientInfoMissing";
import TransportInfoCard from "./TransportInfoCard";
import TransportSearchCard from "./TransportSearchCard";
import TreatmentLocationCard from "./TreatmentLocationCard";
import { CareproviderInfoCard, ClinicInfoCard } from "./shared";

type PatintInfoContextType = {
  Card: ({ children }: ToType) => JSX.Element;
  auction: Auction | null;
  isClinicApp: boolean;
  withEdit: boolean;
};

export const PatientInfoContext = React.createContext<PatintInfoContextType>({
  Card: function Card({ children }: ToType) {
    return <>{children}</>;
  },
  withEdit: false,
  isClinicApp: false,
  auction: null,
});

export function usePatientInfoContext(): PatintInfoContextType {
  return useContext(PatientInfoContext);
}

type PatientInfoContext = {
  assessmentSlug: AssessmentSlug | undefined;
  auctionId: number;
  patientId: number;
};

export const PatientInfoSlugContext = React.createContext<PatientInfoContext>({
  assessmentSlug: ASSESSMENT_SLUG_FIRST_STEP,
  patientId: -1,
  auctionId: -1,
});

export function usePatientInfoSlug(): PatientInfoContext {
  return useContext(PatientInfoSlugContext);
}

export const INFO_WIDTH = 750;

export function CareproviderPatientInfos({
  auctionRequest,
  getOntology,
  goToRequests,
  printParams,
  requestAvailable,
}: {
  auctionRequest?: AuctionRequest | null | undefined;
  getOntology: GetOntologyType;
  goToRequests?: () => void;
  printParams: PrintParams;
  requestAvailable?: boolean;
}) {
  const { isTablet } = useMedia();
  const translations = useTranslations();
  const { ref } = useScrollToElement();

  if (!auctionRequest?.auction?.patient) {
    return null;
  }

  const auction = auctionRequest?.auction;
  const oldAuction = auctionRequest.old_auction;
  const careproviderId = auctionRequest.careprovider?.id;
  const isProviderSearchRequest = !!auctionRequest?.is_provider_search_request;

  return (
    <PatientInfoContext.Provider
      value={{
        Card: CareproviderInfoCard,
        withEdit: false,
        isClinicApp: false,
        auction,
      }}
    >
      <InfosBox>
        <div ref={isTablet ? (ref as ToType) : null} />

        {!isTablet && (
          <ActionBanner
            action={goToRequests}
            text={translations.auctionRequest.backToRequests}
            actions={
              printParams
                ? [
                    <PrintMessengerFab
                      key="print"
                      careproviderName={auctionRequest.careprovider?.name}
                      printParams={printParams}
                    />,
                  ]
                : [<PrintButton key="print" />]
            }
          />
        )}
        <OverviewCard
          auctionRequest={auctionRequest}
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
          requestAvailable={requestAvailable}
        />
        <TreatmentLocationCard
          component="AuctionRequest"
          auction={auction}
          oldAuction={oldAuction}
          show={hasMobile(auctionRequest.solutions)}
        />
        <InformationCard
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
        />
        <TransportSearchCard
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
        />
        <MedicalSuppliesSearchCard
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
        />
        <MedicalCard
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
        />
        <CareNeedsCard
          getOntology={getOntology}
          auction={auction}
          oldAuction={oldAuction}
        />
        {!isProviderSearchRequest && (
          <PatientInfoMissing
            careproviderId={careproviderId}
            auctionRequestId={auctionRequest.id}
          />
        )}
      </InfosBox>
    </PatientInfoContext.Provider>
  );
}

const EditButton = ({ auction }: { auction: Auction }) => {
  const translations = useTranslations();
  const { trackEvent } = useTracking<EditPatientButtonSchema>();
  const appNavigation = useCareseekerNavigationHandlers();
  return (
    <RSButton
      aria-label={translations.actions.edit}
      color="grey"
      id="go_to_assessing"
      LeftIcon={PencilIcon}
      loading="na"
      onClick={() => {
        trackEvent({
          name: TRACK_EVENTS.EDIT_PATIENT_BUTTON_CLICKED,
          auction_status: getAuctionStatusString(auction?.status),
        });
        appNavigation.patient.goToAssessing({
          patientId: auction.patient.id,
          auctionId: auction.id,
        });
      }}
      variant="text"
    >
      {translations.actions.edit}
    </RSButton>
  );
};

const PrintButtonV2 = () => {
  const print = usePrint();
  const translations = useTranslations();
  const { trackEvent } = useTracking();

  usePreventPrintMenu();

  if (print) return null;

  return (
    <RSButton
      aria-label={translations.actions.print}
      color="grey"
      id="print"
      LeftIcon={PrinterIcon}
      loading="na"
      onClick={() => {
        trackEvent({ name: TRACK_EVENTS.PRINT_BUTTON });
        openPrintPage(window);
      }}
      variant="text"
    >
      {translations.actions.print}
    </RSButton>
  );
};

export function ClinicPatientInfos({
  auction,
  getOntology,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
}) {
  const translations = useTranslations();
  return (
    <PatientInfoContext.Provider
      value={{
        Card: ClinicInfoCard,
        withEdit: true,
        isClinicApp: true,
        auction,
      }}
    >
      <VerticalLayout
        data-testid="patient-information"
        maxWidth={dp(INFO_WIDTH)}
        style={{ flex: 1, boxSizing: "border-box" }}
        width="100%"
      >
        <ClinicInfoCard noDivider>
          <HorizontalLayout aligned style={{ justifyContent: "space-between" }}>
            <Title>
              {translations.patient.patientInformation.stepperTitle}
            </Title>
            <HorizontalLayout margin={margin(0, 1)} aligned>
              <EditButton auction={auction} />
              <PrintButtonV2 />
            </HorizontalLayout>
          </HorizontalLayout>
        </ClinicInfoCard>

        <InformationCard getOntology={getOntology} auction={auction} />
        <TransportSearchCard getOntology={getOntology} auction={auction} />
        <MedicalSuppliesSearchCard
          getOntology={getOntology}
          auction={auction}
        />
        <MedicalCard getOntology={getOntology} auction={auction} forClinic />
        <CareNeedsCard getOntology={getOntology} auction={auction} />
        <TransportInfoCard auction={auction} getOntology={getOntology} />
      </VerticalLayout>
    </PatientInfoContext.Provider>
  );
}
